@charset "UTF-8";
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
calcapp-stepper {
  display: flex;
  flex-direction: column;
  padding-top: 2px;
  padding-bottom: 2px; }
  calcapp-stepper .button {
    flex: 1;
    min-height: 0;
    position: relative;
    background-color: transparent;
    border-width: 1px 0px 1px 1px;
    border-radius: 0;
    text-align: center;
    padding: 0;
    font-size: 20px;
    z-index: 0; }
    calcapp-stepper .button:focus {
      outline: thin dotted;
      outline-offset: 3px; }
    calcapp-stepper .button .background {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      opacity: 0;
      border-width: 1px 0px 1px 1px;
      border-color: inherit;
      border-radius: 0;
      transition: opacity 0.15s ease-in-out; }
      calcapp-stepper .button .background.is-activated {
        opacity: 1; }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Material Design Lite */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/*
 * What follows is the result of much research on cross-browser styling.
 * Credit left inline and big thanks to Nicolas Gallagher, Jonathan Neal,
 * Kroc Camen, and the H5BP dev community and team.
 */
/* ==========================================================================
   Base styles: opinionated defaults
   ========================================================================== */
html {
  color: rgba(0,0,0, 0.87);
  font-size: 1em;
  line-height: 1.4; }

/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * These selection rule sets have to be separate.
 * Customize the background color to match your design.
 */
::selection {
  background: #b3d4fc;
  text-shadow: none; }

/*
 * A better looking default horizontal rule
 */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

/*
 * Remove default fieldset styles.
 */
fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

/*
 * Allow only vertical resizing of textareas.
 */
textarea {
  resize: vertical; }

/* ==========================================================================
   Browser Upgrade Prompt
   ========================================================================== */
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

/* ==========================================================================
   Author's custom styles
   ========================================================================== */
/* ==========================================================================
   Helper classes
   ========================================================================== */
/*
 * Hide visually and from screen readers:
 */
.hidden {
  display: none !important; }

/*
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

/*
 * Extends the .visuallyhidden class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

/*
 * Hide visually and from screen readers, but maintain layout
 */
.invisible {
  visibility: hidden; }

/*
 * Clearfix: contain floats
 *
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    `contenteditable` attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that receive the `clearfix` class.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */
.clearfix:before,
.clearfix:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */ }

.clearfix:after {
  clear: both; }

/* ==========================================================================
   EXAMPLE Media Queries for Responsive Design.
   These examples override the primary ('mobile first') styles.
   Modify as content requires.
   ========================================================================== */
@media only screen and (min-width: 35em) {
  /* Style adjustments for viewports that meet the condition */ }

@media print, (min-resolution: 1.25dppx), (min-resolution: 120dpi) {
  /* Style adjustments for high resolution devices */ }

/* ==========================================================================
   Print styles.
   Inlined to avoid the additional HTTP request:
   http://www.phpied.com/delay-loading-your-print-css/
   ========================================================================== */
@media print {
  *,
  *:before,
  *:after,
  *:first-letter {
    background: transparent !important;
    color: #000 !important;
    /* Black prints faster: http://www.sanbeiji.com/archives/953 */
    box-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  /*
     * Don't show links that are fragment identifiers,
     * or use the `javascript:` pseudo protocol
     */
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: ""; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  /*
     * Printing Tables:
     * http://css-discuss.incutio.com/wiki/Printing_Tables
     */
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; } }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Remove the unwanted box around FAB buttons */
/* More info: http://goo.gl/IPwKi */
a, .mdl-accordion, .mdl-button, .mdl-card, .mdl-checkbox, .mdl-dropdown-menu,
.mdl-icon-toggle, .mdl-item, .mdl-radio, .mdl-slider, .mdl-switch, .mdl-tabs__tab {
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0); }

/*
 * Make html take up the entire screen
 * Then set touch-action to avoid touch delay on mobile IE
 */
html {
  width: 100%;
  height: 100%;
  -ms-touch-action: manipulation;
  touch-action: manipulation; }

/*
* Make body take up the entire screen
* Remove body margin so layout containers don't cause extra overflow.
*/
body {
  width: 100%;
  min-height: 100%;
  margin: 0; }

/*
 * Main display reset for IE support.
 * Source: http://weblog.west-wind.com/posts/2015/Jan/12/main-HTML5-Tag-not-working-in-Internet-Explorer-91011
 */
main {
  display: block; }

/*
* Apply no display to elements with the hidden attribute.
* IE 9 and 10 support.
*/
*[hidden] {
  display: none !important; }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
html, body {
  font-family: "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; }

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0; }

/**
  * Styles for HTML elements
  */
h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 56px;
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -0.02em;
  opacity: 0.54;
  font-size: 0.6em; }

h1 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 56px;
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -0.02em;
  margin-top: 24px;
  margin-bottom: 24px; }

h2 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 45px;
  font-weight: 400;
  line-height: 48px;
  margin-top: 24px;
  margin-bottom: 24px; }

h3 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
  margin-top: 24px;
  margin-bottom: 24px; }

h4 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 24px;
  margin-bottom: 16px; }

h5 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.02em;
  margin-top: 24px;
  margin-bottom: 16px; }

h6 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.04em;
  margin-top: 24px;
  margin-bottom: 16px; }

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  margin-bottom: 16px; }

a {
  color: rgb(194,24,91);
  font-weight: 500; }

blockquote {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  position: relative;
  font-size: 24px;
  font-weight: 300;
  font-style: italic;
  line-height: 1.35;
  letter-spacing: 0.08em; }
  blockquote:before {
    position: absolute;
    left: -0.5em;
    content: '“'; }
  blockquote:after {
    content: '”';
    margin-left: -0.05em; }

mark {
  background-color: #f4ff81; }

dt {
  font-weight: 700; }

address {
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
  font-style: normal; }

ul, ol {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0; }

/**
 * Class Name Styles
 */
.mdl-typography--display-4 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 112px;
  font-weight: 300;
  line-height: 1;
  letter-spacing: -0.04em; }

.mdl-typography--display-4-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 112px;
  font-weight: 300;
  line-height: 1;
  letter-spacing: -0.04em;
  opacity: 0.54; }

.mdl-typography--display-3 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 56px;
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -0.02em; }

.mdl-typography--display-3-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 56px;
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -0.02em;
  opacity: 0.54; }

.mdl-typography--display-2 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 45px;
  font-weight: 400;
  line-height: 48px; }

.mdl-typography--display-2-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 45px;
  font-weight: 400;
  line-height: 48px;
  opacity: 0.54; }

.mdl-typography--display-1 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 34px;
  font-weight: 400;
  line-height: 40px; }

.mdl-typography--display-1-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
  opacity: 0.54; }

.mdl-typography--headline {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  -moz-osx-font-smoothing: grayscale; }

.mdl-typography--headline-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  -moz-osx-font-smoothing: grayscale;
  opacity: 0.87; }

.mdl-typography--title {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.02em; }

.mdl-typography--title-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.02em;
  opacity: 0.87; }

.mdl-typography--subhead {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.04em; }

.mdl-typography--subhead-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.04em;
  opacity: 0.87; }

.mdl-typography--body-2 {
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0; }

.mdl-typography--body-2-color-contrast {
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0;
  opacity: 0.87; }

.mdl-typography--body-1 {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0; }

.mdl-typography--body-1-color-contrast {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  opacity: 0.87; }

.mdl-typography--body-2-force-preferred-font {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0; }

.mdl-typography--body-2-force-preferred-font-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  opacity: 0.87; }

.mdl-typography--body-1-force-preferred-font {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0; }

.mdl-typography--body-1-force-preferred-font-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  opacity: 0.87; }

.mdl-typography--caption {
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0; }

.mdl-typography--caption-force-preferred-font {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0; }

.mdl-typography--caption-color-contrast {
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
  opacity: 0.54; }

.mdl-typography--caption-force-preferred-font-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
  opacity: 0.54; }

.mdl-typography--menu {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0; }

.mdl-typography--menu-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0;
  opacity: 0.87; }

.mdl-typography--button {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 0; }

.mdl-typography--button-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 0;
  opacity: 0.87; }

.mdl-typography--text-left {
  text-align: left; }

.mdl-typography--text-right {
  text-align: right; }

.mdl-typography--text-center {
  text-align: center; }

.mdl-typography--text-justify {
  text-align: justify; }

.mdl-typography--text-nowrap {
  white-space: nowrap; }

.mdl-typography--text-lowercase {
  text-transform: lowercase; }

.mdl-typography--text-uppercase {
  text-transform: uppercase; }

.mdl-typography--text-capitalize {
  text-transform: capitalize; }

.mdl-typography--font-thin {
  font-weight: 200 !important; }

.mdl-typography--font-light {
  font-weight: 300 !important; }

.mdl-typography--font-regular {
  font-weight: 400 !important; }

.mdl-typography--font-medium {
  font-weight: 500 !important; }

.mdl-typography--font-bold {
  font-weight: 700 !important; }

.mdl-typography--font-black {
  font-weight: 900 !important; }

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  word-wrap: normal;
  font-feature-settings: 'liga';
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased; }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
.mdl-color-text--red {
  color: rgb(244,67,54) !important; }

.mdl-color--red {
  background-color: rgb(244,67,54) !important; }

.mdl-color-text--red-50 {
  color: rgb(255,235,238) !important; }

.mdl-color--red-50 {
  background-color: rgb(255,235,238) !important; }

.mdl-color-text--red-100 {
  color: rgb(255,205,210) !important; }

.mdl-color--red-100 {
  background-color: rgb(255,205,210) !important; }

.mdl-color-text--red-200 {
  color: rgb(239,154,154) !important; }

.mdl-color--red-200 {
  background-color: rgb(239,154,154) !important; }

.mdl-color-text--red-300 {
  color: rgb(229,115,115) !important; }

.mdl-color--red-300 {
  background-color: rgb(229,115,115) !important; }

.mdl-color-text--red-400 {
  color: rgb(239,83,80) !important; }

.mdl-color--red-400 {
  background-color: rgb(239,83,80) !important; }

.mdl-color-text--red-500 {
  color: rgb(244,67,54) !important; }

.mdl-color--red-500 {
  background-color: rgb(244,67,54) !important; }

.mdl-color-text--red-600 {
  color: rgb(229,57,53) !important; }

.mdl-color--red-600 {
  background-color: rgb(229,57,53) !important; }

.mdl-color-text--red-700 {
  color: rgb(211,47,47) !important; }

.mdl-color--red-700 {
  background-color: rgb(211,47,47) !important; }

.mdl-color-text--red-800 {
  color: rgb(198,40,40) !important; }

.mdl-color--red-800 {
  background-color: rgb(198,40,40) !important; }

.mdl-color-text--red-900 {
  color: rgb(183,28,28) !important; }

.mdl-color--red-900 {
  background-color: rgb(183,28,28) !important; }

.mdl-color-text--red-A100 {
  color: rgb(255,138,128) !important; }

.mdl-color--red-A100 {
  background-color: rgb(255,138,128) !important; }

.mdl-color-text--red-A200 {
  color: rgb(255,82,82) !important; }

.mdl-color--red-A200 {
  background-color: rgb(255,82,82) !important; }

.mdl-color-text--red-A400 {
  color: rgb(255,23,68) !important; }

.mdl-color--red-A400 {
  background-color: rgb(255,23,68) !important; }

.mdl-color-text--red-A700 {
  color: rgb(213,0,0) !important; }

.mdl-color--red-A700 {
  background-color: rgb(213,0,0) !important; }

.mdl-color-text--pink {
  color: rgb(233,30,99) !important; }

.mdl-color--pink {
  background-color: rgb(233,30,99) !important; }

.mdl-color-text--pink-50 {
  color: rgb(252,228,236) !important; }

.mdl-color--pink-50 {
  background-color: rgb(252,228,236) !important; }

.mdl-color-text--pink-100 {
  color: rgb(248,187,208) !important; }

.mdl-color--pink-100 {
  background-color: rgb(248,187,208) !important; }

.mdl-color-text--pink-200 {
  color: rgb(244,143,177) !important; }

.mdl-color--pink-200 {
  background-color: rgb(244,143,177) !important; }

.mdl-color-text--pink-300 {
  color: rgb(240,98,146) !important; }

.mdl-color--pink-300 {
  background-color: rgb(240,98,146) !important; }

.mdl-color-text--pink-400 {
  color: rgb(236,64,122) !important; }

.mdl-color--pink-400 {
  background-color: rgb(236,64,122) !important; }

.mdl-color-text--pink-500 {
  color: rgb(233,30,99) !important; }

.mdl-color--pink-500 {
  background-color: rgb(233,30,99) !important; }

.mdl-color-text--pink-600 {
  color: rgb(216,27,96) !important; }

.mdl-color--pink-600 {
  background-color: rgb(216,27,96) !important; }

.mdl-color-text--pink-700 {
  color: rgb(194,24,91) !important; }

.mdl-color--pink-700 {
  background-color: rgb(194,24,91) !important; }

.mdl-color-text--pink-800 {
  color: rgb(173,20,87) !important; }

.mdl-color--pink-800 {
  background-color: rgb(173,20,87) !important; }

.mdl-color-text--pink-900 {
  color: rgb(136,14,79) !important; }

.mdl-color--pink-900 {
  background-color: rgb(136,14,79) !important; }

.mdl-color-text--pink-A100 {
  color: rgb(255,128,171) !important; }

.mdl-color--pink-A100 {
  background-color: rgb(255,128,171) !important; }

.mdl-color-text--pink-A200 {
  color: rgb(255,64,129) !important; }

.mdl-color--pink-A200 {
  background-color: rgb(255,64,129) !important; }

.mdl-color-text--pink-A400 {
  color: rgb(245,0,87) !important; }

.mdl-color--pink-A400 {
  background-color: rgb(245,0,87) !important; }

.mdl-color-text--pink-A700 {
  color: rgb(197,17,98) !important; }

.mdl-color--pink-A700 {
  background-color: rgb(197,17,98) !important; }

.mdl-color-text--purple {
  color: rgb(156,39,176) !important; }

.mdl-color--purple {
  background-color: rgb(156,39,176) !important; }

.mdl-color-text--purple-50 {
  color: rgb(243,229,245) !important; }

.mdl-color--purple-50 {
  background-color: rgb(243,229,245) !important; }

.mdl-color-text--purple-100 {
  color: rgb(225,190,231) !important; }

.mdl-color--purple-100 {
  background-color: rgb(225,190,231) !important; }

.mdl-color-text--purple-200 {
  color: rgb(206,147,216) !important; }

.mdl-color--purple-200 {
  background-color: rgb(206,147,216) !important; }

.mdl-color-text--purple-300 {
  color: rgb(186,104,200) !important; }

.mdl-color--purple-300 {
  background-color: rgb(186,104,200) !important; }

.mdl-color-text--purple-400 {
  color: rgb(171,71,188) !important; }

.mdl-color--purple-400 {
  background-color: rgb(171,71,188) !important; }

.mdl-color-text--purple-500 {
  color: rgb(156,39,176) !important; }

.mdl-color--purple-500 {
  background-color: rgb(156,39,176) !important; }

.mdl-color-text--purple-600 {
  color: rgb(142,36,170) !important; }

.mdl-color--purple-600 {
  background-color: rgb(142,36,170) !important; }

.mdl-color-text--purple-700 {
  color: rgb(123,31,162) !important; }

.mdl-color--purple-700 {
  background-color: rgb(123,31,162) !important; }

.mdl-color-text--purple-800 {
  color: rgb(106,27,154) !important; }

.mdl-color--purple-800 {
  background-color: rgb(106,27,154) !important; }

.mdl-color-text--purple-900 {
  color: rgb(74,20,140) !important; }

.mdl-color--purple-900 {
  background-color: rgb(74,20,140) !important; }

.mdl-color-text--purple-A100 {
  color: rgb(234,128,252) !important; }

.mdl-color--purple-A100 {
  background-color: rgb(234,128,252) !important; }

.mdl-color-text--purple-A200 {
  color: rgb(224,64,251) !important; }

.mdl-color--purple-A200 {
  background-color: rgb(224,64,251) !important; }

.mdl-color-text--purple-A400 {
  color: rgb(213,0,249) !important; }

.mdl-color--purple-A400 {
  background-color: rgb(213,0,249) !important; }

.mdl-color-text--purple-A700 {
  color: rgb(170,0,255) !important; }

.mdl-color--purple-A700 {
  background-color: rgb(170,0,255) !important; }

.mdl-color-text--deep-purple {
  color: rgb(103,58,183) !important; }

.mdl-color--deep-purple {
  background-color: rgb(103,58,183) !important; }

.mdl-color-text--deep-purple-50 {
  color: rgb(237,231,246) !important; }

.mdl-color--deep-purple-50 {
  background-color: rgb(237,231,246) !important; }

.mdl-color-text--deep-purple-100 {
  color: rgb(209,196,233) !important; }

.mdl-color--deep-purple-100 {
  background-color: rgb(209,196,233) !important; }

.mdl-color-text--deep-purple-200 {
  color: rgb(179,157,219) !important; }

.mdl-color--deep-purple-200 {
  background-color: rgb(179,157,219) !important; }

.mdl-color-text--deep-purple-300 {
  color: rgb(149,117,205) !important; }

.mdl-color--deep-purple-300 {
  background-color: rgb(149,117,205) !important; }

.mdl-color-text--deep-purple-400 {
  color: rgb(126,87,194) !important; }

.mdl-color--deep-purple-400 {
  background-color: rgb(126,87,194) !important; }

.mdl-color-text--deep-purple-500 {
  color: rgb(103,58,183) !important; }

.mdl-color--deep-purple-500 {
  background-color: rgb(103,58,183) !important; }

.mdl-color-text--deep-purple-600 {
  color: rgb(94,53,177) !important; }

.mdl-color--deep-purple-600 {
  background-color: rgb(94,53,177) !important; }

.mdl-color-text--deep-purple-700 {
  color: rgb(81,45,168) !important; }

.mdl-color--deep-purple-700 {
  background-color: rgb(81,45,168) !important; }

.mdl-color-text--deep-purple-800 {
  color: rgb(69,39,160) !important; }

.mdl-color--deep-purple-800 {
  background-color: rgb(69,39,160) !important; }

.mdl-color-text--deep-purple-900 {
  color: rgb(49,27,146) !important; }

.mdl-color--deep-purple-900 {
  background-color: rgb(49,27,146) !important; }

.mdl-color-text--deep-purple-A100 {
  color: rgb(179,136,255) !important; }

.mdl-color--deep-purple-A100 {
  background-color: rgb(179,136,255) !important; }

.mdl-color-text--deep-purple-A200 {
  color: rgb(124,77,255) !important; }

.mdl-color--deep-purple-A200 {
  background-color: rgb(124,77,255) !important; }

.mdl-color-text--deep-purple-A400 {
  color: rgb(101,31,255) !important; }

.mdl-color--deep-purple-A400 {
  background-color: rgb(101,31,255) !important; }

.mdl-color-text--deep-purple-A700 {
  color: rgb(98,0,234) !important; }

.mdl-color--deep-purple-A700 {
  background-color: rgb(98,0,234) !important; }

.mdl-color-text--indigo {
  color: rgb(63,81,181) !important; }

.mdl-color--indigo {
  background-color: rgb(63,81,181) !important; }

.mdl-color-text--indigo-50 {
  color: rgb(232,234,246) !important; }

.mdl-color--indigo-50 {
  background-color: rgb(232,234,246) !important; }

.mdl-color-text--indigo-100 {
  color: rgb(197,202,233) !important; }

.mdl-color--indigo-100 {
  background-color: rgb(197,202,233) !important; }

.mdl-color-text--indigo-200 {
  color: rgb(159,168,218) !important; }

.mdl-color--indigo-200 {
  background-color: rgb(159,168,218) !important; }

.mdl-color-text--indigo-300 {
  color: rgb(121,134,203) !important; }

.mdl-color--indigo-300 {
  background-color: rgb(121,134,203) !important; }

.mdl-color-text--indigo-400 {
  color: rgb(92,107,192) !important; }

.mdl-color--indigo-400 {
  background-color: rgb(92,107,192) !important; }

.mdl-color-text--indigo-500 {
  color: rgb(63,81,181) !important; }

.mdl-color--indigo-500 {
  background-color: rgb(63,81,181) !important; }

.mdl-color-text--indigo-600 {
  color: rgb(57,73,171) !important; }

.mdl-color--indigo-600 {
  background-color: rgb(57,73,171) !important; }

.mdl-color-text--indigo-700 {
  color: rgb(48,63,159) !important; }

.mdl-color--indigo-700 {
  background-color: rgb(48,63,159) !important; }

.mdl-color-text--indigo-800 {
  color: rgb(40,53,147) !important; }

.mdl-color--indigo-800 {
  background-color: rgb(40,53,147) !important; }

.mdl-color-text--indigo-900 {
  color: rgb(26,35,126) !important; }

.mdl-color--indigo-900 {
  background-color: rgb(26,35,126) !important; }

.mdl-color-text--indigo-A100 {
  color: rgb(140,158,255) !important; }

.mdl-color--indigo-A100 {
  background-color: rgb(140,158,255) !important; }

.mdl-color-text--indigo-A200 {
  color: rgb(83,109,254) !important; }

.mdl-color--indigo-A200 {
  background-color: rgb(83,109,254) !important; }

.mdl-color-text--indigo-A400 {
  color: rgb(61,90,254) !important; }

.mdl-color--indigo-A400 {
  background-color: rgb(61,90,254) !important; }

.mdl-color-text--indigo-A700 {
  color: rgb(48,79,254) !important; }

.mdl-color--indigo-A700 {
  background-color: rgb(48,79,254) !important; }

.mdl-color-text--blue {
  color: rgb(33,150,243) !important; }

.mdl-color--blue {
  background-color: rgb(33,150,243) !important; }

.mdl-color-text--blue-50 {
  color: rgb(227,242,253) !important; }

.mdl-color--blue-50 {
  background-color: rgb(227,242,253) !important; }

.mdl-color-text--blue-100 {
  color: rgb(187,222,251) !important; }

.mdl-color--blue-100 {
  background-color: rgb(187,222,251) !important; }

.mdl-color-text--blue-200 {
  color: rgb(144,202,249) !important; }

.mdl-color--blue-200 {
  background-color: rgb(144,202,249) !important; }

.mdl-color-text--blue-300 {
  color: rgb(100,181,246) !important; }

.mdl-color--blue-300 {
  background-color: rgb(100,181,246) !important; }

.mdl-color-text--blue-400 {
  color: rgb(66,165,245) !important; }

.mdl-color--blue-400 {
  background-color: rgb(66,165,245) !important; }

.mdl-color-text--blue-500 {
  color: rgb(33,150,243) !important; }

.mdl-color--blue-500 {
  background-color: rgb(33,150,243) !important; }

.mdl-color-text--blue-600 {
  color: rgb(30,136,229) !important; }

.mdl-color--blue-600 {
  background-color: rgb(30,136,229) !important; }

.mdl-color-text--blue-700 {
  color: rgb(25,118,210) !important; }

.mdl-color--blue-700 {
  background-color: rgb(25,118,210) !important; }

.mdl-color-text--blue-800 {
  color: rgb(21,101,192) !important; }

.mdl-color--blue-800 {
  background-color: rgb(21,101,192) !important; }

.mdl-color-text--blue-900 {
  color: rgb(13,71,161) !important; }

.mdl-color--blue-900 {
  background-color: rgb(13,71,161) !important; }

.mdl-color-text--blue-A100 {
  color: rgb(130,177,255) !important; }

.mdl-color--blue-A100 {
  background-color: rgb(130,177,255) !important; }

.mdl-color-text--blue-A200 {
  color: rgb(68,138,255) !important; }

.mdl-color--blue-A200 {
  background-color: rgb(68,138,255) !important; }

.mdl-color-text--blue-A400 {
  color: rgb(41,121,255) !important; }

.mdl-color--blue-A400 {
  background-color: rgb(41,121,255) !important; }

.mdl-color-text--blue-A700 {
  color: rgb(41,98,255) !important; }

.mdl-color--blue-A700 {
  background-color: rgb(41,98,255) !important; }

.mdl-color-text--light-blue {
  color: rgb(3,169,244) !important; }

.mdl-color--light-blue {
  background-color: rgb(3,169,244) !important; }

.mdl-color-text--light-blue-50 {
  color: rgb(225,245,254) !important; }

.mdl-color--light-blue-50 {
  background-color: rgb(225,245,254) !important; }

.mdl-color-text--light-blue-100 {
  color: rgb(179,229,252) !important; }

.mdl-color--light-blue-100 {
  background-color: rgb(179,229,252) !important; }

.mdl-color-text--light-blue-200 {
  color: rgb(129,212,250) !important; }

.mdl-color--light-blue-200 {
  background-color: rgb(129,212,250) !important; }

.mdl-color-text--light-blue-300 {
  color: rgb(79,195,247) !important; }

.mdl-color--light-blue-300 {
  background-color: rgb(79,195,247) !important; }

.mdl-color-text--light-blue-400 {
  color: rgb(41,182,246) !important; }

.mdl-color--light-blue-400 {
  background-color: rgb(41,182,246) !important; }

.mdl-color-text--light-blue-500 {
  color: rgb(3,169,244) !important; }

.mdl-color--light-blue-500 {
  background-color: rgb(3,169,244) !important; }

.mdl-color-text--light-blue-600 {
  color: rgb(3,155,229) !important; }

.mdl-color--light-blue-600 {
  background-color: rgb(3,155,229) !important; }

.mdl-color-text--light-blue-700 {
  color: rgb(2,136,209) !important; }

.mdl-color--light-blue-700 {
  background-color: rgb(2,136,209) !important; }

.mdl-color-text--light-blue-800 {
  color: rgb(2,119,189) !important; }

.mdl-color--light-blue-800 {
  background-color: rgb(2,119,189) !important; }

.mdl-color-text--light-blue-900 {
  color: rgb(1,87,155) !important; }

.mdl-color--light-blue-900 {
  background-color: rgb(1,87,155) !important; }

.mdl-color-text--light-blue-A100 {
  color: rgb(128,216,255) !important; }

.mdl-color--light-blue-A100 {
  background-color: rgb(128,216,255) !important; }

.mdl-color-text--light-blue-A200 {
  color: rgb(64,196,255) !important; }

.mdl-color--light-blue-A200 {
  background-color: rgb(64,196,255) !important; }

.mdl-color-text--light-blue-A400 {
  color: rgb(0,176,255) !important; }

.mdl-color--light-blue-A400 {
  background-color: rgb(0,176,255) !important; }

.mdl-color-text--light-blue-A700 {
  color: rgb(0,145,234) !important; }

.mdl-color--light-blue-A700 {
  background-color: rgb(0,145,234) !important; }

.mdl-color-text--cyan {
  color: rgb(0,188,212) !important; }

.mdl-color--cyan {
  background-color: rgb(0,188,212) !important; }

.mdl-color-text--cyan-50 {
  color: rgb(224,247,250) !important; }

.mdl-color--cyan-50 {
  background-color: rgb(224,247,250) !important; }

.mdl-color-text--cyan-100 {
  color: rgb(178,235,242) !important; }

.mdl-color--cyan-100 {
  background-color: rgb(178,235,242) !important; }

.mdl-color-text--cyan-200 {
  color: rgb(128,222,234) !important; }

.mdl-color--cyan-200 {
  background-color: rgb(128,222,234) !important; }

.mdl-color-text--cyan-300 {
  color: rgb(77,208,225) !important; }

.mdl-color--cyan-300 {
  background-color: rgb(77,208,225) !important; }

.mdl-color-text--cyan-400 {
  color: rgb(38,198,218) !important; }

.mdl-color--cyan-400 {
  background-color: rgb(38,198,218) !important; }

.mdl-color-text--cyan-500 {
  color: rgb(0,188,212) !important; }

.mdl-color--cyan-500 {
  background-color: rgb(0,188,212) !important; }

.mdl-color-text--cyan-600 {
  color: rgb(0,172,193) !important; }

.mdl-color--cyan-600 {
  background-color: rgb(0,172,193) !important; }

.mdl-color-text--cyan-700 {
  color: rgb(0,151,167) !important; }

.mdl-color--cyan-700 {
  background-color: rgb(0,151,167) !important; }

.mdl-color-text--cyan-800 {
  color: rgb(0,131,143) !important; }

.mdl-color--cyan-800 {
  background-color: rgb(0,131,143) !important; }

.mdl-color-text--cyan-900 {
  color: rgb(0,96,100) !important; }

.mdl-color--cyan-900 {
  background-color: rgb(0,96,100) !important; }

.mdl-color-text--cyan-A100 {
  color: rgb(132,255,255) !important; }

.mdl-color--cyan-A100 {
  background-color: rgb(132,255,255) !important; }

.mdl-color-text--cyan-A200 {
  color: rgb(24,255,255) !important; }

.mdl-color--cyan-A200 {
  background-color: rgb(24,255,255) !important; }

.mdl-color-text--cyan-A400 {
  color: rgb(0,229,255) !important; }

.mdl-color--cyan-A400 {
  background-color: rgb(0,229,255) !important; }

.mdl-color-text--cyan-A700 {
  color: rgb(0,184,212) !important; }

.mdl-color--cyan-A700 {
  background-color: rgb(0,184,212) !important; }

.mdl-color-text--teal {
  color: rgb(0,150,136) !important; }

.mdl-color--teal {
  background-color: rgb(0,150,136) !important; }

.mdl-color-text--teal-50 {
  color: rgb(224,242,241) !important; }

.mdl-color--teal-50 {
  background-color: rgb(224,242,241) !important; }

.mdl-color-text--teal-100 {
  color: rgb(178,223,219) !important; }

.mdl-color--teal-100 {
  background-color: rgb(178,223,219) !important; }

.mdl-color-text--teal-200 {
  color: rgb(128,203,196) !important; }

.mdl-color--teal-200 {
  background-color: rgb(128,203,196) !important; }

.mdl-color-text--teal-300 {
  color: rgb(77,182,172) !important; }

.mdl-color--teal-300 {
  background-color: rgb(77,182,172) !important; }

.mdl-color-text--teal-400 {
  color: rgb(38,166,154) !important; }

.mdl-color--teal-400 {
  background-color: rgb(38,166,154) !important; }

.mdl-color-text--teal-500 {
  color: rgb(0,150,136) !important; }

.mdl-color--teal-500 {
  background-color: rgb(0,150,136) !important; }

.mdl-color-text--teal-600 {
  color: rgb(0,137,123) !important; }

.mdl-color--teal-600 {
  background-color: rgb(0,137,123) !important; }

.mdl-color-text--teal-700 {
  color: rgb(0,121,107) !important; }

.mdl-color--teal-700 {
  background-color: rgb(0,121,107) !important; }

.mdl-color-text--teal-800 {
  color: rgb(0,105,92) !important; }

.mdl-color--teal-800 {
  background-color: rgb(0,105,92) !important; }

.mdl-color-text--teal-900 {
  color: rgb(0,77,64) !important; }

.mdl-color--teal-900 {
  background-color: rgb(0,77,64) !important; }

.mdl-color-text--teal-A100 {
  color: rgb(167,255,235) !important; }

.mdl-color--teal-A100 {
  background-color: rgb(167,255,235) !important; }

.mdl-color-text--teal-A200 {
  color: rgb(100,255,218) !important; }

.mdl-color--teal-A200 {
  background-color: rgb(100,255,218) !important; }

.mdl-color-text--teal-A400 {
  color: rgb(29,233,182) !important; }

.mdl-color--teal-A400 {
  background-color: rgb(29,233,182) !important; }

.mdl-color-text--teal-A700 {
  color: rgb(0,191,165) !important; }

.mdl-color--teal-A700 {
  background-color: rgb(0,191,165) !important; }

.mdl-color-text--green {
  color: rgb(76,175,80) !important; }

.mdl-color--green {
  background-color: rgb(76,175,80) !important; }

.mdl-color-text--green-50 {
  color: rgb(232,245,233) !important; }

.mdl-color--green-50 {
  background-color: rgb(232,245,233) !important; }

.mdl-color-text--green-100 {
  color: rgb(200,230,201) !important; }

.mdl-color--green-100 {
  background-color: rgb(200,230,201) !important; }

.mdl-color-text--green-200 {
  color: rgb(165,214,167) !important; }

.mdl-color--green-200 {
  background-color: rgb(165,214,167) !important; }

.mdl-color-text--green-300 {
  color: rgb(129,199,132) !important; }

.mdl-color--green-300 {
  background-color: rgb(129,199,132) !important; }

.mdl-color-text--green-400 {
  color: rgb(102,187,106) !important; }

.mdl-color--green-400 {
  background-color: rgb(102,187,106) !important; }

.mdl-color-text--green-500 {
  color: rgb(76,175,80) !important; }

.mdl-color--green-500 {
  background-color: rgb(76,175,80) !important; }

.mdl-color-text--green-600 {
  color: rgb(67,160,71) !important; }

.mdl-color--green-600 {
  background-color: rgb(67,160,71) !important; }

.mdl-color-text--green-700 {
  color: rgb(56,142,60) !important; }

.mdl-color--green-700 {
  background-color: rgb(56,142,60) !important; }

.mdl-color-text--green-800 {
  color: rgb(46,125,50) !important; }

.mdl-color--green-800 {
  background-color: rgb(46,125,50) !important; }

.mdl-color-text--green-900 {
  color: rgb(27,94,32) !important; }

.mdl-color--green-900 {
  background-color: rgb(27,94,32) !important; }

.mdl-color-text--green-A100 {
  color: rgb(185,246,202) !important; }

.mdl-color--green-A100 {
  background-color: rgb(185,246,202) !important; }

.mdl-color-text--green-A200 {
  color: rgb(105,240,174) !important; }

.mdl-color--green-A200 {
  background-color: rgb(105,240,174) !important; }

.mdl-color-text--green-A400 {
  color: rgb(0,230,118) !important; }

.mdl-color--green-A400 {
  background-color: rgb(0,230,118) !important; }

.mdl-color-text--green-A700 {
  color: rgb(0,200,83) !important; }

.mdl-color--green-A700 {
  background-color: rgb(0,200,83) !important; }

.mdl-color-text--light-green {
  color: rgb(139,195,74) !important; }

.mdl-color--light-green {
  background-color: rgb(139,195,74) !important; }

.mdl-color-text--light-green-50 {
  color: rgb(241,248,233) !important; }

.mdl-color--light-green-50 {
  background-color: rgb(241,248,233) !important; }

.mdl-color-text--light-green-100 {
  color: rgb(220,237,200) !important; }

.mdl-color--light-green-100 {
  background-color: rgb(220,237,200) !important; }

.mdl-color-text--light-green-200 {
  color: rgb(197,225,165) !important; }

.mdl-color--light-green-200 {
  background-color: rgb(197,225,165) !important; }

.mdl-color-text--light-green-300 {
  color: rgb(174,213,129) !important; }

.mdl-color--light-green-300 {
  background-color: rgb(174,213,129) !important; }

.mdl-color-text--light-green-400 {
  color: rgb(156,204,101) !important; }

.mdl-color--light-green-400 {
  background-color: rgb(156,204,101) !important; }

.mdl-color-text--light-green-500 {
  color: rgb(139,195,74) !important; }

.mdl-color--light-green-500 {
  background-color: rgb(139,195,74) !important; }

.mdl-color-text--light-green-600 {
  color: rgb(124,179,66) !important; }

.mdl-color--light-green-600 {
  background-color: rgb(124,179,66) !important; }

.mdl-color-text--light-green-700 {
  color: rgb(104,159,56) !important; }

.mdl-color--light-green-700 {
  background-color: rgb(104,159,56) !important; }

.mdl-color-text--light-green-800 {
  color: rgb(85,139,47) !important; }

.mdl-color--light-green-800 {
  background-color: rgb(85,139,47) !important; }

.mdl-color-text--light-green-900 {
  color: rgb(51,105,30) !important; }

.mdl-color--light-green-900 {
  background-color: rgb(51,105,30) !important; }

.mdl-color-text--light-green-A100 {
  color: rgb(204,255,144) !important; }

.mdl-color--light-green-A100 {
  background-color: rgb(204,255,144) !important; }

.mdl-color-text--light-green-A200 {
  color: rgb(178,255,89) !important; }

.mdl-color--light-green-A200 {
  background-color: rgb(178,255,89) !important; }

.mdl-color-text--light-green-A400 {
  color: rgb(118,255,3) !important; }

.mdl-color--light-green-A400 {
  background-color: rgb(118,255,3) !important; }

.mdl-color-text--light-green-A700 {
  color: rgb(100,221,23) !important; }

.mdl-color--light-green-A700 {
  background-color: rgb(100,221,23) !important; }

.mdl-color-text--lime {
  color: rgb(205,220,57) !important; }

.mdl-color--lime {
  background-color: rgb(205,220,57) !important; }

.mdl-color-text--lime-50 {
  color: rgb(249,251,231) !important; }

.mdl-color--lime-50 {
  background-color: rgb(249,251,231) !important; }

.mdl-color-text--lime-100 {
  color: rgb(240,244,195) !important; }

.mdl-color--lime-100 {
  background-color: rgb(240,244,195) !important; }

.mdl-color-text--lime-200 {
  color: rgb(230,238,156) !important; }

.mdl-color--lime-200 {
  background-color: rgb(230,238,156) !important; }

.mdl-color-text--lime-300 {
  color: rgb(220,231,117) !important; }

.mdl-color--lime-300 {
  background-color: rgb(220,231,117) !important; }

.mdl-color-text--lime-400 {
  color: rgb(212,225,87) !important; }

.mdl-color--lime-400 {
  background-color: rgb(212,225,87) !important; }

.mdl-color-text--lime-500 {
  color: rgb(205,220,57) !important; }

.mdl-color--lime-500 {
  background-color: rgb(205,220,57) !important; }

.mdl-color-text--lime-600 {
  color: rgb(192,202,51) !important; }

.mdl-color--lime-600 {
  background-color: rgb(192,202,51) !important; }

.mdl-color-text--lime-700 {
  color: rgb(175,180,43) !important; }

.mdl-color--lime-700 {
  background-color: rgb(175,180,43) !important; }

.mdl-color-text--lime-800 {
  color: rgb(158,157,36) !important; }

.mdl-color--lime-800 {
  background-color: rgb(158,157,36) !important; }

.mdl-color-text--lime-900 {
  color: rgb(130,119,23) !important; }

.mdl-color--lime-900 {
  background-color: rgb(130,119,23) !important; }

.mdl-color-text--lime-A100 {
  color: rgb(244,255,129) !important; }

.mdl-color--lime-A100 {
  background-color: rgb(244,255,129) !important; }

.mdl-color-text--lime-A200 {
  color: rgb(238,255,65) !important; }

.mdl-color--lime-A200 {
  background-color: rgb(238,255,65) !important; }

.mdl-color-text--lime-A400 {
  color: rgb(198,255,0) !important; }

.mdl-color--lime-A400 {
  background-color: rgb(198,255,0) !important; }

.mdl-color-text--lime-A700 {
  color: rgb(174,234,0) !important; }

.mdl-color--lime-A700 {
  background-color: rgb(174,234,0) !important; }

.mdl-color-text--yellow {
  color: rgb(255,235,59) !important; }

.mdl-color--yellow {
  background-color: rgb(255,235,59) !important; }

.mdl-color-text--yellow-50 {
  color: rgb(255,253,231) !important; }

.mdl-color--yellow-50 {
  background-color: rgb(255,253,231) !important; }

.mdl-color-text--yellow-100 {
  color: rgb(255,249,196) !important; }

.mdl-color--yellow-100 {
  background-color: rgb(255,249,196) !important; }

.mdl-color-text--yellow-200 {
  color: rgb(255,245,157) !important; }

.mdl-color--yellow-200 {
  background-color: rgb(255,245,157) !important; }

.mdl-color-text--yellow-300 {
  color: rgb(255,241,118) !important; }

.mdl-color--yellow-300 {
  background-color: rgb(255,241,118) !important; }

.mdl-color-text--yellow-400 {
  color: rgb(255,238,88) !important; }

.mdl-color--yellow-400 {
  background-color: rgb(255,238,88) !important; }

.mdl-color-text--yellow-500 {
  color: rgb(255,235,59) !important; }

.mdl-color--yellow-500 {
  background-color: rgb(255,235,59) !important; }

.mdl-color-text--yellow-600 {
  color: rgb(253,216,53) !important; }

.mdl-color--yellow-600 {
  background-color: rgb(253,216,53) !important; }

.mdl-color-text--yellow-700 {
  color: rgb(251,192,45) !important; }

.mdl-color--yellow-700 {
  background-color: rgb(251,192,45) !important; }

.mdl-color-text--yellow-800 {
  color: rgb(249,168,37) !important; }

.mdl-color--yellow-800 {
  background-color: rgb(249,168,37) !important; }

.mdl-color-text--yellow-900 {
  color: rgb(245,127,23) !important; }

.mdl-color--yellow-900 {
  background-color: rgb(245,127,23) !important; }

.mdl-color-text--yellow-A100 {
  color: rgb(255,255,141) !important; }

.mdl-color--yellow-A100 {
  background-color: rgb(255,255,141) !important; }

.mdl-color-text--yellow-A200 {
  color: rgb(255,255,0) !important; }

.mdl-color--yellow-A200 {
  background-color: rgb(255,255,0) !important; }

.mdl-color-text--yellow-A400 {
  color: rgb(255,234,0) !important; }

.mdl-color--yellow-A400 {
  background-color: rgb(255,234,0) !important; }

.mdl-color-text--yellow-A700 {
  color: rgb(255,214,0) !important; }

.mdl-color--yellow-A700 {
  background-color: rgb(255,214,0) !important; }

.mdl-color-text--amber {
  color: rgb(255,193,7) !important; }

.mdl-color--amber {
  background-color: rgb(255,193,7) !important; }

.mdl-color-text--amber-50 {
  color: rgb(255,248,225) !important; }

.mdl-color--amber-50 {
  background-color: rgb(255,248,225) !important; }

.mdl-color-text--amber-100 {
  color: rgb(255,236,179) !important; }

.mdl-color--amber-100 {
  background-color: rgb(255,236,179) !important; }

.mdl-color-text--amber-200 {
  color: rgb(255,224,130) !important; }

.mdl-color--amber-200 {
  background-color: rgb(255,224,130) !important; }

.mdl-color-text--amber-300 {
  color: rgb(255,213,79) !important; }

.mdl-color--amber-300 {
  background-color: rgb(255,213,79) !important; }

.mdl-color-text--amber-400 {
  color: rgb(255,202,40) !important; }

.mdl-color--amber-400 {
  background-color: rgb(255,202,40) !important; }

.mdl-color-text--amber-500 {
  color: rgb(255,193,7) !important; }

.mdl-color--amber-500 {
  background-color: rgb(255,193,7) !important; }

.mdl-color-text--amber-600 {
  color: rgb(255,179,0) !important; }

.mdl-color--amber-600 {
  background-color: rgb(255,179,0) !important; }

.mdl-color-text--amber-700 {
  color: rgb(255,160,0) !important; }

.mdl-color--amber-700 {
  background-color: rgb(255,160,0) !important; }

.mdl-color-text--amber-800 {
  color: rgb(255,143,0) !important; }

.mdl-color--amber-800 {
  background-color: rgb(255,143,0) !important; }

.mdl-color-text--amber-900 {
  color: rgb(255,111,0) !important; }

.mdl-color--amber-900 {
  background-color: rgb(255,111,0) !important; }

.mdl-color-text--amber-A100 {
  color: rgb(255,229,127) !important; }

.mdl-color--amber-A100 {
  background-color: rgb(255,229,127) !important; }

.mdl-color-text--amber-A200 {
  color: rgb(255,215,64) !important; }

.mdl-color--amber-A200 {
  background-color: rgb(255,215,64) !important; }

.mdl-color-text--amber-A400 {
  color: rgb(255,196,0) !important; }

.mdl-color--amber-A400 {
  background-color: rgb(255,196,0) !important; }

.mdl-color-text--amber-A700 {
  color: rgb(255,171,0) !important; }

.mdl-color--amber-A700 {
  background-color: rgb(255,171,0) !important; }

.mdl-color-text--orange {
  color: rgb(255,152,0) !important; }

.mdl-color--orange {
  background-color: rgb(255,152,0) !important; }

.mdl-color-text--orange-50 {
  color: rgb(255,243,224) !important; }

.mdl-color--orange-50 {
  background-color: rgb(255,243,224) !important; }

.mdl-color-text--orange-100 {
  color: rgb(255,224,178) !important; }

.mdl-color--orange-100 {
  background-color: rgb(255,224,178) !important; }

.mdl-color-text--orange-200 {
  color: rgb(255,204,128) !important; }

.mdl-color--orange-200 {
  background-color: rgb(255,204,128) !important; }

.mdl-color-text--orange-300 {
  color: rgb(255,183,77) !important; }

.mdl-color--orange-300 {
  background-color: rgb(255,183,77) !important; }

.mdl-color-text--orange-400 {
  color: rgb(255,167,38) !important; }

.mdl-color--orange-400 {
  background-color: rgb(255,167,38) !important; }

.mdl-color-text--orange-500 {
  color: rgb(255,152,0) !important; }

.mdl-color--orange-500 {
  background-color: rgb(255,152,0) !important; }

.mdl-color-text--orange-600 {
  color: rgb(251,140,0) !important; }

.mdl-color--orange-600 {
  background-color: rgb(251,140,0) !important; }

.mdl-color-text--orange-700 {
  color: rgb(245,124,0) !important; }

.mdl-color--orange-700 {
  background-color: rgb(245,124,0) !important; }

.mdl-color-text--orange-800 {
  color: rgb(239,108,0) !important; }

.mdl-color--orange-800 {
  background-color: rgb(239,108,0) !important; }

.mdl-color-text--orange-900 {
  color: rgb(230,81,0) !important; }

.mdl-color--orange-900 {
  background-color: rgb(230,81,0) !important; }

.mdl-color-text--orange-A100 {
  color: rgb(255,209,128) !important; }

.mdl-color--orange-A100 {
  background-color: rgb(255,209,128) !important; }

.mdl-color-text--orange-A200 {
  color: rgb(255,171,64) !important; }

.mdl-color--orange-A200 {
  background-color: rgb(255,171,64) !important; }

.mdl-color-text--orange-A400 {
  color: rgb(255,145,0) !important; }

.mdl-color--orange-A400 {
  background-color: rgb(255,145,0) !important; }

.mdl-color-text--orange-A700 {
  color: rgb(255,109,0) !important; }

.mdl-color--orange-A700 {
  background-color: rgb(255,109,0) !important; }

.mdl-color-text--deep-orange {
  color: rgb(255,87,34) !important; }

.mdl-color--deep-orange {
  background-color: rgb(255,87,34) !important; }

.mdl-color-text--deep-orange-50 {
  color: rgb(251,233,231) !important; }

.mdl-color--deep-orange-50 {
  background-color: rgb(251,233,231) !important; }

.mdl-color-text--deep-orange-100 {
  color: rgb(255,204,188) !important; }

.mdl-color--deep-orange-100 {
  background-color: rgb(255,204,188) !important; }

.mdl-color-text--deep-orange-200 {
  color: rgb(255,171,145) !important; }

.mdl-color--deep-orange-200 {
  background-color: rgb(255,171,145) !important; }

.mdl-color-text--deep-orange-300 {
  color: rgb(255,138,101) !important; }

.mdl-color--deep-orange-300 {
  background-color: rgb(255,138,101) !important; }

.mdl-color-text--deep-orange-400 {
  color: rgb(255,112,67) !important; }

.mdl-color--deep-orange-400 {
  background-color: rgb(255,112,67) !important; }

.mdl-color-text--deep-orange-500 {
  color: rgb(255,87,34) !important; }

.mdl-color--deep-orange-500 {
  background-color: rgb(255,87,34) !important; }

.mdl-color-text--deep-orange-600 {
  color: rgb(244,81,30) !important; }

.mdl-color--deep-orange-600 {
  background-color: rgb(244,81,30) !important; }

.mdl-color-text--deep-orange-700 {
  color: rgb(230,74,25) !important; }

.mdl-color--deep-orange-700 {
  background-color: rgb(230,74,25) !important; }

.mdl-color-text--deep-orange-800 {
  color: rgb(216,67,21) !important; }

.mdl-color--deep-orange-800 {
  background-color: rgb(216,67,21) !important; }

.mdl-color-text--deep-orange-900 {
  color: rgb(191,54,12) !important; }

.mdl-color--deep-orange-900 {
  background-color: rgb(191,54,12) !important; }

.mdl-color-text--deep-orange-A100 {
  color: rgb(255,158,128) !important; }

.mdl-color--deep-orange-A100 {
  background-color: rgb(255,158,128) !important; }

.mdl-color-text--deep-orange-A200 {
  color: rgb(255,110,64) !important; }

.mdl-color--deep-orange-A200 {
  background-color: rgb(255,110,64) !important; }

.mdl-color-text--deep-orange-A400 {
  color: rgb(255,61,0) !important; }

.mdl-color--deep-orange-A400 {
  background-color: rgb(255,61,0) !important; }

.mdl-color-text--deep-orange-A700 {
  color: rgb(221,44,0) !important; }

.mdl-color--deep-orange-A700 {
  background-color: rgb(221,44,0) !important; }

.mdl-color-text--brown {
  color: rgb(121,85,72) !important; }

.mdl-color--brown {
  background-color: rgb(121,85,72) !important; }

.mdl-color-text--brown-50 {
  color: rgb(239,235,233) !important; }

.mdl-color--brown-50 {
  background-color: rgb(239,235,233) !important; }

.mdl-color-text--brown-100 {
  color: rgb(215,204,200) !important; }

.mdl-color--brown-100 {
  background-color: rgb(215,204,200) !important; }

.mdl-color-text--brown-200 {
  color: rgb(188,170,164) !important; }

.mdl-color--brown-200 {
  background-color: rgb(188,170,164) !important; }

.mdl-color-text--brown-300 {
  color: rgb(161,136,127) !important; }

.mdl-color--brown-300 {
  background-color: rgb(161,136,127) !important; }

.mdl-color-text--brown-400 {
  color: rgb(141,110,99) !important; }

.mdl-color--brown-400 {
  background-color: rgb(141,110,99) !important; }

.mdl-color-text--brown-500 {
  color: rgb(121,85,72) !important; }

.mdl-color--brown-500 {
  background-color: rgb(121,85,72) !important; }

.mdl-color-text--brown-600 {
  color: rgb(109,76,65) !important; }

.mdl-color--brown-600 {
  background-color: rgb(109,76,65) !important; }

.mdl-color-text--brown-700 {
  color: rgb(93,64,55) !important; }

.mdl-color--brown-700 {
  background-color: rgb(93,64,55) !important; }

.mdl-color-text--brown-800 {
  color: rgb(78,52,46) !important; }

.mdl-color--brown-800 {
  background-color: rgb(78,52,46) !important; }

.mdl-color-text--brown-900 {
  color: rgb(62,39,35) !important; }

.mdl-color--brown-900 {
  background-color: rgb(62,39,35) !important; }

.mdl-color-text--grey {
  color: rgb(158,158,158) !important; }

.mdl-color--grey {
  background-color: rgb(158,158,158) !important; }

.mdl-color-text--grey-50 {
  color: rgb(250,250,250) !important; }

.mdl-color--grey-50 {
  background-color: rgb(250,250,250) !important; }

.mdl-color-text--grey-100 {
  color: rgb(245,245,245) !important; }

.mdl-color--grey-100 {
  background-color: rgb(245,245,245) !important; }

.mdl-color-text--grey-200 {
  color: rgb(238,238,238) !important; }

.mdl-color--grey-200 {
  background-color: rgb(238,238,238) !important; }

.mdl-color-text--grey-300 {
  color: rgb(224,224,224) !important; }

.mdl-color--grey-300 {
  background-color: rgb(224,224,224) !important; }

.mdl-color-text--grey-400 {
  color: rgb(189,189,189) !important; }

.mdl-color--grey-400 {
  background-color: rgb(189,189,189) !important; }

.mdl-color-text--grey-500 {
  color: rgb(158,158,158) !important; }

.mdl-color--grey-500 {
  background-color: rgb(158,158,158) !important; }

.mdl-color-text--grey-600 {
  color: rgb(117,117,117) !important; }

.mdl-color--grey-600 {
  background-color: rgb(117,117,117) !important; }

.mdl-color-text--grey-700 {
  color: rgb(97,97,97) !important; }

.mdl-color--grey-700 {
  background-color: rgb(97,97,97) !important; }

.mdl-color-text--grey-800 {
  color: rgb(66,66,66) !important; }

.mdl-color--grey-800 {
  background-color: rgb(66,66,66) !important; }

.mdl-color-text--grey-900 {
  color: rgb(33,33,33) !important; }

.mdl-color--grey-900 {
  background-color: rgb(33,33,33) !important; }

.mdl-color-text--blue-grey {
  color: rgb(96,125,139) !important; }

.mdl-color--blue-grey {
  background-color: rgb(96,125,139) !important; }

.mdl-color-text--blue-grey-50 {
  color: rgb(236,239,241) !important; }

.mdl-color--blue-grey-50 {
  background-color: rgb(236,239,241) !important; }

.mdl-color-text--blue-grey-100 {
  color: rgb(207,216,220) !important; }

.mdl-color--blue-grey-100 {
  background-color: rgb(207,216,220) !important; }

.mdl-color-text--blue-grey-200 {
  color: rgb(176,190,197) !important; }

.mdl-color--blue-grey-200 {
  background-color: rgb(176,190,197) !important; }

.mdl-color-text--blue-grey-300 {
  color: rgb(144,164,174) !important; }

.mdl-color--blue-grey-300 {
  background-color: rgb(144,164,174) !important; }

.mdl-color-text--blue-grey-400 {
  color: rgb(120,144,156) !important; }

.mdl-color--blue-grey-400 {
  background-color: rgb(120,144,156) !important; }

.mdl-color-text--blue-grey-500 {
  color: rgb(96,125,139) !important; }

.mdl-color--blue-grey-500 {
  background-color: rgb(96,125,139) !important; }

.mdl-color-text--blue-grey-600 {
  color: rgb(84,110,122) !important; }

.mdl-color--blue-grey-600 {
  background-color: rgb(84,110,122) !important; }

.mdl-color-text--blue-grey-700 {
  color: rgb(69,90,100) !important; }

.mdl-color--blue-grey-700 {
  background-color: rgb(69,90,100) !important; }

.mdl-color-text--blue-grey-800 {
  color: rgb(55,71,79) !important; }

.mdl-color--blue-grey-800 {
  background-color: rgb(55,71,79) !important; }

.mdl-color-text--blue-grey-900 {
  color: rgb(38,50,56) !important; }

.mdl-color--blue-grey-900 {
  background-color: rgb(38,50,56) !important; }

.mdl-color--black {
  background-color: rgb(0,0,0) !important; }

.mdl-color-text--black {
  color: rgb(0,0,0) !important; }

.mdl-color--white {
  background-color: rgb(255,255,255) !important; }

.mdl-color-text--white {
  color: rgb(255,255,255) !important; }

.mdl-color--primary {
  background-color: rgb(33,150,243) !important; }

.mdl-color--primary-contrast {
  background-color: rgb(255,255,255) !important; }

.mdl-color--primary-dark {
  background-color: rgb(48,63,159) !important; }

.mdl-color--accent {
  background-color: rgb(194,24,91) !important; }

.mdl-color--accent-contrast {
  background-color: rgb(255,255,255) !important; }

.mdl-color-text--primary {
  color: rgb(33,150,243) !important; }

.mdl-color-text--primary-contrast {
  color: rgb(255,255,255) !important; }

.mdl-color-text--primary-dark {
  color: rgb(48,63,159) !important; }

.mdl-color-text--accent {
  color: rgb(194,24,91) !important; }

.mdl-color-text--accent-contrast {
  color: rgb(255,255,255) !important; }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
.mdl-ripple {
  background: rgb(0,0,0);
  border-radius: 50%;
  height: 50px;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: translate(-50%, -50%);
  width: 50px;
  overflow: hidden; }
  .mdl-ripple.is-animating {
    transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1), width 0.3s cubic-bezier(0, 0, 0.2, 1), height 0.3s cubic-bezier(0, 0, 0.2, 1), opacity 0.6s cubic-bezier(0, 0, 0.2, 1); }
  .mdl-ripple.is-visible {
    opacity: 0.3; }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
.mdl-animation--default {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1); }

.mdl-animation--fast-out-slow-in {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1); }

.mdl-animation--linear-out-slow-in {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1); }

.mdl-animation--fast-out-linear-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1); }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
.mdl-badge {
  position: relative;
  white-space: nowrap;
  margin-right: 24px; }
  .mdl-badge:not([data-badge]) {
    margin-right: auto; }
  .mdl-badge[data-badge]:after {
    content: attr(data-badge);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: absolute;
    top: -11px;
    right: -24px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 600;
    font-size: 12px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: rgb(194,24,91);
    color: rgb(255,255,255); }
    .mdl-button .mdl-badge[data-badge]:after {
      top: -10px;
      right: -5px; }
  .mdl-badge.mdl-badge--no-background[data-badge]:after {
    color: rgb(194,24,91);
    background: rgba(255,255,255,0.2);
    box-shadow: 0 0 1px gray; }
  .mdl-badge.mdl-badge--overlap {
    margin-right: 10px; }
    .mdl-badge.mdl-badge--overlap:after {
      right: -10px; }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
.mdl-button {
  background: transparent;
  border: none;
  border-radius: 2px;
  color: rgb(0,0,0);
  position: relative;
  height: 36px;
  margin: 0;
  min-width: 64px;
  padding: 0 16px;
  display: inline-block;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 0;
  overflow: hidden;
  will-change: box-shadow;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  outline: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  line-height: 36px;
  vertical-align: middle; }
  .mdl-button::-moz-focus-inner {
    border: 0; }
  .mdl-button:hover {
    background-color: rgba(158,158,158, 0.20); }
  .mdl-button:focus:not(:active) {
    background-color: rgba(0,0,0, 0.12); }
  .mdl-button:active {
    background-color: rgba(158,158,158, 0.40); }
  .mdl-button.mdl-button--colored {
    color: rgb(33,150,243); }
    .mdl-button.mdl-button--colored:focus:not(:active) {
      background-color: rgba(0,0,0, 0.12); }

input.mdl-button[type="submit"] {
  -webkit-appearance: none; }

.mdl-button--raised {
  background: rgba(158,158,158, 0.20);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }
  .mdl-button--raised:active {
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
    background-color: rgba(158,158,158, 0.40); }
  .mdl-button--raised:focus:not(:active) {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.18), 0 8px 16px rgba(0, 0, 0, 0.36);
    background-color: rgba(158,158,158, 0.40); }
  .mdl-button--raised.mdl-button--colored {
    background: rgb(33,150,243);
    color: rgb(255,255,255); }
    .mdl-button--raised.mdl-button--colored:hover {
      background-color: rgb(33,150,243); }
    .mdl-button--raised.mdl-button--colored:active {
      background-color: rgb(33,150,243); }
    .mdl-button--raised.mdl-button--colored:focus:not(:active) {
      background-color: rgb(33,150,243); }
    .mdl-button--raised.mdl-button--colored .mdl-ripple {
      background: rgb(255,255,255); }

.mdl-button--fab {
  border-radius: 50%;
  font-size: 24px;
  height: 56px;
  margin: auto;
  min-width: 56px;
  width: 56px;
  padding: 0;
  overflow: hidden;
  background: rgba(158,158,158, 0.20);
  box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
  position: relative;
  line-height: normal; }
  .mdl-button--fab .material-icons {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-12px, -12px);
    line-height: 24px;
    width: 24px; }
  .mdl-button--fab.mdl-button--mini-fab {
    height: 40px;
    min-width: 40px;
    width: 40px; }
  .mdl-button--fab .mdl-button__ripple-container {
    border-radius: 50%;
    -webkit-mask-image: -webkit-radial-gradient(circle, white, black); }
  .mdl-button--fab:active {
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
    background-color: rgba(158,158,158, 0.40); }
  .mdl-button--fab:focus:not(:active) {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.18), 0 8px 16px rgba(0, 0, 0, 0.36);
    background-color: rgba(158,158,158, 0.40); }
  .mdl-button--fab.mdl-button--colored {
    background: rgb(194,24,91);
    color: rgb(255,255,255); }
    .mdl-button--fab.mdl-button--colored:hover {
      background-color: rgb(194,24,91); }
    .mdl-button--fab.mdl-button--colored:focus:not(:active) {
      background-color: rgb(194,24,91); }
    .mdl-button--fab.mdl-button--colored:active {
      background-color: rgb(194,24,91); }
    .mdl-button--fab.mdl-button--colored .mdl-ripple {
      background: rgb(255,255,255); }

.mdl-button--icon {
  border-radius: 50%;
  font-size: 24px;
  height: 32px;
  margin-left: 0;
  margin-right: 0;
  min-width: 32px;
  width: 32px;
  padding: 0;
  overflow: hidden;
  color: inherit;
  line-height: normal; }
  .mdl-button--icon .material-icons {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-12px, -12px);
    line-height: 24px;
    width: 24px; }
  .mdl-button--icon.mdl-button--mini-icon {
    height: 24px;
    min-width: 24px;
    width: 24px; }
    .mdl-button--icon.mdl-button--mini-icon .material-icons {
      top: 0px;
      left: 0px; }
  .mdl-button--icon .mdl-button__ripple-container {
    border-radius: 50%;
    -webkit-mask-image: -webkit-radial-gradient(circle, white, black); }

.mdl-button__ripple-container {
  display: block;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 0;
  overflow: hidden; }
  .mdl-button[disabled] .mdl-button__ripple-container .mdl-ripple,
  .mdl-button.mdl-button--disabled .mdl-button__ripple-container .mdl-ripple {
    background-color: transparent; }

.mdl-button--primary.mdl-button--primary {
  color: rgb(33,150,243); }
  .mdl-button--primary.mdl-button--primary .mdl-ripple {
    background: rgb(255,255,255); }
  .mdl-button--primary.mdl-button--primary.mdl-button--raised, .mdl-button--primary.mdl-button--primary.mdl-button--fab {
    color: rgb(255,255,255);
    background-color: rgb(33,150,243); }

.mdl-button--accent.mdl-button--accent {
  color: rgb(194,24,91); }
  .mdl-button--accent.mdl-button--accent .mdl-ripple {
    background: rgb(255,255,255); }
  .mdl-button--accent.mdl-button--accent.mdl-button--raised, .mdl-button--accent.mdl-button--accent.mdl-button--fab {
    color: rgb(255,255,255);
    background-color: rgb(194,24,91); }

.mdl-button[disabled][disabled], .mdl-button.mdl-button--disabled.mdl-button--disabled {
  color: rgba(0,0,0, 0.26);
  cursor: default;
  background-color: transparent; }

.mdl-button--fab[disabled][disabled], .mdl-button--fab.mdl-button--disabled.mdl-button--disabled {
  background-color: rgba(0,0,0, 0.12);
  color: rgba(0,0,0, 0.26); }

.mdl-button--raised[disabled][disabled], .mdl-button--raised.mdl-button--disabled.mdl-button--disabled {
  background-color: rgba(0,0,0, 0.12);
  color: rgba(0,0,0, 0.26);
  box-shadow: none; }

.mdl-button--colored[disabled][disabled], .mdl-button--colored.mdl-button--disabled.mdl-button--disabled {
  color: rgba(0,0,0, 0.26); }

.mdl-button .material-icons {
  vertical-align: middle; }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
.mdl-card {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  min-height: 200px;
  overflow: hidden;
  width: 330px;
  z-index: 1;
  position: relative;
  background: rgb(255,255,255);
  border-radius: 2px;
  box-sizing: border-box; }

.mdl-card__media {
  background-color: rgb(194,24,91);
  background-repeat: repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-origin: padding-box;
  background-attachment: scroll;
  box-sizing: border-box; }

.mdl-card__title {
  align-items: center;
  color: rgb(0,0,0);
  display: block;
  display: flex;
  justify-content: stretch;
  line-height: normal;
  padding: 16px 16px;
  perspective-origin: 165px 56px;
  transform-origin: 165px 56px;
  box-sizing: border-box; }
  .mdl-card__title.mdl-card--border {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

.mdl-card__title-text {
  align-self: flex-end;
  color: inherit;
  display: block;
  display: flex;
  font-size: 24px;
  font-weight: 300;
  line-height: normal;
  overflow: hidden;
  transform-origin: 149px 48px;
  margin: 0; }

.mdl-card__subtitle-text {
  font-size: 14px;
  color: rgba(0,0,0, 0.54);
  margin: 0; }

.mdl-card__supporting-text {
  color: rgba(0,0,0, 0.54);
  font-size: 1rem;
  line-height: 18px;
  overflow: hidden;
  padding: 16px 16px;
  width: 90%; }
  .mdl-card__supporting-text.mdl-card--border {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

.mdl-card__actions {
  font-size: 16px;
  line-height: normal;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  padding: 8px;
  box-sizing: border-box; }
  .mdl-card__actions.mdl-card--border {
    border-top: 1px solid rgba(0, 0, 0, 0.1); }

.mdl-card--expand {
  flex-grow: 1; }

.mdl-card__menu {
  position: absolute;
  right: 16px;
  top: 16px; }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
.mdl-checkbox {
  position: relative;
  z-index: 1;
  vertical-align: middle;
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  height: 24px;
  margin: 0;
  padding: 0; }
  .mdl-checkbox.is-upgraded {
    padding-left: 24px; }

.mdl-checkbox__input {
  line-height: 24px; }
  .mdl-checkbox.is-upgraded .mdl-checkbox__input {
    position: absolute;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
    -ms-appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: none; }

.mdl-checkbox__box-outline {
  position: absolute;
  top: 3px;
  left: 0;
  display: inline-block;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  margin: 0;
  cursor: pointer;
  overflow: hidden;
  border: 2px solid rgba(0,0,0, 0.54);
  border-radius: 2px;
  z-index: 2; }
  .mdl-checkbox.is-checked .mdl-checkbox__box-outline {
    border: 2px solid rgb(33,150,243); }
  fieldset[disabled] .mdl-checkbox .mdl-checkbox__box-outline,
  .mdl-checkbox.is-disabled .mdl-checkbox__box-outline {
    border: 2px solid rgba(0,0,0, 0.26);
    cursor: auto; }

.mdl-checkbox__focus-helper {
  position: absolute;
  top: 3px;
  left: 0;
  display: inline-block;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: transparent; }
  .mdl-checkbox.is-focused .mdl-checkbox__focus-helper {
    box-shadow: 0 0 0px 8px rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.1); }
  .mdl-checkbox.is-focused.is-checked .mdl-checkbox__focus-helper {
    box-shadow: 0 0 0px 8px rgba(33,150,243, 0.26);
    background-color: rgba(33,150,243, 0.26); }

.mdl-checkbox__tick-outline {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  mask: url("/vendor/material-design-lite/src/images/tick-mask.svg?embed");
  background: transparent;
  transition-duration: 0.28s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: background; }
  .mdl-checkbox.is-checked .mdl-checkbox__tick-outline {
    background: rgb(33,150,243) url("/vendor/material-design-lite/src/images/tick.svg?embed"); }
  fieldset[disabled] .mdl-checkbox.is-checked .mdl-checkbox__tick-outline,
  .mdl-checkbox.is-checked.is-disabled .mdl-checkbox__tick-outline {
    background: rgba(0,0,0, 0.26) url("/vendor/material-design-lite/src/images/tick.svg?embed"); }

.mdl-checkbox__label {
  position: relative;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  margin: 0; }
  fieldset[disabled] .mdl-checkbox .mdl-checkbox__label,
  .mdl-checkbox.is-disabled .mdl-checkbox__label {
    color: rgba(0,0,0, 0.26);
    cursor: auto; }

.mdl-checkbox__ripple-container {
  position: absolute;
  z-index: 2;
  top: -6px;
  left: -10px;
  box-sizing: border-box;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(circle, white, black); }
  .mdl-checkbox__ripple-container .mdl-ripple {
    background: rgb(33,150,243); }
  fieldset[disabled] .mdl-checkbox .mdl-checkbox__ripple-container,
  .mdl-checkbox.is-disabled .mdl-checkbox__ripple-container {
    cursor: auto; }
  fieldset[disabled] .mdl-checkbox .mdl-checkbox__ripple-container .mdl-ripple,
  .mdl-checkbox.is-disabled .mdl-checkbox__ripple-container .mdl-ripple {
    background: transparent; }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
.mdl-chip {
  height: 32px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 32px;
  padding: 0 12px;
  border: 0;
  border-radius: 16px;
  background-color: #dedede;
  display: inline-block;
  color: rgba(0,0,0, 0.87);
  margin: 2px 0;
  font-size: 0;
  white-space: nowrap; }
  .mdl-chip__text {
    font-size: 13px;
    vertical-align: middle;
    display: inline-block; }
  .mdl-chip__action {
    height: 24px;
    width: 24px;
    background: transparent;
    opacity: 0.54;
    display: inline-block;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    padding: 0;
    margin: 0 0 0 4px;
    font-size: 13px;
    text-decoration: none;
    color: rgba(0,0,0, 0.87);
    border: none;
    outline: none;
    overflow: hidden; }
  .mdl-chip__contact {
    height: 32px;
    width: 32px;
    border-radius: 16px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
    overflow: hidden;
    text-align: center;
    font-size: 18px;
    line-height: 32px; }
  .mdl-chip:focus {
    outline: 0;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }
  .mdl-chip:active {
    background-color: #d6d6d6; }
  .mdl-chip--deletable {
    padding-right: 4px; }
  .mdl-chip--contact {
    padding-left: 0; }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
.mdl-data-table {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-collapse: collapse;
  white-space: nowrap;
  font-size: 13px;
  background-color: rgb(255,255,255); }
  .mdl-data-table thead {
    padding-bottom: 3px; }
    .mdl-data-table thead .mdl-data-table__select {
      margin-top: 0; }
  .mdl-data-table tbody tr {
    position: relative;
    height: 48px;
    transition-duration: 0.28s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-property: background-color; }
    .mdl-data-table tbody tr.is-selected {
      background-color: #e0e0e0; }
    .mdl-data-table tbody tr:hover {
      background-color: #eeeeee; }
  .mdl-data-table td, .mdl-data-table th {
    padding: 0 18px 12px 18px;
    text-align: right; }
    .mdl-data-table td:first-of-type, .mdl-data-table th:first-of-type {
      padding-left: 24px; }
    .mdl-data-table td:last-of-type, .mdl-data-table th:last-of-type {
      padding-right: 24px; }
  .mdl-data-table td {
    position: relative;
    vertical-align: middle;
    height: 48px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding-top: 12px;
    box-sizing: border-box; }
    .mdl-data-table td .mdl-data-table__select {
      vertical-align: middle; }
  .mdl-data-table th {
    position: relative;
    vertical-align: bottom;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0;
    height: 48px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.54);
    padding-bottom: 8px;
    box-sizing: border-box; }
    .mdl-data-table th.mdl-data-table__header--sorted-ascending, .mdl-data-table th.mdl-data-table__header--sorted-descending {
      color: rgba(0, 0, 0, 0.87); }
      .mdl-data-table th.mdl-data-table__header--sorted-ascending:before, .mdl-data-table th.mdl-data-table__header--sorted-descending:before {
        font-family: 'Material Icons';
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        word-wrap: normal;
        font-feature-settings: 'liga';
        -webkit-font-feature-settings: 'liga';
        -webkit-font-smoothing: antialiased;
        font-size: 16px;
        content: "\e5d8";
        margin-right: 5px;
        vertical-align: sub; }
      .mdl-data-table th.mdl-data-table__header--sorted-ascending:hover, .mdl-data-table th.mdl-data-table__header--sorted-descending:hover {
        cursor: pointer; }
        .mdl-data-table th.mdl-data-table__header--sorted-ascending:hover:before, .mdl-data-table th.mdl-data-table__header--sorted-descending:hover:before {
          color: rgba(0, 0, 0, 0.26); }
    .mdl-data-table th.mdl-data-table__header--sorted-descending:before {
      content: "\e5db"; }

.mdl-data-table__select {
  width: 16px; }

.mdl-data-table__cell--non-numeric.mdl-data-table__cell--non-numeric {
  text-align: left; }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
.mdl-dialog {
  border: none;
  box-shadow: 0 9px 46px 8px rgba(0, 0, 0, 0.14), 0 11px 15px -7px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.2);
  width: 280px; }
  .mdl-dialog__title {
    padding: 24px 24px 0;
    margin: 0;
    font-size: 2.5rem; }
  .mdl-dialog__actions {
    padding: 8px 8px 8px 24px;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap; }
    .mdl-dialog__actions > * {
      margin-right: 8px;
      height: 36px; }
      .mdl-dialog__actions > *:first-child {
        margin-right: 0; }
    .mdl-dialog__actions--full-width {
      padding: 0 0 8px 0; }
      .mdl-dialog__actions--full-width > * {
        height: 48px;
        flex: 0 0 100%;
        padding-right: 16px;
        margin-right: 0;
        text-align: right; }
  .mdl-dialog__content {
    padding: 20px 24px 24px 24px;
    color: rgba(0,0,0, 0.54); }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
.mdl-mega-footer {
  padding: 16px 40px;
  color: rgb(158,158,158);
  background-color: rgb(66,66,66); }

.mdl-mega-footer--top-section:after,
.mdl-mega-footer--middle-section:after,
.mdl-mega-footer--bottom-section:after,
.mdl-mega-footer__top-section:after,
.mdl-mega-footer__middle-section:after,
.mdl-mega-footer__bottom-section:after {
  content: '';
  display: block;
  clear: both; }

.mdl-mega-footer--left-section,
.mdl-mega-footer__left-section {
  margin-bottom: 16px; }

.mdl-mega-footer--right-section,
.mdl-mega-footer__right-section {
  margin-bottom: 16px; }

.mdl-mega-footer--right-section a,
.mdl-mega-footer__right-section a {
  display: block;
  margin-bottom: 16px;
  color: inherit;
  text-decoration: none; }

@media screen and (min-width: 760px) {
  .mdl-mega-footer--left-section,
  .mdl-mega-footer__left-section {
    float: left; }
  .mdl-mega-footer--right-section,
  .mdl-mega-footer__right-section {
    float: right; }
  .mdl-mega-footer--right-section a,
  .mdl-mega-footer__right-section a {
    display: inline-block;
    margin-left: 16px;
    line-height: 36px;
    vertical-align: middle; } }

.mdl-mega-footer--social-btn,
.mdl-mega-footer__social-btn {
  width: 36px;
  height: 36px;
  padding: 0;
  margin: 0;
  background-color: rgb(158,158,158);
  border: none; }

.mdl-mega-footer--drop-down-section,
.mdl-mega-footer__drop-down-section {
  display: block;
  position: relative; }

@media screen and (min-width: 760px) {
  .mdl-mega-footer--drop-down-section,
  .mdl-mega-footer__drop-down-section {
    width: 33%; }
  .mdl-mega-footer--drop-down-section:nth-child(1),
  .mdl-mega-footer--drop-down-section:nth-child(2),
  .mdl-mega-footer__drop-down-section:nth-child(1),
  .mdl-mega-footer__drop-down-section:nth-child(2) {
    float: left; }
  .mdl-mega-footer--drop-down-section:nth-child(3),
  .mdl-mega-footer__drop-down-section:nth-child(3) {
    float: right; }
    .mdl-mega-footer--drop-down-section:nth-child(3):after,
    .mdl-mega-footer__drop-down-section:nth-child(3):after {
      clear: right; }
  .mdl-mega-footer--drop-down-section:nth-child(4),
  .mdl-mega-footer__drop-down-section:nth-child(4) {
    clear: right;
    float: right; }
  .mdl-mega-footer--middle-section:after,
  .mdl-mega-footer__middle-section:after {
    content: '';
    display: block;
    clear: both; }
  .mdl-mega-footer--bottom-section,
  .mdl-mega-footer__bottom-section {
    padding-top: 0; } }

@media screen and (min-width: 1024px) {
  .mdl-mega-footer--drop-down-section,
  .mdl-mega-footer--drop-down-section:nth-child(3),
  .mdl-mega-footer--drop-down-section:nth-child(4),
  .mdl-mega-footer__drop-down-section,
  .mdl-mega-footer__drop-down-section:nth-child(3),
  .mdl-mega-footer__drop-down-section:nth-child(4) {
    width: 24%;
    float: left; } }

.mdl-mega-footer--heading-checkbox,
.mdl-mega-footer__heading-checkbox {
  position: absolute;
  width: 100%;
  height: 55.8px;
  padding: 32px;
  margin: 0;
  margin-top: -16px;
  cursor: pointer;
  z-index: 1;
  opacity: 0; }
  .mdl-mega-footer--heading-checkbox + .mdl-mega-footer--heading:after,
  .mdl-mega-footer--heading-checkbox + .mdl-mega-footer__heading:after,
  .mdl-mega-footer__heading-checkbox + .mdl-mega-footer--heading:after,
  .mdl-mega-footer__heading-checkbox + .mdl-mega-footer__heading:after {
    font-family: 'Material Icons';
    content: '\E5CE'; }

.mdl-mega-footer--heading-checkbox:checked ~ .mdl-mega-footer--link-list,
.mdl-mega-footer--heading-checkbox:checked ~ .mdl-mega-footer__link-list,
.mdl-mega-footer--heading-checkbox:checked + .mdl-mega-footer--heading + .mdl-mega-footer--link-list,
.mdl-mega-footer--heading-checkbox:checked + .mdl-mega-footer__heading + .mdl-mega-footer__link-list,
.mdl-mega-footer__heading-checkbox:checked ~ .mdl-mega-footer--link-list,
.mdl-mega-footer__heading-checkbox:checked ~ .mdl-mega-footer__link-list,
.mdl-mega-footer__heading-checkbox:checked + .mdl-mega-footer--heading + .mdl-mega-footer--link-list,
.mdl-mega-footer__heading-checkbox:checked + .mdl-mega-footer__heading + .mdl-mega-footer__link-list {
  display: none; }

.mdl-mega-footer--heading-checkbox:checked + .mdl-mega-footer--heading:after,
.mdl-mega-footer--heading-checkbox:checked + .mdl-mega-footer__heading:after,
.mdl-mega-footer__heading-checkbox:checked + .mdl-mega-footer--heading:after,
.mdl-mega-footer__heading-checkbox:checked + .mdl-mega-footer__heading:after {
  font-family: 'Material Icons';
  content: '\E5CF'; }

.mdl-mega-footer--heading,
.mdl-mega-footer__heading {
  position: relative;
  width: 100%;
  padding-right: 39.8px;
  margin-bottom: 16px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 23.8px;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: rgb(224,224,224); }

.mdl-mega-footer--heading:after,
.mdl-mega-footer__heading:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 23.8px;
  height: 23.8px;
  background-size: cover; }

.mdl-mega-footer--link-list,
.mdl-mega-footer__link-list {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 32px; }
  .mdl-mega-footer--link-list:after,
  .mdl-mega-footer__link-list:after {
    clear: both;
    display: block;
    content: ''; }

.mdl-mega-footer--link-list li,
.mdl-mega-footer__link-list li {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  line-height: 20px; }

.mdl-mega-footer--link-list a,
.mdl-mega-footer__link-list a {
  color: inherit;
  text-decoration: none;
  white-space: nowrap; }

@media screen and (min-width: 760px) {
  .mdl-mega-footer--heading-checkbox,
  .mdl-mega-footer__heading-checkbox {
    display: none; }
    .mdl-mega-footer--heading-checkbox + .mdl-mega-footer--heading:after,
    .mdl-mega-footer--heading-checkbox + .mdl-mega-footer__heading:after,
    .mdl-mega-footer__heading-checkbox + .mdl-mega-footer--heading:after,
    .mdl-mega-footer__heading-checkbox + .mdl-mega-footer__heading:after {
      content: ''; }
  .mdl-mega-footer--heading-checkbox:checked ~ .mdl-mega-footer--link-list,
  .mdl-mega-footer--heading-checkbox:checked ~ .mdl-mega-footer__link-list,
  .mdl-mega-footer--heading-checkbox:checked + .mdl-mega-footer__heading + .mdl-mega-footer__link-list,
  .mdl-mega-footer--heading-checkbox:checked + .mdl-mega-footer--heading + .mdl-mega-footer--link-list,
  .mdl-mega-footer__heading-checkbox:checked ~ .mdl-mega-footer--link-list,
  .mdl-mega-footer__heading-checkbox:checked ~ .mdl-mega-footer__link-list,
  .mdl-mega-footer__heading-checkbox:checked + .mdl-mega-footer__heading + .mdl-mega-footer__link-list,
  .mdl-mega-footer__heading-checkbox:checked + .mdl-mega-footer--heading + .mdl-mega-footer--link-list {
    display: block; }
  .mdl-mega-footer--heading-checkbox:checked + .mdl-mega-footer--heading:after,
  .mdl-mega-footer--heading-checkbox:checked + .mdl-mega-footer__heading:after,
  .mdl-mega-footer__heading-checkbox:checked + .mdl-mega-footer--heading:after,
  .mdl-mega-footer__heading-checkbox:checked + .mdl-mega-footer__heading:after {
    content: ''; } }

.mdl-mega-footer--bottom-section,
.mdl-mega-footer__bottom-section {
  padding-top: 16px;
  margin-bottom: 16px; }

.mdl-logo {
  margin-bottom: 16px;
  color: white; }

.mdl-mega-footer--bottom-section .mdl-mega-footer--link-list li,
.mdl-mega-footer__bottom-section .mdl-mega-footer__link-list li {
  float: left;
  margin-bottom: 0;
  margin-right: 16px; }

@media screen and (min-width: 760px) {
  .mdl-logo {
    float: left;
    margin-bottom: 0;
    margin-right: 16px; } }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
.mdl-mini-footer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 32px 16px;
  color: rgb(158,158,158);
  background-color: rgb(66,66,66); }
  .mdl-mini-footer:after {
    content: '';
    display: block; }
  .mdl-mini-footer .mdl-logo {
    line-height: 36px; }

.mdl-mini-footer--link-list,
.mdl-mini-footer__link-list {
  display: flex;
  flex-flow: row nowrap;
  list-style: none;
  margin: 0;
  padding: 0; }
  .mdl-mini-footer--link-list li,
  .mdl-mini-footer__link-list li {
    margin-bottom: 0;
    margin-right: 16px; }
    @media screen and (min-width: 760px) {
      .mdl-mini-footer--link-list li,
      .mdl-mini-footer__link-list li {
        line-height: 36px; } }
  .mdl-mini-footer--link-list a,
  .mdl-mini-footer__link-list a {
    color: inherit;
    text-decoration: none;
    white-space: nowrap; }

.mdl-mini-footer--left-section,
.mdl-mini-footer__left-section {
  display: inline-block;
  order: 0; }

.mdl-mini-footer--right-section,
.mdl-mini-footer__right-section {
  display: inline-block;
  order: 1; }

.mdl-mini-footer--social-btn,
.mdl-mini-footer__social-btn {
  width: 36px;
  height: 36px;
  padding: 0;
  margin: 0;
  background-color: rgb(158,158,158);
  border: none; }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
.mdl-icon-toggle {
  position: relative;
  z-index: 1;
  vertical-align: middle;
  display: inline-block;
  height: 32px;
  margin: 0;
  padding: 0; }

.mdl-icon-toggle__input {
  line-height: 32px; }
  .mdl-icon-toggle.is-upgraded .mdl-icon-toggle__input {
    position: absolute;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
    -ms-appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: none; }

.mdl-icon-toggle__label {
  display: inline-block;
  position: relative;
  cursor: pointer;
  height: 32px;
  width: 32px;
  min-width: 32px;
  color: rgb(97,97,97);
  border-radius: 50%;
  padding: 0;
  margin-left: 0;
  margin-right: 0;
  text-align: center;
  background-color: transparent;
  will-change: background-color;
  transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1); }
  .mdl-icon-toggle__label.material-icons {
    line-height: 32px;
    font-size: 24px; }
  .mdl-icon-toggle.is-checked .mdl-icon-toggle__label {
    color: rgb(33,150,243); }
  .mdl-icon-toggle.is-disabled .mdl-icon-toggle__label {
    color: rgba(0,0,0, 0.26);
    cursor: auto;
    transition: none; }
  .mdl-icon-toggle.is-focused .mdl-icon-toggle__label {
    background-color: rgba(0,0,0, 0.12); }
  .mdl-icon-toggle.is-focused.is-checked .mdl-icon-toggle__label {
    background-color: rgba(33,150,243, 0.26); }

.mdl-icon-toggle__ripple-container {
  position: absolute;
  z-index: 2;
  top: -2px;
  left: -2px;
  box-sizing: border-box;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(circle, white, black); }
  .mdl-icon-toggle__ripple-container .mdl-ripple {
    background: rgb(97,97,97); }
  .mdl-icon-toggle.is-disabled .mdl-icon-toggle__ripple-container {
    cursor: auto; }
  .mdl-icon-toggle.is-disabled .mdl-icon-toggle__ripple-container .mdl-ripple {
    background: transparent; }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
.mdl-list {
  display: block;
  padding: 8px 0;
  list-style: none; }

.mdl-list__item {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.04em;
  line-height: 1;
  display: flex;
  min-height: 48px;
  box-sizing: border-box;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding: 16px;
  cursor: default;
  color: rgba(0,0,0, 0.87);
  overflow: hidden; }
  .mdl-list__item .mdl-list__item-primary-content {
    order: 0;
    flex-grow: 2;
    text-decoration: none;
    box-sizing: border-box;
    display: flex;
    align-items: center; }
    .mdl-list__item .mdl-list__item-primary-content .mdl-list__item-icon {
      margin-right: 32px; }
    .mdl-list__item .mdl-list__item-primary-content .mdl-list__item-avatar {
      margin-right: 16px; }
  .mdl-list__item .mdl-list__item-secondary-content {
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    margin-left: 16px; }
    .mdl-list__item .mdl-list__item-secondary-content .mdl-list__item-secondary-action label {
      display: inline; }
    .mdl-list__item .mdl-list__item-secondary-content .mdl-list__item-secondary-info {
      font-size: 12px;
      font-weight: 400;
      line-height: 1;
      letter-spacing: 0;
      color: rgba(0,0,0, 0.54); }
    .mdl-list__item .mdl-list__item-secondary-content .mdl-list__item-sub-header {
      padding: 0 0 0 16px; }

.mdl-list__item-icon,
.mdl-list__item-icon.material-icons {
  height: 24px;
  width: 24px;
  font-size: 24px;
  box-sizing: border-box;
  color: rgb(117,117,117); }

.mdl-list__item-avatar,
.mdl-list__item-avatar.material-icons {
  height: 40px;
  width: 40px;
  box-sizing: border-box;
  border-radius: 50%;
  background-color: rgb(117,117,117);
  font-size: 40px;
  color: white; }

.mdl-list__item--two-line {
  height: 72px; }
  .mdl-list__item--two-line .mdl-list__item-primary-content {
    height: 36px;
    line-height: 20px;
    display: block; }
    .mdl-list__item--two-line .mdl-list__item-primary-content .mdl-list__item-avatar {
      float: left; }
    .mdl-list__item--two-line .mdl-list__item-primary-content .mdl-list__item-icon {
      float: left;
      margin-top: 6px; }
    .mdl-list__item--two-line .mdl-list__item-primary-content .mdl-list__item-secondary-content {
      height: 36px; }
    .mdl-list__item--two-line .mdl-list__item-primary-content .mdl-list__item-sub-title {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0;
      line-height: 18px;
      color: rgba(0,0,0, 0.54);
      display: block;
      padding: 0; }

.mdl-list__item--three-line {
  height: 88px; }
  .mdl-list__item--three-line .mdl-list__item-primary-content {
    height: 52px;
    line-height: 20px;
    display: block; }
    .mdl-list__item--three-line .mdl-list__item-primary-content .mdl-list__item-avatar,
    .mdl-list__item--three-line .mdl-list__item-primary-content .mdl-list__item-icon {
      float: left; }
  .mdl-list__item--three-line .mdl-list__item-secondary-content {
    height: 52px; }
  .mdl-list__item--three-line .mdl-list__item-text-body {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    line-height: 18px;
    height: 52px;
    color: rgba(0,0,0, 0.54);
    display: block;
    padding: 0; }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
.mdl-menu__container {
  display: block;
  margin: 0;
  padding: 0;
  border: none;
  position: absolute;
  overflow: visible;
  height: 0;
  width: 0;
  visibility: hidden;
  z-index: -1; }
  .mdl-menu__container.is-visible, .mdl-menu__container.is-animating {
    z-index: 999;
    visibility: visible; }

.mdl-menu__outline {
  display: block;
  background: rgb(255,255,255);
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 2px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  opacity: 0;
  transform: scale(0);
  transform-origin: 0 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  will-change: transform;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: -1; }
  .mdl-menu__container.is-visible .mdl-menu__outline {
    opacity: 1;
    transform: scale(1);
    z-index: 999; }
  .mdl-menu__outline.mdl-menu--bottom-right {
    transform-origin: 100% 0; }
  .mdl-menu__outline.mdl-menu--top-left {
    transform-origin: 0 100%; }
  .mdl-menu__outline.mdl-menu--top-right {
    transform-origin: 100% 100%; }

.mdl-menu {
  position: absolute;
  list-style: none;
  top: 0;
  left: 0;
  height: auto;
  width: auto;
  min-width: 124px;
  padding: 8px 0;
  margin: 0;
  opacity: 0;
  clip: rect(0 0 0 0);
  z-index: -1; }
  .mdl-menu__container.is-visible .mdl-menu {
    opacity: 1;
    z-index: 999; }
  .mdl-menu.is-animating {
    transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1), clip 0.3s cubic-bezier(0.4, 0, 0.2, 1); }
  .mdl-menu.mdl-menu--bottom-right {
    left: auto;
    right: 0; }
  .mdl-menu.mdl-menu--top-left {
    top: auto;
    bottom: 0; }
  .mdl-menu.mdl-menu--top-right {
    top: auto;
    left: auto;
    bottom: 0;
    right: 0; }
  .mdl-menu.mdl-menu--unaligned {
    top: auto;
    left: auto; }

.mdl-menu__item {
  display: block;
  border: none;
  color: rgba(0,0,0, 0.87);
  background-color: transparent;
  text-align: left;
  margin: 0;
  padding: 0 16px;
  outline-color: rgb(189,189,189);
  position: relative;
  overflow: hidden;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-decoration: none;
  cursor: pointer;
  height: 48px;
  line-height: 48px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  user-select: none; }
  .mdl-menu__container.is-visible .mdl-menu__item {
    opacity: 1; }
  .mdl-menu__item::-moz-focus-inner {
    border: 0; }
  .mdl-menu__item--full-bleed-divider {
    border-bottom: 1px solid rgba(0,0,0, 0.12); }
  .mdl-menu__item[disabled], .mdl-menu__item[data-mdl-disabled] {
    color: rgb(189,189,189);
    background-color: transparent;
    cursor: auto; }
    .mdl-menu__item[disabled]:hover, .mdl-menu__item[data-mdl-disabled]:hover {
      background-color: transparent; }
    .mdl-menu__item[disabled]:focus, .mdl-menu__item[data-mdl-disabled]:focus {
      background-color: transparent; }
    .mdl-menu__item[disabled] .mdl-ripple, .mdl-menu__item[data-mdl-disabled] .mdl-ripple {
      background: transparent; }
  .mdl-menu__item:hover {
    background-color: rgb(238,238,238); }
  .mdl-menu__item:focus {
    outline: none;
    background-color: rgb(238,238,238); }
  .mdl-menu__item:active {
    background-color: rgb(224,224,224); }

.mdl-menu__item--ripple-container {
  display: block;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 0;
  overflow: hidden; }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
.mdl-progress {
  display: block;
  position: relative;
  height: 4px;
  width: 500px;
  max-width: 100%; }

.mdl-progress > .bar {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 0%;
  transition: width 0.2s cubic-bezier(0.4, 0, 0.2, 1); }

.mdl-progress > .progressbar {
  background-color: rgb(33,150,243);
  z-index: 1;
  left: 0; }

.mdl-progress > .bufferbar {
  background-image: linear-gradient(to right, rgba(255,255,255, 0.7), rgba(255,255,255, 0.7)), linear-gradient(to right, rgb(33,150,243), rgb(33,150,243));
  z-index: 0;
  left: 0; }

.mdl-progress > .auxbar {
  right: 0; }

@supports (-webkit-appearance: none) {
  .mdl-progress:not(.mdl-progress--indeterminate):not(.mdl-progress--indeterminate) > .auxbar,
  .mdl-progress:not(.mdl-progress__indeterminate):not(.mdl-progress__indeterminate) > .auxbar {
    background-image: linear-gradient(to right, rgba(255,255,255, 0.7), rgba(255,255,255, 0.7)), linear-gradient(to right, rgb(33,150,243), rgb(33,150,243));
    mask: url("/vendor/material-design-lite/src/images/buffer.svg?embed"); } }

.mdl-progress:not(.mdl-progress--indeterminate) > .auxbar,
.mdl-progress:not(.mdl-progress__indeterminate) > .auxbar {
  background-image: linear-gradient(to right, rgba(255,255,255, 0.9), rgba(255,255,255, 0.9)), linear-gradient(to right, rgb(33,150,243), rgb(33,150,243)); }

.mdl-progress.mdl-progress--indeterminate > .bar1,
.mdl-progress.mdl-progress__indeterminate > .bar1 {
  background-color: rgb(33,150,243);
  animation-name: indeterminate1;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear; }

.mdl-progress.mdl-progress--indeterminate > .bar3,
.mdl-progress.mdl-progress__indeterminate > .bar3 {
  background-image: none;
  background-color: rgb(33,150,243);
  animation-name: indeterminate2;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear; }

@keyframes indeterminate1 {
  0% {
    left: 0%;
    width: 0%; }
  50% {
    left: 25%;
    width: 75%; }
  75% {
    left: 100%;
    width: 0%; } }

@keyframes indeterminate2 {
  0% {
    left: 0%;
    width: 0%; }
  50% {
    left: 0%;
    width: 0%; }
  75% {
    left: 0%;
    width: 25%; }
  100% {
    left: 100%;
    width: 0%; } }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
.mdl-navigation {
  display: flex;
  flex-wrap: nowrap;
  box-sizing: border-box; }

.mdl-navigation__link {
  color: rgb(66,66,66);
  text-decoration: none;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  opacity: 0.87; }
  .mdl-navigation__link .material-icons {
    vertical-align: middle; }

.mdl-layout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  -webkit-overflow-scrolling: touch; }

.mdl-layout.is-small-screen .mdl-layout--large-screen-only {
  display: none; }

.mdl-layout:not(.is-small-screen) .mdl-layout--small-screen-only {
  display: none; }

.mdl-layout__container {
  position: absolute;
  width: 100%;
  height: 100%; }

.mdl-layout__title,
.mdl-layout-title {
  display: block;
  position: relative;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.02em;
  font-weight: 400;
  box-sizing: border-box; }

.mdl-layout-spacer {
  flex-grow: 1; }

.mdl-layout__drawer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 240px;
  height: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-right: 1px solid rgb(224,224,224);
  background: rgb(250,250,250);
  transform: translateX(-250px);
  transform-style: preserve-3d;
  will-change: transform;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: transform;
  color: rgb(66,66,66);
  overflow: visible;
  overflow-y: auto;
  z-index: 5; }
  .mdl-layout__drawer.is-visible {
    transform: translateX(0); }
    .mdl-layout__drawer.is-visible ~ .mdl-layout__content.mdl-layout__content {
      overflow: hidden; }
  .mdl-layout__drawer > * {
    flex-shrink: 0; }
  .mdl-layout__drawer > .mdl-layout__title,
  .mdl-layout__drawer > .mdl-layout-title {
    line-height: 64px;
    padding-left: 40px; }
    @media screen and (max-width: 1024px) {
      .mdl-layout__drawer > .mdl-layout__title,
      .mdl-layout__drawer > .mdl-layout-title {
        line-height: 56px;
        padding-left: 16px; } }
  .mdl-layout__drawer .mdl-navigation {
    flex-direction: column;
    align-items: stretch;
    padding-top: 16px; }
    .mdl-layout__drawer .mdl-navigation .mdl-navigation__link {
      display: block;
      flex-shrink: 0;
      padding: 16px 40px;
      margin: 0;
      color: #757575; }
      @media screen and (max-width: 1024px) {
        .mdl-layout__drawer .mdl-navigation .mdl-navigation__link {
          padding: 16px 16px; } }
      .mdl-layout__drawer .mdl-navigation .mdl-navigation__link:hover {
        background-color: rgb(224,224,224); }
      .mdl-layout__drawer .mdl-navigation .mdl-navigation__link--current {
        background-color: rgb(224,224,224);
        color: rgb(0,0,0); }
  @media screen and (min-width: 1025px) {
    .mdl-layout--fixed-drawer > .mdl-layout__drawer {
      transform: translateX(0); } }

.mdl-layout__drawer-button {
  display: block;
  position: absolute;
  height: 48px;
  width: 48px;
  border: 0;
  flex-shrink: 0;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  font-size: 26px;
  line-height: 56px;
  font-family: Helvetica, Arial, sans-serif;
  margin: 8px 12px;
  top: 0;
  left: 0;
  color: rgb(255,255,255);
  z-index: 4; }
  .mdl-layout__header .mdl-layout__drawer-button {
    position: absolute;
    color: rgb(255,255,255);
    background-color: inherit; }
    @media screen and (max-width: 1024px) {
      .mdl-layout__header .mdl-layout__drawer-button {
        margin: 4px; } }
  @media screen and (max-width: 1024px) {
    .mdl-layout__drawer-button {
      margin: 4px;
      color: rgba(0, 0, 0, 0.5); } }
  @media screen and (min-width: 1025px) {
    .mdl-layout__drawer-button {
      line-height: 54px; }
      .mdl-layout--no-desktop-drawer-button .mdl-layout__drawer-button,
      .mdl-layout--fixed-drawer > .mdl-layout__drawer-button,
      .mdl-layout--no-drawer-button .mdl-layout__drawer-button {
        display: none; } }

.mdl-layout__header {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  border: none;
  min-height: 64px;
  max-height: 1000px;
  z-index: 3;
  background-color: rgb(33,150,243);
  color: rgb(255,255,255);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: max-height, box-shadow; }
  @media screen and (max-width: 1024px) {
    .mdl-layout__header {
      min-height: 56px; } }
  .mdl-layout--fixed-drawer.is-upgraded:not(.is-small-screen) > .mdl-layout__header {
    margin-left: 240px;
    width: calc(100% - 240px); }
  @media screen and (min-width: 1025px) {
    .mdl-layout--fixed-drawer > .mdl-layout__header .mdl-layout__header-row {
      padding-left: 40px; } }
  .mdl-layout__header > .mdl-layout-icon {
    position: absolute;
    left: 40px;
    top: 16px;
    height: 32px;
    width: 32px;
    overflow: hidden;
    z-index: 3;
    display: block; }
    @media screen and (max-width: 1024px) {
      .mdl-layout__header > .mdl-layout-icon {
        left: 16px;
        top: 12px; } }
  .mdl-layout.has-drawer .mdl-layout__header > .mdl-layout-icon {
    display: none; }
  .mdl-layout__header.is-compact {
    max-height: 64px; }
    @media screen and (max-width: 1024px) {
      .mdl-layout__header.is-compact {
        max-height: 56px; } }
  .mdl-layout__header.is-compact.has-tabs {
    height: 112px; }
    @media screen and (max-width: 1024px) {
      .mdl-layout__header.is-compact.has-tabs {
        min-height: 104px; } }
  @media screen and (max-width: 1024px) {
    .mdl-layout__header {
      display: none; }
    .mdl-layout--fixed-header > .mdl-layout__header {
      display: flex; } }

.mdl-layout__header--transparent.mdl-layout__header--transparent {
  background-color: transparent;
  box-shadow: none; }

.mdl-layout__header--seamed {
  box-shadow: none; }

.mdl-layout__header--scroll {
  box-shadow: none; }

.mdl-layout__header--waterfall {
  box-shadow: none;
  overflow: hidden; }
  .mdl-layout__header--waterfall.is-casting-shadow {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }
  .mdl-layout__header--waterfall.mdl-layout__header--waterfall-hide-top {
    justify-content: flex-end; }

.mdl-layout__header-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-shrink: 0;
  box-sizing: border-box;
  align-self: stretch;
  align-items: center;
  height: 64px;
  margin: 0;
  padding: 0 40px 0 80px; }
  .mdl-layout--no-drawer-button .mdl-layout__header-row {
    padding-left: 40px; }
  @media screen and (min-width: 1025px) {
    .mdl-layout--no-desktop-drawer-button .mdl-layout__header-row {
      padding-left: 40px; } }
  @media screen and (max-width: 1024px) {
    .mdl-layout__header-row {
      height: 56px;
      padding: 0 16px 0 72px; }
      .mdl-layout--no-drawer-button .mdl-layout__header-row {
        padding-left: 16px; } }
  .mdl-layout__header-row > * {
    flex-shrink: 0; }
  .mdl-layout__header--scroll .mdl-layout__header-row {
    width: 100%; }
  .mdl-layout__header-row .mdl-navigation {
    margin: 0;
    padding: 0;
    height: 64px;
    flex-direction: row;
    align-items: center; }
    @media screen and (max-width: 1024px) {
      .mdl-layout__header-row .mdl-navigation {
        height: 56px; } }
  .mdl-layout__header-row .mdl-navigation__link {
    display: block;
    color: rgb(255,255,255);
    line-height: 64px;
    padding: 0 24px; }
    @media screen and (max-width: 1024px) {
      .mdl-layout__header-row .mdl-navigation__link {
        line-height: 56px;
        padding: 0 16px; } }

.mdl-layout__obfuscator {
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 4;
  visibility: hidden;
  transition-property: background-color;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1); }
  .mdl-layout__obfuscator.is-visible {
    background-color: rgba(0, 0, 0, 0.5);
    visibility: visible; }
  @supports (pointer-events: auto) {
    .mdl-layout__obfuscator {
      background-color: rgba(0, 0, 0, 0.5);
      opacity: 0;
      transition-property: opacity;
      visibility: visible;
      pointer-events: none; }
      .mdl-layout__obfuscator.is-visible {
        pointer-events: auto;
        opacity: 1; } }

.mdl-layout__content {
  -ms-flex: 0 1 auto;
  position: relative;
  display: inline-block;
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  z-index: 1;
  -webkit-overflow-scrolling: touch; }
  .mdl-layout--fixed-drawer > .mdl-layout__content {
    margin-left: 240px; }
  .mdl-layout__container.has-scrolling-header .mdl-layout__content {
    overflow: visible; }
  @media screen and (max-width: 1024px) {
    .mdl-layout--fixed-drawer > .mdl-layout__content {
      margin-left: 0; }
    .mdl-layout__container.has-scrolling-header .mdl-layout__content {
      overflow-y: auto;
      overflow-x: hidden; } }

.mdl-layout__tab-bar {
  height: 96px;
  margin: 0;
  width: calc(100% - 112px);
  padding: 0 0 0 56px;
  display: flex;
  background-color: rgb(33,150,243);
  overflow-y: hidden;
  overflow-x: scroll; }
  .mdl-layout__tab-bar::-webkit-scrollbar {
    display: none; }
  .mdl-layout--no-drawer-button .mdl-layout__tab-bar {
    padding-left: 16px;
    width: calc(100% - 32px); }
  @media screen and (min-width: 1025px) {
    .mdl-layout--no-desktop-drawer-button .mdl-layout__tab-bar {
      padding-left: 16px;
      width: calc(100% - 32px); } }
  @media screen and (max-width: 1024px) {
    .mdl-layout__tab-bar {
      width: calc(100% - 60px);
      padding: 0 0 0 60px; }
      .mdl-layout--no-drawer-button .mdl-layout__tab-bar {
        width: calc(100% - 8px);
        padding-left: 4px; } }
  .mdl-layout--fixed-tabs .mdl-layout__tab-bar {
    padding: 0;
    overflow: hidden;
    width: 100%; }

.mdl-layout__tab-bar-container {
  position: relative;
  height: 48px;
  width: 100%;
  border: none;
  margin: 0;
  z-index: 2;
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden; }
  .mdl-layout__container > .mdl-layout__tab-bar-container {
    position: absolute;
    top: 0;
    left: 0; }

.mdl-layout__tab-bar-button {
  display: inline-block;
  position: absolute;
  top: 0;
  height: 48px;
  width: 56px;
  z-index: 4;
  text-align: center;
  background-color: rgb(33,150,243);
  color: transparent;
  cursor: pointer;
  user-select: none; }
  .mdl-layout--no-desktop-drawer-button .mdl-layout__tab-bar-button,
  .mdl-layout--no-drawer-button .mdl-layout__tab-bar-button {
    width: 16px; }
    .mdl-layout--no-desktop-drawer-button .mdl-layout__tab-bar-button .material-icons,
    .mdl-layout--no-drawer-button .mdl-layout__tab-bar-button .material-icons {
      position: relative;
      left: -4px; }
  @media screen and (max-width: 1024px) {
    .mdl-layout__tab-bar-button {
      width: 60px; } }
  .mdl-layout--fixed-tabs .mdl-layout__tab-bar-button {
    display: none; }
  .mdl-layout__tab-bar-button .material-icons {
    line-height: 48px; }
  .mdl-layout__tab-bar-button.is-active {
    color: rgb(255,255,255); }

.mdl-layout__tab-bar-left-button {
  left: 0; }

.mdl-layout__tab-bar-right-button {
  right: 0; }

.mdl-layout__tab {
  margin: 0;
  border: none;
  padding: 0 24px 0 24px;
  float: left;
  position: relative;
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  text-decoration: none;
  height: 48px;
  line-height: 48px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: rgba(255,255,255, 0.6);
  overflow: hidden; }
  @media screen and (max-width: 1024px) {
    .mdl-layout__tab {
      padding: 0 12px 0 12px; } }
  .mdl-layout--fixed-tabs .mdl-layout__tab {
    float: none;
    flex-grow: 1;
    padding: 0; }
  .mdl-layout.is-upgraded .mdl-layout__tab.is-active {
    color: rgb(255,255,255); }
  .mdl-layout.is-upgraded .mdl-layout__tab.is-active::after {
    height: 2px;
    width: 100%;
    display: block;
    content: " ";
    bottom: 0;
    left: 0;
    position: absolute;
    background: rgb(194,24,91);
    animation: border-expand 0.2s cubic-bezier(0.4, 0, 0.4, 1) 0.01s alternate forwards;
    transition: all 1s cubic-bezier(0.4, 0, 1, 1); }
  .mdl-layout__tab .mdl-layout__tab-ripple-container {
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    overflow: hidden; }
    .mdl-layout__tab .mdl-layout__tab-ripple-container .mdl-ripple {
      background-color: rgb(255,255,255); }

.mdl-layout__tab-panel {
  display: block; }
  .mdl-layout.is-upgraded .mdl-layout__tab-panel {
    display: none; }
  .mdl-layout.is-upgraded .mdl-layout__tab-panel.is-active {
    display: block; }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
.mdl-radio {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
  height: 24px;
  margin: 0;
  padding-left: 0; }
  .mdl-radio.is-upgraded {
    padding-left: 24px; }

.mdl-radio__button {
  line-height: 24px; }
  .mdl-radio.is-upgraded .mdl-radio__button {
    position: absolute;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
    -ms-appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: none; }

.mdl-radio__outer-circle {
  position: absolute;
  top: 4px;
  left: 0;
  display: inline-block;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  margin: 0;
  cursor: pointer;
  border: 2px solid rgba(0,0,0, 0.54);
  border-radius: 50%;
  z-index: 2; }
  .mdl-radio.is-checked .mdl-radio__outer-circle {
    border: 2px solid rgb(33,150,243); }
  .mdl-radio__outer-circle fieldset[disabled] .mdl-radio,
  .mdl-radio.is-disabled .mdl-radio__outer-circle {
    border: 2px solid rgba(0,0,0, 0.26);
    cursor: auto; }

.mdl-radio__inner-circle {
  position: absolute;
  z-index: 1;
  margin: 0;
  top: 8px;
  left: 4px;
  box-sizing: border-box;
  width: 8px;
  height: 8px;
  cursor: pointer;
  transition-duration: 0.28s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: transform;
  transform: scale(0, 0);
  border-radius: 50%;
  background: rgb(33,150,243); }
  .mdl-radio.is-checked .mdl-radio__inner-circle {
    transform: scale(1, 1); }
  fieldset[disabled] .mdl-radio .mdl-radio__inner-circle,
  .mdl-radio.is-disabled .mdl-radio__inner-circle {
    background: rgba(0,0,0, 0.26);
    cursor: auto; }
  .mdl-radio.is-focused .mdl-radio__inner-circle {
    box-shadow: 0 0 0px 10px rgba(0, 0, 0, 0.1); }

.mdl-radio__label {
  cursor: pointer; }
  fieldset[disabled] .mdl-radio .mdl-radio__label,
  .mdl-radio.is-disabled .mdl-radio__label {
    color: rgba(0,0,0, 0.26);
    cursor: auto; }

.mdl-radio__ripple-container {
  position: absolute;
  z-index: 2;
  top: -9px;
  left: -13px;
  box-sizing: border-box;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(circle, white, black); }
  .mdl-radio__ripple-container .mdl-ripple {
    background: rgb(33,150,243); }
  fieldset[disabled] .mdl-radio .mdl-radio__ripple-container,
  .mdl-radio.is-disabled .mdl-radio__ripple-container {
    cursor: auto; }
  fieldset[disabled] .mdl-radio .mdl-radio__ripple-container .mdl-ripple,
  .mdl-radio.is-disabled .mdl-radio__ripple-container .mdl-ripple {
    background: transparent; }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
_:-ms-input-placeholder, :root .mdl-slider.mdl-slider.is-upgraded {
  -ms-appearance: none;
  height: 32px;
  margin: 0; }

.mdl-slider {
  width: calc(100% - 40px);
  margin: 0 20px; }
  .mdl-slider.is-upgraded {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 2px;
    background: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    outline: 0;
    padding: 0;
    color: rgb(33,150,243);
    align-self: center;
    z-index: 1;
    cursor: pointer;
    /**************************** Tracks ****************************/
    /**************************** Thumbs ****************************/
    /**************************** 0-value ****************************/
    /**************************** Disabled ****************************/ }
    .mdl-slider.is-upgraded::-moz-focus-outer {
      border: 0; }
    .mdl-slider.is-upgraded::-ms-tooltip {
      display: none; }
    .mdl-slider.is-upgraded::-webkit-slider-runnable-track {
      background: transparent; }
    .mdl-slider.is-upgraded::-moz-range-track {
      background: transparent;
      border: none; }
    .mdl-slider.is-upgraded::-ms-track {
      background: none;
      color: transparent;
      height: 2px;
      width: 100%;
      border: none; }
    .mdl-slider.is-upgraded::-ms-fill-lower {
      padding: 0;
      background: linear-gradient(to right, transparent, transparent 16px, rgb(33,150,243) 16px, rgb(33,150,243) 0); }
    .mdl-slider.is-upgraded::-ms-fill-upper {
      padding: 0;
      background: linear-gradient(to left, transparent, transparent 16px, rgba(0,0,0, 0.26) 16px, rgba(0,0,0, 0.26) 0); }
    .mdl-slider.is-upgraded::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 12px;
      height: 12px;
      box-sizing: border-box;
      border-radius: 50%;
      background: rgb(33,150,243);
      border: none;
      transition: transform 0.18s cubic-bezier(0.4, 0, 0.2, 1), border 0.18s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.18s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1); }
    .mdl-slider.is-upgraded::-moz-range-thumb {
      -moz-appearance: none;
      width: 12px;
      height: 12px;
      box-sizing: border-box;
      border-radius: 50%;
      background-image: none;
      background: rgb(33,150,243);
      border: none; }
    .mdl-slider.is-upgraded:focus:not(:active)::-webkit-slider-thumb {
      box-shadow: 0 0 0 10px rgba(33,150,243, 0.26); }
    .mdl-slider.is-upgraded:focus:not(:active)::-moz-range-thumb {
      box-shadow: 0 0 0 10px rgba(33,150,243, 0.26); }
    .mdl-slider.is-upgraded:active::-webkit-slider-thumb {
      background-image: none;
      background: rgb(33,150,243);
      transform: scale(1.5); }
    .mdl-slider.is-upgraded:active::-moz-range-thumb {
      background-image: none;
      background: rgb(33,150,243);
      transform: scale(1.5); }
    .mdl-slider.is-upgraded::-ms-thumb {
      width: 32px;
      height: 32px;
      border: none;
      border-radius: 50%;
      background: rgb(33,150,243);
      transform: scale(0.375);
      transition: transform 0.18s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1); }
    .mdl-slider.is-upgraded:focus:not(:active)::-ms-thumb {
      background: radial-gradient(circle closest-side, rgb(33,150,243) 0%, rgb(33,150,243) 37.5%, rgba(33,150,243, 0.26) 37.5%, rgba(33,150,243, 0.26) 100%);
      transform: scale(1); }
    .mdl-slider.is-upgraded:active::-ms-thumb {
      background: rgb(33,150,243);
      transform: scale(0.5625); }
    .mdl-slider.is-upgraded.is-lowest-value::-webkit-slider-thumb {
      border: 2px solid rgba(0,0,0, 0.26);
      background: transparent; }
    .mdl-slider.is-upgraded.is-lowest-value::-moz-range-thumb {
      border: 2px solid rgba(0,0,0, 0.26);
      background: transparent; }
    .mdl-slider.is-upgraded.is-lowest-value +
.mdl-slider__background-flex > .mdl-slider__background-upper {
      left: 6px; }
    .mdl-slider.is-upgraded.is-lowest-value:focus:not(:active)::-webkit-slider-thumb {
      box-shadow: 0 0 0 10px rgba(0,0,0, 0.12);
      background: rgba(0,0,0, 0.12); }
    .mdl-slider.is-upgraded.is-lowest-value:focus:not(:active)::-moz-range-thumb {
      box-shadow: 0 0 0 10px rgba(0,0,0, 0.12);
      background: rgba(0,0,0, 0.12); }
    .mdl-slider.is-upgraded.is-lowest-value:active::-webkit-slider-thumb {
      border: 1.6px solid rgba(0,0,0, 0.26);
      transform: scale(1.5); }
    .mdl-slider.is-upgraded.is-lowest-value:active +
.mdl-slider__background-flex > .mdl-slider__background-upper {
      left: 9px; }
    .mdl-slider.is-upgraded.is-lowest-value:active::-moz-range-thumb {
      border: 1.5px solid rgba(0,0,0, 0.26);
      transform: scale(1.5); }
    .mdl-slider.is-upgraded.is-lowest-value::-ms-thumb {
      background: radial-gradient(circle closest-side, transparent 0%, transparent 66.67%, rgba(0,0,0, 0.26) 66.67%, rgba(0,0,0, 0.26) 100%); }
    .mdl-slider.is-upgraded.is-lowest-value:focus:not(:active)::-ms-thumb {
      background: radial-gradient(circle closest-side, rgba(0,0,0, 0.12) 0%, rgba(0,0,0, 0.12) 25%, rgba(0,0,0, 0.26) 25%, rgba(0,0,0, 0.26) 37.5%, rgba(0,0,0, 0.12) 37.5%, rgba(0,0,0, 0.12) 100%);
      transform: scale(1); }
    .mdl-slider.is-upgraded.is-lowest-value:active::-ms-thumb {
      transform: scale(0.5625);
      background: radial-gradient(circle closest-side, transparent 0%, transparent 77.78%, rgba(0,0,0, 0.26) 77.78%, rgba(0,0,0, 0.26) 100%); }
    .mdl-slider.is-upgraded.is-lowest-value::-ms-fill-lower {
      background: transparent; }
    .mdl-slider.is-upgraded.is-lowest-value::-ms-fill-upper {
      margin-left: 6px; }
    .mdl-slider.is-upgraded.is-lowest-value:active::-ms-fill-upper {
      margin-left: 9px; }
    .mdl-slider.is-upgraded:disabled:focus::-webkit-slider-thumb, .mdl-slider.is-upgraded:disabled:active::-webkit-slider-thumb, .mdl-slider.is-upgraded:disabled::-webkit-slider-thumb {
      transform: scale(0.667);
      background: rgba(0,0,0, 0.26); }
    .mdl-slider.is-upgraded:disabled:focus::-moz-range-thumb, .mdl-slider.is-upgraded:disabled:active::-moz-range-thumb, .mdl-slider.is-upgraded:disabled::-moz-range-thumb {
      transform: scale(0.667);
      background: rgba(0,0,0, 0.26); }
    .mdl-slider.is-upgraded:disabled +
.mdl-slider__background-flex > .mdl-slider__background-lower {
      background-color: rgba(0,0,0, 0.26);
      left: -6px; }
    .mdl-slider.is-upgraded:disabled +
.mdl-slider__background-flex > .mdl-slider__background-upper {
      left: 6px; }
    .mdl-slider.is-upgraded.is-lowest-value:disabled:focus::-webkit-slider-thumb, .mdl-slider.is-upgraded.is-lowest-value:disabled:active::-webkit-slider-thumb, .mdl-slider.is-upgraded.is-lowest-value:disabled::-webkit-slider-thumb {
      border: 3px solid rgba(0,0,0, 0.26);
      background: transparent;
      transform: scale(0.667); }
    .mdl-slider.is-upgraded.is-lowest-value:disabled:focus::-moz-range-thumb, .mdl-slider.is-upgraded.is-lowest-value:disabled:active::-moz-range-thumb, .mdl-slider.is-upgraded.is-lowest-value:disabled::-moz-range-thumb {
      border: 3px solid rgba(0,0,0, 0.26);
      background: transparent;
      transform: scale(0.667); }
    .mdl-slider.is-upgraded.is-lowest-value:disabled:active +
.mdl-slider__background-flex > .mdl-slider__background-upper {
      left: 6px; }
    .mdl-slider.is-upgraded:disabled:focus::-ms-thumb, .mdl-slider.is-upgraded:disabled:active::-ms-thumb, .mdl-slider.is-upgraded:disabled::-ms-thumb {
      transform: scale(0.25);
      background: rgba(0,0,0, 0.26); }
    .mdl-slider.is-upgraded.is-lowest-value:disabled:focus::-ms-thumb, .mdl-slider.is-upgraded.is-lowest-value:disabled:active::-ms-thumb, .mdl-slider.is-upgraded.is-lowest-value:disabled::-ms-thumb {
      transform: scale(0.25);
      background: radial-gradient(circle closest-side, transparent 0%, transparent 50%, rgba(0,0,0, 0.26) 50%, rgba(0,0,0, 0.26) 100%); }
    .mdl-slider.is-upgraded:disabled::-ms-fill-lower {
      margin-right: 6px;
      background: linear-gradient(to right, transparent, transparent 25px, rgba(0,0,0, 0.26) 25px, rgba(0,0,0, 0.26) 0); }
    .mdl-slider.is-upgraded:disabled::-ms-fill-upper {
      margin-left: 6px; }
    .mdl-slider.is-upgraded.is-lowest-value:disabled:active::-ms-fill-upper {
      margin-left: 6px; }

.mdl-slider__ie-container {
  height: 18px;
  overflow: visible;
  border: none;
  margin: none;
  padding: none; }

.mdl-slider__container {
  height: 18px;
  position: relative;
  background: none;
  display: flex;
  flex-direction: row; }

.mdl-slider__background-flex {
  background: transparent;
  position: absolute;
  height: 2px;
  width: calc(100% - 52px);
  top: 50%;
  left: 0;
  margin: 0 26px;
  display: flex;
  overflow: hidden;
  border: 0;
  padding: 0;
  transform: translate(0, -1px); }

.mdl-slider__background-lower {
  background: rgb(33,150,243);
  flex: 0;
  position: relative;
  border: 0;
  padding: 0; }

.mdl-slider__background-upper {
  background: rgba(0,0,0, 0.26);
  flex: 0;
  position: relative;
  border: 0;
  padding: 0;
  transition: left 0.18s cubic-bezier(0.4, 0, 0.2, 1); }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
.mdl-snackbar {
  position: fixed;
  bottom: 0;
  left: 50%;
  cursor: default;
  background-color: #323232;
  z-index: 3;
  display: block;
  display: flex;
  justify-content: space-between;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  will-change: transform;
  transform: translate(0, 80px);
  transition: transform 0.25s cubic-bezier(0.4, 0, 1, 1);
  pointer-events: none; }
  @media (max-width: 767px) {
    .mdl-snackbar {
      width: 100%;
      left: 0;
      min-height: 48px;
      max-height: 80px; } }
  @media (min-width: 768px) {
    .mdl-snackbar {
      min-width: 288px;
      max-width: 568px;
      border-radius: 2px;
      transform: translate(-50%, 80px); } }
  .mdl-snackbar--active {
    transform: translate(0, 0);
    pointer-events: auto;
    transition: transform 0.25s cubic-bezier(0, 0, 0.2, 1); }
    @media (min-width: 768px) {
      .mdl-snackbar--active {
        transform: translate(-50%, 0); } }
  .mdl-snackbar__text {
    padding: 14px 12px 14px 24px;
    vertical-align: middle;
    color: white;
    float: left; }
  .mdl-snackbar__action {
    background: transparent;
    border: none;
    color: rgb(194,24,91);
    float: right;
    text-transform: uppercase;
    padding: 14px 24px 14px 12px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1;
    letter-spacing: 0;
    overflow: hidden;
    outline: none;
    opacity: 0;
    pointer-events: none;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    align-self: center; }
    .mdl-snackbar__action::-moz-focus-inner {
      border: 0; }
    .mdl-snackbar__action:not([aria-hidden]) {
      opacity: 1;
      pointer-events: auto; }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
.mdl-spinner {
  display: inline-block;
  position: relative;
  width: 28px;
  height: 28px; }
  .mdl-spinner:not(.is-upgraded).is-active:after {
    content: "Loading..."; }
  .mdl-spinner.is-upgraded.is-active {
    animation: mdl-spinner__container-rotate 1568.23529412ms linear infinite; }

@keyframes mdl-spinner__container-rotate {
  to {
    transform: rotate(360deg); } }

.mdl-spinner__layer {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0; }

.mdl-spinner__layer-1 {
  border-color: rgb(66,165,245); }
  .mdl-spinner--single-color .mdl-spinner__layer-1 {
    border-color: rgb(33,150,243); }
  .mdl-spinner.is-active .mdl-spinner__layer-1 {
    animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdl-spinner__layer-1-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both; }

.mdl-spinner__layer-2 {
  border-color: rgb(244,67,54); }
  .mdl-spinner--single-color .mdl-spinner__layer-2 {
    border-color: rgb(33,150,243); }
  .mdl-spinner.is-active .mdl-spinner__layer-2 {
    animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdl-spinner__layer-2-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both; }

.mdl-spinner__layer-3 {
  border-color: rgb(253,216,53); }
  .mdl-spinner--single-color .mdl-spinner__layer-3 {
    border-color: rgb(33,150,243); }
  .mdl-spinner.is-active .mdl-spinner__layer-3 {
    animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdl-spinner__layer-3-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both; }

.mdl-spinner__layer-4 {
  border-color: rgb(76,175,80); }
  .mdl-spinner--single-color .mdl-spinner__layer-4 {
    border-color: rgb(33,150,243); }
  .mdl-spinner.is-active .mdl-spinner__layer-4 {
    animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdl-spinner__layer-4-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both; }

@keyframes mdl-spinner__fill-unfill-rotate {
  12.5% {
    transform: rotate(135deg); }
  25% {
    transform: rotate(270deg); }
  37.5% {
    transform: rotate(405deg); }
  50% {
    transform: rotate(540deg); }
  62.5% {
    transform: rotate(675deg); }
  75% {
    transform: rotate(810deg); }
  87.5% {
    transform: rotate(945deg); }
  to {
    transform: rotate(1080deg); } }

/**
* HACK: Even though the intention is to have the current .mdl-spinner__layer-N
* at `opacity: 1`, we set it to `opacity: 0.99` instead since this forces Chrome
* to do proper subpixel rendering for the elements being animated. This is
* especially visible in Chrome 39 on Ubuntu 14.04. See:
*
* - https://github.com/Polymer/paper-spinner/issues/9
* - https://code.google.com/p/chromium/issues/detail?id=436255
*/
@keyframes mdl-spinner__layer-1-fade-in-out {
  from {
    opacity: 0.99; }
  25% {
    opacity: 0.99; }
  26% {
    opacity: 0; }
  89% {
    opacity: 0; }
  90% {
    opacity: 0.99; }
  100% {
    opacity: 0.99; } }

@keyframes mdl-spinner__layer-2-fade-in-out {
  from {
    opacity: 0; }
  15% {
    opacity: 0; }
  25% {
    opacity: 0.99; }
  50% {
    opacity: 0.99; }
  51% {
    opacity: 0; } }

@keyframes mdl-spinner__layer-3-fade-in-out {
  from {
    opacity: 0; }
  40% {
    opacity: 0; }
  50% {
    opacity: 0.99; }
  75% {
    opacity: 0.99; }
  76% {
    opacity: 0; } }

@keyframes mdl-spinner__layer-4-fade-in-out {
  from {
    opacity: 0; }
  65% {
    opacity: 0; }
  75% {
    opacity: 0.99; }
  90% {
    opacity: 0.99; }
  100% {
    opacity: 0; } }

/**
* Patch the gap that appear between the two adjacent
* div.mdl-spinner__circle-clipper while the spinner is rotating
* (appears on Chrome 38, Safari 7.1, and IE 11).
*
* Update: the gap no longer appears on Chrome when .mdl-spinner__layer-N's
* opacity is 0.99, but still does on Safari and IE.
*/
.mdl-spinner__gap-patch {
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 45%;
  width: 10%;
  height: 100%;
  overflow: hidden;
  border-color: inherit; }
  .mdl-spinner__gap-patch .mdl-spinner__circle {
    width: 1000%;
    left: -450%; }

.mdl-spinner__circle-clipper {
  display: inline-block;
  position: relative;
  width: 50%;
  height: 100%;
  overflow: hidden;
  border-color: inherit; }
  .mdl-spinner__circle-clipper.mdl-spinner__left {
    float: left; }
  .mdl-spinner__circle-clipper.mdl-spinner__right {
    float: right; }
  .mdl-spinner__circle-clipper .mdl-spinner__circle {
    width: 200%; }

.mdl-spinner__circle {
  box-sizing: border-box;
  height: 100%;
  border-width: 3px;
  border-style: solid;
  border-color: inherit;
  border-bottom-color: transparent !important;
  border-radius: 50%;
  animation: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }
  .mdl-spinner__left .mdl-spinner__circle {
    border-right-color: transparent !important;
    transform: rotate(129deg); }
    .mdl-spinner.is-active .mdl-spinner__left .mdl-spinner__circle {
      animation: mdl-spinner__left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both; }
  .mdl-spinner__right .mdl-spinner__circle {
    left: -100%;
    border-left-color: transparent !important;
    transform: rotate(-129deg); }
    .mdl-spinner.is-active .mdl-spinner__right .mdl-spinner__circle {
      animation: mdl-spinner__right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both; }

@keyframes mdl-spinner__left-spin {
  from {
    transform: rotate(130deg); }
  50% {
    transform: rotate(-5deg); }
  to {
    transform: rotate(130deg); } }

@keyframes mdl-spinner__right-spin {
  from {
    transform: rotate(-130deg); }
  50% {
    transform: rotate(5deg); }
  to {
    transform: rotate(-130deg); } }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
.mdl-switch {
  position: relative;
  z-index: 1;
  vertical-align: middle;
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  height: 24px;
  margin: 0;
  padding: 0;
  overflow: visible;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .mdl-switch.is-upgraded {
    padding-left: 28px; }

.mdl-switch__input {
  line-height: 24px; }
  .mdl-switch.is-upgraded .mdl-switch__input {
    position: absolute;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
    -ms-appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: none; }

.mdl-switch__track {
  background: rgba(0,0,0, 0.26);
  position: absolute;
  left: 0;
  top: 5px;
  height: 14px;
  width: 36px;
  border-radius: 14px;
  cursor: pointer; }
  .mdl-switch.is-checked .mdl-switch__track {
    background: rgba(33,150,243, 0.5); }
  .mdl-switch__track fieldset[disabled] .mdl-switch,
  .mdl-switch.is-disabled .mdl-switch__track {
    background: rgba(0,0,0, 0.12);
    cursor: auto; }

.mdl-switch__thumb {
  background: rgb(250,250,250);
  position: absolute;
  left: 0;
  top: 2px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition-duration: 0.28s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: left; }
  .mdl-switch.is-checked .mdl-switch__thumb {
    background: rgb(33,150,243);
    left: 16px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 1px 8px 0 rgba(0, 0, 0, 0.12); }
  .mdl-switch__thumb fieldset[disabled] .mdl-switch,
  .mdl-switch.is-disabled .mdl-switch__thumb {
    background: rgb(189,189,189);
    cursor: auto; }

.mdl-switch__focus-helper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-4px, -4px);
  display: inline-block;
  box-sizing: border-box;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: transparent; }
  .mdl-switch.is-focused .mdl-switch__focus-helper {
    box-shadow: 0 0 0px 20px rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.1); }
  .mdl-switch.is-focused.is-checked .mdl-switch__focus-helper {
    box-shadow: 0 0 0px 20px rgba(33,150,243, 0.26);
    background-color: rgba(33,150,243, 0.26); }

.mdl-switch__label {
  position: relative;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  left: 24px; }
  .mdl-switch__label fieldset[disabled] .mdl-switch,
  .mdl-switch.is-disabled .mdl-switch__label {
    color: rgb(189,189,189);
    cursor: auto; }

.mdl-switch__ripple-container {
  position: absolute;
  z-index: 2;
  top: -12px;
  left: -14px;
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(circle, white, black);
  transition-duration: 0.40s;
  transition-timing-function: step-end;
  transition-property: left; }
  .mdl-switch__ripple-container .mdl-ripple {
    background: rgb(33,150,243); }
  .mdl-switch__ripple-container fieldset[disabled] .mdl-switch,
  .mdl-switch.is-disabled .mdl-switch__ripple-container {
    cursor: auto; }
  fieldset[disabled] .mdl-switch .mdl-switch__ripple-container .mdl-ripple,
  .mdl-switch.is-disabled .mdl-switch__ripple-container .mdl-ripple {
    background: transparent; }
  .mdl-switch.is-checked .mdl-switch__ripple-container {
    left: 2px; }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
.mdl-tabs {
  display: block;
  width: 100%; }

.mdl-tabs__tab-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: space-between;
  align-items: flex-start;
  height: 48px;
  padding: 0 0 0 0;
  margin: 0;
  border-bottom: 1px solid rgb(224,224,224); }

.mdl-tabs__tab {
  margin: 0;
  border: none;
  padding: 0 24px 0 24px;
  float: left;
  position: relative;
  display: block;
  text-decoration: none;
  height: 48px;
  line-height: 48px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: rgba(0,0,0, 0.54);
  overflow: hidden; }
  .mdl-tabs.is-upgraded .mdl-tabs__tab.is-active {
    color: rgba(0,0,0, 0.87); }
  .mdl-tabs.is-upgraded .mdl-tabs__tab.is-active:after {
    height: 2px;
    width: 100%;
    display: block;
    content: " ";
    bottom: 0px;
    left: 0px;
    position: absolute;
    background: rgb(33,150,243);
    animation: border-expand 0.2s cubic-bezier(0.4, 0, 0.4, 1) 0.01s alternate forwards;
    transition: all 1s cubic-bezier(0.4, 0, 1, 1); }
  .mdl-tabs__tab .mdl-tabs__ripple-container {
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0px;
    top: 0px;
    z-index: 1;
    overflow: hidden; }
    .mdl-tabs__tab .mdl-tabs__ripple-container .mdl-ripple {
      background: rgb(33,150,243); }

.mdl-tabs__panel {
  display: block; }
  .mdl-tabs.is-upgraded .mdl-tabs__panel {
    display: none; }
  .mdl-tabs.is-upgraded .mdl-tabs__panel.is-active {
    display: block; }

@keyframes border-expand {
  0% {
    opacity: 0;
    width: 0; }
  100% {
    opacity: 1;
    width: 100%; } }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
.mdl-textfield {
  position: relative;
  font-size: 16px;
  display: inline-block;
  box-sizing: border-box;
  width: 300px;
  max-width: 100%;
  margin: 0;
  padding: 20px 0; }
  .mdl-textfield .mdl-button {
    position: absolute;
    bottom: 20px; }

.mdl-textfield--align-right {
  text-align: right; }

.mdl-textfield--full-width {
  width: 100%; }

.mdl-textfield--expandable {
  min-width: 32px;
  width: auto;
  min-height: 32px; }
  .mdl-textfield--expandable .mdl-button--icon {
    top: 16px; }

.mdl-textfield__input {
  border: none;
  border-bottom: 1px solid rgba(0,0,0, 0.12);
  display: block;
  font-size: 16px;
  font-family: "Helvetica", "Arial", sans-serif;
  margin: 0;
  padding: 4px 0;
  width: 100%;
  background: none;
  text-align: left;
  color: inherit; }
  .mdl-textfield__input[type="number"] {
    -moz-appearance: textfield; }
  .mdl-textfield__input[type="number"]::-webkit-inner-spin-button, .mdl-textfield__input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .mdl-textfield.is-focused .mdl-textfield__input {
    outline: none; }
  .mdl-textfield.is-invalid .mdl-textfield__input {
    border-color: rgb(213,0,0);
    box-shadow: none; }
  fieldset[disabled] .mdl-textfield .mdl-textfield__input,
  .mdl-textfield.is-disabled .mdl-textfield__input {
    background-color: transparent;
    border-bottom: 1px dotted rgba(0,0,0, 0.12);
    color: rgba(0,0,0, 0.26); }

.mdl-textfield textarea.mdl-textfield__input {
  display: block; }

.mdl-textfield__label {
  bottom: 0;
  color: rgba(0,0,0, 0.26);
  font-size: 16px;
  left: 0;
  right: 0;
  pointer-events: none;
  position: absolute;
  display: block;
  top: 24px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-align: left; }
  .mdl-textfield.is-dirty .mdl-textfield__label,
  .mdl-textfield.has-placeholder .mdl-textfield__label {
    visibility: hidden; }
  .mdl-textfield--floating-label .mdl-textfield__label {
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1); }
  .mdl-textfield--floating-label.has-placeholder .mdl-textfield__label {
    transition: none; }
  fieldset[disabled] .mdl-textfield .mdl-textfield__label,
  .mdl-textfield.is-disabled.is-disabled .mdl-textfield__label {
    color: rgba(0,0,0, 0.26); }
  .mdl-textfield--floating-label.is-focused .mdl-textfield__label,
  .mdl-textfield--floating-label.is-dirty .mdl-textfield__label,
  .mdl-textfield--floating-label.has-placeholder .mdl-textfield__label {
    color: rgb(33,150,243);
    font-size: 12px;
    top: 4px;
    visibility: visible; }
  .mdl-textfield--floating-label.is-focused .mdl-textfield__expandable-holder .mdl-textfield__label,
  .mdl-textfield--floating-label.is-dirty .mdl-textfield__expandable-holder .mdl-textfield__label,
  .mdl-textfield--floating-label.has-placeholder .mdl-textfield__expandable-holder .mdl-textfield__label {
    top: -16px; }
  .mdl-textfield--floating-label.is-invalid .mdl-textfield__label {
    color: rgb(213,0,0);
    font-size: 12px; }
  .mdl-textfield__label:after {
    background-color: rgb(33,150,243);
    bottom: 20px;
    content: '';
    height: 2px;
    left: 45%;
    position: absolute;
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    visibility: hidden;
    width: 10px; }
  .mdl-textfield.is-focused .mdl-textfield__label:after {
    left: 0;
    visibility: visible;
    width: 100%; }
  .mdl-textfield.is-invalid .mdl-textfield__label:after {
    background-color: rgb(213,0,0); }

.mdl-textfield__error {
  color: rgb(213,0,0);
  position: absolute;
  font-size: 12px;
  margin-top: 3px;
  visibility: hidden;
  display: block; }
  .mdl-textfield.is-invalid .mdl-textfield__error {
    visibility: visible; }

.mdl-textfield__expandable-holder {
  display: inline-block;
  position: relative;
  margin-left: 32px;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  display: inline-block;
  max-width: 0.1px; }
  .mdl-textfield.is-focused .mdl-textfield__expandable-holder, .mdl-textfield.is-dirty .mdl-textfield__expandable-holder {
    max-width: 600px; }
  .mdl-textfield__expandable-holder .mdl-textfield__label:after {
    bottom: 0; }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
.mdl-tooltip {
  transform: scale(0);
  transform-origin: top center;
  z-index: 999;
  background: rgba(97,97,97, 0.9);
  border-radius: 2px;
  color: rgb(255,255,255);
  display: inline-block;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  max-width: 170px;
  position: fixed;
  top: -500px;
  left: -500px;
  padding: 8px;
  text-align: center; }

.mdl-tooltip.is-active {
  animation: pulse 200ms cubic-bezier(0, 0, 0.2, 1) forwards; }

.mdl-tooltip--large {
  line-height: 14px;
  font-size: 14px;
  padding: 16px; }

@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 0; }
  50% {
    transform: scale(0.99); }
  100% {
    transform: scale(1);
    opacity: 1;
    visibility: visible; } }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
.mdl-shadow--2dp {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }

.mdl-shadow--3dp {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 1px 8px 0 rgba(0, 0, 0, 0.12); }

.mdl-shadow--4dp {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2); }

.mdl-shadow--6dp {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2); }

.mdl-shadow--8dp {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2); }

.mdl-shadow--16dp {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2); }

.mdl-shadow--24dp {
  box-shadow: 0 9px 46px 8px rgba(0, 0, 0, 0.14), 0 11px 15px -7px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.2); }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*
* NOTE: Some rules here are applied using duplicate selectors.
* This is on purpose to increase their specificity when applied.
* For example: `.mdl-cell--1-col-phone.mdl-cell--1-col-phone`
*/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
.mdl-grid {
  display: flex;
  flex-flow: row wrap;
  margin: 0 auto 0 auto;
  align-items: stretch; }
  .mdl-grid.mdl-grid--no-spacing {
    padding: 0; }

.mdl-cell {
  box-sizing: border-box; }

.mdl-cell--top {
  align-self: flex-start; }

.mdl-cell--middle {
  align-self: center; }

.mdl-cell--bottom {
  align-self: flex-end; }

.mdl-cell--stretch {
  align-self: stretch; }

.mdl-grid.mdl-grid--no-spacing > .mdl-cell {
  margin: 0; }

.mdl-cell--order-1 {
  order: 1; }

.mdl-cell--order-2 {
  order: 2; }

.mdl-cell--order-3 {
  order: 3; }

.mdl-cell--order-4 {
  order: 4; }

.mdl-cell--order-5 {
  order: 5; }

.mdl-cell--order-6 {
  order: 6; }

.mdl-cell--order-7 {
  order: 7; }

.mdl-cell--order-8 {
  order: 8; }

.mdl-cell--order-9 {
  order: 9; }

.mdl-cell--order-10 {
  order: 10; }

.mdl-cell--order-11 {
  order: 11; }

.mdl-cell--order-12 {
  order: 12; }

@media (max-width: 767px) {
  .mdl-grid {
    padding: 8px; }
  .mdl-cell {
    margin: 8px;
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell {
      width: 100%; }
  .mdl-cell--hide-phone {
    display: none !important; }
  .mdl-cell--order-1-phone.mdl-cell--order-1-phone {
    order: 1; }
  .mdl-cell--order-2-phone.mdl-cell--order-2-phone {
    order: 2; }
  .mdl-cell--order-3-phone.mdl-cell--order-3-phone {
    order: 3; }
  .mdl-cell--order-4-phone.mdl-cell--order-4-phone {
    order: 4; }
  .mdl-cell--order-5-phone.mdl-cell--order-5-phone {
    order: 5; }
  .mdl-cell--order-6-phone.mdl-cell--order-6-phone {
    order: 6; }
  .mdl-cell--order-7-phone.mdl-cell--order-7-phone {
    order: 7; }
  .mdl-cell--order-8-phone.mdl-cell--order-8-phone {
    order: 8; }
  .mdl-cell--order-9-phone.mdl-cell--order-9-phone {
    order: 9; }
  .mdl-cell--order-10-phone.mdl-cell--order-10-phone {
    order: 10; }
  .mdl-cell--order-11-phone.mdl-cell--order-11-phone {
    order: 11; }
  .mdl-cell--order-12-phone.mdl-cell--order-12-phone {
    order: 12; }
  .mdl-cell--1-col,
  .mdl-cell--1-col-phone.mdl-cell--1-col-phone {
    width: calc(25% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--1-col, .mdl-grid--no-spacing >
    .mdl-cell--1-col-phone.mdl-cell--1-col-phone {
      width: 25%; }
  .mdl-cell--2-col,
  .mdl-cell--2-col-phone.mdl-cell--2-col-phone {
    width: calc(50% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--2-col, .mdl-grid--no-spacing >
    .mdl-cell--2-col-phone.mdl-cell--2-col-phone {
      width: 50%; }
  .mdl-cell--3-col,
  .mdl-cell--3-col-phone.mdl-cell--3-col-phone {
    width: calc(75% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--3-col, .mdl-grid--no-spacing >
    .mdl-cell--3-col-phone.mdl-cell--3-col-phone {
      width: 75%; }
  .mdl-cell--4-col,
  .mdl-cell--4-col-phone.mdl-cell--4-col-phone {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--4-col, .mdl-grid--no-spacing >
    .mdl-cell--4-col-phone.mdl-cell--4-col-phone {
      width: 100%; }
  .mdl-cell--5-col,
  .mdl-cell--5-col-phone.mdl-cell--5-col-phone {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--5-col, .mdl-grid--no-spacing >
    .mdl-cell--5-col-phone.mdl-cell--5-col-phone {
      width: 100%; }
  .mdl-cell--6-col,
  .mdl-cell--6-col-phone.mdl-cell--6-col-phone {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--6-col, .mdl-grid--no-spacing >
    .mdl-cell--6-col-phone.mdl-cell--6-col-phone {
      width: 100%; }
  .mdl-cell--7-col,
  .mdl-cell--7-col-phone.mdl-cell--7-col-phone {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--7-col, .mdl-grid--no-spacing >
    .mdl-cell--7-col-phone.mdl-cell--7-col-phone {
      width: 100%; }
  .mdl-cell--8-col,
  .mdl-cell--8-col-phone.mdl-cell--8-col-phone {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--8-col, .mdl-grid--no-spacing >
    .mdl-cell--8-col-phone.mdl-cell--8-col-phone {
      width: 100%; }
  .mdl-cell--9-col,
  .mdl-cell--9-col-phone.mdl-cell--9-col-phone {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--9-col, .mdl-grid--no-spacing >
    .mdl-cell--9-col-phone.mdl-cell--9-col-phone {
      width: 100%; }
  .mdl-cell--10-col,
  .mdl-cell--10-col-phone.mdl-cell--10-col-phone {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--10-col, .mdl-grid--no-spacing >
    .mdl-cell--10-col-phone.mdl-cell--10-col-phone {
      width: 100%; }
  .mdl-cell--11-col,
  .mdl-cell--11-col-phone.mdl-cell--11-col-phone {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--11-col, .mdl-grid--no-spacing >
    .mdl-cell--11-col-phone.mdl-cell--11-col-phone {
      width: 100%; }
  .mdl-cell--12-col,
  .mdl-cell--12-col-phone.mdl-cell--12-col-phone {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--12-col, .mdl-grid--no-spacing >
    .mdl-cell--12-col-phone.mdl-cell--12-col-phone {
      width: 100%; }
  .mdl-cell--1-offset,
  .mdl-cell--1-offset-phone.mdl-cell--1-offset-phone {
    margin-left: calc(25% + 8px); }
    .mdl-grid.mdl-grid--no-spacing > .mdl-cell--1-offset, .mdl-grid.mdl-grid--no-spacing >
    .mdl-cell--1-offset-phone.mdl-cell--1-offset-phone {
      margin-left: 25%; }
  .mdl-cell--2-offset,
  .mdl-cell--2-offset-phone.mdl-cell--2-offset-phone {
    margin-left: calc(50% + 8px); }
    .mdl-grid.mdl-grid--no-spacing > .mdl-cell--2-offset, .mdl-grid.mdl-grid--no-spacing >
    .mdl-cell--2-offset-phone.mdl-cell--2-offset-phone {
      margin-left: 50%; }
  .mdl-cell--3-offset,
  .mdl-cell--3-offset-phone.mdl-cell--3-offset-phone {
    margin-left: calc(75% + 8px); }
    .mdl-grid.mdl-grid--no-spacing > .mdl-cell--3-offset, .mdl-grid.mdl-grid--no-spacing >
    .mdl-cell--3-offset-phone.mdl-cell--3-offset-phone {
      margin-left: 75%; } }

@media (min-width: 768px) and (max-width: 767px) {
  .mdl-grid {
    padding: 8px; }
  .mdl-cell {
    margin: 8px;
    width: calc(50% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell {
      width: 50%; }
  .mdl-cell--hide-tablet {
    display: none !important; }
  .mdl-cell--order-1-tablet.mdl-cell--order-1-tablet {
    order: 1; }
  .mdl-cell--order-2-tablet.mdl-cell--order-2-tablet {
    order: 2; }
  .mdl-cell--order-3-tablet.mdl-cell--order-3-tablet {
    order: 3; }
  .mdl-cell--order-4-tablet.mdl-cell--order-4-tablet {
    order: 4; }
  .mdl-cell--order-5-tablet.mdl-cell--order-5-tablet {
    order: 5; }
  .mdl-cell--order-6-tablet.mdl-cell--order-6-tablet {
    order: 6; }
  .mdl-cell--order-7-tablet.mdl-cell--order-7-tablet {
    order: 7; }
  .mdl-cell--order-8-tablet.mdl-cell--order-8-tablet {
    order: 8; }
  .mdl-cell--order-9-tablet.mdl-cell--order-9-tablet {
    order: 9; }
  .mdl-cell--order-10-tablet.mdl-cell--order-10-tablet {
    order: 10; }
  .mdl-cell--order-11-tablet.mdl-cell--order-11-tablet {
    order: 11; }
  .mdl-cell--order-12-tablet.mdl-cell--order-12-tablet {
    order: 12; }
  .mdl-cell--1-col,
  .mdl-cell--1-col-tablet.mdl-cell--1-col-tablet {
    width: calc(12.5% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--1-col, .mdl-grid--no-spacing >
    .mdl-cell--1-col-tablet.mdl-cell--1-col-tablet {
      width: 12.5%; }
  .mdl-cell--2-col,
  .mdl-cell--2-col-tablet.mdl-cell--2-col-tablet {
    width: calc(25% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--2-col, .mdl-grid--no-spacing >
    .mdl-cell--2-col-tablet.mdl-cell--2-col-tablet {
      width: 25%; }
  .mdl-cell--3-col,
  .mdl-cell--3-col-tablet.mdl-cell--3-col-tablet {
    width: calc(37.5% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--3-col, .mdl-grid--no-spacing >
    .mdl-cell--3-col-tablet.mdl-cell--3-col-tablet {
      width: 37.5%; }
  .mdl-cell--4-col,
  .mdl-cell--4-col-tablet.mdl-cell--4-col-tablet {
    width: calc(50% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--4-col, .mdl-grid--no-spacing >
    .mdl-cell--4-col-tablet.mdl-cell--4-col-tablet {
      width: 50%; }
  .mdl-cell--5-col,
  .mdl-cell--5-col-tablet.mdl-cell--5-col-tablet {
    width: calc(62.5% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--5-col, .mdl-grid--no-spacing >
    .mdl-cell--5-col-tablet.mdl-cell--5-col-tablet {
      width: 62.5%; }
  .mdl-cell--6-col,
  .mdl-cell--6-col-tablet.mdl-cell--6-col-tablet {
    width: calc(75% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--6-col, .mdl-grid--no-spacing >
    .mdl-cell--6-col-tablet.mdl-cell--6-col-tablet {
      width: 75%; }
  .mdl-cell--7-col,
  .mdl-cell--7-col-tablet.mdl-cell--7-col-tablet {
    width: calc(87.5% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--7-col, .mdl-grid--no-spacing >
    .mdl-cell--7-col-tablet.mdl-cell--7-col-tablet {
      width: 87.5%; }
  .mdl-cell--8-col,
  .mdl-cell--8-col-tablet.mdl-cell--8-col-tablet {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--8-col, .mdl-grid--no-spacing >
    .mdl-cell--8-col-tablet.mdl-cell--8-col-tablet {
      width: 100%; }
  .mdl-cell--9-col,
  .mdl-cell--9-col-tablet.mdl-cell--9-col-tablet {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--9-col, .mdl-grid--no-spacing >
    .mdl-cell--9-col-tablet.mdl-cell--9-col-tablet {
      width: 100%; }
  .mdl-cell--10-col,
  .mdl-cell--10-col-tablet.mdl-cell--10-col-tablet {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--10-col, .mdl-grid--no-spacing >
    .mdl-cell--10-col-tablet.mdl-cell--10-col-tablet {
      width: 100%; }
  .mdl-cell--11-col,
  .mdl-cell--11-col-tablet.mdl-cell--11-col-tablet {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--11-col, .mdl-grid--no-spacing >
    .mdl-cell--11-col-tablet.mdl-cell--11-col-tablet {
      width: 100%; }
  .mdl-cell--12-col,
  .mdl-cell--12-col-tablet.mdl-cell--12-col-tablet {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--12-col, .mdl-grid--no-spacing >
    .mdl-cell--12-col-tablet.mdl-cell--12-col-tablet {
      width: 100%; }
  .mdl-cell--1-offset,
  .mdl-cell--1-offset-tablet.mdl-cell--1-offset-tablet {
    margin-left: calc(12.5% + 8px); }
    .mdl-grid.mdl-grid--no-spacing > .mdl-cell--1-offset, .mdl-grid.mdl-grid--no-spacing >
    .mdl-cell--1-offset-tablet.mdl-cell--1-offset-tablet {
      margin-left: 12.5%; }
  .mdl-cell--2-offset,
  .mdl-cell--2-offset-tablet.mdl-cell--2-offset-tablet {
    margin-left: calc(25% + 8px); }
    .mdl-grid.mdl-grid--no-spacing > .mdl-cell--2-offset, .mdl-grid.mdl-grid--no-spacing >
    .mdl-cell--2-offset-tablet.mdl-cell--2-offset-tablet {
      margin-left: 25%; }
  .mdl-cell--3-offset,
  .mdl-cell--3-offset-tablet.mdl-cell--3-offset-tablet {
    margin-left: calc(37.5% + 8px); }
    .mdl-grid.mdl-grid--no-spacing > .mdl-cell--3-offset, .mdl-grid.mdl-grid--no-spacing >
    .mdl-cell--3-offset-tablet.mdl-cell--3-offset-tablet {
      margin-left: 37.5%; }
  .mdl-cell--4-offset,
  .mdl-cell--4-offset-tablet.mdl-cell--4-offset-tablet {
    margin-left: calc(50% + 8px); }
    .mdl-grid.mdl-grid--no-spacing > .mdl-cell--4-offset, .mdl-grid.mdl-grid--no-spacing >
    .mdl-cell--4-offset-tablet.mdl-cell--4-offset-tablet {
      margin-left: 50%; }
  .mdl-cell--5-offset,
  .mdl-cell--5-offset-tablet.mdl-cell--5-offset-tablet {
    margin-left: calc(62.5% + 8px); }
    .mdl-grid.mdl-grid--no-spacing > .mdl-cell--5-offset, .mdl-grid.mdl-grid--no-spacing >
    .mdl-cell--5-offset-tablet.mdl-cell--5-offset-tablet {
      margin-left: 62.5%; }
  .mdl-cell--6-offset,
  .mdl-cell--6-offset-tablet.mdl-cell--6-offset-tablet {
    margin-left: calc(75% + 8px); }
    .mdl-grid.mdl-grid--no-spacing > .mdl-cell--6-offset, .mdl-grid.mdl-grid--no-spacing >
    .mdl-cell--6-offset-tablet.mdl-cell--6-offset-tablet {
      margin-left: 75%; }
  .mdl-cell--7-offset,
  .mdl-cell--7-offset-tablet.mdl-cell--7-offset-tablet {
    margin-left: calc(87.5% + 8px); }
    .mdl-grid.mdl-grid--no-spacing > .mdl-cell--7-offset, .mdl-grid.mdl-grid--no-spacing >
    .mdl-cell--7-offset-tablet.mdl-cell--7-offset-tablet {
      margin-left: 87.5%; } }

@media (min-width: 768px) {
  .mdl-grid {
    padding: 8px; }
  .mdl-cell {
    margin: 8px;
    width: calc(33.3333333333% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell {
      width: 33.3333333333%; }
  .mdl-cell--hide-desktop {
    display: none !important; }
  .mdl-cell--order-1-desktop.mdl-cell--order-1-desktop {
    order: 1; }
  .mdl-cell--order-2-desktop.mdl-cell--order-2-desktop {
    order: 2; }
  .mdl-cell--order-3-desktop.mdl-cell--order-3-desktop {
    order: 3; }
  .mdl-cell--order-4-desktop.mdl-cell--order-4-desktop {
    order: 4; }
  .mdl-cell--order-5-desktop.mdl-cell--order-5-desktop {
    order: 5; }
  .mdl-cell--order-6-desktop.mdl-cell--order-6-desktop {
    order: 6; }
  .mdl-cell--order-7-desktop.mdl-cell--order-7-desktop {
    order: 7; }
  .mdl-cell--order-8-desktop.mdl-cell--order-8-desktop {
    order: 8; }
  .mdl-cell--order-9-desktop.mdl-cell--order-9-desktop {
    order: 9; }
  .mdl-cell--order-10-desktop.mdl-cell--order-10-desktop {
    order: 10; }
  .mdl-cell--order-11-desktop.mdl-cell--order-11-desktop {
    order: 11; }
  .mdl-cell--order-12-desktop.mdl-cell--order-12-desktop {
    order: 12; }
  .mdl-cell--1-col,
  .mdl-cell--1-col-desktop.mdl-cell--1-col-desktop {
    width: calc(8.3333333333% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--1-col, .mdl-grid--no-spacing >
    .mdl-cell--1-col-desktop.mdl-cell--1-col-desktop {
      width: 8.3333333333%; }
  .mdl-cell--2-col,
  .mdl-cell--2-col-desktop.mdl-cell--2-col-desktop {
    width: calc(16.6666666667% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--2-col, .mdl-grid--no-spacing >
    .mdl-cell--2-col-desktop.mdl-cell--2-col-desktop {
      width: 16.6666666667%; }
  .mdl-cell--3-col,
  .mdl-cell--3-col-desktop.mdl-cell--3-col-desktop {
    width: calc(25% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--3-col, .mdl-grid--no-spacing >
    .mdl-cell--3-col-desktop.mdl-cell--3-col-desktop {
      width: 25%; }
  .mdl-cell--4-col,
  .mdl-cell--4-col-desktop.mdl-cell--4-col-desktop {
    width: calc(33.3333333333% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--4-col, .mdl-grid--no-spacing >
    .mdl-cell--4-col-desktop.mdl-cell--4-col-desktop {
      width: 33.3333333333%; }
  .mdl-cell--5-col,
  .mdl-cell--5-col-desktop.mdl-cell--5-col-desktop {
    width: calc(41.6666666667% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--5-col, .mdl-grid--no-spacing >
    .mdl-cell--5-col-desktop.mdl-cell--5-col-desktop {
      width: 41.6666666667%; }
  .mdl-cell--6-col,
  .mdl-cell--6-col-desktop.mdl-cell--6-col-desktop {
    width: calc(50% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--6-col, .mdl-grid--no-spacing >
    .mdl-cell--6-col-desktop.mdl-cell--6-col-desktop {
      width: 50%; }
  .mdl-cell--7-col,
  .mdl-cell--7-col-desktop.mdl-cell--7-col-desktop {
    width: calc(58.3333333333% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--7-col, .mdl-grid--no-spacing >
    .mdl-cell--7-col-desktop.mdl-cell--7-col-desktop {
      width: 58.3333333333%; }
  .mdl-cell--8-col,
  .mdl-cell--8-col-desktop.mdl-cell--8-col-desktop {
    width: calc(66.6666666667% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--8-col, .mdl-grid--no-spacing >
    .mdl-cell--8-col-desktop.mdl-cell--8-col-desktop {
      width: 66.6666666667%; }
  .mdl-cell--9-col,
  .mdl-cell--9-col-desktop.mdl-cell--9-col-desktop {
    width: calc(75% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--9-col, .mdl-grid--no-spacing >
    .mdl-cell--9-col-desktop.mdl-cell--9-col-desktop {
      width: 75%; }
  .mdl-cell--10-col,
  .mdl-cell--10-col-desktop.mdl-cell--10-col-desktop {
    width: calc(83.3333333333% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--10-col, .mdl-grid--no-spacing >
    .mdl-cell--10-col-desktop.mdl-cell--10-col-desktop {
      width: 83.3333333333%; }
  .mdl-cell--11-col,
  .mdl-cell--11-col-desktop.mdl-cell--11-col-desktop {
    width: calc(91.6666666667% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--11-col, .mdl-grid--no-spacing >
    .mdl-cell--11-col-desktop.mdl-cell--11-col-desktop {
      width: 91.6666666667%; }
  .mdl-cell--12-col,
  .mdl-cell--12-col-desktop.mdl-cell--12-col-desktop {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--12-col, .mdl-grid--no-spacing >
    .mdl-cell--12-col-desktop.mdl-cell--12-col-desktop {
      width: 100%; }
  .mdl-cell--1-offset,
  .mdl-cell--1-offset-desktop.mdl-cell--1-offset-desktop {
    margin-left: calc(8.3333333333% + 8px); }
    .mdl-grid.mdl-grid--no-spacing > .mdl-cell--1-offset, .mdl-grid.mdl-grid--no-spacing >
    .mdl-cell--1-offset-desktop.mdl-cell--1-offset-desktop {
      margin-left: 8.3333333333%; }
  .mdl-cell--2-offset,
  .mdl-cell--2-offset-desktop.mdl-cell--2-offset-desktop {
    margin-left: calc(16.6666666667% + 8px); }
    .mdl-grid.mdl-grid--no-spacing > .mdl-cell--2-offset, .mdl-grid.mdl-grid--no-spacing >
    .mdl-cell--2-offset-desktop.mdl-cell--2-offset-desktop {
      margin-left: 16.6666666667%; }
  .mdl-cell--3-offset,
  .mdl-cell--3-offset-desktop.mdl-cell--3-offset-desktop {
    margin-left: calc(25% + 8px); }
    .mdl-grid.mdl-grid--no-spacing > .mdl-cell--3-offset, .mdl-grid.mdl-grid--no-spacing >
    .mdl-cell--3-offset-desktop.mdl-cell--3-offset-desktop {
      margin-left: 25%; }
  .mdl-cell--4-offset,
  .mdl-cell--4-offset-desktop.mdl-cell--4-offset-desktop {
    margin-left: calc(33.3333333333% + 8px); }
    .mdl-grid.mdl-grid--no-spacing > .mdl-cell--4-offset, .mdl-grid.mdl-grid--no-spacing >
    .mdl-cell--4-offset-desktop.mdl-cell--4-offset-desktop {
      margin-left: 33.3333333333%; }
  .mdl-cell--5-offset,
  .mdl-cell--5-offset-desktop.mdl-cell--5-offset-desktop {
    margin-left: calc(41.6666666667% + 8px); }
    .mdl-grid.mdl-grid--no-spacing > .mdl-cell--5-offset, .mdl-grid.mdl-grid--no-spacing >
    .mdl-cell--5-offset-desktop.mdl-cell--5-offset-desktop {
      margin-left: 41.6666666667%; }
  .mdl-cell--6-offset,
  .mdl-cell--6-offset-desktop.mdl-cell--6-offset-desktop {
    margin-left: calc(50% + 8px); }
    .mdl-grid.mdl-grid--no-spacing > .mdl-cell--6-offset, .mdl-grid.mdl-grid--no-spacing >
    .mdl-cell--6-offset-desktop.mdl-cell--6-offset-desktop {
      margin-left: 50%; }
  .mdl-cell--7-offset,
  .mdl-cell--7-offset-desktop.mdl-cell--7-offset-desktop {
    margin-left: calc(58.3333333333% + 8px); }
    .mdl-grid.mdl-grid--no-spacing > .mdl-cell--7-offset, .mdl-grid.mdl-grid--no-spacing >
    .mdl-cell--7-offset-desktop.mdl-cell--7-offset-desktop {
      margin-left: 58.3333333333%; }
  .mdl-cell--8-offset,
  .mdl-cell--8-offset-desktop.mdl-cell--8-offset-desktop {
    margin-left: calc(66.6666666667% + 8px); }
    .mdl-grid.mdl-grid--no-spacing > .mdl-cell--8-offset, .mdl-grid.mdl-grid--no-spacing >
    .mdl-cell--8-offset-desktop.mdl-cell--8-offset-desktop {
      margin-left: 66.6666666667%; }
  .mdl-cell--9-offset,
  .mdl-cell--9-offset-desktop.mdl-cell--9-offset-desktop {
    margin-left: calc(75% + 8px); }
    .mdl-grid.mdl-grid--no-spacing > .mdl-cell--9-offset, .mdl-grid.mdl-grid--no-spacing >
    .mdl-cell--9-offset-desktop.mdl-cell--9-offset-desktop {
      margin-left: 75%; }
  .mdl-cell--10-offset,
  .mdl-cell--10-offset-desktop.mdl-cell--10-offset-desktop {
    margin-left: calc(83.3333333333% + 8px); }
    .mdl-grid.mdl-grid--no-spacing > .mdl-cell--10-offset, .mdl-grid.mdl-grid--no-spacing >
    .mdl-cell--10-offset-desktop.mdl-cell--10-offset-desktop {
      margin-left: 83.3333333333%; }
  .mdl-cell--11-offset,
  .mdl-cell--11-offset-desktop.mdl-cell--11-offset-desktop {
    margin-left: calc(91.6666666667% + 8px); }
    .mdl-grid.mdl-grid--no-spacing > .mdl-cell--11-offset, .mdl-grid.mdl-grid--no-spacing >
    .mdl-cell--11-offset-desktop.mdl-cell--11-offset-desktop {
      margin-left: 91.6666666667%; } }

/* MaterialDesignIcons.com */
@font-face {
  font-family: "Material Design Icons";
  src: url("../assets/fonts/materialdesignicons-webfont.eot?v=7.0.96");
  src: url("../assets/fonts/materialdesignicons-webfont.eot?#iefix&v=7.0.96") format("embedded-opentype"), url("../assets/fonts/materialdesignicons-webfont.woff2?v=7.0.96") format("woff2"), url("../assets/fonts/materialdesignicons-webfont.woff?v=7.0.96") format("woff"), url("../assets/fonts/materialdesignicons-webfont.ttf?v=7.0.96") format("truetype");
  font-weight: normal;
  font-style: normal; }

.mdi:before,
.mdi-set {
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.mdi-ab-testing::before {
  content: "\F01C9"; }

.mdi-abacus::before {
  content: "\F16E0"; }

.mdi-abjad-arabic::before {
  content: "\F1328"; }

.mdi-abjad-hebrew::before {
  content: "\F1329"; }

.mdi-abugida-devanagari::before {
  content: "\F132A"; }

.mdi-abugida-thai::before {
  content: "\F132B"; }

.mdi-access-point::before {
  content: "\F0003"; }

.mdi-access-point-check::before {
  content: "\F1538"; }

.mdi-access-point-minus::before {
  content: "\F1539"; }

.mdi-access-point-network::before {
  content: "\F0002"; }

.mdi-access-point-network-off::before {
  content: "\F0BE1"; }

.mdi-access-point-off::before {
  content: "\F1511"; }

.mdi-access-point-plus::before {
  content: "\F153A"; }

.mdi-access-point-remove::before {
  content: "\F153B"; }

.mdi-account::before {
  content: "\F0004"; }

.mdi-account-alert::before {
  content: "\F0005"; }

.mdi-account-alert-outline::before {
  content: "\F0B50"; }

.mdi-account-arrow-down::before {
  content: "\F1868"; }

.mdi-account-arrow-down-outline::before {
  content: "\F1869"; }

.mdi-account-arrow-left::before {
  content: "\F0B51"; }

.mdi-account-arrow-left-outline::before {
  content: "\F0B52"; }

.mdi-account-arrow-right::before {
  content: "\F0B53"; }

.mdi-account-arrow-right-outline::before {
  content: "\F0B54"; }

.mdi-account-arrow-up::before {
  content: "\F1867"; }

.mdi-account-arrow-up-outline::before {
  content: "\F186A"; }

.mdi-account-badge::before {
  content: "\F1B0A"; }

.mdi-account-badge-outline::before {
  content: "\F1B0B"; }

.mdi-account-box::before {
  content: "\F0006"; }

.mdi-account-box-multiple::before {
  content: "\F0934"; }

.mdi-account-box-multiple-outline::before {
  content: "\F100A"; }

.mdi-account-box-outline::before {
  content: "\F0007"; }

.mdi-account-cancel::before {
  content: "\F12DF"; }

.mdi-account-cancel-outline::before {
  content: "\F12E0"; }

.mdi-account-card::before {
  content: "\F1BA4"; }

.mdi-account-card-outline::before {
  content: "\F1BA5"; }

.mdi-account-cash::before {
  content: "\F1097"; }

.mdi-account-cash-outline::before {
  content: "\F1098"; }

.mdi-account-check::before {
  content: "\F0008"; }

.mdi-account-check-outline::before {
  content: "\F0BE2"; }

.mdi-account-child::before {
  content: "\F0A89"; }

.mdi-account-child-circle::before {
  content: "\F0A8A"; }

.mdi-account-child-outline::before {
  content: "\F10C8"; }

.mdi-account-circle::before {
  content: "\F0009"; }

.mdi-account-circle-outline::before {
  content: "\F0B55"; }

.mdi-account-clock::before {
  content: "\F0B56"; }

.mdi-account-clock-outline::before {
  content: "\F0B57"; }

.mdi-account-cog::before {
  content: "\F1370"; }

.mdi-account-cog-outline::before {
  content: "\F1371"; }

.mdi-account-convert::before {
  content: "\F000A"; }

.mdi-account-convert-outline::before {
  content: "\F1301"; }

.mdi-account-cowboy-hat::before {
  content: "\F0E9B"; }

.mdi-account-cowboy-hat-outline::before {
  content: "\F17F3"; }

.mdi-account-credit-card::before {
  content: "\F1BA6"; }

.mdi-account-credit-card-outline::before {
  content: "\F1BA7"; }

.mdi-account-details::before {
  content: "\F0631"; }

.mdi-account-details-outline::before {
  content: "\F1372"; }

.mdi-account-edit::before {
  content: "\F06BC"; }

.mdi-account-edit-outline::before {
  content: "\F0FFB"; }

.mdi-account-eye::before {
  content: "\F0420"; }

.mdi-account-eye-outline::before {
  content: "\F127B"; }

.mdi-account-filter::before {
  content: "\F0936"; }

.mdi-account-filter-outline::before {
  content: "\F0F9D"; }

.mdi-account-group::before {
  content: "\F0849"; }

.mdi-account-group-outline::before {
  content: "\F0B58"; }

.mdi-account-hard-hat::before {
  content: "\F05B5"; }

.mdi-account-hard-hat-outline::before {
  content: "\F1A1F"; }

.mdi-account-heart::before {
  content: "\F0899"; }

.mdi-account-heart-outline::before {
  content: "\F0BE3"; }

.mdi-account-injury::before {
  content: "\F1815"; }

.mdi-account-injury-outline::before {
  content: "\F1816"; }

.mdi-account-key::before {
  content: "\F000B"; }

.mdi-account-key-outline::before {
  content: "\F0BE4"; }

.mdi-account-lock::before {
  content: "\F115E"; }

.mdi-account-lock-open::before {
  content: "\F1960"; }

.mdi-account-lock-open-outline::before {
  content: "\F1961"; }

.mdi-account-lock-outline::before {
  content: "\F115F"; }

.mdi-account-minus::before {
  content: "\F000D"; }

.mdi-account-minus-outline::before {
  content: "\F0AEC"; }

.mdi-account-multiple::before {
  content: "\F000E"; }

.mdi-account-multiple-check::before {
  content: "\F08C5"; }

.mdi-account-multiple-check-outline::before {
  content: "\F11FE"; }

.mdi-account-multiple-minus::before {
  content: "\F05D3"; }

.mdi-account-multiple-minus-outline::before {
  content: "\F0BE5"; }

.mdi-account-multiple-outline::before {
  content: "\F000F"; }

.mdi-account-multiple-plus::before {
  content: "\F0010"; }

.mdi-account-multiple-plus-outline::before {
  content: "\F0800"; }

.mdi-account-multiple-remove::before {
  content: "\F120A"; }

.mdi-account-multiple-remove-outline::before {
  content: "\F120B"; }

.mdi-account-music::before {
  content: "\F0803"; }

.mdi-account-music-outline::before {
  content: "\F0CE9"; }

.mdi-account-network::before {
  content: "\F0011"; }

.mdi-account-network-off::before {
  content: "\F1AF1"; }

.mdi-account-network-off-outline::before {
  content: "\F1AF2"; }

.mdi-account-network-outline::before {
  content: "\F0BE6"; }

.mdi-account-off::before {
  content: "\F0012"; }

.mdi-account-off-outline::before {
  content: "\F0BE7"; }

.mdi-account-outline::before {
  content: "\F0013"; }

.mdi-account-plus::before {
  content: "\F0014"; }

.mdi-account-plus-outline::before {
  content: "\F0801"; }

.mdi-account-question::before {
  content: "\F0B59"; }

.mdi-account-question-outline::before {
  content: "\F0B5A"; }

.mdi-account-reactivate::before {
  content: "\F152B"; }

.mdi-account-reactivate-outline::before {
  content: "\F152C"; }

.mdi-account-remove::before {
  content: "\F0015"; }

.mdi-account-remove-outline::before {
  content: "\F0AED"; }

.mdi-account-school::before {
  content: "\F1A20"; }

.mdi-account-school-outline::before {
  content: "\F1A21"; }

.mdi-account-search::before {
  content: "\F0016"; }

.mdi-account-search-outline::before {
  content: "\F0935"; }

.mdi-account-settings::before {
  content: "\F0630"; }

.mdi-account-settings-outline::before {
  content: "\F10C9"; }

.mdi-account-star::before {
  content: "\F0017"; }

.mdi-account-star-outline::before {
  content: "\F0BE8"; }

.mdi-account-supervisor::before {
  content: "\F0A8B"; }

.mdi-account-supervisor-circle::before {
  content: "\F0A8C"; }

.mdi-account-supervisor-circle-outline::before {
  content: "\F14EC"; }

.mdi-account-supervisor-outline::before {
  content: "\F112D"; }

.mdi-account-switch::before {
  content: "\F0019"; }

.mdi-account-switch-outline::before {
  content: "\F04CB"; }

.mdi-account-sync::before {
  content: "\F191B"; }

.mdi-account-sync-outline::before {
  content: "\F191C"; }

.mdi-account-tie::before {
  content: "\F0CE3"; }

.mdi-account-tie-hat::before {
  content: "\F1898"; }

.mdi-account-tie-hat-outline::before {
  content: "\F1899"; }

.mdi-account-tie-outline::before {
  content: "\F10CA"; }

.mdi-account-tie-voice::before {
  content: "\F1308"; }

.mdi-account-tie-voice-off::before {
  content: "\F130A"; }

.mdi-account-tie-voice-off-outline::before {
  content: "\F130B"; }

.mdi-account-tie-voice-outline::before {
  content: "\F1309"; }

.mdi-account-tie-woman::before {
  content: "\F1A8C"; }

.mdi-account-voice::before {
  content: "\F05CB"; }

.mdi-account-voice-off::before {
  content: "\F0ED4"; }

.mdi-account-wrench::before {
  content: "\F189A"; }

.mdi-account-wrench-outline::before {
  content: "\F189B"; }

.mdi-adjust::before {
  content: "\F001A"; }

.mdi-advertisements::before {
  content: "\F192A"; }

.mdi-advertisements-off::before {
  content: "\F192B"; }

.mdi-air-conditioner::before {
  content: "\F001B"; }

.mdi-air-filter::before {
  content: "\F0D43"; }

.mdi-air-horn::before {
  content: "\F0DAC"; }

.mdi-air-humidifier::before {
  content: "\F1099"; }

.mdi-air-humidifier-off::before {
  content: "\F1466"; }

.mdi-air-purifier::before {
  content: "\F0D44"; }

.mdi-air-purifier-off::before {
  content: "\F1B57"; }

.mdi-airbag::before {
  content: "\F0BE9"; }

.mdi-airballoon::before {
  content: "\F001C"; }

.mdi-airballoon-outline::before {
  content: "\F100B"; }

.mdi-airplane::before {
  content: "\F001D"; }

.mdi-airplane-alert::before {
  content: "\F187A"; }

.mdi-airplane-check::before {
  content: "\F187B"; }

.mdi-airplane-clock::before {
  content: "\F187C"; }

.mdi-airplane-cog::before {
  content: "\F187D"; }

.mdi-airplane-edit::before {
  content: "\F187E"; }

.mdi-airplane-landing::before {
  content: "\F05D4"; }

.mdi-airplane-marker::before {
  content: "\F187F"; }

.mdi-airplane-minus::before {
  content: "\F1880"; }

.mdi-airplane-off::before {
  content: "\F001E"; }

.mdi-airplane-plus::before {
  content: "\F1881"; }

.mdi-airplane-remove::before {
  content: "\F1882"; }

.mdi-airplane-search::before {
  content: "\F1883"; }

.mdi-airplane-settings::before {
  content: "\F1884"; }

.mdi-airplane-takeoff::before {
  content: "\F05D5"; }

.mdi-airport::before {
  content: "\F084B"; }

.mdi-alarm::before {
  content: "\F0020"; }

.mdi-alarm-bell::before {
  content: "\F078E"; }

.mdi-alarm-check::before {
  content: "\F0021"; }

.mdi-alarm-light::before {
  content: "\F078F"; }

.mdi-alarm-light-off::before {
  content: "\F171E"; }

.mdi-alarm-light-off-outline::before {
  content: "\F171F"; }

.mdi-alarm-light-outline::before {
  content: "\F0BEA"; }

.mdi-alarm-multiple::before {
  content: "\F0022"; }

.mdi-alarm-note::before {
  content: "\F0E71"; }

.mdi-alarm-note-off::before {
  content: "\F0E72"; }

.mdi-alarm-off::before {
  content: "\F0023"; }

.mdi-alarm-panel::before {
  content: "\F15C4"; }

.mdi-alarm-panel-outline::before {
  content: "\F15C5"; }

.mdi-alarm-plus::before {
  content: "\F0024"; }

.mdi-alarm-snooze::before {
  content: "\F068E"; }

.mdi-album::before {
  content: "\F0025"; }

.mdi-alert::before {
  content: "\F0026"; }

.mdi-alert-box::before {
  content: "\F0027"; }

.mdi-alert-box-outline::before {
  content: "\F0CE4"; }

.mdi-alert-circle::before {
  content: "\F0028"; }

.mdi-alert-circle-check::before {
  content: "\F11ED"; }

.mdi-alert-circle-check-outline::before {
  content: "\F11EE"; }

.mdi-alert-circle-outline::before {
  content: "\F05D6"; }

.mdi-alert-decagram::before {
  content: "\F06BD"; }

.mdi-alert-decagram-outline::before {
  content: "\F0CE5"; }

.mdi-alert-minus::before {
  content: "\F14BB"; }

.mdi-alert-minus-outline::before {
  content: "\F14BE"; }

.mdi-alert-octagon::before {
  content: "\F0029"; }

.mdi-alert-octagon-outline::before {
  content: "\F0CE6"; }

.mdi-alert-octagram::before {
  content: "\F0767"; }

.mdi-alert-octagram-outline::before {
  content: "\F0CE7"; }

.mdi-alert-outline::before {
  content: "\F002A"; }

.mdi-alert-plus::before {
  content: "\F14BA"; }

.mdi-alert-plus-outline::before {
  content: "\F14BD"; }

.mdi-alert-remove::before {
  content: "\F14BC"; }

.mdi-alert-remove-outline::before {
  content: "\F14BF"; }

.mdi-alert-rhombus::before {
  content: "\F11CE"; }

.mdi-alert-rhombus-outline::before {
  content: "\F11CF"; }

.mdi-alien::before {
  content: "\F089A"; }

.mdi-alien-outline::before {
  content: "\F10CB"; }

.mdi-align-horizontal-center::before {
  content: "\F11C3"; }

.mdi-align-horizontal-distribute::before {
  content: "\F1962"; }

.mdi-align-horizontal-left::before {
  content: "\F11C2"; }

.mdi-align-horizontal-right::before {
  content: "\F11C4"; }

.mdi-align-vertical-bottom::before {
  content: "\F11C5"; }

.mdi-align-vertical-center::before {
  content: "\F11C6"; }

.mdi-align-vertical-distribute::before {
  content: "\F1963"; }

.mdi-align-vertical-top::before {
  content: "\F11C7"; }

.mdi-all-inclusive::before {
  content: "\F06BE"; }

.mdi-all-inclusive-box::before {
  content: "\F188D"; }

.mdi-all-inclusive-box-outline::before {
  content: "\F188E"; }

.mdi-allergy::before {
  content: "\F1258"; }

.mdi-alpha::before {
  content: "\F002B"; }

.mdi-alpha-a::before {
  content: "\F0AEE"; }

.mdi-alpha-a-box::before {
  content: "\F0B08"; }

.mdi-alpha-a-box-outline::before {
  content: "\F0BEB"; }

.mdi-alpha-a-circle::before {
  content: "\F0BEC"; }

.mdi-alpha-a-circle-outline::before {
  content: "\F0BED"; }

.mdi-alpha-b::before {
  content: "\F0AEF"; }

.mdi-alpha-b-box::before {
  content: "\F0B09"; }

.mdi-alpha-b-box-outline::before {
  content: "\F0BEE"; }

.mdi-alpha-b-circle::before {
  content: "\F0BEF"; }

.mdi-alpha-b-circle-outline::before {
  content: "\F0BF0"; }

.mdi-alpha-c::before {
  content: "\F0AF0"; }

.mdi-alpha-c-box::before {
  content: "\F0B0A"; }

.mdi-alpha-c-box-outline::before {
  content: "\F0BF1"; }

.mdi-alpha-c-circle::before {
  content: "\F0BF2"; }

.mdi-alpha-c-circle-outline::before {
  content: "\F0BF3"; }

.mdi-alpha-d::before {
  content: "\F0AF1"; }

.mdi-alpha-d-box::before {
  content: "\F0B0B"; }

.mdi-alpha-d-box-outline::before {
  content: "\F0BF4"; }

.mdi-alpha-d-circle::before {
  content: "\F0BF5"; }

.mdi-alpha-d-circle-outline::before {
  content: "\F0BF6"; }

.mdi-alpha-e::before {
  content: "\F0AF2"; }

.mdi-alpha-e-box::before {
  content: "\F0B0C"; }

.mdi-alpha-e-box-outline::before {
  content: "\F0BF7"; }

.mdi-alpha-e-circle::before {
  content: "\F0BF8"; }

.mdi-alpha-e-circle-outline::before {
  content: "\F0BF9"; }

.mdi-alpha-f::before {
  content: "\F0AF3"; }

.mdi-alpha-f-box::before {
  content: "\F0B0D"; }

.mdi-alpha-f-box-outline::before {
  content: "\F0BFA"; }

.mdi-alpha-f-circle::before {
  content: "\F0BFB"; }

.mdi-alpha-f-circle-outline::before {
  content: "\F0BFC"; }

.mdi-alpha-g::before {
  content: "\F0AF4"; }

.mdi-alpha-g-box::before {
  content: "\F0B0E"; }

.mdi-alpha-g-box-outline::before {
  content: "\F0BFD"; }

.mdi-alpha-g-circle::before {
  content: "\F0BFE"; }

.mdi-alpha-g-circle-outline::before {
  content: "\F0BFF"; }

.mdi-alpha-h::before {
  content: "\F0AF5"; }

.mdi-alpha-h-box::before {
  content: "\F0B0F"; }

.mdi-alpha-h-box-outline::before {
  content: "\F0C00"; }

.mdi-alpha-h-circle::before {
  content: "\F0C01"; }

.mdi-alpha-h-circle-outline::before {
  content: "\F0C02"; }

.mdi-alpha-i::before {
  content: "\F0AF6"; }

.mdi-alpha-i-box::before {
  content: "\F0B10"; }

.mdi-alpha-i-box-outline::before {
  content: "\F0C03"; }

.mdi-alpha-i-circle::before {
  content: "\F0C04"; }

.mdi-alpha-i-circle-outline::before {
  content: "\F0C05"; }

.mdi-alpha-j::before {
  content: "\F0AF7"; }

.mdi-alpha-j-box::before {
  content: "\F0B11"; }

.mdi-alpha-j-box-outline::before {
  content: "\F0C06"; }

.mdi-alpha-j-circle::before {
  content: "\F0C07"; }

.mdi-alpha-j-circle-outline::before {
  content: "\F0C08"; }

.mdi-alpha-k::before {
  content: "\F0AF8"; }

.mdi-alpha-k-box::before {
  content: "\F0B12"; }

.mdi-alpha-k-box-outline::before {
  content: "\F0C09"; }

.mdi-alpha-k-circle::before {
  content: "\F0C0A"; }

.mdi-alpha-k-circle-outline::before {
  content: "\F0C0B"; }

.mdi-alpha-l::before {
  content: "\F0AF9"; }

.mdi-alpha-l-box::before {
  content: "\F0B13"; }

.mdi-alpha-l-box-outline::before {
  content: "\F0C0C"; }

.mdi-alpha-l-circle::before {
  content: "\F0C0D"; }

.mdi-alpha-l-circle-outline::before {
  content: "\F0C0E"; }

.mdi-alpha-m::before {
  content: "\F0AFA"; }

.mdi-alpha-m-box::before {
  content: "\F0B14"; }

.mdi-alpha-m-box-outline::before {
  content: "\F0C0F"; }

.mdi-alpha-m-circle::before {
  content: "\F0C10"; }

.mdi-alpha-m-circle-outline::before {
  content: "\F0C11"; }

.mdi-alpha-n::before {
  content: "\F0AFB"; }

.mdi-alpha-n-box::before {
  content: "\F0B15"; }

.mdi-alpha-n-box-outline::before {
  content: "\F0C12"; }

.mdi-alpha-n-circle::before {
  content: "\F0C13"; }

.mdi-alpha-n-circle-outline::before {
  content: "\F0C14"; }

.mdi-alpha-o::before {
  content: "\F0AFC"; }

.mdi-alpha-o-box::before {
  content: "\F0B16"; }

.mdi-alpha-o-box-outline::before {
  content: "\F0C15"; }

.mdi-alpha-o-circle::before {
  content: "\F0C16"; }

.mdi-alpha-o-circle-outline::before {
  content: "\F0C17"; }

.mdi-alpha-p::before {
  content: "\F0AFD"; }

.mdi-alpha-p-box::before {
  content: "\F0B17"; }

.mdi-alpha-p-box-outline::before {
  content: "\F0C18"; }

.mdi-alpha-p-circle::before {
  content: "\F0C19"; }

.mdi-alpha-p-circle-outline::before {
  content: "\F0C1A"; }

.mdi-alpha-q::before {
  content: "\F0AFE"; }

.mdi-alpha-q-box::before {
  content: "\F0B18"; }

.mdi-alpha-q-box-outline::before {
  content: "\F0C1B"; }

.mdi-alpha-q-circle::before {
  content: "\F0C1C"; }

.mdi-alpha-q-circle-outline::before {
  content: "\F0C1D"; }

.mdi-alpha-r::before {
  content: "\F0AFF"; }

.mdi-alpha-r-box::before {
  content: "\F0B19"; }

.mdi-alpha-r-box-outline::before {
  content: "\F0C1E"; }

.mdi-alpha-r-circle::before {
  content: "\F0C1F"; }

.mdi-alpha-r-circle-outline::before {
  content: "\F0C20"; }

.mdi-alpha-s::before {
  content: "\F0B00"; }

.mdi-alpha-s-box::before {
  content: "\F0B1A"; }

.mdi-alpha-s-box-outline::before {
  content: "\F0C21"; }

.mdi-alpha-s-circle::before {
  content: "\F0C22"; }

.mdi-alpha-s-circle-outline::before {
  content: "\F0C23"; }

.mdi-alpha-t::before {
  content: "\F0B01"; }

.mdi-alpha-t-box::before {
  content: "\F0B1B"; }

.mdi-alpha-t-box-outline::before {
  content: "\F0C24"; }

.mdi-alpha-t-circle::before {
  content: "\F0C25"; }

.mdi-alpha-t-circle-outline::before {
  content: "\F0C26"; }

.mdi-alpha-u::before {
  content: "\F0B02"; }

.mdi-alpha-u-box::before {
  content: "\F0B1C"; }

.mdi-alpha-u-box-outline::before {
  content: "\F0C27"; }

.mdi-alpha-u-circle::before {
  content: "\F0C28"; }

.mdi-alpha-u-circle-outline::before {
  content: "\F0C29"; }

.mdi-alpha-v::before {
  content: "\F0B03"; }

.mdi-alpha-v-box::before {
  content: "\F0B1D"; }

.mdi-alpha-v-box-outline::before {
  content: "\F0C2A"; }

.mdi-alpha-v-circle::before {
  content: "\F0C2B"; }

.mdi-alpha-v-circle-outline::before {
  content: "\F0C2C"; }

.mdi-alpha-w::before {
  content: "\F0B04"; }

.mdi-alpha-w-box::before {
  content: "\F0B1E"; }

.mdi-alpha-w-box-outline::before {
  content: "\F0C2D"; }

.mdi-alpha-w-circle::before {
  content: "\F0C2E"; }

.mdi-alpha-w-circle-outline::before {
  content: "\F0C2F"; }

.mdi-alpha-x::before {
  content: "\F0B05"; }

.mdi-alpha-x-box::before {
  content: "\F0B1F"; }

.mdi-alpha-x-box-outline::before {
  content: "\F0C30"; }

.mdi-alpha-x-circle::before {
  content: "\F0C31"; }

.mdi-alpha-x-circle-outline::before {
  content: "\F0C32"; }

.mdi-alpha-y::before {
  content: "\F0B06"; }

.mdi-alpha-y-box::before {
  content: "\F0B20"; }

.mdi-alpha-y-box-outline::before {
  content: "\F0C33"; }

.mdi-alpha-y-circle::before {
  content: "\F0C34"; }

.mdi-alpha-y-circle-outline::before {
  content: "\F0C35"; }

.mdi-alpha-z::before {
  content: "\F0B07"; }

.mdi-alpha-z-box::before {
  content: "\F0B21"; }

.mdi-alpha-z-box-outline::before {
  content: "\F0C36"; }

.mdi-alpha-z-circle::before {
  content: "\F0C37"; }

.mdi-alpha-z-circle-outline::before {
  content: "\F0C38"; }

.mdi-alphabet-aurebesh::before {
  content: "\F132C"; }

.mdi-alphabet-cyrillic::before {
  content: "\F132D"; }

.mdi-alphabet-greek::before {
  content: "\F132E"; }

.mdi-alphabet-latin::before {
  content: "\F132F"; }

.mdi-alphabet-piqad::before {
  content: "\F1330"; }

.mdi-alphabet-tengwar::before {
  content: "\F1337"; }

.mdi-alphabetical::before {
  content: "\F002C"; }

.mdi-alphabetical-off::before {
  content: "\F100C"; }

.mdi-alphabetical-variant::before {
  content: "\F100D"; }

.mdi-alphabetical-variant-off::before {
  content: "\F100E"; }

.mdi-altimeter::before {
  content: "\F05D7"; }

.mdi-ambulance::before {
  content: "\F002F"; }

.mdi-ammunition::before {
  content: "\F0CE8"; }

.mdi-ampersand::before {
  content: "\F0A8D"; }

.mdi-amplifier::before {
  content: "\F0030"; }

.mdi-amplifier-off::before {
  content: "\F11B5"; }

.mdi-anchor::before {
  content: "\F0031"; }

.mdi-android::before {
  content: "\F0032"; }

.mdi-android-studio::before {
  content: "\F0034"; }

.mdi-angle-acute::before {
  content: "\F0937"; }

.mdi-angle-obtuse::before {
  content: "\F0938"; }

.mdi-angle-right::before {
  content: "\F0939"; }

.mdi-angular::before {
  content: "\F06B2"; }

.mdi-angularjs::before {
  content: "\F06BF"; }

.mdi-animation::before {
  content: "\F05D8"; }

.mdi-animation-outline::before {
  content: "\F0A8F"; }

.mdi-animation-play::before {
  content: "\F093A"; }

.mdi-animation-play-outline::before {
  content: "\F0A90"; }

.mdi-ansible::before {
  content: "\F109A"; }

.mdi-antenna::before {
  content: "\F1119"; }

.mdi-anvil::before {
  content: "\F089B"; }

.mdi-apache-kafka::before {
  content: "\F100F"; }

.mdi-api::before {
  content: "\F109B"; }

.mdi-api-off::before {
  content: "\F1257"; }

.mdi-apple::before {
  content: "\F0035"; }

.mdi-apple-finder::before {
  content: "\F0036"; }

.mdi-apple-icloud::before {
  content: "\F0038"; }

.mdi-apple-ios::before {
  content: "\F0037"; }

.mdi-apple-keyboard-caps::before {
  content: "\F0632"; }

.mdi-apple-keyboard-command::before {
  content: "\F0633"; }

.mdi-apple-keyboard-control::before {
  content: "\F0634"; }

.mdi-apple-keyboard-option::before {
  content: "\F0635"; }

.mdi-apple-keyboard-shift::before {
  content: "\F0636"; }

.mdi-apple-safari::before {
  content: "\F0039"; }

.mdi-application::before {
  content: "\F08C6"; }

.mdi-application-array::before {
  content: "\F10F5"; }

.mdi-application-array-outline::before {
  content: "\F10F6"; }

.mdi-application-braces::before {
  content: "\F10F7"; }

.mdi-application-braces-outline::before {
  content: "\F10F8"; }

.mdi-application-brackets::before {
  content: "\F0C8B"; }

.mdi-application-brackets-outline::before {
  content: "\F0C8C"; }

.mdi-application-cog::before {
  content: "\F0675"; }

.mdi-application-cog-outline::before {
  content: "\F1577"; }

.mdi-application-edit::before {
  content: "\F00AE"; }

.mdi-application-edit-outline::before {
  content: "\F0619"; }

.mdi-application-export::before {
  content: "\F0DAD"; }

.mdi-application-import::before {
  content: "\F0DAE"; }

.mdi-application-outline::before {
  content: "\F0614"; }

.mdi-application-parentheses::before {
  content: "\F10F9"; }

.mdi-application-parentheses-outline::before {
  content: "\F10FA"; }

.mdi-application-settings::before {
  content: "\F0B60"; }

.mdi-application-settings-outline::before {
  content: "\F1555"; }

.mdi-application-variable::before {
  content: "\F10FB"; }

.mdi-application-variable-outline::before {
  content: "\F10FC"; }

.mdi-approximately-equal::before {
  content: "\F0F9E"; }

.mdi-approximately-equal-box::before {
  content: "\F0F9F"; }

.mdi-apps::before {
  content: "\F003B"; }

.mdi-apps-box::before {
  content: "\F0D46"; }

.mdi-arch::before {
  content: "\F08C7"; }

.mdi-archive::before {
  content: "\F003C"; }

.mdi-archive-alert::before {
  content: "\F14FD"; }

.mdi-archive-alert-outline::before {
  content: "\F14FE"; }

.mdi-archive-arrow-down::before {
  content: "\F1259"; }

.mdi-archive-arrow-down-outline::before {
  content: "\F125A"; }

.mdi-archive-arrow-up::before {
  content: "\F125B"; }

.mdi-archive-arrow-up-outline::before {
  content: "\F125C"; }

.mdi-archive-cancel::before {
  content: "\F174B"; }

.mdi-archive-cancel-outline::before {
  content: "\F174C"; }

.mdi-archive-check::before {
  content: "\F174D"; }

.mdi-archive-check-outline::before {
  content: "\F174E"; }

.mdi-archive-clock::before {
  content: "\F174F"; }

.mdi-archive-clock-outline::before {
  content: "\F1750"; }

.mdi-archive-cog::before {
  content: "\F1751"; }

.mdi-archive-cog-outline::before {
  content: "\F1752"; }

.mdi-archive-edit::before {
  content: "\F1753"; }

.mdi-archive-edit-outline::before {
  content: "\F1754"; }

.mdi-archive-eye::before {
  content: "\F1755"; }

.mdi-archive-eye-outline::before {
  content: "\F1756"; }

.mdi-archive-lock::before {
  content: "\F1757"; }

.mdi-archive-lock-open::before {
  content: "\F1758"; }

.mdi-archive-lock-open-outline::before {
  content: "\F1759"; }

.mdi-archive-lock-outline::before {
  content: "\F175A"; }

.mdi-archive-marker::before {
  content: "\F175B"; }

.mdi-archive-marker-outline::before {
  content: "\F175C"; }

.mdi-archive-minus::before {
  content: "\F175D"; }

.mdi-archive-minus-outline::before {
  content: "\F175E"; }

.mdi-archive-music::before {
  content: "\F175F"; }

.mdi-archive-music-outline::before {
  content: "\F1760"; }

.mdi-archive-off::before {
  content: "\F1761"; }

.mdi-archive-off-outline::before {
  content: "\F1762"; }

.mdi-archive-outline::before {
  content: "\F120E"; }

.mdi-archive-plus::before {
  content: "\F1763"; }

.mdi-archive-plus-outline::before {
  content: "\F1764"; }

.mdi-archive-refresh::before {
  content: "\F1765"; }

.mdi-archive-refresh-outline::before {
  content: "\F1766"; }

.mdi-archive-remove::before {
  content: "\F1767"; }

.mdi-archive-remove-outline::before {
  content: "\F1768"; }

.mdi-archive-search::before {
  content: "\F1769"; }

.mdi-archive-search-outline::before {
  content: "\F176A"; }

.mdi-archive-settings::before {
  content: "\F176B"; }

.mdi-archive-settings-outline::before {
  content: "\F176C"; }

.mdi-archive-star::before {
  content: "\F176D"; }

.mdi-archive-star-outline::before {
  content: "\F176E"; }

.mdi-archive-sync::before {
  content: "\F176F"; }

.mdi-archive-sync-outline::before {
  content: "\F1770"; }

.mdi-arm-flex::before {
  content: "\F0FD7"; }

.mdi-arm-flex-outline::before {
  content: "\F0FD6"; }

.mdi-arrange-bring-forward::before {
  content: "\F003D"; }

.mdi-arrange-bring-to-front::before {
  content: "\F003E"; }

.mdi-arrange-send-backward::before {
  content: "\F003F"; }

.mdi-arrange-send-to-back::before {
  content: "\F0040"; }

.mdi-arrow-all::before {
  content: "\F0041"; }

.mdi-arrow-bottom-left::before {
  content: "\F0042"; }

.mdi-arrow-bottom-left-bold-box::before {
  content: "\F1964"; }

.mdi-arrow-bottom-left-bold-box-outline::before {
  content: "\F1965"; }

.mdi-arrow-bottom-left-bold-outline::before {
  content: "\F09B7"; }

.mdi-arrow-bottom-left-thick::before {
  content: "\F09B8"; }

.mdi-arrow-bottom-left-thin::before {
  content: "\F19B6"; }

.mdi-arrow-bottom-left-thin-circle-outline::before {
  content: "\F1596"; }

.mdi-arrow-bottom-right::before {
  content: "\F0043"; }

.mdi-arrow-bottom-right-bold-box::before {
  content: "\F1966"; }

.mdi-arrow-bottom-right-bold-box-outline::before {
  content: "\F1967"; }

.mdi-arrow-bottom-right-bold-outline::before {
  content: "\F09B9"; }

.mdi-arrow-bottom-right-thick::before {
  content: "\F09BA"; }

.mdi-arrow-bottom-right-thin::before {
  content: "\F19B7"; }

.mdi-arrow-bottom-right-thin-circle-outline::before {
  content: "\F1595"; }

.mdi-arrow-collapse::before {
  content: "\F0615"; }

.mdi-arrow-collapse-all::before {
  content: "\F0044"; }

.mdi-arrow-collapse-down::before {
  content: "\F0792"; }

.mdi-arrow-collapse-horizontal::before {
  content: "\F084C"; }

.mdi-arrow-collapse-left::before {
  content: "\F0793"; }

.mdi-arrow-collapse-right::before {
  content: "\F0794"; }

.mdi-arrow-collapse-up::before {
  content: "\F0795"; }

.mdi-arrow-collapse-vertical::before {
  content: "\F084D"; }

.mdi-arrow-decision::before {
  content: "\F09BB"; }

.mdi-arrow-decision-auto::before {
  content: "\F09BC"; }

.mdi-arrow-decision-auto-outline::before {
  content: "\F09BD"; }

.mdi-arrow-decision-outline::before {
  content: "\F09BE"; }

.mdi-arrow-down::before {
  content: "\F0045"; }

.mdi-arrow-down-bold::before {
  content: "\F072E"; }

.mdi-arrow-down-bold-box::before {
  content: "\F072F"; }

.mdi-arrow-down-bold-box-outline::before {
  content: "\F0730"; }

.mdi-arrow-down-bold-circle::before {
  content: "\F0047"; }

.mdi-arrow-down-bold-circle-outline::before {
  content: "\F0048"; }

.mdi-arrow-down-bold-hexagon-outline::before {
  content: "\F0049"; }

.mdi-arrow-down-bold-outline::before {
  content: "\F09BF"; }

.mdi-arrow-down-box::before {
  content: "\F06C0"; }

.mdi-arrow-down-circle::before {
  content: "\F0CDB"; }

.mdi-arrow-down-circle-outline::before {
  content: "\F0CDC"; }

.mdi-arrow-down-drop-circle::before {
  content: "\F004A"; }

.mdi-arrow-down-drop-circle-outline::before {
  content: "\F004B"; }

.mdi-arrow-down-left::before {
  content: "\F17A1"; }

.mdi-arrow-down-left-bold::before {
  content: "\F17A2"; }

.mdi-arrow-down-right::before {
  content: "\F17A3"; }

.mdi-arrow-down-right-bold::before {
  content: "\F17A4"; }

.mdi-arrow-down-thick::before {
  content: "\F0046"; }

.mdi-arrow-down-thin::before {
  content: "\F19B3"; }

.mdi-arrow-down-thin-circle-outline::before {
  content: "\F1599"; }

.mdi-arrow-expand::before {
  content: "\F0616"; }

.mdi-arrow-expand-all::before {
  content: "\F004C"; }

.mdi-arrow-expand-down::before {
  content: "\F0796"; }

.mdi-arrow-expand-horizontal::before {
  content: "\F084E"; }

.mdi-arrow-expand-left::before {
  content: "\F0797"; }

.mdi-arrow-expand-right::before {
  content: "\F0798"; }

.mdi-arrow-expand-up::before {
  content: "\F0799"; }

.mdi-arrow-expand-vertical::before {
  content: "\F084F"; }

.mdi-arrow-horizontal-lock::before {
  content: "\F115B"; }

.mdi-arrow-left::before {
  content: "\F004D"; }

.mdi-arrow-left-bold::before {
  content: "\F0731"; }

.mdi-arrow-left-bold-box::before {
  content: "\F0732"; }

.mdi-arrow-left-bold-box-outline::before {
  content: "\F0733"; }

.mdi-arrow-left-bold-circle::before {
  content: "\F004F"; }

.mdi-arrow-left-bold-circle-outline::before {
  content: "\F0050"; }

.mdi-arrow-left-bold-hexagon-outline::before {
  content: "\F0051"; }

.mdi-arrow-left-bold-outline::before {
  content: "\F09C0"; }

.mdi-arrow-left-bottom::before {
  content: "\F17A5"; }

.mdi-arrow-left-bottom-bold::before {
  content: "\F17A6"; }

.mdi-arrow-left-box::before {
  content: "\F06C1"; }

.mdi-arrow-left-circle::before {
  content: "\F0CDD"; }

.mdi-arrow-left-circle-outline::before {
  content: "\F0CDE"; }

.mdi-arrow-left-drop-circle::before {
  content: "\F0052"; }

.mdi-arrow-left-drop-circle-outline::before {
  content: "\F0053"; }

.mdi-arrow-left-right::before {
  content: "\F0E73"; }

.mdi-arrow-left-right-bold::before {
  content: "\F0E74"; }

.mdi-arrow-left-right-bold-outline::before {
  content: "\F09C1"; }

.mdi-arrow-left-thick::before {
  content: "\F004E"; }

.mdi-arrow-left-thin::before {
  content: "\F19B1"; }

.mdi-arrow-left-thin-circle-outline::before {
  content: "\F159A"; }

.mdi-arrow-left-top::before {
  content: "\F17A7"; }

.mdi-arrow-left-top-bold::before {
  content: "\F17A8"; }

.mdi-arrow-projectile::before {
  content: "\F1840"; }

.mdi-arrow-projectile-multiple::before {
  content: "\F183F"; }

.mdi-arrow-right::before {
  content: "\F0054"; }

.mdi-arrow-right-bold::before {
  content: "\F0734"; }

.mdi-arrow-right-bold-box::before {
  content: "\F0735"; }

.mdi-arrow-right-bold-box-outline::before {
  content: "\F0736"; }

.mdi-arrow-right-bold-circle::before {
  content: "\F0056"; }

.mdi-arrow-right-bold-circle-outline::before {
  content: "\F0057"; }

.mdi-arrow-right-bold-hexagon-outline::before {
  content: "\F0058"; }

.mdi-arrow-right-bold-outline::before {
  content: "\F09C2"; }

.mdi-arrow-right-bottom::before {
  content: "\F17A9"; }

.mdi-arrow-right-bottom-bold::before {
  content: "\F17AA"; }

.mdi-arrow-right-box::before {
  content: "\F06C2"; }

.mdi-arrow-right-circle::before {
  content: "\F0CDF"; }

.mdi-arrow-right-circle-outline::before {
  content: "\F0CE0"; }

.mdi-arrow-right-drop-circle::before {
  content: "\F0059"; }

.mdi-arrow-right-drop-circle-outline::before {
  content: "\F005A"; }

.mdi-arrow-right-thick::before {
  content: "\F0055"; }

.mdi-arrow-right-thin::before {
  content: "\F19B0"; }

.mdi-arrow-right-thin-circle-outline::before {
  content: "\F1598"; }

.mdi-arrow-right-top::before {
  content: "\F17AB"; }

.mdi-arrow-right-top-bold::before {
  content: "\F17AC"; }

.mdi-arrow-split-horizontal::before {
  content: "\F093B"; }

.mdi-arrow-split-vertical::before {
  content: "\F093C"; }

.mdi-arrow-top-left::before {
  content: "\F005B"; }

.mdi-arrow-top-left-bold-box::before {
  content: "\F1968"; }

.mdi-arrow-top-left-bold-box-outline::before {
  content: "\F1969"; }

.mdi-arrow-top-left-bold-outline::before {
  content: "\F09C3"; }

.mdi-arrow-top-left-bottom-right::before {
  content: "\F0E75"; }

.mdi-arrow-top-left-bottom-right-bold::before {
  content: "\F0E76"; }

.mdi-arrow-top-left-thick::before {
  content: "\F09C4"; }

.mdi-arrow-top-left-thin::before {
  content: "\F19B5"; }

.mdi-arrow-top-left-thin-circle-outline::before {
  content: "\F1593"; }

.mdi-arrow-top-right::before {
  content: "\F005C"; }

.mdi-arrow-top-right-bold-box::before {
  content: "\F196A"; }

.mdi-arrow-top-right-bold-box-outline::before {
  content: "\F196B"; }

.mdi-arrow-top-right-bold-outline::before {
  content: "\F09C5"; }

.mdi-arrow-top-right-bottom-left::before {
  content: "\F0E77"; }

.mdi-arrow-top-right-bottom-left-bold::before {
  content: "\F0E78"; }

.mdi-arrow-top-right-thick::before {
  content: "\F09C6"; }

.mdi-arrow-top-right-thin::before {
  content: "\F19B4"; }

.mdi-arrow-top-right-thin-circle-outline::before {
  content: "\F1594"; }

.mdi-arrow-u-down-left::before {
  content: "\F17AD"; }

.mdi-arrow-u-down-left-bold::before {
  content: "\F17AE"; }

.mdi-arrow-u-down-right::before {
  content: "\F17AF"; }

.mdi-arrow-u-down-right-bold::before {
  content: "\F17B0"; }

.mdi-arrow-u-left-bottom::before {
  content: "\F17B1"; }

.mdi-arrow-u-left-bottom-bold::before {
  content: "\F17B2"; }

.mdi-arrow-u-left-top::before {
  content: "\F17B3"; }

.mdi-arrow-u-left-top-bold::before {
  content: "\F17B4"; }

.mdi-arrow-u-right-bottom::before {
  content: "\F17B5"; }

.mdi-arrow-u-right-bottom-bold::before {
  content: "\F17B6"; }

.mdi-arrow-u-right-top::before {
  content: "\F17B7"; }

.mdi-arrow-u-right-top-bold::before {
  content: "\F17B8"; }

.mdi-arrow-u-up-left::before {
  content: "\F17B9"; }

.mdi-arrow-u-up-left-bold::before {
  content: "\F17BA"; }

.mdi-arrow-u-up-right::before {
  content: "\F17BB"; }

.mdi-arrow-u-up-right-bold::before {
  content: "\F17BC"; }

.mdi-arrow-up::before {
  content: "\F005D"; }

.mdi-arrow-up-bold::before {
  content: "\F0737"; }

.mdi-arrow-up-bold-box::before {
  content: "\F0738"; }

.mdi-arrow-up-bold-box-outline::before {
  content: "\F0739"; }

.mdi-arrow-up-bold-circle::before {
  content: "\F005F"; }

.mdi-arrow-up-bold-circle-outline::before {
  content: "\F0060"; }

.mdi-arrow-up-bold-hexagon-outline::before {
  content: "\F0061"; }

.mdi-arrow-up-bold-outline::before {
  content: "\F09C7"; }

.mdi-arrow-up-box::before {
  content: "\F06C3"; }

.mdi-arrow-up-circle::before {
  content: "\F0CE1"; }

.mdi-arrow-up-circle-outline::before {
  content: "\F0CE2"; }

.mdi-arrow-up-down::before {
  content: "\F0E79"; }

.mdi-arrow-up-down-bold::before {
  content: "\F0E7A"; }

.mdi-arrow-up-down-bold-outline::before {
  content: "\F09C8"; }

.mdi-arrow-up-drop-circle::before {
  content: "\F0062"; }

.mdi-arrow-up-drop-circle-outline::before {
  content: "\F0063"; }

.mdi-arrow-up-left::before {
  content: "\F17BD"; }

.mdi-arrow-up-left-bold::before {
  content: "\F17BE"; }

.mdi-arrow-up-right::before {
  content: "\F17BF"; }

.mdi-arrow-up-right-bold::before {
  content: "\F17C0"; }

.mdi-arrow-up-thick::before {
  content: "\F005E"; }

.mdi-arrow-up-thin::before {
  content: "\F19B2"; }

.mdi-arrow-up-thin-circle-outline::before {
  content: "\F1597"; }

.mdi-arrow-vertical-lock::before {
  content: "\F115C"; }

.mdi-artboard::before {
  content: "\F1B9A"; }

.mdi-artstation::before {
  content: "\F0B5B"; }

.mdi-aspect-ratio::before {
  content: "\F0A24"; }

.mdi-assistant::before {
  content: "\F0064"; }

.mdi-asterisk::before {
  content: "\F06C4"; }

.mdi-asterisk-circle-outline::before {
  content: "\F1A27"; }

.mdi-at::before {
  content: "\F0065"; }

.mdi-atlassian::before {
  content: "\F0804"; }

.mdi-atm::before {
  content: "\F0D47"; }

.mdi-atom::before {
  content: "\F0768"; }

.mdi-atom-variant::before {
  content: "\F0E7B"; }

.mdi-attachment::before {
  content: "\F0066"; }

.mdi-attachment-check::before {
  content: "\F1AC1"; }

.mdi-attachment-lock::before {
  content: "\F19C4"; }

.mdi-attachment-minus::before {
  content: "\F1AC2"; }

.mdi-attachment-off::before {
  content: "\F1AC3"; }

.mdi-attachment-plus::before {
  content: "\F1AC4"; }

.mdi-attachment-remove::before {
  content: "\F1AC5"; }

.mdi-atv::before {
  content: "\F1B70"; }

.mdi-audio-input-rca::before {
  content: "\F186B"; }

.mdi-audio-input-stereo-minijack::before {
  content: "\F186C"; }

.mdi-audio-input-xlr::before {
  content: "\F186D"; }

.mdi-audio-video::before {
  content: "\F093D"; }

.mdi-audio-video-off::before {
  content: "\F11B6"; }

.mdi-augmented-reality::before {
  content: "\F0850"; }

.mdi-auto-download::before {
  content: "\F137E"; }

.mdi-auto-fix::before {
  content: "\F0068"; }

.mdi-auto-upload::before {
  content: "\F0069"; }

.mdi-autorenew::before {
  content: "\F006A"; }

.mdi-autorenew-off::before {
  content: "\F19E7"; }

.mdi-av-timer::before {
  content: "\F006B"; }

.mdi-awning::before {
  content: "\F1B87"; }

.mdi-awning-outline::before {
  content: "\F1B88"; }

.mdi-aws::before {
  content: "\F0E0F"; }

.mdi-axe::before {
  content: "\F08C8"; }

.mdi-axe-battle::before {
  content: "\F1842"; }

.mdi-axis::before {
  content: "\F0D48"; }

.mdi-axis-arrow::before {
  content: "\F0D49"; }

.mdi-axis-arrow-info::before {
  content: "\F140E"; }

.mdi-axis-arrow-lock::before {
  content: "\F0D4A"; }

.mdi-axis-lock::before {
  content: "\F0D4B"; }

.mdi-axis-x-arrow::before {
  content: "\F0D4C"; }

.mdi-axis-x-arrow-lock::before {
  content: "\F0D4D"; }

.mdi-axis-x-rotate-clockwise::before {
  content: "\F0D4E"; }

.mdi-axis-x-rotate-counterclockwise::before {
  content: "\F0D4F"; }

.mdi-axis-x-y-arrow-lock::before {
  content: "\F0D50"; }

.mdi-axis-y-arrow::before {
  content: "\F0D51"; }

.mdi-axis-y-arrow-lock::before {
  content: "\F0D52"; }

.mdi-axis-y-rotate-clockwise::before {
  content: "\F0D53"; }

.mdi-axis-y-rotate-counterclockwise::before {
  content: "\F0D54"; }

.mdi-axis-z-arrow::before {
  content: "\F0D55"; }

.mdi-axis-z-arrow-lock::before {
  content: "\F0D56"; }

.mdi-axis-z-rotate-clockwise::before {
  content: "\F0D57"; }

.mdi-axis-z-rotate-counterclockwise::before {
  content: "\F0D58"; }

.mdi-babel::before {
  content: "\F0A25"; }

.mdi-baby::before {
  content: "\F006C"; }

.mdi-baby-bottle::before {
  content: "\F0F39"; }

.mdi-baby-bottle-outline::before {
  content: "\F0F3A"; }

.mdi-baby-buggy::before {
  content: "\F13E0"; }

.mdi-baby-buggy-off::before {
  content: "\F1AF3"; }

.mdi-baby-carriage::before {
  content: "\F068F"; }

.mdi-baby-carriage-off::before {
  content: "\F0FA0"; }

.mdi-baby-face::before {
  content: "\F0E7C"; }

.mdi-baby-face-outline::before {
  content: "\F0E7D"; }

.mdi-backburger::before {
  content: "\F006D"; }

.mdi-backspace::before {
  content: "\F006E"; }

.mdi-backspace-outline::before {
  content: "\F0B5C"; }

.mdi-backspace-reverse::before {
  content: "\F0E7E"; }

.mdi-backspace-reverse-outline::before {
  content: "\F0E7F"; }

.mdi-backup-restore::before {
  content: "\F006F"; }

.mdi-bacteria::before {
  content: "\F0ED5"; }

.mdi-bacteria-outline::before {
  content: "\F0ED6"; }

.mdi-badge-account::before {
  content: "\F0DA7"; }

.mdi-badge-account-alert::before {
  content: "\F0DA8"; }

.mdi-badge-account-alert-outline::before {
  content: "\F0DA9"; }

.mdi-badge-account-horizontal::before {
  content: "\F0E0D"; }

.mdi-badge-account-horizontal-outline::before {
  content: "\F0E0E"; }

.mdi-badge-account-outline::before {
  content: "\F0DAA"; }

.mdi-badminton::before {
  content: "\F0851"; }

.mdi-bag-carry-on::before {
  content: "\F0F3B"; }

.mdi-bag-carry-on-check::before {
  content: "\F0D65"; }

.mdi-bag-carry-on-off::before {
  content: "\F0F3C"; }

.mdi-bag-checked::before {
  content: "\F0F3D"; }

.mdi-bag-personal::before {
  content: "\F0E10"; }

.mdi-bag-personal-off::before {
  content: "\F0E11"; }

.mdi-bag-personal-off-outline::before {
  content: "\F0E12"; }

.mdi-bag-personal-outline::before {
  content: "\F0E13"; }

.mdi-bag-personal-tag::before {
  content: "\F1B0C"; }

.mdi-bag-personal-tag-outline::before {
  content: "\F1B0D"; }

.mdi-bag-suitcase::before {
  content: "\F158B"; }

.mdi-bag-suitcase-off::before {
  content: "\F158D"; }

.mdi-bag-suitcase-off-outline::before {
  content: "\F158E"; }

.mdi-bag-suitcase-outline::before {
  content: "\F158C"; }

.mdi-baguette::before {
  content: "\F0F3E"; }

.mdi-balcony::before {
  content: "\F1817"; }

.mdi-balloon::before {
  content: "\F0A26"; }

.mdi-ballot::before {
  content: "\F09C9"; }

.mdi-ballot-outline::before {
  content: "\F09CA"; }

.mdi-ballot-recount::before {
  content: "\F0C39"; }

.mdi-ballot-recount-outline::before {
  content: "\F0C3A"; }

.mdi-bandage::before {
  content: "\F0DAF"; }

.mdi-bank::before {
  content: "\F0070"; }

.mdi-bank-check::before {
  content: "\F1655"; }

.mdi-bank-minus::before {
  content: "\F0DB0"; }

.mdi-bank-off::before {
  content: "\F1656"; }

.mdi-bank-off-outline::before {
  content: "\F1657"; }

.mdi-bank-outline::before {
  content: "\F0E80"; }

.mdi-bank-plus::before {
  content: "\F0DB1"; }

.mdi-bank-remove::before {
  content: "\F0DB2"; }

.mdi-bank-transfer::before {
  content: "\F0A27"; }

.mdi-bank-transfer-in::before {
  content: "\F0A28"; }

.mdi-bank-transfer-out::before {
  content: "\F0A29"; }

.mdi-barcode::before {
  content: "\F0071"; }

.mdi-barcode-off::before {
  content: "\F1236"; }

.mdi-barcode-scan::before {
  content: "\F0072"; }

.mdi-barley::before {
  content: "\F0073"; }

.mdi-barley-off::before {
  content: "\F0B5D"; }

.mdi-barn::before {
  content: "\F0B5E"; }

.mdi-barrel::before {
  content: "\F0074"; }

.mdi-barrel-outline::before {
  content: "\F1A28"; }

.mdi-baseball::before {
  content: "\F0852"; }

.mdi-baseball-bat::before {
  content: "\F0853"; }

.mdi-baseball-diamond::before {
  content: "\F15EC"; }

.mdi-baseball-diamond-outline::before {
  content: "\F15ED"; }

.mdi-bash::before {
  content: "\F1183"; }

.mdi-basket::before {
  content: "\F0076"; }

.mdi-basket-check::before {
  content: "\F18E5"; }

.mdi-basket-check-outline::before {
  content: "\F18E6"; }

.mdi-basket-fill::before {
  content: "\F0077"; }

.mdi-basket-minus::before {
  content: "\F1523"; }

.mdi-basket-minus-outline::before {
  content: "\F1524"; }

.mdi-basket-off::before {
  content: "\F1525"; }

.mdi-basket-off-outline::before {
  content: "\F1526"; }

.mdi-basket-outline::before {
  content: "\F1181"; }

.mdi-basket-plus::before {
  content: "\F1527"; }

.mdi-basket-plus-outline::before {
  content: "\F1528"; }

.mdi-basket-remove::before {
  content: "\F1529"; }

.mdi-basket-remove-outline::before {
  content: "\F152A"; }

.mdi-basket-unfill::before {
  content: "\F0078"; }

.mdi-basketball::before {
  content: "\F0806"; }

.mdi-basketball-hoop::before {
  content: "\F0C3B"; }

.mdi-basketball-hoop-outline::before {
  content: "\F0C3C"; }

.mdi-bat::before {
  content: "\F0B5F"; }

.mdi-bathtub::before {
  content: "\F1818"; }

.mdi-bathtub-outline::before {
  content: "\F1819"; }

.mdi-battery::before {
  content: "\F0079"; }

.mdi-battery-10::before {
  content: "\F007A"; }

.mdi-battery-10-bluetooth::before {
  content: "\F093E"; }

.mdi-battery-20::before {
  content: "\F007B"; }

.mdi-battery-20-bluetooth::before {
  content: "\F093F"; }

.mdi-battery-30::before {
  content: "\F007C"; }

.mdi-battery-30-bluetooth::before {
  content: "\F0940"; }

.mdi-battery-40::before {
  content: "\F007D"; }

.mdi-battery-40-bluetooth::before {
  content: "\F0941"; }

.mdi-battery-50::before {
  content: "\F007E"; }

.mdi-battery-50-bluetooth::before {
  content: "\F0942"; }

.mdi-battery-60::before {
  content: "\F007F"; }

.mdi-battery-60-bluetooth::before {
  content: "\F0943"; }

.mdi-battery-70::before {
  content: "\F0080"; }

.mdi-battery-70-bluetooth::before {
  content: "\F0944"; }

.mdi-battery-80::before {
  content: "\F0081"; }

.mdi-battery-80-bluetooth::before {
  content: "\F0945"; }

.mdi-battery-90::before {
  content: "\F0082"; }

.mdi-battery-90-bluetooth::before {
  content: "\F0946"; }

.mdi-battery-alert::before {
  content: "\F0083"; }

.mdi-battery-alert-bluetooth::before {
  content: "\F0947"; }

.mdi-battery-alert-variant::before {
  content: "\F10CC"; }

.mdi-battery-alert-variant-outline::before {
  content: "\F10CD"; }

.mdi-battery-arrow-down::before {
  content: "\F17DE"; }

.mdi-battery-arrow-down-outline::before {
  content: "\F17DF"; }

.mdi-battery-arrow-up::before {
  content: "\F17E0"; }

.mdi-battery-arrow-up-outline::before {
  content: "\F17E1"; }

.mdi-battery-bluetooth::before {
  content: "\F0948"; }

.mdi-battery-bluetooth-variant::before {
  content: "\F0949"; }

.mdi-battery-charging::before {
  content: "\F0084"; }

.mdi-battery-charging-10::before {
  content: "\F089C"; }

.mdi-battery-charging-100::before {
  content: "\F0085"; }

.mdi-battery-charging-20::before {
  content: "\F0086"; }

.mdi-battery-charging-30::before {
  content: "\F0087"; }

.mdi-battery-charging-40::before {
  content: "\F0088"; }

.mdi-battery-charging-50::before {
  content: "\F089D"; }

.mdi-battery-charging-60::before {
  content: "\F0089"; }

.mdi-battery-charging-70::before {
  content: "\F089E"; }

.mdi-battery-charging-80::before {
  content: "\F008A"; }

.mdi-battery-charging-90::before {
  content: "\F008B"; }

.mdi-battery-charging-high::before {
  content: "\F12A6"; }

.mdi-battery-charging-low::before {
  content: "\F12A4"; }

.mdi-battery-charging-medium::before {
  content: "\F12A5"; }

.mdi-battery-charging-outline::before {
  content: "\F089F"; }

.mdi-battery-charging-wireless::before {
  content: "\F0807"; }

.mdi-battery-charging-wireless-10::before {
  content: "\F0808"; }

.mdi-battery-charging-wireless-20::before {
  content: "\F0809"; }

.mdi-battery-charging-wireless-30::before {
  content: "\F080A"; }

.mdi-battery-charging-wireless-40::before {
  content: "\F080B"; }

.mdi-battery-charging-wireless-50::before {
  content: "\F080C"; }

.mdi-battery-charging-wireless-60::before {
  content: "\F080D"; }

.mdi-battery-charging-wireless-70::before {
  content: "\F080E"; }

.mdi-battery-charging-wireless-80::before {
  content: "\F080F"; }

.mdi-battery-charging-wireless-90::before {
  content: "\F0810"; }

.mdi-battery-charging-wireless-alert::before {
  content: "\F0811"; }

.mdi-battery-charging-wireless-outline::before {
  content: "\F0812"; }

.mdi-battery-check::before {
  content: "\F17E2"; }

.mdi-battery-check-outline::before {
  content: "\F17E3"; }

.mdi-battery-clock::before {
  content: "\F19E5"; }

.mdi-battery-clock-outline::before {
  content: "\F19E6"; }

.mdi-battery-heart::before {
  content: "\F120F"; }

.mdi-battery-heart-outline::before {
  content: "\F1210"; }

.mdi-battery-heart-variant::before {
  content: "\F1211"; }

.mdi-battery-high::before {
  content: "\F12A3"; }

.mdi-battery-lock::before {
  content: "\F179C"; }

.mdi-battery-lock-open::before {
  content: "\F179D"; }

.mdi-battery-low::before {
  content: "\F12A1"; }

.mdi-battery-medium::before {
  content: "\F12A2"; }

.mdi-battery-minus::before {
  content: "\F17E4"; }

.mdi-battery-minus-outline::before {
  content: "\F17E5"; }

.mdi-battery-minus-variant::before {
  content: "\F008C"; }

.mdi-battery-negative::before {
  content: "\F008D"; }

.mdi-battery-off::before {
  content: "\F125D"; }

.mdi-battery-off-outline::before {
  content: "\F125E"; }

.mdi-battery-outline::before {
  content: "\F008E"; }

.mdi-battery-plus::before {
  content: "\F17E6"; }

.mdi-battery-plus-outline::before {
  content: "\F17E7"; }

.mdi-battery-plus-variant::before {
  content: "\F008F"; }

.mdi-battery-positive::before {
  content: "\F0090"; }

.mdi-battery-remove::before {
  content: "\F17E8"; }

.mdi-battery-remove-outline::before {
  content: "\F17E9"; }

.mdi-battery-sync::before {
  content: "\F1834"; }

.mdi-battery-sync-outline::before {
  content: "\F1835"; }

.mdi-battery-unknown::before {
  content: "\F0091"; }

.mdi-battery-unknown-bluetooth::before {
  content: "\F094A"; }

.mdi-beach::before {
  content: "\F0092"; }

.mdi-beaker::before {
  content: "\F0CEA"; }

.mdi-beaker-alert::before {
  content: "\F1229"; }

.mdi-beaker-alert-outline::before {
  content: "\F122A"; }

.mdi-beaker-check::before {
  content: "\F122B"; }

.mdi-beaker-check-outline::before {
  content: "\F122C"; }

.mdi-beaker-minus::before {
  content: "\F122D"; }

.mdi-beaker-minus-outline::before {
  content: "\F122E"; }

.mdi-beaker-outline::before {
  content: "\F0690"; }

.mdi-beaker-plus::before {
  content: "\F122F"; }

.mdi-beaker-plus-outline::before {
  content: "\F1230"; }

.mdi-beaker-question::before {
  content: "\F1231"; }

.mdi-beaker-question-outline::before {
  content: "\F1232"; }

.mdi-beaker-remove::before {
  content: "\F1233"; }

.mdi-beaker-remove-outline::before {
  content: "\F1234"; }

.mdi-bed::before {
  content: "\F02E3"; }

.mdi-bed-clock::before {
  content: "\F1B94"; }

.mdi-bed-double::before {
  content: "\F0FD4"; }

.mdi-bed-double-outline::before {
  content: "\F0FD3"; }

.mdi-bed-empty::before {
  content: "\F08A0"; }

.mdi-bed-king::before {
  content: "\F0FD2"; }

.mdi-bed-king-outline::before {
  content: "\F0FD1"; }

.mdi-bed-outline::before {
  content: "\F0099"; }

.mdi-bed-queen::before {
  content: "\F0FD0"; }

.mdi-bed-queen-outline::before {
  content: "\F0FDB"; }

.mdi-bed-single::before {
  content: "\F106D"; }

.mdi-bed-single-outline::before {
  content: "\F106E"; }

.mdi-bee::before {
  content: "\F0FA1"; }

.mdi-bee-flower::before {
  content: "\F0FA2"; }

.mdi-beehive-off-outline::before {
  content: "\F13ED"; }

.mdi-beehive-outline::before {
  content: "\F10CE"; }

.mdi-beekeeper::before {
  content: "\F14E2"; }

.mdi-beer::before {
  content: "\F0098"; }

.mdi-beer-outline::before {
  content: "\F130C"; }

.mdi-bell::before {
  content: "\F009A"; }

.mdi-bell-alert::before {
  content: "\F0D59"; }

.mdi-bell-alert-outline::before {
  content: "\F0E81"; }

.mdi-bell-badge::before {
  content: "\F116B"; }

.mdi-bell-badge-outline::before {
  content: "\F0178"; }

.mdi-bell-cancel::before {
  content: "\F13E7"; }

.mdi-bell-cancel-outline::before {
  content: "\F13E8"; }

.mdi-bell-check::before {
  content: "\F11E5"; }

.mdi-bell-check-outline::before {
  content: "\F11E6"; }

.mdi-bell-circle::before {
  content: "\F0D5A"; }

.mdi-bell-circle-outline::before {
  content: "\F0D5B"; }

.mdi-bell-cog::before {
  content: "\F1A29"; }

.mdi-bell-cog-outline::before {
  content: "\F1A2A"; }

.mdi-bell-minus::before {
  content: "\F13E9"; }

.mdi-bell-minus-outline::before {
  content: "\F13EA"; }

.mdi-bell-off::before {
  content: "\F009B"; }

.mdi-bell-off-outline::before {
  content: "\F0A91"; }

.mdi-bell-outline::before {
  content: "\F009C"; }

.mdi-bell-plus::before {
  content: "\F009D"; }

.mdi-bell-plus-outline::before {
  content: "\F0A92"; }

.mdi-bell-remove::before {
  content: "\F13EB"; }

.mdi-bell-remove-outline::before {
  content: "\F13EC"; }

.mdi-bell-ring::before {
  content: "\F009E"; }

.mdi-bell-ring-outline::before {
  content: "\F009F"; }

.mdi-bell-sleep::before {
  content: "\F00A0"; }

.mdi-bell-sleep-outline::before {
  content: "\F0A93"; }

.mdi-beta::before {
  content: "\F00A1"; }

.mdi-betamax::before {
  content: "\F09CB"; }

.mdi-biathlon::before {
  content: "\F0E14"; }

.mdi-bicycle::before {
  content: "\F109C"; }

.mdi-bicycle-basket::before {
  content: "\F1235"; }

.mdi-bicycle-cargo::before {
  content: "\F189C"; }

.mdi-bicycle-electric::before {
  content: "\F15B4"; }

.mdi-bicycle-penny-farthing::before {
  content: "\F15E9"; }

.mdi-bike::before {
  content: "\F00A3"; }

.mdi-bike-fast::before {
  content: "\F111F"; }

.mdi-billboard::before {
  content: "\F1010"; }

.mdi-billiards::before {
  content: "\F0B61"; }

.mdi-billiards-rack::before {
  content: "\F0B62"; }

.mdi-binoculars::before {
  content: "\F00A5"; }

.mdi-bio::before {
  content: "\F00A6"; }

.mdi-biohazard::before {
  content: "\F00A7"; }

.mdi-bird::before {
  content: "\F15C6"; }

.mdi-bitbucket::before {
  content: "\F00A8"; }

.mdi-bitcoin::before {
  content: "\F0813"; }

.mdi-black-mesa::before {
  content: "\F00A9"; }

.mdi-blender::before {
  content: "\F0CEB"; }

.mdi-blender-outline::before {
  content: "\F181A"; }

.mdi-blender-software::before {
  content: "\F00AB"; }

.mdi-blinds::before {
  content: "\F00AC"; }

.mdi-blinds-horizontal::before {
  content: "\F1A2B"; }

.mdi-blinds-horizontal-closed::before {
  content: "\F1A2C"; }

.mdi-blinds-open::before {
  content: "\F1011"; }

.mdi-blinds-vertical::before {
  content: "\F1A2D"; }

.mdi-blinds-vertical-closed::before {
  content: "\F1A2E"; }

.mdi-block-helper::before {
  content: "\F00AD"; }

.mdi-blood-bag::before {
  content: "\F0CEC"; }

.mdi-bluetooth::before {
  content: "\F00AF"; }

.mdi-bluetooth-audio::before {
  content: "\F00B0"; }

.mdi-bluetooth-connect::before {
  content: "\F00B1"; }

.mdi-bluetooth-off::before {
  content: "\F00B2"; }

.mdi-bluetooth-settings::before {
  content: "\F00B3"; }

.mdi-bluetooth-transfer::before {
  content: "\F00B4"; }

.mdi-blur::before {
  content: "\F00B5"; }

.mdi-blur-linear::before {
  content: "\F00B6"; }

.mdi-blur-off::before {
  content: "\F00B7"; }

.mdi-blur-radial::before {
  content: "\F00B8"; }

.mdi-bolt::before {
  content: "\F0DB3"; }

.mdi-bomb::before {
  content: "\F0691"; }

.mdi-bomb-off::before {
  content: "\F06C5"; }

.mdi-bone::before {
  content: "\F00B9"; }

.mdi-bone-off::before {
  content: "\F19E0"; }

.mdi-book::before {
  content: "\F00BA"; }

.mdi-book-account::before {
  content: "\F13AD"; }

.mdi-book-account-outline::before {
  content: "\F13AE"; }

.mdi-book-alert::before {
  content: "\F167C"; }

.mdi-book-alert-outline::before {
  content: "\F167D"; }

.mdi-book-alphabet::before {
  content: "\F061D"; }

.mdi-book-arrow-down::before {
  content: "\F167E"; }

.mdi-book-arrow-down-outline::before {
  content: "\F167F"; }

.mdi-book-arrow-left::before {
  content: "\F1680"; }

.mdi-book-arrow-left-outline::before {
  content: "\F1681"; }

.mdi-book-arrow-right::before {
  content: "\F1682"; }

.mdi-book-arrow-right-outline::before {
  content: "\F1683"; }

.mdi-book-arrow-up::before {
  content: "\F1684"; }

.mdi-book-arrow-up-outline::before {
  content: "\F1685"; }

.mdi-book-cancel::before {
  content: "\F1686"; }

.mdi-book-cancel-outline::before {
  content: "\F1687"; }

.mdi-book-check::before {
  content: "\F14F3"; }

.mdi-book-check-outline::before {
  content: "\F14F4"; }

.mdi-book-clock::before {
  content: "\F1688"; }

.mdi-book-clock-outline::before {
  content: "\F1689"; }

.mdi-book-cog::before {
  content: "\F168A"; }

.mdi-book-cog-outline::before {
  content: "\F168B"; }

.mdi-book-cross::before {
  content: "\F00A2"; }

.mdi-book-edit::before {
  content: "\F168C"; }

.mdi-book-edit-outline::before {
  content: "\F168D"; }

.mdi-book-education::before {
  content: "\F16C9"; }

.mdi-book-education-outline::before {
  content: "\F16CA"; }

.mdi-book-heart::before {
  content: "\F1A1D"; }

.mdi-book-heart-outline::before {
  content: "\F1A1E"; }

.mdi-book-information-variant::before {
  content: "\F106F"; }

.mdi-book-lock::before {
  content: "\F079A"; }

.mdi-book-lock-open::before {
  content: "\F079B"; }

.mdi-book-lock-open-outline::before {
  content: "\F168E"; }

.mdi-book-lock-outline::before {
  content: "\F168F"; }

.mdi-book-marker::before {
  content: "\F1690"; }

.mdi-book-marker-outline::before {
  content: "\F1691"; }

.mdi-book-minus::before {
  content: "\F05D9"; }

.mdi-book-minus-multiple::before {
  content: "\F0A94"; }

.mdi-book-minus-multiple-outline::before {
  content: "\F090B"; }

.mdi-book-minus-outline::before {
  content: "\F1692"; }

.mdi-book-multiple::before {
  content: "\F00BB"; }

.mdi-book-multiple-outline::before {
  content: "\F0436"; }

.mdi-book-music::before {
  content: "\F0067"; }

.mdi-book-music-outline::before {
  content: "\F1693"; }

.mdi-book-off::before {
  content: "\F1694"; }

.mdi-book-off-outline::before {
  content: "\F1695"; }

.mdi-book-open::before {
  content: "\F00BD"; }

.mdi-book-open-blank-variant::before {
  content: "\F00BE"; }

.mdi-book-open-outline::before {
  content: "\F0B63"; }

.mdi-book-open-page-variant::before {
  content: "\F05DA"; }

.mdi-book-open-page-variant-outline::before {
  content: "\F15D6"; }

.mdi-book-open-variant::before {
  content: "\F14F7"; }

.mdi-book-outline::before {
  content: "\F0B64"; }

.mdi-book-play::before {
  content: "\F0E82"; }

.mdi-book-play-outline::before {
  content: "\F0E83"; }

.mdi-book-plus::before {
  content: "\F05DB"; }

.mdi-book-plus-multiple::before {
  content: "\F0A95"; }

.mdi-book-plus-multiple-outline::before {
  content: "\F0ADE"; }

.mdi-book-plus-outline::before {
  content: "\F1696"; }

.mdi-book-refresh::before {
  content: "\F1697"; }

.mdi-book-refresh-outline::before {
  content: "\F1698"; }

.mdi-book-remove::before {
  content: "\F0A97"; }

.mdi-book-remove-multiple::before {
  content: "\F0A96"; }

.mdi-book-remove-multiple-outline::before {
  content: "\F04CA"; }

.mdi-book-remove-outline::before {
  content: "\F1699"; }

.mdi-book-search::before {
  content: "\F0E84"; }

.mdi-book-search-outline::before {
  content: "\F0E85"; }

.mdi-book-settings::before {
  content: "\F169A"; }

.mdi-book-settings-outline::before {
  content: "\F169B"; }

.mdi-book-sync::before {
  content: "\F169C"; }

.mdi-book-sync-outline::before {
  content: "\F16C8"; }

.mdi-book-variant::before {
  content: "\F00BF"; }

.mdi-bookmark::before {
  content: "\F00C0"; }

.mdi-bookmark-box::before {
  content: "\F1B75"; }

.mdi-bookmark-box-multiple::before {
  content: "\F196C"; }

.mdi-bookmark-box-multiple-outline::before {
  content: "\F196D"; }

.mdi-bookmark-box-outline::before {
  content: "\F1B76"; }

.mdi-bookmark-check::before {
  content: "\F00C1"; }

.mdi-bookmark-check-outline::before {
  content: "\F137B"; }

.mdi-bookmark-minus::before {
  content: "\F09CC"; }

.mdi-bookmark-minus-outline::before {
  content: "\F09CD"; }

.mdi-bookmark-multiple::before {
  content: "\F0E15"; }

.mdi-bookmark-multiple-outline::before {
  content: "\F0E16"; }

.mdi-bookmark-music::before {
  content: "\F00C2"; }

.mdi-bookmark-music-outline::before {
  content: "\F1379"; }

.mdi-bookmark-off::before {
  content: "\F09CE"; }

.mdi-bookmark-off-outline::before {
  content: "\F09CF"; }

.mdi-bookmark-outline::before {
  content: "\F00C3"; }

.mdi-bookmark-plus::before {
  content: "\F00C5"; }

.mdi-bookmark-plus-outline::before {
  content: "\F00C4"; }

.mdi-bookmark-remove::before {
  content: "\F00C6"; }

.mdi-bookmark-remove-outline::before {
  content: "\F137A"; }

.mdi-bookshelf::before {
  content: "\F125F"; }

.mdi-boom-gate::before {
  content: "\F0E86"; }

.mdi-boom-gate-alert::before {
  content: "\F0E87"; }

.mdi-boom-gate-alert-outline::before {
  content: "\F0E88"; }

.mdi-boom-gate-arrow-down::before {
  content: "\F0E89"; }

.mdi-boom-gate-arrow-down-outline::before {
  content: "\F0E8A"; }

.mdi-boom-gate-arrow-up::before {
  content: "\F0E8C"; }

.mdi-boom-gate-arrow-up-outline::before {
  content: "\F0E8D"; }

.mdi-boom-gate-outline::before {
  content: "\F0E8B"; }

.mdi-boom-gate-up::before {
  content: "\F17F9"; }

.mdi-boom-gate-up-outline::before {
  content: "\F17FA"; }

.mdi-boombox::before {
  content: "\F05DC"; }

.mdi-boomerang::before {
  content: "\F10CF"; }

.mdi-bootstrap::before {
  content: "\F06C6"; }

.mdi-border-all::before {
  content: "\F00C7"; }

.mdi-border-all-variant::before {
  content: "\F08A1"; }

.mdi-border-bottom::before {
  content: "\F00C8"; }

.mdi-border-bottom-variant::before {
  content: "\F08A2"; }

.mdi-border-color::before {
  content: "\F00C9"; }

.mdi-border-horizontal::before {
  content: "\F00CA"; }

.mdi-border-inside::before {
  content: "\F00CB"; }

.mdi-border-left::before {
  content: "\F00CC"; }

.mdi-border-left-variant::before {
  content: "\F08A3"; }

.mdi-border-none::before {
  content: "\F00CD"; }

.mdi-border-none-variant::before {
  content: "\F08A4"; }

.mdi-border-outside::before {
  content: "\F00CE"; }

.mdi-border-radius::before {
  content: "\F1AF4"; }

.mdi-border-right::before {
  content: "\F00CF"; }

.mdi-border-right-variant::before {
  content: "\F08A5"; }

.mdi-border-style::before {
  content: "\F00D0"; }

.mdi-border-top::before {
  content: "\F00D1"; }

.mdi-border-top-variant::before {
  content: "\F08A6"; }

.mdi-border-vertical::before {
  content: "\F00D2"; }

.mdi-bottle-soda::before {
  content: "\F1070"; }

.mdi-bottle-soda-classic::before {
  content: "\F1071"; }

.mdi-bottle-soda-classic-outline::before {
  content: "\F1363"; }

.mdi-bottle-soda-outline::before {
  content: "\F1072"; }

.mdi-bottle-tonic::before {
  content: "\F112E"; }

.mdi-bottle-tonic-outline::before {
  content: "\F112F"; }

.mdi-bottle-tonic-plus::before {
  content: "\F1130"; }

.mdi-bottle-tonic-plus-outline::before {
  content: "\F1131"; }

.mdi-bottle-tonic-skull::before {
  content: "\F1132"; }

.mdi-bottle-tonic-skull-outline::before {
  content: "\F1133"; }

.mdi-bottle-wine::before {
  content: "\F0854"; }

.mdi-bottle-wine-outline::before {
  content: "\F1310"; }

.mdi-bow-arrow::before {
  content: "\F1841"; }

.mdi-bow-tie::before {
  content: "\F0678"; }

.mdi-bowl::before {
  content: "\F028E"; }

.mdi-bowl-mix::before {
  content: "\F0617"; }

.mdi-bowl-mix-outline::before {
  content: "\F02E4"; }

.mdi-bowl-outline::before {
  content: "\F02A9"; }

.mdi-bowling::before {
  content: "\F00D3"; }

.mdi-box::before {
  content: "\F00D4"; }

.mdi-box-cutter::before {
  content: "\F00D5"; }

.mdi-box-cutter-off::before {
  content: "\F0B4A"; }

.mdi-box-shadow::before {
  content: "\F0637"; }

.mdi-boxing-glove::before {
  content: "\F0B65"; }

.mdi-braille::before {
  content: "\F09D0"; }

.mdi-brain::before {
  content: "\F09D1"; }

.mdi-bread-slice::before {
  content: "\F0CEE"; }

.mdi-bread-slice-outline::before {
  content: "\F0CEF"; }

.mdi-bridge::before {
  content: "\F0618"; }

.mdi-briefcase::before {
  content: "\F00D6"; }

.mdi-briefcase-account::before {
  content: "\F0CF0"; }

.mdi-briefcase-account-outline::before {
  content: "\F0CF1"; }

.mdi-briefcase-arrow-left-right::before {
  content: "\F1A8D"; }

.mdi-briefcase-arrow-left-right-outline::before {
  content: "\F1A8E"; }

.mdi-briefcase-arrow-up-down::before {
  content: "\F1A8F"; }

.mdi-briefcase-arrow-up-down-outline::before {
  content: "\F1A90"; }

.mdi-briefcase-check::before {
  content: "\F00D7"; }

.mdi-briefcase-check-outline::before {
  content: "\F131E"; }

.mdi-briefcase-clock::before {
  content: "\F10D0"; }

.mdi-briefcase-clock-outline::before {
  content: "\F10D1"; }

.mdi-briefcase-download::before {
  content: "\F00D8"; }

.mdi-briefcase-download-outline::before {
  content: "\F0C3D"; }

.mdi-briefcase-edit::before {
  content: "\F0A98"; }

.mdi-briefcase-edit-outline::before {
  content: "\F0C3E"; }

.mdi-briefcase-eye::before {
  content: "\F17D9"; }

.mdi-briefcase-eye-outline::before {
  content: "\F17DA"; }

.mdi-briefcase-minus::before {
  content: "\F0A2A"; }

.mdi-briefcase-minus-outline::before {
  content: "\F0C3F"; }

.mdi-briefcase-off::before {
  content: "\F1658"; }

.mdi-briefcase-off-outline::before {
  content: "\F1659"; }

.mdi-briefcase-outline::before {
  content: "\F0814"; }

.mdi-briefcase-plus::before {
  content: "\F0A2B"; }

.mdi-briefcase-plus-outline::before {
  content: "\F0C40"; }

.mdi-briefcase-remove::before {
  content: "\F0A2C"; }

.mdi-briefcase-remove-outline::before {
  content: "\F0C41"; }

.mdi-briefcase-search::before {
  content: "\F0A2D"; }

.mdi-briefcase-search-outline::before {
  content: "\F0C42"; }

.mdi-briefcase-upload::before {
  content: "\F00D9"; }

.mdi-briefcase-upload-outline::before {
  content: "\F0C43"; }

.mdi-briefcase-variant::before {
  content: "\F1494"; }

.mdi-briefcase-variant-off::before {
  content: "\F165A"; }

.mdi-briefcase-variant-off-outline::before {
  content: "\F165B"; }

.mdi-briefcase-variant-outline::before {
  content: "\F1495"; }

.mdi-brightness-1::before {
  content: "\F00DA"; }

.mdi-brightness-2::before {
  content: "\F00DB"; }

.mdi-brightness-3::before {
  content: "\F00DC"; }

.mdi-brightness-4::before {
  content: "\F00DD"; }

.mdi-brightness-5::before {
  content: "\F00DE"; }

.mdi-brightness-6::before {
  content: "\F00DF"; }

.mdi-brightness-7::before {
  content: "\F00E0"; }

.mdi-brightness-auto::before {
  content: "\F00E1"; }

.mdi-brightness-percent::before {
  content: "\F0CF2"; }

.mdi-broadcast::before {
  content: "\F1720"; }

.mdi-broadcast-off::before {
  content: "\F1721"; }

.mdi-broom::before {
  content: "\F00E2"; }

.mdi-brush::before {
  content: "\F00E3"; }

.mdi-brush-off::before {
  content: "\F1771"; }

.mdi-brush-outline::before {
  content: "\F1A0D"; }

.mdi-brush-variant::before {
  content: "\F1813"; }

.mdi-bucket::before {
  content: "\F1415"; }

.mdi-bucket-outline::before {
  content: "\F1416"; }

.mdi-buffet::before {
  content: "\F0578"; }

.mdi-bug::before {
  content: "\F00E4"; }

.mdi-bug-check::before {
  content: "\F0A2E"; }

.mdi-bug-check-outline::before {
  content: "\F0A2F"; }

.mdi-bug-outline::before {
  content: "\F0A30"; }

.mdi-bug-pause::before {
  content: "\F1AF5"; }

.mdi-bug-pause-outline::before {
  content: "\F1AF6"; }

.mdi-bug-play::before {
  content: "\F1AF7"; }

.mdi-bug-play-outline::before {
  content: "\F1AF8"; }

.mdi-bug-stop::before {
  content: "\F1AF9"; }

.mdi-bug-stop-outline::before {
  content: "\F1AFA"; }

.mdi-bugle::before {
  content: "\F0DB4"; }

.mdi-bulkhead-light::before {
  content: "\F1A2F"; }

.mdi-bulldozer::before {
  content: "\F0B22"; }

.mdi-bullet::before {
  content: "\F0CF3"; }

.mdi-bulletin-board::before {
  content: "\F00E5"; }

.mdi-bullhorn::before {
  content: "\F00E6"; }

.mdi-bullhorn-outline::before {
  content: "\F0B23"; }

.mdi-bullhorn-variant::before {
  content: "\F196E"; }

.mdi-bullhorn-variant-outline::before {
  content: "\F196F"; }

.mdi-bullseye::before {
  content: "\F05DD"; }

.mdi-bullseye-arrow::before {
  content: "\F08C9"; }

.mdi-bulma::before {
  content: "\F12E7"; }

.mdi-bunk-bed::before {
  content: "\F1302"; }

.mdi-bunk-bed-outline::before {
  content: "\F0097"; }

.mdi-bus::before {
  content: "\F00E7"; }

.mdi-bus-alert::before {
  content: "\F0A99"; }

.mdi-bus-articulated-end::before {
  content: "\F079C"; }

.mdi-bus-articulated-front::before {
  content: "\F079D"; }

.mdi-bus-clock::before {
  content: "\F08CA"; }

.mdi-bus-double-decker::before {
  content: "\F079E"; }

.mdi-bus-electric::before {
  content: "\F191D"; }

.mdi-bus-marker::before {
  content: "\F1212"; }

.mdi-bus-multiple::before {
  content: "\F0F3F"; }

.mdi-bus-school::before {
  content: "\F079F"; }

.mdi-bus-side::before {
  content: "\F07A0"; }

.mdi-bus-stop::before {
  content: "\F1012"; }

.mdi-bus-stop-covered::before {
  content: "\F1013"; }

.mdi-bus-stop-uncovered::before {
  content: "\F1014"; }

.mdi-butterfly::before {
  content: "\F1589"; }

.mdi-butterfly-outline::before {
  content: "\F158A"; }

.mdi-button-cursor::before {
  content: "\F1B4F"; }

.mdi-button-pointer::before {
  content: "\F1B50"; }

.mdi-cabin-a-frame::before {
  content: "\F188C"; }

.mdi-cable-data::before {
  content: "\F1394"; }

.mdi-cached::before {
  content: "\F00E8"; }

.mdi-cactus::before {
  content: "\F0DB5"; }

.mdi-cake::before {
  content: "\F00E9"; }

.mdi-cake-layered::before {
  content: "\F00EA"; }

.mdi-cake-variant::before {
  content: "\F00EB"; }

.mdi-cake-variant-outline::before {
  content: "\F17F0"; }

.mdi-calculator::before {
  content: "\F00EC"; }

.mdi-calculator-variant::before {
  content: "\F0A9A"; }

.mdi-calculator-variant-outline::before {
  content: "\F15A6"; }

.mdi-calendar::before {
  content: "\F00ED"; }

.mdi-calendar-account::before {
  content: "\F0ED7"; }

.mdi-calendar-account-outline::before {
  content: "\F0ED8"; }

.mdi-calendar-alert::before {
  content: "\F0A31"; }

.mdi-calendar-alert-outline::before {
  content: "\F1B62"; }

.mdi-calendar-arrow-left::before {
  content: "\F1134"; }

.mdi-calendar-arrow-right::before {
  content: "\F1135"; }

.mdi-calendar-badge::before {
  content: "\F1B9D"; }

.mdi-calendar-badge-outline::before {
  content: "\F1B9E"; }

.mdi-calendar-blank::before {
  content: "\F00EE"; }

.mdi-calendar-blank-multiple::before {
  content: "\F1073"; }

.mdi-calendar-blank-outline::before {
  content: "\F0B66"; }

.mdi-calendar-check::before {
  content: "\F00EF"; }

.mdi-calendar-check-outline::before {
  content: "\F0C44"; }

.mdi-calendar-clock::before {
  content: "\F00F0"; }

.mdi-calendar-clock-outline::before {
  content: "\F16E1"; }

.mdi-calendar-collapse-horizontal::before {
  content: "\F189D"; }

.mdi-calendar-collapse-horizontal-outline::before {
  content: "\F1B63"; }

.mdi-calendar-cursor::before {
  content: "\F157B"; }

.mdi-calendar-cursor-outline::before {
  content: "\F1B64"; }

.mdi-calendar-edit::before {
  content: "\F08A7"; }

.mdi-calendar-edit-outline::before {
  content: "\F1B65"; }

.mdi-calendar-end::before {
  content: "\F166C"; }

.mdi-calendar-end-outline::before {
  content: "\F1B66"; }

.mdi-calendar-expand-horizontal::before {
  content: "\F189E"; }

.mdi-calendar-expand-horizontal-outline::before {
  content: "\F1B67"; }

.mdi-calendar-export::before {
  content: "\F0B24"; }

.mdi-calendar-export-outline::before {
  content: "\F1B68"; }

.mdi-calendar-filter::before {
  content: "\F1A32"; }

.mdi-calendar-filter-outline::before {
  content: "\F1A33"; }

.mdi-calendar-heart::before {
  content: "\F09D2"; }

.mdi-calendar-heart-outline::before {
  content: "\F1B69"; }

.mdi-calendar-import::before {
  content: "\F0B25"; }

.mdi-calendar-import-outline::before {
  content: "\F1B6A"; }

.mdi-calendar-lock::before {
  content: "\F1641"; }

.mdi-calendar-lock-open::before {
  content: "\F1B5B"; }

.mdi-calendar-lock-open-outline::before {
  content: "\F1B5C"; }

.mdi-calendar-lock-outline::before {
  content: "\F1642"; }

.mdi-calendar-minus::before {
  content: "\F0D5C"; }

.mdi-calendar-minus-outline::before {
  content: "\F1B6B"; }

.mdi-calendar-month::before {
  content: "\F0E17"; }

.mdi-calendar-month-outline::before {
  content: "\F0E18"; }

.mdi-calendar-multiple::before {
  content: "\F00F1"; }

.mdi-calendar-multiple-check::before {
  content: "\F00F2"; }

.mdi-calendar-multiselect::before {
  content: "\F0A32"; }

.mdi-calendar-multiselect-outline::before {
  content: "\F1B55"; }

.mdi-calendar-outline::before {
  content: "\F0B67"; }

.mdi-calendar-plus::before {
  content: "\F00F3"; }

.mdi-calendar-plus-outline::before {
  content: "\F1B6C"; }

.mdi-calendar-question::before {
  content: "\F0692"; }

.mdi-calendar-question-outline::before {
  content: "\F1B6D"; }

.mdi-calendar-range::before {
  content: "\F0679"; }

.mdi-calendar-range-outline::before {
  content: "\F0B68"; }

.mdi-calendar-refresh::before {
  content: "\F01E1"; }

.mdi-calendar-refresh-outline::before {
  content: "\F0203"; }

.mdi-calendar-remove::before {
  content: "\F00F4"; }

.mdi-calendar-remove-outline::before {
  content: "\F0C45"; }

.mdi-calendar-search::before {
  content: "\F094C"; }

.mdi-calendar-search-outline::before {
  content: "\F1B6E"; }

.mdi-calendar-star::before {
  content: "\F09D3"; }

.mdi-calendar-star-outline::before {
  content: "\F1B53"; }

.mdi-calendar-start::before {
  content: "\F166D"; }

.mdi-calendar-start-outline::before {
  content: "\F1B6F"; }

.mdi-calendar-sync::before {
  content: "\F0E8E"; }

.mdi-calendar-sync-outline::before {
  content: "\F0E8F"; }

.mdi-calendar-text::before {
  content: "\F00F5"; }

.mdi-calendar-text-outline::before {
  content: "\F0C46"; }

.mdi-calendar-today::before {
  content: "\F00F6"; }

.mdi-calendar-today-outline::before {
  content: "\F1A30"; }

.mdi-calendar-week::before {
  content: "\F0A33"; }

.mdi-calendar-week-begin::before {
  content: "\F0A34"; }

.mdi-calendar-week-begin-outline::before {
  content: "\F1A31"; }

.mdi-calendar-week-outline::before {
  content: "\F1A34"; }

.mdi-calendar-weekend::before {
  content: "\F0ED9"; }

.mdi-calendar-weekend-outline::before {
  content: "\F0EDA"; }

.mdi-call-made::before {
  content: "\F00F7"; }

.mdi-call-merge::before {
  content: "\F00F8"; }

.mdi-call-missed::before {
  content: "\F00F9"; }

.mdi-call-received::before {
  content: "\F00FA"; }

.mdi-call-split::before {
  content: "\F00FB"; }

.mdi-camcorder::before {
  content: "\F00FC"; }

.mdi-camcorder-off::before {
  content: "\F00FF"; }

.mdi-camera::before {
  content: "\F0100"; }

.mdi-camera-account::before {
  content: "\F08CB"; }

.mdi-camera-burst::before {
  content: "\F0693"; }

.mdi-camera-control::before {
  content: "\F0B69"; }

.mdi-camera-document::before {
  content: "\F1871"; }

.mdi-camera-document-off::before {
  content: "\F1872"; }

.mdi-camera-enhance::before {
  content: "\F0101"; }

.mdi-camera-enhance-outline::before {
  content: "\F0B6A"; }

.mdi-camera-flip::before {
  content: "\F15D9"; }

.mdi-camera-flip-outline::before {
  content: "\F15DA"; }

.mdi-camera-front::before {
  content: "\F0102"; }

.mdi-camera-front-variant::before {
  content: "\F0103"; }

.mdi-camera-gopro::before {
  content: "\F07A1"; }

.mdi-camera-image::before {
  content: "\F08CC"; }

.mdi-camera-iris::before {
  content: "\F0104"; }

.mdi-camera-lock::before {
  content: "\F1A14"; }

.mdi-camera-lock-outline::before {
  content: "\F1A15"; }

.mdi-camera-marker::before {
  content: "\F19A7"; }

.mdi-camera-marker-outline::before {
  content: "\F19A8"; }

.mdi-camera-metering-center::before {
  content: "\F07A2"; }

.mdi-camera-metering-matrix::before {
  content: "\F07A3"; }

.mdi-camera-metering-partial::before {
  content: "\F07A4"; }

.mdi-camera-metering-spot::before {
  content: "\F07A5"; }

.mdi-camera-off::before {
  content: "\F05DF"; }

.mdi-camera-off-outline::before {
  content: "\F19BF"; }

.mdi-camera-outline::before {
  content: "\F0D5D"; }

.mdi-camera-party-mode::before {
  content: "\F0105"; }

.mdi-camera-plus::before {
  content: "\F0EDB"; }

.mdi-camera-plus-outline::before {
  content: "\F0EDC"; }

.mdi-camera-rear::before {
  content: "\F0106"; }

.mdi-camera-rear-variant::before {
  content: "\F0107"; }

.mdi-camera-retake::before {
  content: "\F0E19"; }

.mdi-camera-retake-outline::before {
  content: "\F0E1A"; }

.mdi-camera-switch::before {
  content: "\F0108"; }

.mdi-camera-switch-outline::before {
  content: "\F084A"; }

.mdi-camera-timer::before {
  content: "\F0109"; }

.mdi-camera-wireless::before {
  content: "\F0DB6"; }

.mdi-camera-wireless-outline::before {
  content: "\F0DB7"; }

.mdi-campfire::before {
  content: "\F0EDD"; }

.mdi-cancel::before {
  content: "\F073A"; }

.mdi-candelabra::before {
  content: "\F17D2"; }

.mdi-candelabra-fire::before {
  content: "\F17D3"; }

.mdi-candle::before {
  content: "\F05E2"; }

.mdi-candy::before {
  content: "\F1970"; }

.mdi-candy-off::before {
  content: "\F1971"; }

.mdi-candy-off-outline::before {
  content: "\F1972"; }

.mdi-candy-outline::before {
  content: "\F1973"; }

.mdi-candycane::before {
  content: "\F010A"; }

.mdi-cannabis::before {
  content: "\F07A6"; }

.mdi-cannabis-off::before {
  content: "\F166E"; }

.mdi-caps-lock::before {
  content: "\F0A9B"; }

.mdi-car::before {
  content: "\F010B"; }

.mdi-car-2-plus::before {
  content: "\F1015"; }

.mdi-car-3-plus::before {
  content: "\F1016"; }

.mdi-car-arrow-left::before {
  content: "\F13B2"; }

.mdi-car-arrow-right::before {
  content: "\F13B3"; }

.mdi-car-back::before {
  content: "\F0E1B"; }

.mdi-car-battery::before {
  content: "\F010C"; }

.mdi-car-brake-abs::before {
  content: "\F0C47"; }

.mdi-car-brake-alert::before {
  content: "\F0C48"; }

.mdi-car-brake-fluid-level::before {
  content: "\F1909"; }

.mdi-car-brake-hold::before {
  content: "\F0D5E"; }

.mdi-car-brake-low-pressure::before {
  content: "\F190A"; }

.mdi-car-brake-parking::before {
  content: "\F0D5F"; }

.mdi-car-brake-retarder::before {
  content: "\F1017"; }

.mdi-car-brake-temperature::before {
  content: "\F190B"; }

.mdi-car-brake-worn-linings::before {
  content: "\F190C"; }

.mdi-car-child-seat::before {
  content: "\F0FA3"; }

.mdi-car-clock::before {
  content: "\F1974"; }

.mdi-car-clutch::before {
  content: "\F1018"; }

.mdi-car-cog::before {
  content: "\F13CC"; }

.mdi-car-connected::before {
  content: "\F010D"; }

.mdi-car-convertible::before {
  content: "\F07A7"; }

.mdi-car-coolant-level::before {
  content: "\F1019"; }

.mdi-car-cruise-control::before {
  content: "\F0D60"; }

.mdi-car-defrost-front::before {
  content: "\F0D61"; }

.mdi-car-defrost-rear::before {
  content: "\F0D62"; }

.mdi-car-door::before {
  content: "\F0B6B"; }

.mdi-car-door-lock::before {
  content: "\F109D"; }

.mdi-car-electric::before {
  content: "\F0B6C"; }

.mdi-car-electric-outline::before {
  content: "\F15B5"; }

.mdi-car-emergency::before {
  content: "\F160F"; }

.mdi-car-esp::before {
  content: "\F0C49"; }

.mdi-car-estate::before {
  content: "\F07A8"; }

.mdi-car-hatchback::before {
  content: "\F07A9"; }

.mdi-car-info::before {
  content: "\F11BE"; }

.mdi-car-key::before {
  content: "\F0B6D"; }

.mdi-car-lifted-pickup::before {
  content: "\F152D"; }

.mdi-car-light-alert::before {
  content: "\F190D"; }

.mdi-car-light-dimmed::before {
  content: "\F0C4A"; }

.mdi-car-light-fog::before {
  content: "\F0C4B"; }

.mdi-car-light-high::before {
  content: "\F0C4C"; }

.mdi-car-limousine::before {
  content: "\F08CD"; }

.mdi-car-multiple::before {
  content: "\F0B6E"; }

.mdi-car-off::before {
  content: "\F0E1C"; }

.mdi-car-outline::before {
  content: "\F14ED"; }

.mdi-car-parking-lights::before {
  content: "\F0D63"; }

.mdi-car-pickup::before {
  content: "\F07AA"; }

.mdi-car-search::before {
  content: "\F1B8D"; }

.mdi-car-search-outline::before {
  content: "\F1B8E"; }

.mdi-car-seat::before {
  content: "\F0FA4"; }

.mdi-car-seat-cooler::before {
  content: "\F0FA5"; }

.mdi-car-seat-heater::before {
  content: "\F0FA6"; }

.mdi-car-select::before {
  content: "\F1879"; }

.mdi-car-settings::before {
  content: "\F13CD"; }

.mdi-car-shift-pattern::before {
  content: "\F0F40"; }

.mdi-car-side::before {
  content: "\F07AB"; }

.mdi-car-speed-limiter::before {
  content: "\F190E"; }

.mdi-car-sports::before {
  content: "\F07AC"; }

.mdi-car-tire-alert::before {
  content: "\F0C4D"; }

.mdi-car-traction-control::before {
  content: "\F0D64"; }

.mdi-car-turbocharger::before {
  content: "\F101A"; }

.mdi-car-wash::before {
  content: "\F010E"; }

.mdi-car-windshield::before {
  content: "\F101B"; }

.mdi-car-windshield-outline::before {
  content: "\F101C"; }

.mdi-car-wireless::before {
  content: "\F1878"; }

.mdi-car-wrench::before {
  content: "\F1814"; }

.mdi-carabiner::before {
  content: "\F14C0"; }

.mdi-caravan::before {
  content: "\F07AD"; }

.mdi-card::before {
  content: "\F0B6F"; }

.mdi-card-account-details::before {
  content: "\F05D2"; }

.mdi-card-account-details-outline::before {
  content: "\F0DAB"; }

.mdi-card-account-details-star::before {
  content: "\F02A3"; }

.mdi-card-account-details-star-outline::before {
  content: "\F06DB"; }

.mdi-card-account-mail::before {
  content: "\F018E"; }

.mdi-card-account-mail-outline::before {
  content: "\F0E98"; }

.mdi-card-account-phone::before {
  content: "\F0E99"; }

.mdi-card-account-phone-outline::before {
  content: "\F0E9A"; }

.mdi-card-bulleted::before {
  content: "\F0B70"; }

.mdi-card-bulleted-off::before {
  content: "\F0B71"; }

.mdi-card-bulleted-off-outline::before {
  content: "\F0B72"; }

.mdi-card-bulleted-outline::before {
  content: "\F0B73"; }

.mdi-card-bulleted-settings::before {
  content: "\F0B74"; }

.mdi-card-bulleted-settings-outline::before {
  content: "\F0B75"; }

.mdi-card-minus::before {
  content: "\F1600"; }

.mdi-card-minus-outline::before {
  content: "\F1601"; }

.mdi-card-multiple::before {
  content: "\F17F1"; }

.mdi-card-multiple-outline::before {
  content: "\F17F2"; }

.mdi-card-off::before {
  content: "\F1602"; }

.mdi-card-off-outline::before {
  content: "\F1603"; }

.mdi-card-outline::before {
  content: "\F0B76"; }

.mdi-card-plus::before {
  content: "\F11FF"; }

.mdi-card-plus-outline::before {
  content: "\F1200"; }

.mdi-card-remove::before {
  content: "\F1604"; }

.mdi-card-remove-outline::before {
  content: "\F1605"; }

.mdi-card-search::before {
  content: "\F1074"; }

.mdi-card-search-outline::before {
  content: "\F1075"; }

.mdi-card-text::before {
  content: "\F0B77"; }

.mdi-card-text-outline::before {
  content: "\F0B78"; }

.mdi-cards::before {
  content: "\F0638"; }

.mdi-cards-club::before {
  content: "\F08CE"; }

.mdi-cards-club-outline::before {
  content: "\F189F"; }

.mdi-cards-diamond::before {
  content: "\F08CF"; }

.mdi-cards-diamond-outline::before {
  content: "\F101D"; }

.mdi-cards-heart::before {
  content: "\F08D0"; }

.mdi-cards-heart-outline::before {
  content: "\F18A0"; }

.mdi-cards-outline::before {
  content: "\F0639"; }

.mdi-cards-playing::before {
  content: "\F18A1"; }

.mdi-cards-playing-club::before {
  content: "\F18A2"; }

.mdi-cards-playing-club-multiple::before {
  content: "\F18A3"; }

.mdi-cards-playing-club-multiple-outline::before {
  content: "\F18A4"; }

.mdi-cards-playing-club-outline::before {
  content: "\F18A5"; }

.mdi-cards-playing-diamond::before {
  content: "\F18A6"; }

.mdi-cards-playing-diamond-multiple::before {
  content: "\F18A7"; }

.mdi-cards-playing-diamond-multiple-outline::before {
  content: "\F18A8"; }

.mdi-cards-playing-diamond-outline::before {
  content: "\F18A9"; }

.mdi-cards-playing-heart::before {
  content: "\F18AA"; }

.mdi-cards-playing-heart-multiple::before {
  content: "\F18AB"; }

.mdi-cards-playing-heart-multiple-outline::before {
  content: "\F18AC"; }

.mdi-cards-playing-heart-outline::before {
  content: "\F18AD"; }

.mdi-cards-playing-outline::before {
  content: "\F063A"; }

.mdi-cards-playing-spade::before {
  content: "\F18AE"; }

.mdi-cards-playing-spade-multiple::before {
  content: "\F18AF"; }

.mdi-cards-playing-spade-multiple-outline::before {
  content: "\F18B0"; }

.mdi-cards-playing-spade-outline::before {
  content: "\F18B1"; }

.mdi-cards-spade::before {
  content: "\F08D1"; }

.mdi-cards-spade-outline::before {
  content: "\F18B2"; }

.mdi-cards-variant::before {
  content: "\F06C7"; }

.mdi-carrot::before {
  content: "\F010F"; }

.mdi-cart::before {
  content: "\F0110"; }

.mdi-cart-arrow-down::before {
  content: "\F0D66"; }

.mdi-cart-arrow-right::before {
  content: "\F0C4E"; }

.mdi-cart-arrow-up::before {
  content: "\F0D67"; }

.mdi-cart-check::before {
  content: "\F15EA"; }

.mdi-cart-heart::before {
  content: "\F18E0"; }

.mdi-cart-minus::before {
  content: "\F0D68"; }

.mdi-cart-off::before {
  content: "\F066B"; }

.mdi-cart-outline::before {
  content: "\F0111"; }

.mdi-cart-percent::before {
  content: "\F1BAE"; }

.mdi-cart-plus::before {
  content: "\F0112"; }

.mdi-cart-remove::before {
  content: "\F0D69"; }

.mdi-cart-variant::before {
  content: "\F15EB"; }

.mdi-case-sensitive-alt::before {
  content: "\F0113"; }

.mdi-cash::before {
  content: "\F0114"; }

.mdi-cash-100::before {
  content: "\F0115"; }

.mdi-cash-check::before {
  content: "\F14EE"; }

.mdi-cash-clock::before {
  content: "\F1A91"; }

.mdi-cash-fast::before {
  content: "\F185C"; }

.mdi-cash-lock::before {
  content: "\F14EA"; }

.mdi-cash-lock-open::before {
  content: "\F14EB"; }

.mdi-cash-marker::before {
  content: "\F0DB8"; }

.mdi-cash-minus::before {
  content: "\F1260"; }

.mdi-cash-multiple::before {
  content: "\F0116"; }

.mdi-cash-plus::before {
  content: "\F1261"; }

.mdi-cash-refund::before {
  content: "\F0A9C"; }

.mdi-cash-register::before {
  content: "\F0CF4"; }

.mdi-cash-remove::before {
  content: "\F1262"; }

.mdi-cash-sync::before {
  content: "\F1A92"; }

.mdi-cassette::before {
  content: "\F09D4"; }

.mdi-cast::before {
  content: "\F0118"; }

.mdi-cast-audio::before {
  content: "\F101E"; }

.mdi-cast-audio-variant::before {
  content: "\F1749"; }

.mdi-cast-connected::before {
  content: "\F0119"; }

.mdi-cast-education::before {
  content: "\F0E1D"; }

.mdi-cast-off::before {
  content: "\F078A"; }

.mdi-cast-variant::before {
  content: "\F001F"; }

.mdi-castle::before {
  content: "\F011A"; }

.mdi-cat::before {
  content: "\F011B"; }

.mdi-cctv::before {
  content: "\F07AE"; }

.mdi-cctv-off::before {
  content: "\F185F"; }

.mdi-ceiling-fan::before {
  content: "\F1797"; }

.mdi-ceiling-fan-light::before {
  content: "\F1798"; }

.mdi-ceiling-light::before {
  content: "\F0769"; }

.mdi-ceiling-light-multiple::before {
  content: "\F18DD"; }

.mdi-ceiling-light-multiple-outline::before {
  content: "\F18DE"; }

.mdi-ceiling-light-outline::before {
  content: "\F17C7"; }

.mdi-cellphone::before {
  content: "\F011C"; }

.mdi-cellphone-arrow-down::before {
  content: "\F09D5"; }

.mdi-cellphone-arrow-down-variant::before {
  content: "\F19C5"; }

.mdi-cellphone-basic::before {
  content: "\F011E"; }

.mdi-cellphone-charging::before {
  content: "\F1397"; }

.mdi-cellphone-check::before {
  content: "\F17FD"; }

.mdi-cellphone-cog::before {
  content: "\F0951"; }

.mdi-cellphone-dock::before {
  content: "\F011F"; }

.mdi-cellphone-information::before {
  content: "\F0F41"; }

.mdi-cellphone-key::before {
  content: "\F094E"; }

.mdi-cellphone-link::before {
  content: "\F0121"; }

.mdi-cellphone-link-off::before {
  content: "\F0122"; }

.mdi-cellphone-lock::before {
  content: "\F094F"; }

.mdi-cellphone-marker::before {
  content: "\F183A"; }

.mdi-cellphone-message::before {
  content: "\F08D3"; }

.mdi-cellphone-message-off::before {
  content: "\F10D2"; }

.mdi-cellphone-nfc::before {
  content: "\F0E90"; }

.mdi-cellphone-nfc-off::before {
  content: "\F12D8"; }

.mdi-cellphone-off::before {
  content: "\F0950"; }

.mdi-cellphone-play::before {
  content: "\F101F"; }

.mdi-cellphone-remove::before {
  content: "\F094D"; }

.mdi-cellphone-screenshot::before {
  content: "\F0A35"; }

.mdi-cellphone-settings::before {
  content: "\F0123"; }

.mdi-cellphone-sound::before {
  content: "\F0952"; }

.mdi-cellphone-text::before {
  content: "\F08D2"; }

.mdi-cellphone-wireless::before {
  content: "\F0815"; }

.mdi-centos::before {
  content: "\F111A"; }

.mdi-certificate::before {
  content: "\F0124"; }

.mdi-certificate-outline::before {
  content: "\F1188"; }

.mdi-chair-rolling::before {
  content: "\F0F48"; }

.mdi-chair-school::before {
  content: "\F0125"; }

.mdi-chandelier::before {
  content: "\F1793"; }

.mdi-charity::before {
  content: "\F0C4F"; }

.mdi-chart-arc::before {
  content: "\F0126"; }

.mdi-chart-areaspline::before {
  content: "\F0127"; }

.mdi-chart-areaspline-variant::before {
  content: "\F0E91"; }

.mdi-chart-bar::before {
  content: "\F0128"; }

.mdi-chart-bar-stacked::before {
  content: "\F076A"; }

.mdi-chart-bell-curve::before {
  content: "\F0C50"; }

.mdi-chart-bell-curve-cumulative::before {
  content: "\F0FA7"; }

.mdi-chart-box::before {
  content: "\F154D"; }

.mdi-chart-box-outline::before {
  content: "\F154E"; }

.mdi-chart-box-plus-outline::before {
  content: "\F154F"; }

.mdi-chart-bubble::before {
  content: "\F05E3"; }

.mdi-chart-donut::before {
  content: "\F07AF"; }

.mdi-chart-donut-variant::before {
  content: "\F07B0"; }

.mdi-chart-gantt::before {
  content: "\F066C"; }

.mdi-chart-histogram::before {
  content: "\F0129"; }

.mdi-chart-line::before {
  content: "\F012A"; }

.mdi-chart-line-stacked::before {
  content: "\F076B"; }

.mdi-chart-line-variant::before {
  content: "\F07B1"; }

.mdi-chart-multiline::before {
  content: "\F08D4"; }

.mdi-chart-multiple::before {
  content: "\F1213"; }

.mdi-chart-pie::before {
  content: "\F012B"; }

.mdi-chart-ppf::before {
  content: "\F1380"; }

.mdi-chart-sankey::before {
  content: "\F11DF"; }

.mdi-chart-sankey-variant::before {
  content: "\F11E0"; }

.mdi-chart-scatter-plot::before {
  content: "\F0E92"; }

.mdi-chart-scatter-plot-hexbin::before {
  content: "\F066D"; }

.mdi-chart-timeline::before {
  content: "\F066E"; }

.mdi-chart-timeline-variant::before {
  content: "\F0E93"; }

.mdi-chart-timeline-variant-shimmer::before {
  content: "\F15B6"; }

.mdi-chart-tree::before {
  content: "\F0E94"; }

.mdi-chart-waterfall::before {
  content: "\F1918"; }

.mdi-chat::before {
  content: "\F0B79"; }

.mdi-chat-alert::before {
  content: "\F0B7A"; }

.mdi-chat-alert-outline::before {
  content: "\F12C9"; }

.mdi-chat-minus::before {
  content: "\F1410"; }

.mdi-chat-minus-outline::before {
  content: "\F1413"; }

.mdi-chat-outline::before {
  content: "\F0EDE"; }

.mdi-chat-plus::before {
  content: "\F140F"; }

.mdi-chat-plus-outline::before {
  content: "\F1412"; }

.mdi-chat-processing::before {
  content: "\F0B7B"; }

.mdi-chat-processing-outline::before {
  content: "\F12CA"; }

.mdi-chat-question::before {
  content: "\F1738"; }

.mdi-chat-question-outline::before {
  content: "\F1739"; }

.mdi-chat-remove::before {
  content: "\F1411"; }

.mdi-chat-remove-outline::before {
  content: "\F1414"; }

.mdi-chat-sleep::before {
  content: "\F12D1"; }

.mdi-chat-sleep-outline::before {
  content: "\F12D2"; }

.mdi-check::before {
  content: "\F012C"; }

.mdi-check-all::before {
  content: "\F012D"; }

.mdi-check-bold::before {
  content: "\F0E1E"; }

.mdi-check-circle::before {
  content: "\F05E0"; }

.mdi-check-circle-outline::before {
  content: "\F05E1"; }

.mdi-check-decagram::before {
  content: "\F0791"; }

.mdi-check-decagram-outline::before {
  content: "\F1740"; }

.mdi-check-network::before {
  content: "\F0C53"; }

.mdi-check-network-outline::before {
  content: "\F0C54"; }

.mdi-check-outline::before {
  content: "\F0855"; }

.mdi-check-underline::before {
  content: "\F0E1F"; }

.mdi-check-underline-circle::before {
  content: "\F0E20"; }

.mdi-check-underline-circle-outline::before {
  content: "\F0E21"; }

.mdi-checkbook::before {
  content: "\F0A9D"; }

.mdi-checkbox-blank::before {
  content: "\F012E"; }

.mdi-checkbox-blank-badge::before {
  content: "\F1176"; }

.mdi-checkbox-blank-badge-outline::before {
  content: "\F0117"; }

.mdi-checkbox-blank-circle::before {
  content: "\F012F"; }

.mdi-checkbox-blank-circle-outline::before {
  content: "\F0130"; }

.mdi-checkbox-blank-off::before {
  content: "\F12EC"; }

.mdi-checkbox-blank-off-outline::before {
  content: "\F12ED"; }

.mdi-checkbox-blank-outline::before {
  content: "\F0131"; }

.mdi-checkbox-intermediate::before {
  content: "\F0856"; }

.mdi-checkbox-intermediate-variant::before {
  content: "\F1B54"; }

.mdi-checkbox-marked::before {
  content: "\F0132"; }

.mdi-checkbox-marked-circle::before {
  content: "\F0133"; }

.mdi-checkbox-marked-circle-outline::before {
  content: "\F0134"; }

.mdi-checkbox-marked-circle-plus-outline::before {
  content: "\F1927"; }

.mdi-checkbox-marked-outline::before {
  content: "\F0135"; }

.mdi-checkbox-multiple-blank::before {
  content: "\F0136"; }

.mdi-checkbox-multiple-blank-circle::before {
  content: "\F063B"; }

.mdi-checkbox-multiple-blank-circle-outline::before {
  content: "\F063C"; }

.mdi-checkbox-multiple-blank-outline::before {
  content: "\F0137"; }

.mdi-checkbox-multiple-marked::before {
  content: "\F0138"; }

.mdi-checkbox-multiple-marked-circle::before {
  content: "\F063D"; }

.mdi-checkbox-multiple-marked-circle-outline::before {
  content: "\F063E"; }

.mdi-checkbox-multiple-marked-outline::before {
  content: "\F0139"; }

.mdi-checkbox-multiple-outline::before {
  content: "\F0C51"; }

.mdi-checkbox-outline::before {
  content: "\F0C52"; }

.mdi-checkerboard::before {
  content: "\F013A"; }

.mdi-checkerboard-minus::before {
  content: "\F1202"; }

.mdi-checkerboard-plus::before {
  content: "\F1201"; }

.mdi-checkerboard-remove::before {
  content: "\F1203"; }

.mdi-cheese::before {
  content: "\F12B9"; }

.mdi-cheese-off::before {
  content: "\F13EE"; }

.mdi-chef-hat::before {
  content: "\F0B7C"; }

.mdi-chemical-weapon::before {
  content: "\F013B"; }

.mdi-chess-bishop::before {
  content: "\F085C"; }

.mdi-chess-king::before {
  content: "\F0857"; }

.mdi-chess-knight::before {
  content: "\F0858"; }

.mdi-chess-pawn::before {
  content: "\F0859"; }

.mdi-chess-queen::before {
  content: "\F085A"; }

.mdi-chess-rook::before {
  content: "\F085B"; }

.mdi-chevron-double-down::before {
  content: "\F013C"; }

.mdi-chevron-double-left::before {
  content: "\F013D"; }

.mdi-chevron-double-right::before {
  content: "\F013E"; }

.mdi-chevron-double-up::before {
  content: "\F013F"; }

.mdi-chevron-down::before {
  content: "\F0140"; }

.mdi-chevron-down-box::before {
  content: "\F09D6"; }

.mdi-chevron-down-box-outline::before {
  content: "\F09D7"; }

.mdi-chevron-down-circle::before {
  content: "\F0B26"; }

.mdi-chevron-down-circle-outline::before {
  content: "\F0B27"; }

.mdi-chevron-left::before {
  content: "\F0141"; }

.mdi-chevron-left-box::before {
  content: "\F09D8"; }

.mdi-chevron-left-box-outline::before {
  content: "\F09D9"; }

.mdi-chevron-left-circle::before {
  content: "\F0B28"; }

.mdi-chevron-left-circle-outline::before {
  content: "\F0B29"; }

.mdi-chevron-right::before {
  content: "\F0142"; }

.mdi-chevron-right-box::before {
  content: "\F09DA"; }

.mdi-chevron-right-box-outline::before {
  content: "\F09DB"; }

.mdi-chevron-right-circle::before {
  content: "\F0B2A"; }

.mdi-chevron-right-circle-outline::before {
  content: "\F0B2B"; }

.mdi-chevron-triple-down::before {
  content: "\F0DB9"; }

.mdi-chevron-triple-left::before {
  content: "\F0DBA"; }

.mdi-chevron-triple-right::before {
  content: "\F0DBB"; }

.mdi-chevron-triple-up::before {
  content: "\F0DBC"; }

.mdi-chevron-up::before {
  content: "\F0143"; }

.mdi-chevron-up-box::before {
  content: "\F09DC"; }

.mdi-chevron-up-box-outline::before {
  content: "\F09DD"; }

.mdi-chevron-up-circle::before {
  content: "\F0B2C"; }

.mdi-chevron-up-circle-outline::before {
  content: "\F0B2D"; }

.mdi-chili-alert::before {
  content: "\F17EA"; }

.mdi-chili-alert-outline::before {
  content: "\F17EB"; }

.mdi-chili-hot::before {
  content: "\F07B2"; }

.mdi-chili-hot-outline::before {
  content: "\F17EC"; }

.mdi-chili-medium::before {
  content: "\F07B3"; }

.mdi-chili-medium-outline::before {
  content: "\F17ED"; }

.mdi-chili-mild::before {
  content: "\F07B4"; }

.mdi-chili-mild-outline::before {
  content: "\F17EE"; }

.mdi-chili-off::before {
  content: "\F1467"; }

.mdi-chili-off-outline::before {
  content: "\F17EF"; }

.mdi-chip::before {
  content: "\F061A"; }

.mdi-church::before {
  content: "\F0144"; }

.mdi-church-outline::before {
  content: "\F1B02"; }

.mdi-cigar::before {
  content: "\F1189"; }

.mdi-cigar-off::before {
  content: "\F141B"; }

.mdi-circle::before {
  content: "\F0765"; }

.mdi-circle-box::before {
  content: "\F15DC"; }

.mdi-circle-box-outline::before {
  content: "\F15DD"; }

.mdi-circle-double::before {
  content: "\F0E95"; }

.mdi-circle-edit-outline::before {
  content: "\F08D5"; }

.mdi-circle-expand::before {
  content: "\F0E96"; }

.mdi-circle-half::before {
  content: "\F1395"; }

.mdi-circle-half-full::before {
  content: "\F1396"; }

.mdi-circle-medium::before {
  content: "\F09DE"; }

.mdi-circle-multiple::before {
  content: "\F0B38"; }

.mdi-circle-multiple-outline::before {
  content: "\F0695"; }

.mdi-circle-off-outline::before {
  content: "\F10D3"; }

.mdi-circle-opacity::before {
  content: "\F1853"; }

.mdi-circle-outline::before {
  content: "\F0766"; }

.mdi-circle-slice-1::before {
  content: "\F0A9E"; }

.mdi-circle-slice-2::before {
  content: "\F0A9F"; }

.mdi-circle-slice-3::before {
  content: "\F0AA0"; }

.mdi-circle-slice-4::before {
  content: "\F0AA1"; }

.mdi-circle-slice-5::before {
  content: "\F0AA2"; }

.mdi-circle-slice-6::before {
  content: "\F0AA3"; }

.mdi-circle-slice-7::before {
  content: "\F0AA4"; }

.mdi-circle-slice-8::before {
  content: "\F0AA5"; }

.mdi-circle-small::before {
  content: "\F09DF"; }

.mdi-circular-saw::before {
  content: "\F0E22"; }

.mdi-city::before {
  content: "\F0146"; }

.mdi-city-variant::before {
  content: "\F0A36"; }

.mdi-city-variant-outline::before {
  content: "\F0A37"; }

.mdi-clipboard::before {
  content: "\F0147"; }

.mdi-clipboard-account::before {
  content: "\F0148"; }

.mdi-clipboard-account-outline::before {
  content: "\F0C55"; }

.mdi-clipboard-alert::before {
  content: "\F0149"; }

.mdi-clipboard-alert-outline::before {
  content: "\F0CF7"; }

.mdi-clipboard-arrow-down::before {
  content: "\F014A"; }

.mdi-clipboard-arrow-down-outline::before {
  content: "\F0C56"; }

.mdi-clipboard-arrow-left::before {
  content: "\F014B"; }

.mdi-clipboard-arrow-left-outline::before {
  content: "\F0CF8"; }

.mdi-clipboard-arrow-right::before {
  content: "\F0CF9"; }

.mdi-clipboard-arrow-right-outline::before {
  content: "\F0CFA"; }

.mdi-clipboard-arrow-up::before {
  content: "\F0C57"; }

.mdi-clipboard-arrow-up-outline::before {
  content: "\F0C58"; }

.mdi-clipboard-check::before {
  content: "\F014E"; }

.mdi-clipboard-check-multiple::before {
  content: "\F1263"; }

.mdi-clipboard-check-multiple-outline::before {
  content: "\F1264"; }

.mdi-clipboard-check-outline::before {
  content: "\F08A8"; }

.mdi-clipboard-clock::before {
  content: "\F16E2"; }

.mdi-clipboard-clock-outline::before {
  content: "\F16E3"; }

.mdi-clipboard-edit::before {
  content: "\F14E5"; }

.mdi-clipboard-edit-outline::before {
  content: "\F14E6"; }

.mdi-clipboard-file::before {
  content: "\F1265"; }

.mdi-clipboard-file-outline::before {
  content: "\F1266"; }

.mdi-clipboard-flow::before {
  content: "\F06C8"; }

.mdi-clipboard-flow-outline::before {
  content: "\F1117"; }

.mdi-clipboard-list::before {
  content: "\F10D4"; }

.mdi-clipboard-list-outline::before {
  content: "\F10D5"; }

.mdi-clipboard-minus::before {
  content: "\F1618"; }

.mdi-clipboard-minus-outline::before {
  content: "\F1619"; }

.mdi-clipboard-multiple::before {
  content: "\F1267"; }

.mdi-clipboard-multiple-outline::before {
  content: "\F1268"; }

.mdi-clipboard-off::before {
  content: "\F161A"; }

.mdi-clipboard-off-outline::before {
  content: "\F161B"; }

.mdi-clipboard-outline::before {
  content: "\F014C"; }

.mdi-clipboard-play::before {
  content: "\F0C59"; }

.mdi-clipboard-play-multiple::before {
  content: "\F1269"; }

.mdi-clipboard-play-multiple-outline::before {
  content: "\F126A"; }

.mdi-clipboard-play-outline::before {
  content: "\F0C5A"; }

.mdi-clipboard-plus::before {
  content: "\F0751"; }

.mdi-clipboard-plus-outline::before {
  content: "\F131F"; }

.mdi-clipboard-pulse::before {
  content: "\F085D"; }

.mdi-clipboard-pulse-outline::before {
  content: "\F085E"; }

.mdi-clipboard-remove::before {
  content: "\F161C"; }

.mdi-clipboard-remove-outline::before {
  content: "\F161D"; }

.mdi-clipboard-search::before {
  content: "\F161E"; }

.mdi-clipboard-search-outline::before {
  content: "\F161F"; }

.mdi-clipboard-text::before {
  content: "\F014D"; }

.mdi-clipboard-text-clock::before {
  content: "\F18F9"; }

.mdi-clipboard-text-clock-outline::before {
  content: "\F18FA"; }

.mdi-clipboard-text-multiple::before {
  content: "\F126B"; }

.mdi-clipboard-text-multiple-outline::before {
  content: "\F126C"; }

.mdi-clipboard-text-off::before {
  content: "\F1620"; }

.mdi-clipboard-text-off-outline::before {
  content: "\F1621"; }

.mdi-clipboard-text-outline::before {
  content: "\F0A38"; }

.mdi-clipboard-text-play::before {
  content: "\F0C5B"; }

.mdi-clipboard-text-play-outline::before {
  content: "\F0C5C"; }

.mdi-clipboard-text-search::before {
  content: "\F1622"; }

.mdi-clipboard-text-search-outline::before {
  content: "\F1623"; }

.mdi-clippy::before {
  content: "\F014F"; }

.mdi-clock::before {
  content: "\F0954"; }

.mdi-clock-alert::before {
  content: "\F0955"; }

.mdi-clock-alert-outline::before {
  content: "\F05CE"; }

.mdi-clock-check::before {
  content: "\F0FA8"; }

.mdi-clock-check-outline::before {
  content: "\F0FA9"; }

.mdi-clock-digital::before {
  content: "\F0E97"; }

.mdi-clock-edit::before {
  content: "\F19BA"; }

.mdi-clock-edit-outline::before {
  content: "\F19BB"; }

.mdi-clock-end::before {
  content: "\F0151"; }

.mdi-clock-fast::before {
  content: "\F0152"; }

.mdi-clock-in::before {
  content: "\F0153"; }

.mdi-clock-minus::before {
  content: "\F1863"; }

.mdi-clock-minus-outline::before {
  content: "\F1864"; }

.mdi-clock-out::before {
  content: "\F0154"; }

.mdi-clock-outline::before {
  content: "\F0150"; }

.mdi-clock-plus::before {
  content: "\F1861"; }

.mdi-clock-plus-outline::before {
  content: "\F1862"; }

.mdi-clock-remove::before {
  content: "\F1865"; }

.mdi-clock-remove-outline::before {
  content: "\F1866"; }

.mdi-clock-start::before {
  content: "\F0155"; }

.mdi-clock-time-eight::before {
  content: "\F1446"; }

.mdi-clock-time-eight-outline::before {
  content: "\F1452"; }

.mdi-clock-time-eleven::before {
  content: "\F1449"; }

.mdi-clock-time-eleven-outline::before {
  content: "\F1455"; }

.mdi-clock-time-five::before {
  content: "\F1443"; }

.mdi-clock-time-five-outline::before {
  content: "\F144F"; }

.mdi-clock-time-four::before {
  content: "\F1442"; }

.mdi-clock-time-four-outline::before {
  content: "\F144E"; }

.mdi-clock-time-nine::before {
  content: "\F1447"; }

.mdi-clock-time-nine-outline::before {
  content: "\F1453"; }

.mdi-clock-time-one::before {
  content: "\F143F"; }

.mdi-clock-time-one-outline::before {
  content: "\F144B"; }

.mdi-clock-time-seven::before {
  content: "\F1445"; }

.mdi-clock-time-seven-outline::before {
  content: "\F1451"; }

.mdi-clock-time-six::before {
  content: "\F1444"; }

.mdi-clock-time-six-outline::before {
  content: "\F1450"; }

.mdi-clock-time-ten::before {
  content: "\F1448"; }

.mdi-clock-time-ten-outline::before {
  content: "\F1454"; }

.mdi-clock-time-three::before {
  content: "\F1441"; }

.mdi-clock-time-three-outline::before {
  content: "\F144D"; }

.mdi-clock-time-twelve::before {
  content: "\F144A"; }

.mdi-clock-time-twelve-outline::before {
  content: "\F1456"; }

.mdi-clock-time-two::before {
  content: "\F1440"; }

.mdi-clock-time-two-outline::before {
  content: "\F144C"; }

.mdi-close::before {
  content: "\F0156"; }

.mdi-close-box::before {
  content: "\F0157"; }

.mdi-close-box-multiple::before {
  content: "\F0C5D"; }

.mdi-close-box-multiple-outline::before {
  content: "\F0C5E"; }

.mdi-close-box-outline::before {
  content: "\F0158"; }

.mdi-close-circle::before {
  content: "\F0159"; }

.mdi-close-circle-multiple::before {
  content: "\F062A"; }

.mdi-close-circle-multiple-outline::before {
  content: "\F0883"; }

.mdi-close-circle-outline::before {
  content: "\F015A"; }

.mdi-close-network::before {
  content: "\F015B"; }

.mdi-close-network-outline::before {
  content: "\F0C5F"; }

.mdi-close-octagon::before {
  content: "\F015C"; }

.mdi-close-octagon-outline::before {
  content: "\F015D"; }

.mdi-close-outline::before {
  content: "\F06C9"; }

.mdi-close-thick::before {
  content: "\F1398"; }

.mdi-closed-caption::before {
  content: "\F015E"; }

.mdi-closed-caption-outline::before {
  content: "\F0DBD"; }

.mdi-cloud::before {
  content: "\F015F"; }

.mdi-cloud-alert::before {
  content: "\F09E0"; }

.mdi-cloud-braces::before {
  content: "\F07B5"; }

.mdi-cloud-check::before {
  content: "\F0160"; }

.mdi-cloud-check-outline::before {
  content: "\F12CC"; }

.mdi-cloud-circle::before {
  content: "\F0161"; }

.mdi-cloud-download::before {
  content: "\F0162"; }

.mdi-cloud-download-outline::before {
  content: "\F0B7D"; }

.mdi-cloud-lock::before {
  content: "\F11F1"; }

.mdi-cloud-lock-outline::before {
  content: "\F11F2"; }

.mdi-cloud-off-outline::before {
  content: "\F0164"; }

.mdi-cloud-outline::before {
  content: "\F0163"; }

.mdi-cloud-percent::before {
  content: "\F1A35"; }

.mdi-cloud-percent-outline::before {
  content: "\F1A36"; }

.mdi-cloud-print::before {
  content: "\F0165"; }

.mdi-cloud-print-outline::before {
  content: "\F0166"; }

.mdi-cloud-question::before {
  content: "\F0A39"; }

.mdi-cloud-refresh::before {
  content: "\F052A"; }

.mdi-cloud-search::before {
  content: "\F0956"; }

.mdi-cloud-search-outline::before {
  content: "\F0957"; }

.mdi-cloud-sync::before {
  content: "\F063F"; }

.mdi-cloud-sync-outline::before {
  content: "\F12D6"; }

.mdi-cloud-tags::before {
  content: "\F07B6"; }

.mdi-cloud-upload::before {
  content: "\F0167"; }

.mdi-cloud-upload-outline::before {
  content: "\F0B7E"; }

.mdi-clouds::before {
  content: "\F1B95"; }

.mdi-clover::before {
  content: "\F0816"; }

.mdi-coach-lamp::before {
  content: "\F1020"; }

.mdi-coach-lamp-variant::before {
  content: "\F1A37"; }

.mdi-coat-rack::before {
  content: "\F109E"; }

.mdi-code-array::before {
  content: "\F0168"; }

.mdi-code-braces::before {
  content: "\F0169"; }

.mdi-code-braces-box::before {
  content: "\F10D6"; }

.mdi-code-brackets::before {
  content: "\F016A"; }

.mdi-code-equal::before {
  content: "\F016B"; }

.mdi-code-greater-than::before {
  content: "\F016C"; }

.mdi-code-greater-than-or-equal::before {
  content: "\F016D"; }

.mdi-code-json::before {
  content: "\F0626"; }

.mdi-code-less-than::before {
  content: "\F016E"; }

.mdi-code-less-than-or-equal::before {
  content: "\F016F"; }

.mdi-code-not-equal::before {
  content: "\F0170"; }

.mdi-code-not-equal-variant::before {
  content: "\F0171"; }

.mdi-code-parentheses::before {
  content: "\F0172"; }

.mdi-code-parentheses-box::before {
  content: "\F10D7"; }

.mdi-code-string::before {
  content: "\F0173"; }

.mdi-code-tags::before {
  content: "\F0174"; }

.mdi-code-tags-check::before {
  content: "\F0694"; }

.mdi-codepen::before {
  content: "\F0175"; }

.mdi-coffee::before {
  content: "\F0176"; }

.mdi-coffee-maker::before {
  content: "\F109F"; }

.mdi-coffee-maker-check::before {
  content: "\F1931"; }

.mdi-coffee-maker-check-outline::before {
  content: "\F1932"; }

.mdi-coffee-maker-outline::before {
  content: "\F181B"; }

.mdi-coffee-off::before {
  content: "\F0FAA"; }

.mdi-coffee-off-outline::before {
  content: "\F0FAB"; }

.mdi-coffee-outline::before {
  content: "\F06CA"; }

.mdi-coffee-to-go::before {
  content: "\F0177"; }

.mdi-coffee-to-go-outline::before {
  content: "\F130E"; }

.mdi-coffin::before {
  content: "\F0B7F"; }

.mdi-cog::before {
  content: "\F0493"; }

.mdi-cog-box::before {
  content: "\F0494"; }

.mdi-cog-clockwise::before {
  content: "\F11DD"; }

.mdi-cog-counterclockwise::before {
  content: "\F11DE"; }

.mdi-cog-off::before {
  content: "\F13CE"; }

.mdi-cog-off-outline::before {
  content: "\F13CF"; }

.mdi-cog-outline::before {
  content: "\F08BB"; }

.mdi-cog-pause::before {
  content: "\F1933"; }

.mdi-cog-pause-outline::before {
  content: "\F1934"; }

.mdi-cog-play::before {
  content: "\F1935"; }

.mdi-cog-play-outline::before {
  content: "\F1936"; }

.mdi-cog-refresh::before {
  content: "\F145E"; }

.mdi-cog-refresh-outline::before {
  content: "\F145F"; }

.mdi-cog-stop::before {
  content: "\F1937"; }

.mdi-cog-stop-outline::before {
  content: "\F1938"; }

.mdi-cog-sync::before {
  content: "\F1460"; }

.mdi-cog-sync-outline::before {
  content: "\F1461"; }

.mdi-cog-transfer::before {
  content: "\F105B"; }

.mdi-cog-transfer-outline::before {
  content: "\F105C"; }

.mdi-cogs::before {
  content: "\F08D6"; }

.mdi-collage::before {
  content: "\F0640"; }

.mdi-collapse-all::before {
  content: "\F0AA6"; }

.mdi-collapse-all-outline::before {
  content: "\F0AA7"; }

.mdi-color-helper::before {
  content: "\F0179"; }

.mdi-comma::before {
  content: "\F0E23"; }

.mdi-comma-box::before {
  content: "\F0E2B"; }

.mdi-comma-box-outline::before {
  content: "\F0E24"; }

.mdi-comma-circle::before {
  content: "\F0E25"; }

.mdi-comma-circle-outline::before {
  content: "\F0E26"; }

.mdi-comment::before {
  content: "\F017A"; }

.mdi-comment-account::before {
  content: "\F017B"; }

.mdi-comment-account-outline::before {
  content: "\F017C"; }

.mdi-comment-alert::before {
  content: "\F017D"; }

.mdi-comment-alert-outline::before {
  content: "\F017E"; }

.mdi-comment-arrow-left::before {
  content: "\F09E1"; }

.mdi-comment-arrow-left-outline::before {
  content: "\F09E2"; }

.mdi-comment-arrow-right::before {
  content: "\F09E3"; }

.mdi-comment-arrow-right-outline::before {
  content: "\F09E4"; }

.mdi-comment-bookmark::before {
  content: "\F15AE"; }

.mdi-comment-bookmark-outline::before {
  content: "\F15AF"; }

.mdi-comment-check::before {
  content: "\F017F"; }

.mdi-comment-check-outline::before {
  content: "\F0180"; }

.mdi-comment-edit::before {
  content: "\F11BF"; }

.mdi-comment-edit-outline::before {
  content: "\F12C4"; }

.mdi-comment-eye::before {
  content: "\F0A3A"; }

.mdi-comment-eye-outline::before {
  content: "\F0A3B"; }

.mdi-comment-flash::before {
  content: "\F15B0"; }

.mdi-comment-flash-outline::before {
  content: "\F15B1"; }

.mdi-comment-minus::before {
  content: "\F15DF"; }

.mdi-comment-minus-outline::before {
  content: "\F15E0"; }

.mdi-comment-multiple::before {
  content: "\F085F"; }

.mdi-comment-multiple-outline::before {
  content: "\F0181"; }

.mdi-comment-off::before {
  content: "\F15E1"; }

.mdi-comment-off-outline::before {
  content: "\F15E2"; }

.mdi-comment-outline::before {
  content: "\F0182"; }

.mdi-comment-plus::before {
  content: "\F09E5"; }

.mdi-comment-plus-outline::before {
  content: "\F0183"; }

.mdi-comment-processing::before {
  content: "\F0184"; }

.mdi-comment-processing-outline::before {
  content: "\F0185"; }

.mdi-comment-question::before {
  content: "\F0817"; }

.mdi-comment-question-outline::before {
  content: "\F0186"; }

.mdi-comment-quote::before {
  content: "\F1021"; }

.mdi-comment-quote-outline::before {
  content: "\F1022"; }

.mdi-comment-remove::before {
  content: "\F05DE"; }

.mdi-comment-remove-outline::before {
  content: "\F0187"; }

.mdi-comment-search::before {
  content: "\F0A3C"; }

.mdi-comment-search-outline::before {
  content: "\F0A3D"; }

.mdi-comment-text::before {
  content: "\F0188"; }

.mdi-comment-text-multiple::before {
  content: "\F0860"; }

.mdi-comment-text-multiple-outline::before {
  content: "\F0861"; }

.mdi-comment-text-outline::before {
  content: "\F0189"; }

.mdi-compare::before {
  content: "\F018A"; }

.mdi-compare-horizontal::before {
  content: "\F1492"; }

.mdi-compare-remove::before {
  content: "\F18B3"; }

.mdi-compare-vertical::before {
  content: "\F1493"; }

.mdi-compass::before {
  content: "\F018B"; }

.mdi-compass-off::before {
  content: "\F0B80"; }

.mdi-compass-off-outline::before {
  content: "\F0B81"; }

.mdi-compass-outline::before {
  content: "\F018C"; }

.mdi-compass-rose::before {
  content: "\F1382"; }

.mdi-compost::before {
  content: "\F1A38"; }

.mdi-cone::before {
  content: "\F194C"; }

.mdi-cone-off::before {
  content: "\F194D"; }

.mdi-connection::before {
  content: "\F1616"; }

.mdi-console::before {
  content: "\F018D"; }

.mdi-console-line::before {
  content: "\F07B7"; }

.mdi-console-network::before {
  content: "\F08A9"; }

.mdi-console-network-outline::before {
  content: "\F0C60"; }

.mdi-consolidate::before {
  content: "\F10D8"; }

.mdi-contactless-payment::before {
  content: "\F0D6A"; }

.mdi-contactless-payment-circle::before {
  content: "\F0321"; }

.mdi-contactless-payment-circle-outline::before {
  content: "\F0408"; }

.mdi-contacts::before {
  content: "\F06CB"; }

.mdi-contacts-outline::before {
  content: "\F05B8"; }

.mdi-contain::before {
  content: "\F0A3E"; }

.mdi-contain-end::before {
  content: "\F0A3F"; }

.mdi-contain-start::before {
  content: "\F0A40"; }

.mdi-content-copy::before {
  content: "\F018F"; }

.mdi-content-cut::before {
  content: "\F0190"; }

.mdi-content-duplicate::before {
  content: "\F0191"; }

.mdi-content-paste::before {
  content: "\F0192"; }

.mdi-content-save::before {
  content: "\F0193"; }

.mdi-content-save-alert::before {
  content: "\F0F42"; }

.mdi-content-save-alert-outline::before {
  content: "\F0F43"; }

.mdi-content-save-all::before {
  content: "\F0194"; }

.mdi-content-save-all-outline::before {
  content: "\F0F44"; }

.mdi-content-save-check::before {
  content: "\F18EA"; }

.mdi-content-save-check-outline::before {
  content: "\F18EB"; }

.mdi-content-save-cog::before {
  content: "\F145B"; }

.mdi-content-save-cog-outline::before {
  content: "\F145C"; }

.mdi-content-save-edit::before {
  content: "\F0CFB"; }

.mdi-content-save-edit-outline::before {
  content: "\F0CFC"; }

.mdi-content-save-minus::before {
  content: "\F1B43"; }

.mdi-content-save-minus-outline::before {
  content: "\F1B44"; }

.mdi-content-save-move::before {
  content: "\F0E27"; }

.mdi-content-save-move-outline::before {
  content: "\F0E28"; }

.mdi-content-save-off::before {
  content: "\F1643"; }

.mdi-content-save-off-outline::before {
  content: "\F1644"; }

.mdi-content-save-outline::before {
  content: "\F0818"; }

.mdi-content-save-plus::before {
  content: "\F1B41"; }

.mdi-content-save-plus-outline::before {
  content: "\F1B42"; }

.mdi-content-save-settings::before {
  content: "\F061B"; }

.mdi-content-save-settings-outline::before {
  content: "\F0B2E"; }

.mdi-contrast::before {
  content: "\F0195"; }

.mdi-contrast-box::before {
  content: "\F0196"; }

.mdi-contrast-circle::before {
  content: "\F0197"; }

.mdi-controller::before {
  content: "\F02B4"; }

.mdi-controller-classic::before {
  content: "\F0B82"; }

.mdi-controller-classic-outline::before {
  content: "\F0B83"; }

.mdi-controller-off::before {
  content: "\F02B5"; }

.mdi-cookie::before {
  content: "\F0198"; }

.mdi-cookie-alert::before {
  content: "\F16D0"; }

.mdi-cookie-alert-outline::before {
  content: "\F16D1"; }

.mdi-cookie-check::before {
  content: "\F16D2"; }

.mdi-cookie-check-outline::before {
  content: "\F16D3"; }

.mdi-cookie-clock::before {
  content: "\F16E4"; }

.mdi-cookie-clock-outline::before {
  content: "\F16E5"; }

.mdi-cookie-cog::before {
  content: "\F16D4"; }

.mdi-cookie-cog-outline::before {
  content: "\F16D5"; }

.mdi-cookie-edit::before {
  content: "\F16E6"; }

.mdi-cookie-edit-outline::before {
  content: "\F16E7"; }

.mdi-cookie-lock::before {
  content: "\F16E8"; }

.mdi-cookie-lock-outline::before {
  content: "\F16E9"; }

.mdi-cookie-minus::before {
  content: "\F16DA"; }

.mdi-cookie-minus-outline::before {
  content: "\F16DB"; }

.mdi-cookie-off::before {
  content: "\F16EA"; }

.mdi-cookie-off-outline::before {
  content: "\F16EB"; }

.mdi-cookie-outline::before {
  content: "\F16DE"; }

.mdi-cookie-plus::before {
  content: "\F16D6"; }

.mdi-cookie-plus-outline::before {
  content: "\F16D7"; }

.mdi-cookie-refresh::before {
  content: "\F16EC"; }

.mdi-cookie-refresh-outline::before {
  content: "\F16ED"; }

.mdi-cookie-remove::before {
  content: "\F16D8"; }

.mdi-cookie-remove-outline::before {
  content: "\F16D9"; }

.mdi-cookie-settings::before {
  content: "\F16DC"; }

.mdi-cookie-settings-outline::before {
  content: "\F16DD"; }

.mdi-coolant-temperature::before {
  content: "\F03C8"; }

.mdi-copyleft::before {
  content: "\F1939"; }

.mdi-copyright::before {
  content: "\F05E6"; }

.mdi-cordova::before {
  content: "\F0958"; }

.mdi-corn::before {
  content: "\F07B8"; }

.mdi-corn-off::before {
  content: "\F13EF"; }

.mdi-cosine-wave::before {
  content: "\F1479"; }

.mdi-counter::before {
  content: "\F0199"; }

.mdi-countertop::before {
  content: "\F181C"; }

.mdi-countertop-outline::before {
  content: "\F181D"; }

.mdi-cow::before {
  content: "\F019A"; }

.mdi-cow-off::before {
  content: "\F18FC"; }

.mdi-cpu-32-bit::before {
  content: "\F0EDF"; }

.mdi-cpu-64-bit::before {
  content: "\F0EE0"; }

.mdi-cradle::before {
  content: "\F198B"; }

.mdi-cradle-outline::before {
  content: "\F1991"; }

.mdi-crane::before {
  content: "\F0862"; }

.mdi-creation::before {
  content: "\F0674"; }

.mdi-creative-commons::before {
  content: "\F0D6B"; }

.mdi-credit-card::before {
  content: "\F0FEF"; }

.mdi-credit-card-check::before {
  content: "\F13D0"; }

.mdi-credit-card-check-outline::before {
  content: "\F13D1"; }

.mdi-credit-card-chip::before {
  content: "\F190F"; }

.mdi-credit-card-chip-outline::before {
  content: "\F1910"; }

.mdi-credit-card-clock::before {
  content: "\F0EE1"; }

.mdi-credit-card-clock-outline::before {
  content: "\F0EE2"; }

.mdi-credit-card-edit::before {
  content: "\F17D7"; }

.mdi-credit-card-edit-outline::before {
  content: "\F17D8"; }

.mdi-credit-card-fast::before {
  content: "\F1911"; }

.mdi-credit-card-fast-outline::before {
  content: "\F1912"; }

.mdi-credit-card-lock::before {
  content: "\F18E7"; }

.mdi-credit-card-lock-outline::before {
  content: "\F18E8"; }

.mdi-credit-card-marker::before {
  content: "\F06A8"; }

.mdi-credit-card-marker-outline::before {
  content: "\F0DBE"; }

.mdi-credit-card-minus::before {
  content: "\F0FAC"; }

.mdi-credit-card-minus-outline::before {
  content: "\F0FAD"; }

.mdi-credit-card-multiple::before {
  content: "\F0FF0"; }

.mdi-credit-card-multiple-outline::before {
  content: "\F019C"; }

.mdi-credit-card-off::before {
  content: "\F0FF1"; }

.mdi-credit-card-off-outline::before {
  content: "\F05E4"; }

.mdi-credit-card-outline::before {
  content: "\F019B"; }

.mdi-credit-card-plus::before {
  content: "\F0FF2"; }

.mdi-credit-card-plus-outline::before {
  content: "\F0676"; }

.mdi-credit-card-refresh::before {
  content: "\F1645"; }

.mdi-credit-card-refresh-outline::before {
  content: "\F1646"; }

.mdi-credit-card-refund::before {
  content: "\F0FF3"; }

.mdi-credit-card-refund-outline::before {
  content: "\F0AA8"; }

.mdi-credit-card-remove::before {
  content: "\F0FAE"; }

.mdi-credit-card-remove-outline::before {
  content: "\F0FAF"; }

.mdi-credit-card-scan::before {
  content: "\F0FF4"; }

.mdi-credit-card-scan-outline::before {
  content: "\F019D"; }

.mdi-credit-card-search::before {
  content: "\F1647"; }

.mdi-credit-card-search-outline::before {
  content: "\F1648"; }

.mdi-credit-card-settings::before {
  content: "\F0FF5"; }

.mdi-credit-card-settings-outline::before {
  content: "\F08D7"; }

.mdi-credit-card-sync::before {
  content: "\F1649"; }

.mdi-credit-card-sync-outline::before {
  content: "\F164A"; }

.mdi-credit-card-wireless::before {
  content: "\F0802"; }

.mdi-credit-card-wireless-off::before {
  content: "\F057A"; }

.mdi-credit-card-wireless-off-outline::before {
  content: "\F057B"; }

.mdi-credit-card-wireless-outline::before {
  content: "\F0D6C"; }

.mdi-cricket::before {
  content: "\F0D6D"; }

.mdi-crop::before {
  content: "\F019E"; }

.mdi-crop-free::before {
  content: "\F019F"; }

.mdi-crop-landscape::before {
  content: "\F01A0"; }

.mdi-crop-portrait::before {
  content: "\F01A1"; }

.mdi-crop-rotate::before {
  content: "\F0696"; }

.mdi-crop-square::before {
  content: "\F01A2"; }

.mdi-cross::before {
  content: "\F0953"; }

.mdi-cross-bolnisi::before {
  content: "\F0CED"; }

.mdi-cross-celtic::before {
  content: "\F0CF5"; }

.mdi-cross-outline::before {
  content: "\F0CF6"; }

.mdi-crosshairs::before {
  content: "\F01A3"; }

.mdi-crosshairs-gps::before {
  content: "\F01A4"; }

.mdi-crosshairs-off::before {
  content: "\F0F45"; }

.mdi-crosshairs-question::before {
  content: "\F1136"; }

.mdi-crowd::before {
  content: "\F1975"; }

.mdi-crown::before {
  content: "\F01A5"; }

.mdi-crown-circle::before {
  content: "\F17DC"; }

.mdi-crown-circle-outline::before {
  content: "\F17DD"; }

.mdi-crown-outline::before {
  content: "\F11D0"; }

.mdi-cryengine::before {
  content: "\F0959"; }

.mdi-crystal-ball::before {
  content: "\F0B2F"; }

.mdi-cube::before {
  content: "\F01A6"; }

.mdi-cube-off::before {
  content: "\F141C"; }

.mdi-cube-off-outline::before {
  content: "\F141D"; }

.mdi-cube-outline::before {
  content: "\F01A7"; }

.mdi-cube-scan::before {
  content: "\F0B84"; }

.mdi-cube-send::before {
  content: "\F01A8"; }

.mdi-cube-unfolded::before {
  content: "\F01A9"; }

.mdi-cup::before {
  content: "\F01AA"; }

.mdi-cup-off::before {
  content: "\F05E5"; }

.mdi-cup-off-outline::before {
  content: "\F137D"; }

.mdi-cup-outline::before {
  content: "\F130F"; }

.mdi-cup-water::before {
  content: "\F01AB"; }

.mdi-cupboard::before {
  content: "\F0F46"; }

.mdi-cupboard-outline::before {
  content: "\F0F47"; }

.mdi-cupcake::before {
  content: "\F095A"; }

.mdi-curling::before {
  content: "\F0863"; }

.mdi-currency-bdt::before {
  content: "\F0864"; }

.mdi-currency-brl::before {
  content: "\F0B85"; }

.mdi-currency-btc::before {
  content: "\F01AC"; }

.mdi-currency-cny::before {
  content: "\F07BA"; }

.mdi-currency-eth::before {
  content: "\F07BB"; }

.mdi-currency-eur::before {
  content: "\F01AD"; }

.mdi-currency-eur-off::before {
  content: "\F1315"; }

.mdi-currency-fra::before {
  content: "\F1A39"; }

.mdi-currency-gbp::before {
  content: "\F01AE"; }

.mdi-currency-ils::before {
  content: "\F0C61"; }

.mdi-currency-inr::before {
  content: "\F01AF"; }

.mdi-currency-jpy::before {
  content: "\F07BC"; }

.mdi-currency-krw::before {
  content: "\F07BD"; }

.mdi-currency-kzt::before {
  content: "\F0865"; }

.mdi-currency-mnt::before {
  content: "\F1512"; }

.mdi-currency-ngn::before {
  content: "\F01B0"; }

.mdi-currency-php::before {
  content: "\F09E6"; }

.mdi-currency-rial::before {
  content: "\F0E9C"; }

.mdi-currency-rub::before {
  content: "\F01B1"; }

.mdi-currency-rupee::before {
  content: "\F1976"; }

.mdi-currency-sign::before {
  content: "\F07BE"; }

.mdi-currency-try::before {
  content: "\F01B2"; }

.mdi-currency-twd::before {
  content: "\F07BF"; }

.mdi-currency-uah::before {
  content: "\F1B9B"; }

.mdi-currency-usd::before {
  content: "\F01C1"; }

.mdi-currency-usd-off::before {
  content: "\F067A"; }

.mdi-current-ac::before {
  content: "\F1480"; }

.mdi-current-dc::before {
  content: "\F095C"; }

.mdi-cursor-default::before {
  content: "\F01C0"; }

.mdi-cursor-default-click::before {
  content: "\F0CFD"; }

.mdi-cursor-default-click-outline::before {
  content: "\F0CFE"; }

.mdi-cursor-default-gesture::before {
  content: "\F1127"; }

.mdi-cursor-default-gesture-outline::before {
  content: "\F1128"; }

.mdi-cursor-default-outline::before {
  content: "\F01BF"; }

.mdi-cursor-move::before {
  content: "\F01BE"; }

.mdi-cursor-pointer::before {
  content: "\F01BD"; }

.mdi-cursor-text::before {
  content: "\F05E7"; }

.mdi-curtains::before {
  content: "\F1846"; }

.mdi-curtains-closed::before {
  content: "\F1847"; }

.mdi-cylinder::before {
  content: "\F194E"; }

.mdi-cylinder-off::before {
  content: "\F194F"; }

.mdi-dance-ballroom::before {
  content: "\F15FB"; }

.mdi-dance-pole::before {
  content: "\F1578"; }

.mdi-data-matrix::before {
  content: "\F153C"; }

.mdi-data-matrix-edit::before {
  content: "\F153D"; }

.mdi-data-matrix-minus::before {
  content: "\F153E"; }

.mdi-data-matrix-plus::before {
  content: "\F153F"; }

.mdi-data-matrix-remove::before {
  content: "\F1540"; }

.mdi-data-matrix-scan::before {
  content: "\F1541"; }

.mdi-database::before {
  content: "\F01BC"; }

.mdi-database-alert::before {
  content: "\F163A"; }

.mdi-database-alert-outline::before {
  content: "\F1624"; }

.mdi-database-arrow-down::before {
  content: "\F163B"; }

.mdi-database-arrow-down-outline::before {
  content: "\F1625"; }

.mdi-database-arrow-left::before {
  content: "\F163C"; }

.mdi-database-arrow-left-outline::before {
  content: "\F1626"; }

.mdi-database-arrow-right::before {
  content: "\F163D"; }

.mdi-database-arrow-right-outline::before {
  content: "\F1627"; }

.mdi-database-arrow-up::before {
  content: "\F163E"; }

.mdi-database-arrow-up-outline::before {
  content: "\F1628"; }

.mdi-database-check::before {
  content: "\F0AA9"; }

.mdi-database-check-outline::before {
  content: "\F1629"; }

.mdi-database-clock::before {
  content: "\F163F"; }

.mdi-database-clock-outline::before {
  content: "\F162A"; }

.mdi-database-cog::before {
  content: "\F164B"; }

.mdi-database-cog-outline::before {
  content: "\F164C"; }

.mdi-database-edit::before {
  content: "\F0B86"; }

.mdi-database-edit-outline::before {
  content: "\F162B"; }

.mdi-database-export::before {
  content: "\F095E"; }

.mdi-database-export-outline::before {
  content: "\F162C"; }

.mdi-database-eye::before {
  content: "\F191F"; }

.mdi-database-eye-off::before {
  content: "\F1920"; }

.mdi-database-eye-off-outline::before {
  content: "\F1921"; }

.mdi-database-eye-outline::before {
  content: "\F1922"; }

.mdi-database-import::before {
  content: "\F095D"; }

.mdi-database-import-outline::before {
  content: "\F162D"; }

.mdi-database-lock::before {
  content: "\F0AAA"; }

.mdi-database-lock-outline::before {
  content: "\F162E"; }

.mdi-database-marker::before {
  content: "\F12F6"; }

.mdi-database-marker-outline::before {
  content: "\F162F"; }

.mdi-database-minus::before {
  content: "\F01BB"; }

.mdi-database-minus-outline::before {
  content: "\F1630"; }

.mdi-database-off::before {
  content: "\F1640"; }

.mdi-database-off-outline::before {
  content: "\F1631"; }

.mdi-database-outline::before {
  content: "\F1632"; }

.mdi-database-plus::before {
  content: "\F01BA"; }

.mdi-database-plus-outline::before {
  content: "\F1633"; }

.mdi-database-refresh::before {
  content: "\F05C2"; }

.mdi-database-refresh-outline::before {
  content: "\F1634"; }

.mdi-database-remove::before {
  content: "\F0D00"; }

.mdi-database-remove-outline::before {
  content: "\F1635"; }

.mdi-database-search::before {
  content: "\F0866"; }

.mdi-database-search-outline::before {
  content: "\F1636"; }

.mdi-database-settings::before {
  content: "\F0D01"; }

.mdi-database-settings-outline::before {
  content: "\F1637"; }

.mdi-database-sync::before {
  content: "\F0CFF"; }

.mdi-database-sync-outline::before {
  content: "\F1638"; }

.mdi-death-star::before {
  content: "\F08D8"; }

.mdi-death-star-variant::before {
  content: "\F08D9"; }

.mdi-deathly-hallows::before {
  content: "\F0B87"; }

.mdi-debian::before {
  content: "\F08DA"; }

.mdi-debug-step-into::before {
  content: "\F01B9"; }

.mdi-debug-step-out::before {
  content: "\F01B8"; }

.mdi-debug-step-over::before {
  content: "\F01B7"; }

.mdi-decagram::before {
  content: "\F076C"; }

.mdi-decagram-outline::before {
  content: "\F076D"; }

.mdi-decimal::before {
  content: "\F10A1"; }

.mdi-decimal-comma::before {
  content: "\F10A2"; }

.mdi-decimal-comma-decrease::before {
  content: "\F10A3"; }

.mdi-decimal-comma-increase::before {
  content: "\F10A4"; }

.mdi-decimal-decrease::before {
  content: "\F01B6"; }

.mdi-decimal-increase::before {
  content: "\F01B5"; }

.mdi-delete::before {
  content: "\F01B4"; }

.mdi-delete-alert::before {
  content: "\F10A5"; }

.mdi-delete-alert-outline::before {
  content: "\F10A6"; }

.mdi-delete-circle::before {
  content: "\F0683"; }

.mdi-delete-circle-outline::before {
  content: "\F0B88"; }

.mdi-delete-clock::before {
  content: "\F1556"; }

.mdi-delete-clock-outline::before {
  content: "\F1557"; }

.mdi-delete-empty::before {
  content: "\F06CC"; }

.mdi-delete-empty-outline::before {
  content: "\F0E9D"; }

.mdi-delete-forever::before {
  content: "\F05E8"; }

.mdi-delete-forever-outline::before {
  content: "\F0B89"; }

.mdi-delete-off::before {
  content: "\F10A7"; }

.mdi-delete-off-outline::before {
  content: "\F10A8"; }

.mdi-delete-outline::before {
  content: "\F09E7"; }

.mdi-delete-restore::before {
  content: "\F0819"; }

.mdi-delete-sweep::before {
  content: "\F05E9"; }

.mdi-delete-sweep-outline::before {
  content: "\F0C62"; }

.mdi-delete-variant::before {
  content: "\F01B3"; }

.mdi-delta::before {
  content: "\F01C2"; }

.mdi-desk::before {
  content: "\F1239"; }

.mdi-desk-lamp::before {
  content: "\F095F"; }

.mdi-desk-lamp-off::before {
  content: "\F1B1F"; }

.mdi-desk-lamp-on::before {
  content: "\F1B20"; }

.mdi-deskphone::before {
  content: "\F01C3"; }

.mdi-desktop-classic::before {
  content: "\F07C0"; }

.mdi-desktop-tower::before {
  content: "\F01C5"; }

.mdi-desktop-tower-monitor::before {
  content: "\F0AAB"; }

.mdi-details::before {
  content: "\F01C6"; }

.mdi-dev-to::before {
  content: "\F0D6E"; }

.mdi-developer-board::before {
  content: "\F0697"; }

.mdi-deviantart::before {
  content: "\F01C7"; }

.mdi-devices::before {
  content: "\F0FB0"; }

.mdi-dharmachakra::before {
  content: "\F094B"; }

.mdi-diabetes::before {
  content: "\F1126"; }

.mdi-dialpad::before {
  content: "\F061C"; }

.mdi-diameter::before {
  content: "\F0C63"; }

.mdi-diameter-outline::before {
  content: "\F0C64"; }

.mdi-diameter-variant::before {
  content: "\F0C65"; }

.mdi-diamond::before {
  content: "\F0B8A"; }

.mdi-diamond-outline::before {
  content: "\F0B8B"; }

.mdi-diamond-stone::before {
  content: "\F01C8"; }

.mdi-dice-1::before {
  content: "\F01CA"; }

.mdi-dice-1-outline::before {
  content: "\F114A"; }

.mdi-dice-2::before {
  content: "\F01CB"; }

.mdi-dice-2-outline::before {
  content: "\F114B"; }

.mdi-dice-3::before {
  content: "\F01CC"; }

.mdi-dice-3-outline::before {
  content: "\F114C"; }

.mdi-dice-4::before {
  content: "\F01CD"; }

.mdi-dice-4-outline::before {
  content: "\F114D"; }

.mdi-dice-5::before {
  content: "\F01CE"; }

.mdi-dice-5-outline::before {
  content: "\F114E"; }

.mdi-dice-6::before {
  content: "\F01CF"; }

.mdi-dice-6-outline::before {
  content: "\F114F"; }

.mdi-dice-d10::before {
  content: "\F1153"; }

.mdi-dice-d10-outline::before {
  content: "\F076F"; }

.mdi-dice-d12::before {
  content: "\F1154"; }

.mdi-dice-d12-outline::before {
  content: "\F0867"; }

.mdi-dice-d20::before {
  content: "\F1155"; }

.mdi-dice-d20-outline::before {
  content: "\F05EA"; }

.mdi-dice-d4::before {
  content: "\F1150"; }

.mdi-dice-d4-outline::before {
  content: "\F05EB"; }

.mdi-dice-d6::before {
  content: "\F1151"; }

.mdi-dice-d6-outline::before {
  content: "\F05ED"; }

.mdi-dice-d8::before {
  content: "\F1152"; }

.mdi-dice-d8-outline::before {
  content: "\F05EC"; }

.mdi-dice-multiple::before {
  content: "\F076E"; }

.mdi-dice-multiple-outline::before {
  content: "\F1156"; }

.mdi-digital-ocean::before {
  content: "\F1237"; }

.mdi-dip-switch::before {
  content: "\F07C1"; }

.mdi-directions::before {
  content: "\F01D0"; }

.mdi-directions-fork::before {
  content: "\F0641"; }

.mdi-disc::before {
  content: "\F05EE"; }

.mdi-disc-alert::before {
  content: "\F01D1"; }

.mdi-disc-player::before {
  content: "\F0960"; }

.mdi-dishwasher::before {
  content: "\F0AAC"; }

.mdi-dishwasher-alert::before {
  content: "\F11B8"; }

.mdi-dishwasher-off::before {
  content: "\F11B9"; }

.mdi-disqus::before {
  content: "\F01D2"; }

.mdi-distribute-horizontal-center::before {
  content: "\F11C9"; }

.mdi-distribute-horizontal-left::before {
  content: "\F11C8"; }

.mdi-distribute-horizontal-right::before {
  content: "\F11CA"; }

.mdi-distribute-vertical-bottom::before {
  content: "\F11CB"; }

.mdi-distribute-vertical-center::before {
  content: "\F11CC"; }

.mdi-distribute-vertical-top::before {
  content: "\F11CD"; }

.mdi-diversify::before {
  content: "\F1877"; }

.mdi-diving::before {
  content: "\F1977"; }

.mdi-diving-flippers::before {
  content: "\F0DBF"; }

.mdi-diving-helmet::before {
  content: "\F0DC0"; }

.mdi-diving-scuba::before {
  content: "\F1B77"; }

.mdi-diving-scuba-flag::before {
  content: "\F0DC2"; }

.mdi-diving-scuba-mask::before {
  content: "\F0DC1"; }

.mdi-diving-scuba-tank::before {
  content: "\F0DC3"; }

.mdi-diving-scuba-tank-multiple::before {
  content: "\F0DC4"; }

.mdi-diving-snorkel::before {
  content: "\F0DC5"; }

.mdi-division::before {
  content: "\F01D4"; }

.mdi-division-box::before {
  content: "\F01D5"; }

.mdi-dlna::before {
  content: "\F0A41"; }

.mdi-dna::before {
  content: "\F0684"; }

.mdi-dns::before {
  content: "\F01D6"; }

.mdi-dns-outline::before {
  content: "\F0B8C"; }

.mdi-dock-bottom::before {
  content: "\F10A9"; }

.mdi-dock-left::before {
  content: "\F10AA"; }

.mdi-dock-right::before {
  content: "\F10AB"; }

.mdi-dock-top::before {
  content: "\F1513"; }

.mdi-dock-window::before {
  content: "\F10AC"; }

.mdi-docker::before {
  content: "\F0868"; }

.mdi-doctor::before {
  content: "\F0A42"; }

.mdi-dog::before {
  content: "\F0A43"; }

.mdi-dog-service::before {
  content: "\F0AAD"; }

.mdi-dog-side::before {
  content: "\F0A44"; }

.mdi-dog-side-off::before {
  content: "\F16EE"; }

.mdi-dolby::before {
  content: "\F06B3"; }

.mdi-dolly::before {
  content: "\F0E9E"; }

.mdi-dolphin::before {
  content: "\F18B4"; }

.mdi-domain::before {
  content: "\F01D7"; }

.mdi-domain-off::before {
  content: "\F0D6F"; }

.mdi-domain-plus::before {
  content: "\F10AD"; }

.mdi-domain-remove::before {
  content: "\F10AE"; }

.mdi-dome-light::before {
  content: "\F141E"; }

.mdi-domino-mask::before {
  content: "\F1023"; }

.mdi-donkey::before {
  content: "\F07C2"; }

.mdi-door::before {
  content: "\F081A"; }

.mdi-door-closed::before {
  content: "\F081B"; }

.mdi-door-closed-lock::before {
  content: "\F10AF"; }

.mdi-door-open::before {
  content: "\F081C"; }

.mdi-door-sliding::before {
  content: "\F181E"; }

.mdi-door-sliding-lock::before {
  content: "\F181F"; }

.mdi-door-sliding-open::before {
  content: "\F1820"; }

.mdi-doorbell::before {
  content: "\F12E6"; }

.mdi-doorbell-video::before {
  content: "\F0869"; }

.mdi-dot-net::before {
  content: "\F0AAE"; }

.mdi-dots-circle::before {
  content: "\F1978"; }

.mdi-dots-grid::before {
  content: "\F15FC"; }

.mdi-dots-hexagon::before {
  content: "\F15FF"; }

.mdi-dots-horizontal::before {
  content: "\F01D8"; }

.mdi-dots-horizontal-circle::before {
  content: "\F07C3"; }

.mdi-dots-horizontal-circle-outline::before {
  content: "\F0B8D"; }

.mdi-dots-square::before {
  content: "\F15FD"; }

.mdi-dots-triangle::before {
  content: "\F15FE"; }

.mdi-dots-vertical::before {
  content: "\F01D9"; }

.mdi-dots-vertical-circle::before {
  content: "\F07C4"; }

.mdi-dots-vertical-circle-outline::before {
  content: "\F0B8E"; }

.mdi-download::before {
  content: "\F01DA"; }

.mdi-download-box::before {
  content: "\F1462"; }

.mdi-download-box-outline::before {
  content: "\F1463"; }

.mdi-download-circle::before {
  content: "\F1464"; }

.mdi-download-circle-outline::before {
  content: "\F1465"; }

.mdi-download-lock::before {
  content: "\F1320"; }

.mdi-download-lock-outline::before {
  content: "\F1321"; }

.mdi-download-multiple::before {
  content: "\F09E9"; }

.mdi-download-network::before {
  content: "\F06F4"; }

.mdi-download-network-outline::before {
  content: "\F0C66"; }

.mdi-download-off::before {
  content: "\F10B0"; }

.mdi-download-off-outline::before {
  content: "\F10B1"; }

.mdi-download-outline::before {
  content: "\F0B8F"; }

.mdi-drag::before {
  content: "\F01DB"; }

.mdi-drag-horizontal::before {
  content: "\F01DC"; }

.mdi-drag-horizontal-variant::before {
  content: "\F12F0"; }

.mdi-drag-variant::before {
  content: "\F0B90"; }

.mdi-drag-vertical::before {
  content: "\F01DD"; }

.mdi-drag-vertical-variant::before {
  content: "\F12F1"; }

.mdi-drama-masks::before {
  content: "\F0D02"; }

.mdi-draw::before {
  content: "\F0F49"; }

.mdi-draw-pen::before {
  content: "\F19B9"; }

.mdi-drawing::before {
  content: "\F01DE"; }

.mdi-drawing-box::before {
  content: "\F01DF"; }

.mdi-dresser::before {
  content: "\F0F4A"; }

.mdi-dresser-outline::before {
  content: "\F0F4B"; }

.mdi-drone::before {
  content: "\F01E2"; }

.mdi-dropbox::before {
  content: "\F01E3"; }

.mdi-drupal::before {
  content: "\F01E4"; }

.mdi-duck::before {
  content: "\F01E5"; }

.mdi-dumbbell::before {
  content: "\F01E6"; }

.mdi-dump-truck::before {
  content: "\F0C67"; }

.mdi-ear-hearing::before {
  content: "\F07C5"; }

.mdi-ear-hearing-loop::before {
  content: "\F1AEE"; }

.mdi-ear-hearing-off::before {
  content: "\F0A45"; }

.mdi-earbuds::before {
  content: "\F184F"; }

.mdi-earbuds-off::before {
  content: "\F1850"; }

.mdi-earbuds-off-outline::before {
  content: "\F1851"; }

.mdi-earbuds-outline::before {
  content: "\F1852"; }

.mdi-earth::before {
  content: "\F01E7"; }

.mdi-earth-arrow-right::before {
  content: "\F1311"; }

.mdi-earth-box::before {
  content: "\F06CD"; }

.mdi-earth-box-minus::before {
  content: "\F1407"; }

.mdi-earth-box-off::before {
  content: "\F06CE"; }

.mdi-earth-box-plus::before {
  content: "\F1406"; }

.mdi-earth-box-remove::before {
  content: "\F1408"; }

.mdi-earth-minus::before {
  content: "\F1404"; }

.mdi-earth-off::before {
  content: "\F01E8"; }

.mdi-earth-plus::before {
  content: "\F1403"; }

.mdi-earth-remove::before {
  content: "\F1405"; }

.mdi-egg::before {
  content: "\F0AAF"; }

.mdi-egg-easter::before {
  content: "\F0AB0"; }

.mdi-egg-fried::before {
  content: "\F184A"; }

.mdi-egg-off::before {
  content: "\F13F0"; }

.mdi-egg-off-outline::before {
  content: "\F13F1"; }

.mdi-egg-outline::before {
  content: "\F13F2"; }

.mdi-eiffel-tower::before {
  content: "\F156B"; }

.mdi-eight-track::before {
  content: "\F09EA"; }

.mdi-eject::before {
  content: "\F01EA"; }

.mdi-eject-circle::before {
  content: "\F1B23"; }

.mdi-eject-circle-outline::before {
  content: "\F1B24"; }

.mdi-eject-outline::before {
  content: "\F0B91"; }

.mdi-electric-switch::before {
  content: "\F0E9F"; }

.mdi-electric-switch-closed::before {
  content: "\F10D9"; }

.mdi-electron-framework::before {
  content: "\F1024"; }

.mdi-elephant::before {
  content: "\F07C6"; }

.mdi-elevation-decline::before {
  content: "\F01EB"; }

.mdi-elevation-rise::before {
  content: "\F01EC"; }

.mdi-elevator::before {
  content: "\F01ED"; }

.mdi-elevator-down::before {
  content: "\F12C2"; }

.mdi-elevator-passenger::before {
  content: "\F1381"; }

.mdi-elevator-passenger-off::before {
  content: "\F1979"; }

.mdi-elevator-passenger-off-outline::before {
  content: "\F197A"; }

.mdi-elevator-passenger-outline::before {
  content: "\F197B"; }

.mdi-elevator-up::before {
  content: "\F12C1"; }

.mdi-ellipse::before {
  content: "\F0EA0"; }

.mdi-ellipse-outline::before {
  content: "\F0EA1"; }

.mdi-email::before {
  content: "\F01EE"; }

.mdi-email-alert::before {
  content: "\F06CF"; }

.mdi-email-alert-outline::before {
  content: "\F0D42"; }

.mdi-email-arrow-left::before {
  content: "\F10DA"; }

.mdi-email-arrow-left-outline::before {
  content: "\F10DB"; }

.mdi-email-arrow-right::before {
  content: "\F10DC"; }

.mdi-email-arrow-right-outline::before {
  content: "\F10DD"; }

.mdi-email-box::before {
  content: "\F0D03"; }

.mdi-email-check::before {
  content: "\F0AB1"; }

.mdi-email-check-outline::before {
  content: "\F0AB2"; }

.mdi-email-edit::before {
  content: "\F0EE3"; }

.mdi-email-edit-outline::before {
  content: "\F0EE4"; }

.mdi-email-fast::before {
  content: "\F186F"; }

.mdi-email-fast-outline::before {
  content: "\F1870"; }

.mdi-email-lock::before {
  content: "\F01F1"; }

.mdi-email-lock-outline::before {
  content: "\F1B61"; }

.mdi-email-mark-as-unread::before {
  content: "\F0B92"; }

.mdi-email-minus::before {
  content: "\F0EE5"; }

.mdi-email-minus-outline::before {
  content: "\F0EE6"; }

.mdi-email-multiple::before {
  content: "\F0EE7"; }

.mdi-email-multiple-outline::before {
  content: "\F0EE8"; }

.mdi-email-newsletter::before {
  content: "\F0FB1"; }

.mdi-email-off::before {
  content: "\F13E3"; }

.mdi-email-off-outline::before {
  content: "\F13E4"; }

.mdi-email-open::before {
  content: "\F01EF"; }

.mdi-email-open-multiple::before {
  content: "\F0EE9"; }

.mdi-email-open-multiple-outline::before {
  content: "\F0EEA"; }

.mdi-email-open-outline::before {
  content: "\F05EF"; }

.mdi-email-outline::before {
  content: "\F01F0"; }

.mdi-email-plus::before {
  content: "\F09EB"; }

.mdi-email-plus-outline::before {
  content: "\F09EC"; }

.mdi-email-remove::before {
  content: "\F1661"; }

.mdi-email-remove-outline::before {
  content: "\F1662"; }

.mdi-email-seal::before {
  content: "\F195B"; }

.mdi-email-seal-outline::before {
  content: "\F195C"; }

.mdi-email-search::before {
  content: "\F0961"; }

.mdi-email-search-outline::before {
  content: "\F0962"; }

.mdi-email-sync::before {
  content: "\F12C7"; }

.mdi-email-sync-outline::before {
  content: "\F12C8"; }

.mdi-email-variant::before {
  content: "\F05F0"; }

.mdi-ember::before {
  content: "\F0B30"; }

.mdi-emby::before {
  content: "\F06B4"; }

.mdi-emoticon::before {
  content: "\F0C68"; }

.mdi-emoticon-angry::before {
  content: "\F0C69"; }

.mdi-emoticon-angry-outline::before {
  content: "\F0C6A"; }

.mdi-emoticon-confused::before {
  content: "\F10DE"; }

.mdi-emoticon-confused-outline::before {
  content: "\F10DF"; }

.mdi-emoticon-cool::before {
  content: "\F0C6B"; }

.mdi-emoticon-cool-outline::before {
  content: "\F01F3"; }

.mdi-emoticon-cry::before {
  content: "\F0C6C"; }

.mdi-emoticon-cry-outline::before {
  content: "\F0C6D"; }

.mdi-emoticon-dead::before {
  content: "\F0C6E"; }

.mdi-emoticon-dead-outline::before {
  content: "\F069B"; }

.mdi-emoticon-devil::before {
  content: "\F0C6F"; }

.mdi-emoticon-devil-outline::before {
  content: "\F01F4"; }

.mdi-emoticon-excited::before {
  content: "\F0C70"; }

.mdi-emoticon-excited-outline::before {
  content: "\F069C"; }

.mdi-emoticon-frown::before {
  content: "\F0F4C"; }

.mdi-emoticon-frown-outline::before {
  content: "\F0F4D"; }

.mdi-emoticon-happy::before {
  content: "\F0C71"; }

.mdi-emoticon-happy-outline::before {
  content: "\F01F5"; }

.mdi-emoticon-kiss::before {
  content: "\F0C72"; }

.mdi-emoticon-kiss-outline::before {
  content: "\F0C73"; }

.mdi-emoticon-lol::before {
  content: "\F1214"; }

.mdi-emoticon-lol-outline::before {
  content: "\F1215"; }

.mdi-emoticon-neutral::before {
  content: "\F0C74"; }

.mdi-emoticon-neutral-outline::before {
  content: "\F01F6"; }

.mdi-emoticon-outline::before {
  content: "\F01F2"; }

.mdi-emoticon-poop::before {
  content: "\F01F7"; }

.mdi-emoticon-poop-outline::before {
  content: "\F0C75"; }

.mdi-emoticon-sad::before {
  content: "\F0C76"; }

.mdi-emoticon-sad-outline::before {
  content: "\F01F8"; }

.mdi-emoticon-sick::before {
  content: "\F157C"; }

.mdi-emoticon-sick-outline::before {
  content: "\F157D"; }

.mdi-emoticon-tongue::before {
  content: "\F01F9"; }

.mdi-emoticon-tongue-outline::before {
  content: "\F0C77"; }

.mdi-emoticon-wink::before {
  content: "\F0C78"; }

.mdi-emoticon-wink-outline::before {
  content: "\F0C79"; }

.mdi-engine::before {
  content: "\F01FA"; }

.mdi-engine-off::before {
  content: "\F0A46"; }

.mdi-engine-off-outline::before {
  content: "\F0A47"; }

.mdi-engine-outline::before {
  content: "\F01FB"; }

.mdi-epsilon::before {
  content: "\F10E0"; }

.mdi-equal::before {
  content: "\F01FC"; }

.mdi-equal-box::before {
  content: "\F01FD"; }

.mdi-equalizer::before {
  content: "\F0EA2"; }

.mdi-equalizer-outline::before {
  content: "\F0EA3"; }

.mdi-eraser::before {
  content: "\F01FE"; }

.mdi-eraser-variant::before {
  content: "\F0642"; }

.mdi-escalator::before {
  content: "\F01FF"; }

.mdi-escalator-box::before {
  content: "\F1399"; }

.mdi-escalator-down::before {
  content: "\F12C0"; }

.mdi-escalator-up::before {
  content: "\F12BF"; }

.mdi-eslint::before {
  content: "\F0C7A"; }

.mdi-et::before {
  content: "\F0AB3"; }

.mdi-ethereum::before {
  content: "\F086A"; }

.mdi-ethernet::before {
  content: "\F0200"; }

.mdi-ethernet-cable::before {
  content: "\F0201"; }

.mdi-ethernet-cable-off::before {
  content: "\F0202"; }

.mdi-ev-plug-ccs1::before {
  content: "\F1519"; }

.mdi-ev-plug-ccs2::before {
  content: "\F151A"; }

.mdi-ev-plug-chademo::before {
  content: "\F151B"; }

.mdi-ev-plug-tesla::before {
  content: "\F151C"; }

.mdi-ev-plug-type1::before {
  content: "\F151D"; }

.mdi-ev-plug-type2::before {
  content: "\F151E"; }

.mdi-ev-station::before {
  content: "\F05F1"; }

.mdi-evernote::before {
  content: "\F0204"; }

.mdi-excavator::before {
  content: "\F1025"; }

.mdi-exclamation::before {
  content: "\F0205"; }

.mdi-exclamation-thick::before {
  content: "\F1238"; }

.mdi-exit-run::before {
  content: "\F0A48"; }

.mdi-exit-to-app::before {
  content: "\F0206"; }

.mdi-expand-all::before {
  content: "\F0AB4"; }

.mdi-expand-all-outline::before {
  content: "\F0AB5"; }

.mdi-expansion-card::before {
  content: "\F08AE"; }

.mdi-expansion-card-variant::before {
  content: "\F0FB2"; }

.mdi-exponent::before {
  content: "\F0963"; }

.mdi-exponent-box::before {
  content: "\F0964"; }

.mdi-export::before {
  content: "\F0207"; }

.mdi-export-variant::before {
  content: "\F0B93"; }

.mdi-eye::before {
  content: "\F0208"; }

.mdi-eye-arrow-left::before {
  content: "\F18FD"; }

.mdi-eye-arrow-left-outline::before {
  content: "\F18FE"; }

.mdi-eye-arrow-right::before {
  content: "\F18FF"; }

.mdi-eye-arrow-right-outline::before {
  content: "\F1900"; }

.mdi-eye-check::before {
  content: "\F0D04"; }

.mdi-eye-check-outline::before {
  content: "\F0D05"; }

.mdi-eye-circle::before {
  content: "\F0B94"; }

.mdi-eye-circle-outline::before {
  content: "\F0B95"; }

.mdi-eye-minus::before {
  content: "\F1026"; }

.mdi-eye-minus-outline::before {
  content: "\F1027"; }

.mdi-eye-off::before {
  content: "\F0209"; }

.mdi-eye-off-outline::before {
  content: "\F06D1"; }

.mdi-eye-outline::before {
  content: "\F06D0"; }

.mdi-eye-plus::before {
  content: "\F086B"; }

.mdi-eye-plus-outline::before {
  content: "\F086C"; }

.mdi-eye-refresh::before {
  content: "\F197C"; }

.mdi-eye-refresh-outline::before {
  content: "\F197D"; }

.mdi-eye-remove::before {
  content: "\F15E3"; }

.mdi-eye-remove-outline::before {
  content: "\F15E4"; }

.mdi-eye-settings::before {
  content: "\F086D"; }

.mdi-eye-settings-outline::before {
  content: "\F086E"; }

.mdi-eyedropper::before {
  content: "\F020A"; }

.mdi-eyedropper-minus::before {
  content: "\F13DD"; }

.mdi-eyedropper-off::before {
  content: "\F13DF"; }

.mdi-eyedropper-plus::before {
  content: "\F13DC"; }

.mdi-eyedropper-remove::before {
  content: "\F13DE"; }

.mdi-eyedropper-variant::before {
  content: "\F020B"; }

.mdi-face-agent::before {
  content: "\F0D70"; }

.mdi-face-man::before {
  content: "\F0643"; }

.mdi-face-man-outline::before {
  content: "\F0B96"; }

.mdi-face-man-profile::before {
  content: "\F0644"; }

.mdi-face-man-shimmer::before {
  content: "\F15CC"; }

.mdi-face-man-shimmer-outline::before {
  content: "\F15CD"; }

.mdi-face-mask::before {
  content: "\F1586"; }

.mdi-face-mask-outline::before {
  content: "\F1587"; }

.mdi-face-recognition::before {
  content: "\F0C7B"; }

.mdi-face-woman::before {
  content: "\F1077"; }

.mdi-face-woman-outline::before {
  content: "\F1078"; }

.mdi-face-woman-profile::before {
  content: "\F1076"; }

.mdi-face-woman-shimmer::before {
  content: "\F15CE"; }

.mdi-face-woman-shimmer-outline::before {
  content: "\F15CF"; }

.mdi-facebook::before {
  content: "\F020C"; }

.mdi-facebook-gaming::before {
  content: "\F07DD"; }

.mdi-facebook-messenger::before {
  content: "\F020E"; }

.mdi-facebook-workplace::before {
  content: "\F0B31"; }

.mdi-factory::before {
  content: "\F020F"; }

.mdi-family-tree::before {
  content: "\F160E"; }

.mdi-fan::before {
  content: "\F0210"; }

.mdi-fan-alert::before {
  content: "\F146C"; }

.mdi-fan-auto::before {
  content: "\F171D"; }

.mdi-fan-chevron-down::before {
  content: "\F146D"; }

.mdi-fan-chevron-up::before {
  content: "\F146E"; }

.mdi-fan-clock::before {
  content: "\F1A3A"; }

.mdi-fan-minus::before {
  content: "\F1470"; }

.mdi-fan-off::before {
  content: "\F081D"; }

.mdi-fan-plus::before {
  content: "\F146F"; }

.mdi-fan-remove::before {
  content: "\F1471"; }

.mdi-fan-speed-1::before {
  content: "\F1472"; }

.mdi-fan-speed-2::before {
  content: "\F1473"; }

.mdi-fan-speed-3::before {
  content: "\F1474"; }

.mdi-fast-forward::before {
  content: "\F0211"; }

.mdi-fast-forward-10::before {
  content: "\F0D71"; }

.mdi-fast-forward-15::before {
  content: "\F193A"; }

.mdi-fast-forward-30::before {
  content: "\F0D06"; }

.mdi-fast-forward-45::before {
  content: "\F1B12"; }

.mdi-fast-forward-5::before {
  content: "\F11F8"; }

.mdi-fast-forward-60::before {
  content: "\F160B"; }

.mdi-fast-forward-outline::before {
  content: "\F06D2"; }

.mdi-faucet::before {
  content: "\F1B29"; }

.mdi-faucet-variant::before {
  content: "\F1B2A"; }

.mdi-fax::before {
  content: "\F0212"; }

.mdi-feather::before {
  content: "\F06D3"; }

.mdi-feature-search::before {
  content: "\F0A49"; }

.mdi-feature-search-outline::before {
  content: "\F0A4A"; }

.mdi-fedora::before {
  content: "\F08DB"; }

.mdi-fence::before {
  content: "\F179A"; }

.mdi-fence-electric::before {
  content: "\F17F6"; }

.mdi-fencing::before {
  content: "\F14C1"; }

.mdi-ferris-wheel::before {
  content: "\F0EA4"; }

.mdi-ferry::before {
  content: "\F0213"; }

.mdi-file::before {
  content: "\F0214"; }

.mdi-file-account::before {
  content: "\F073B"; }

.mdi-file-account-outline::before {
  content: "\F1028"; }

.mdi-file-alert::before {
  content: "\F0A4B"; }

.mdi-file-alert-outline::before {
  content: "\F0A4C"; }

.mdi-file-arrow-left-right::before {
  content: "\F1A93"; }

.mdi-file-arrow-left-right-outline::before {
  content: "\F1A94"; }

.mdi-file-arrow-up-down::before {
  content: "\F1A95"; }

.mdi-file-arrow-up-down-outline::before {
  content: "\F1A96"; }

.mdi-file-cabinet::before {
  content: "\F0AB6"; }

.mdi-file-cad::before {
  content: "\F0EEB"; }

.mdi-file-cad-box::before {
  content: "\F0EEC"; }

.mdi-file-cancel::before {
  content: "\F0DC6"; }

.mdi-file-cancel-outline::before {
  content: "\F0DC7"; }

.mdi-file-certificate::before {
  content: "\F1186"; }

.mdi-file-certificate-outline::before {
  content: "\F1187"; }

.mdi-file-chart::before {
  content: "\F0215"; }

.mdi-file-chart-check::before {
  content: "\F19C6"; }

.mdi-file-chart-check-outline::before {
  content: "\F19C7"; }

.mdi-file-chart-outline::before {
  content: "\F1029"; }

.mdi-file-check::before {
  content: "\F0216"; }

.mdi-file-check-outline::before {
  content: "\F0E29"; }

.mdi-file-clock::before {
  content: "\F12E1"; }

.mdi-file-clock-outline::before {
  content: "\F12E2"; }

.mdi-file-cloud::before {
  content: "\F0217"; }

.mdi-file-cloud-outline::before {
  content: "\F102A"; }

.mdi-file-code::before {
  content: "\F022E"; }

.mdi-file-code-outline::before {
  content: "\F102B"; }

.mdi-file-cog::before {
  content: "\F107B"; }

.mdi-file-cog-outline::before {
  content: "\F107C"; }

.mdi-file-compare::before {
  content: "\F08AA"; }

.mdi-file-delimited::before {
  content: "\F0218"; }

.mdi-file-delimited-outline::before {
  content: "\F0EA5"; }

.mdi-file-document::before {
  content: "\F0219"; }

.mdi-file-document-alert::before {
  content: "\F1A97"; }

.mdi-file-document-alert-outline::before {
  content: "\F1A98"; }

.mdi-file-document-check::before {
  content: "\F1A99"; }

.mdi-file-document-check-outline::before {
  content: "\F1A9A"; }

.mdi-file-document-edit::before {
  content: "\F0DC8"; }

.mdi-file-document-edit-outline::before {
  content: "\F0DC9"; }

.mdi-file-document-minus::before {
  content: "\F1A9B"; }

.mdi-file-document-minus-outline::before {
  content: "\F1A9C"; }

.mdi-file-document-multiple::before {
  content: "\F1517"; }

.mdi-file-document-multiple-outline::before {
  content: "\F1518"; }

.mdi-file-document-outline::before {
  content: "\F09EE"; }

.mdi-file-document-plus::before {
  content: "\F1A9D"; }

.mdi-file-document-plus-outline::before {
  content: "\F1A9E"; }

.mdi-file-document-remove::before {
  content: "\F1A9F"; }

.mdi-file-document-remove-outline::before {
  content: "\F1AA0"; }

.mdi-file-download::before {
  content: "\F0965"; }

.mdi-file-download-outline::before {
  content: "\F0966"; }

.mdi-file-edit::before {
  content: "\F11E7"; }

.mdi-file-edit-outline::before {
  content: "\F11E8"; }

.mdi-file-excel::before {
  content: "\F021B"; }

.mdi-file-excel-box::before {
  content: "\F021C"; }

.mdi-file-excel-box-outline::before {
  content: "\F102C"; }

.mdi-file-excel-outline::before {
  content: "\F102D"; }

.mdi-file-export::before {
  content: "\F021D"; }

.mdi-file-export-outline::before {
  content: "\F102E"; }

.mdi-file-eye::before {
  content: "\F0DCA"; }

.mdi-file-eye-outline::before {
  content: "\F0DCB"; }

.mdi-file-find::before {
  content: "\F021E"; }

.mdi-file-find-outline::before {
  content: "\F0B97"; }

.mdi-file-gif-box::before {
  content: "\F0D78"; }

.mdi-file-hidden::before {
  content: "\F0613"; }

.mdi-file-image::before {
  content: "\F021F"; }

.mdi-file-image-marker::before {
  content: "\F1772"; }

.mdi-file-image-marker-outline::before {
  content: "\F1773"; }

.mdi-file-image-minus::before {
  content: "\F193B"; }

.mdi-file-image-minus-outline::before {
  content: "\F193C"; }

.mdi-file-image-outline::before {
  content: "\F0EB0"; }

.mdi-file-image-plus::before {
  content: "\F193D"; }

.mdi-file-image-plus-outline::before {
  content: "\F193E"; }

.mdi-file-image-remove::before {
  content: "\F193F"; }

.mdi-file-image-remove-outline::before {
  content: "\F1940"; }

.mdi-file-import::before {
  content: "\F0220"; }

.mdi-file-import-outline::before {
  content: "\F102F"; }

.mdi-file-jpg-box::before {
  content: "\F0225"; }

.mdi-file-key::before {
  content: "\F1184"; }

.mdi-file-key-outline::before {
  content: "\F1185"; }

.mdi-file-link::before {
  content: "\F1177"; }

.mdi-file-link-outline::before {
  content: "\F1178"; }

.mdi-file-lock::before {
  content: "\F0221"; }

.mdi-file-lock-open::before {
  content: "\F19C8"; }

.mdi-file-lock-open-outline::before {
  content: "\F19C9"; }

.mdi-file-lock-outline::before {
  content: "\F1030"; }

.mdi-file-marker::before {
  content: "\F1774"; }

.mdi-file-marker-outline::before {
  content: "\F1775"; }

.mdi-file-minus::before {
  content: "\F1AA1"; }

.mdi-file-minus-outline::before {
  content: "\F1AA2"; }

.mdi-file-move::before {
  content: "\F0AB9"; }

.mdi-file-move-outline::before {
  content: "\F1031"; }

.mdi-file-multiple::before {
  content: "\F0222"; }

.mdi-file-multiple-outline::before {
  content: "\F1032"; }

.mdi-file-music::before {
  content: "\F0223"; }

.mdi-file-music-outline::before {
  content: "\F0E2A"; }

.mdi-file-outline::before {
  content: "\F0224"; }

.mdi-file-pdf-box::before {
  content: "\F0226"; }

.mdi-file-percent::before {
  content: "\F081E"; }

.mdi-file-percent-outline::before {
  content: "\F1033"; }

.mdi-file-phone::before {
  content: "\F1179"; }

.mdi-file-phone-outline::before {
  content: "\F117A"; }

.mdi-file-plus::before {
  content: "\F0752"; }

.mdi-file-plus-outline::before {
  content: "\F0EED"; }

.mdi-file-png-box::before {
  content: "\F0E2D"; }

.mdi-file-powerpoint::before {
  content: "\F0227"; }

.mdi-file-powerpoint-box::before {
  content: "\F0228"; }

.mdi-file-powerpoint-box-outline::before {
  content: "\F1034"; }

.mdi-file-powerpoint-outline::before {
  content: "\F1035"; }

.mdi-file-presentation-box::before {
  content: "\F0229"; }

.mdi-file-question::before {
  content: "\F086F"; }

.mdi-file-question-outline::before {
  content: "\F1036"; }

.mdi-file-refresh::before {
  content: "\F0918"; }

.mdi-file-refresh-outline::before {
  content: "\F0541"; }

.mdi-file-remove::before {
  content: "\F0B98"; }

.mdi-file-remove-outline::before {
  content: "\F1037"; }

.mdi-file-replace::before {
  content: "\F0B32"; }

.mdi-file-replace-outline::before {
  content: "\F0B33"; }

.mdi-file-restore::before {
  content: "\F0670"; }

.mdi-file-restore-outline::before {
  content: "\F1038"; }

.mdi-file-rotate-left::before {
  content: "\F1A3B"; }

.mdi-file-rotate-left-outline::before {
  content: "\F1A3C"; }

.mdi-file-rotate-right::before {
  content: "\F1A3D"; }

.mdi-file-rotate-right-outline::before {
  content: "\F1A3E"; }

.mdi-file-search::before {
  content: "\F0C7C"; }

.mdi-file-search-outline::before {
  content: "\F0C7D"; }

.mdi-file-send::before {
  content: "\F022A"; }

.mdi-file-send-outline::before {
  content: "\F1039"; }

.mdi-file-settings::before {
  content: "\F1079"; }

.mdi-file-settings-outline::before {
  content: "\F107A"; }

.mdi-file-sign::before {
  content: "\F19C3"; }

.mdi-file-star::before {
  content: "\F103A"; }

.mdi-file-star-outline::before {
  content: "\F103B"; }

.mdi-file-swap::before {
  content: "\F0FB4"; }

.mdi-file-swap-outline::before {
  content: "\F0FB5"; }

.mdi-file-sync::before {
  content: "\F1216"; }

.mdi-file-sync-outline::before {
  content: "\F1217"; }

.mdi-file-table::before {
  content: "\F0C7E"; }

.mdi-file-table-box::before {
  content: "\F10E1"; }

.mdi-file-table-box-multiple::before {
  content: "\F10E2"; }

.mdi-file-table-box-multiple-outline::before {
  content: "\F10E3"; }

.mdi-file-table-box-outline::before {
  content: "\F10E4"; }

.mdi-file-table-outline::before {
  content: "\F0C7F"; }

.mdi-file-tree::before {
  content: "\F0645"; }

.mdi-file-tree-outline::before {
  content: "\F13D2"; }

.mdi-file-undo::before {
  content: "\F08DC"; }

.mdi-file-undo-outline::before {
  content: "\F103C"; }

.mdi-file-upload::before {
  content: "\F0A4D"; }

.mdi-file-upload-outline::before {
  content: "\F0A4E"; }

.mdi-file-video::before {
  content: "\F022B"; }

.mdi-file-video-outline::before {
  content: "\F0E2C"; }

.mdi-file-word::before {
  content: "\F022C"; }

.mdi-file-word-box::before {
  content: "\F022D"; }

.mdi-file-word-box-outline::before {
  content: "\F103D"; }

.mdi-file-word-outline::before {
  content: "\F103E"; }

.mdi-file-xml-box::before {
  content: "\F1B4B"; }

.mdi-film::before {
  content: "\F022F"; }

.mdi-filmstrip::before {
  content: "\F0230"; }

.mdi-filmstrip-box::before {
  content: "\F0332"; }

.mdi-filmstrip-box-multiple::before {
  content: "\F0D18"; }

.mdi-filmstrip-off::before {
  content: "\F0231"; }

.mdi-filter::before {
  content: "\F0232"; }

.mdi-filter-check::before {
  content: "\F18EC"; }

.mdi-filter-check-outline::before {
  content: "\F18ED"; }

.mdi-filter-cog::before {
  content: "\F1AA3"; }

.mdi-filter-cog-outline::before {
  content: "\F1AA4"; }

.mdi-filter-menu::before {
  content: "\F10E5"; }

.mdi-filter-menu-outline::before {
  content: "\F10E6"; }

.mdi-filter-minus::before {
  content: "\F0EEE"; }

.mdi-filter-minus-outline::before {
  content: "\F0EEF"; }

.mdi-filter-multiple::before {
  content: "\F1A3F"; }

.mdi-filter-multiple-outline::before {
  content: "\F1A40"; }

.mdi-filter-off::before {
  content: "\F14EF"; }

.mdi-filter-off-outline::before {
  content: "\F14F0"; }

.mdi-filter-outline::before {
  content: "\F0233"; }

.mdi-filter-plus::before {
  content: "\F0EF0"; }

.mdi-filter-plus-outline::before {
  content: "\F0EF1"; }

.mdi-filter-remove::before {
  content: "\F0234"; }

.mdi-filter-remove-outline::before {
  content: "\F0235"; }

.mdi-filter-settings::before {
  content: "\F1AA5"; }

.mdi-filter-settings-outline::before {
  content: "\F1AA6"; }

.mdi-filter-variant::before {
  content: "\F0236"; }

.mdi-filter-variant-minus::before {
  content: "\F1112"; }

.mdi-filter-variant-plus::before {
  content: "\F1113"; }

.mdi-filter-variant-remove::before {
  content: "\F103F"; }

.mdi-finance::before {
  content: "\F081F"; }

.mdi-find-replace::before {
  content: "\F06D4"; }

.mdi-fingerprint::before {
  content: "\F0237"; }

.mdi-fingerprint-off::before {
  content: "\F0EB1"; }

.mdi-fire::before {
  content: "\F0238"; }

.mdi-fire-alert::before {
  content: "\F15D7"; }

.mdi-fire-circle::before {
  content: "\F1807"; }

.mdi-fire-extinguisher::before {
  content: "\F0EF2"; }

.mdi-fire-hydrant::before {
  content: "\F1137"; }

.mdi-fire-hydrant-alert::before {
  content: "\F1138"; }

.mdi-fire-hydrant-off::before {
  content: "\F1139"; }

.mdi-fire-off::before {
  content: "\F1722"; }

.mdi-fire-truck::before {
  content: "\F08AB"; }

.mdi-firebase::before {
  content: "\F0967"; }

.mdi-firefox::before {
  content: "\F0239"; }

.mdi-fireplace::before {
  content: "\F0E2E"; }

.mdi-fireplace-off::before {
  content: "\F0E2F"; }

.mdi-firewire::before {
  content: "\F05BE"; }

.mdi-firework::before {
  content: "\F0E30"; }

.mdi-firework-off::before {
  content: "\F1723"; }

.mdi-fish::before {
  content: "\F023A"; }

.mdi-fish-off::before {
  content: "\F13F3"; }

.mdi-fishbowl::before {
  content: "\F0EF3"; }

.mdi-fishbowl-outline::before {
  content: "\F0EF4"; }

.mdi-fit-to-page::before {
  content: "\F0EF5"; }

.mdi-fit-to-page-outline::before {
  content: "\F0EF6"; }

.mdi-fit-to-screen::before {
  content: "\F18F4"; }

.mdi-fit-to-screen-outline::before {
  content: "\F18F5"; }

.mdi-flag::before {
  content: "\F023B"; }

.mdi-flag-checkered::before {
  content: "\F023C"; }

.mdi-flag-minus::before {
  content: "\F0B99"; }

.mdi-flag-minus-outline::before {
  content: "\F10B2"; }

.mdi-flag-off::before {
  content: "\F18EE"; }

.mdi-flag-off-outline::before {
  content: "\F18EF"; }

.mdi-flag-outline::before {
  content: "\F023D"; }

.mdi-flag-plus::before {
  content: "\F0B9A"; }

.mdi-flag-plus-outline::before {
  content: "\F10B3"; }

.mdi-flag-remove::before {
  content: "\F0B9B"; }

.mdi-flag-remove-outline::before {
  content: "\F10B4"; }

.mdi-flag-triangle::before {
  content: "\F023F"; }

.mdi-flag-variant::before {
  content: "\F0240"; }

.mdi-flag-variant-minus::before {
  content: "\F1BB4"; }

.mdi-flag-variant-minus-outline::before {
  content: "\F1BB5"; }

.mdi-flag-variant-off::before {
  content: "\F1BB0"; }

.mdi-flag-variant-off-outline::before {
  content: "\F1BB1"; }

.mdi-flag-variant-outline::before {
  content: "\F023E"; }

.mdi-flag-variant-plus::before {
  content: "\F1BB2"; }

.mdi-flag-variant-plus-outline::before {
  content: "\F1BB3"; }

.mdi-flag-variant-remove::before {
  content: "\F1BB6"; }

.mdi-flag-variant-remove-outline::before {
  content: "\F1BB7"; }

.mdi-flare::before {
  content: "\F0D72"; }

.mdi-flash::before {
  content: "\F0241"; }

.mdi-flash-alert::before {
  content: "\F0EF7"; }

.mdi-flash-alert-outline::before {
  content: "\F0EF8"; }

.mdi-flash-auto::before {
  content: "\F0242"; }

.mdi-flash-off::before {
  content: "\F0243"; }

.mdi-flash-off-outline::before {
  content: "\F1B45"; }

.mdi-flash-outline::before {
  content: "\F06D5"; }

.mdi-flash-red-eye::before {
  content: "\F067B"; }

.mdi-flash-triangle::before {
  content: "\F1B1D"; }

.mdi-flash-triangle-outline::before {
  content: "\F1B1E"; }

.mdi-flashlight::before {
  content: "\F0244"; }

.mdi-flashlight-off::before {
  content: "\F0245"; }

.mdi-flask::before {
  content: "\F0093"; }

.mdi-flask-empty::before {
  content: "\F0094"; }

.mdi-flask-empty-minus::before {
  content: "\F123A"; }

.mdi-flask-empty-minus-outline::before {
  content: "\F123B"; }

.mdi-flask-empty-off::before {
  content: "\F13F4"; }

.mdi-flask-empty-off-outline::before {
  content: "\F13F5"; }

.mdi-flask-empty-outline::before {
  content: "\F0095"; }

.mdi-flask-empty-plus::before {
  content: "\F123C"; }

.mdi-flask-empty-plus-outline::before {
  content: "\F123D"; }

.mdi-flask-empty-remove::before {
  content: "\F123E"; }

.mdi-flask-empty-remove-outline::before {
  content: "\F123F"; }

.mdi-flask-minus::before {
  content: "\F1240"; }

.mdi-flask-minus-outline::before {
  content: "\F1241"; }

.mdi-flask-off::before {
  content: "\F13F6"; }

.mdi-flask-off-outline::before {
  content: "\F13F7"; }

.mdi-flask-outline::before {
  content: "\F0096"; }

.mdi-flask-plus::before {
  content: "\F1242"; }

.mdi-flask-plus-outline::before {
  content: "\F1243"; }

.mdi-flask-remove::before {
  content: "\F1244"; }

.mdi-flask-remove-outline::before {
  content: "\F1245"; }

.mdi-flask-round-bottom::before {
  content: "\F124B"; }

.mdi-flask-round-bottom-empty::before {
  content: "\F124C"; }

.mdi-flask-round-bottom-empty-outline::before {
  content: "\F124D"; }

.mdi-flask-round-bottom-outline::before {
  content: "\F124E"; }

.mdi-fleur-de-lis::before {
  content: "\F1303"; }

.mdi-flip-horizontal::before {
  content: "\F10E7"; }

.mdi-flip-to-back::before {
  content: "\F0247"; }

.mdi-flip-to-front::before {
  content: "\F0248"; }

.mdi-flip-vertical::before {
  content: "\F10E8"; }

.mdi-floor-lamp::before {
  content: "\F08DD"; }

.mdi-floor-lamp-dual::before {
  content: "\F1040"; }

.mdi-floor-lamp-dual-outline::before {
  content: "\F17CE"; }

.mdi-floor-lamp-outline::before {
  content: "\F17C8"; }

.mdi-floor-lamp-torchiere::before {
  content: "\F1747"; }

.mdi-floor-lamp-torchiere-outline::before {
  content: "\F17D6"; }

.mdi-floor-lamp-torchiere-variant::before {
  content: "\F1041"; }

.mdi-floor-lamp-torchiere-variant-outline::before {
  content: "\F17CF"; }

.mdi-floor-plan::before {
  content: "\F0821"; }

.mdi-floppy::before {
  content: "\F0249"; }

.mdi-floppy-variant::before {
  content: "\F09EF"; }

.mdi-flower::before {
  content: "\F024A"; }

.mdi-flower-outline::before {
  content: "\F09F0"; }

.mdi-flower-pollen::before {
  content: "\F1885"; }

.mdi-flower-pollen-outline::before {
  content: "\F1886"; }

.mdi-flower-poppy::before {
  content: "\F0D08"; }

.mdi-flower-tulip::before {
  content: "\F09F1"; }

.mdi-flower-tulip-outline::before {
  content: "\F09F2"; }

.mdi-focus-auto::before {
  content: "\F0F4E"; }

.mdi-focus-field::before {
  content: "\F0F4F"; }

.mdi-focus-field-horizontal::before {
  content: "\F0F50"; }

.mdi-focus-field-vertical::before {
  content: "\F0F51"; }

.mdi-folder::before {
  content: "\F024B"; }

.mdi-folder-account::before {
  content: "\F024C"; }

.mdi-folder-account-outline::before {
  content: "\F0B9C"; }

.mdi-folder-alert::before {
  content: "\F0DCC"; }

.mdi-folder-alert-outline::before {
  content: "\F0DCD"; }

.mdi-folder-arrow-down::before {
  content: "\F19E8"; }

.mdi-folder-arrow-down-outline::before {
  content: "\F19E9"; }

.mdi-folder-arrow-left::before {
  content: "\F19EA"; }

.mdi-folder-arrow-left-outline::before {
  content: "\F19EB"; }

.mdi-folder-arrow-left-right::before {
  content: "\F19EC"; }

.mdi-folder-arrow-left-right-outline::before {
  content: "\F19ED"; }

.mdi-folder-arrow-right::before {
  content: "\F19EE"; }

.mdi-folder-arrow-right-outline::before {
  content: "\F19EF"; }

.mdi-folder-arrow-up::before {
  content: "\F19F0"; }

.mdi-folder-arrow-up-down::before {
  content: "\F19F1"; }

.mdi-folder-arrow-up-down-outline::before {
  content: "\F19F2"; }

.mdi-folder-arrow-up-outline::before {
  content: "\F19F3"; }

.mdi-folder-cancel::before {
  content: "\F19F4"; }

.mdi-folder-cancel-outline::before {
  content: "\F19F5"; }

.mdi-folder-check::before {
  content: "\F197E"; }

.mdi-folder-check-outline::before {
  content: "\F197F"; }

.mdi-folder-clock::before {
  content: "\F0ABA"; }

.mdi-folder-clock-outline::before {
  content: "\F0ABB"; }

.mdi-folder-cog::before {
  content: "\F107F"; }

.mdi-folder-cog-outline::before {
  content: "\F1080"; }

.mdi-folder-download::before {
  content: "\F024D"; }

.mdi-folder-download-outline::before {
  content: "\F10E9"; }

.mdi-folder-edit::before {
  content: "\F08DE"; }

.mdi-folder-edit-outline::before {
  content: "\F0DCE"; }

.mdi-folder-eye::before {
  content: "\F178A"; }

.mdi-folder-eye-outline::before {
  content: "\F178B"; }

.mdi-folder-file::before {
  content: "\F19F6"; }

.mdi-folder-file-outline::before {
  content: "\F19F7"; }

.mdi-folder-google-drive::before {
  content: "\F024E"; }

.mdi-folder-heart::before {
  content: "\F10EA"; }

.mdi-folder-heart-outline::before {
  content: "\F10EB"; }

.mdi-folder-hidden::before {
  content: "\F179E"; }

.mdi-folder-home::before {
  content: "\F10B5"; }

.mdi-folder-home-outline::before {
  content: "\F10B6"; }

.mdi-folder-image::before {
  content: "\F024F"; }

.mdi-folder-information::before {
  content: "\F10B7"; }

.mdi-folder-information-outline::before {
  content: "\F10B8"; }

.mdi-folder-key::before {
  content: "\F08AC"; }

.mdi-folder-key-network::before {
  content: "\F08AD"; }

.mdi-folder-key-network-outline::before {
  content: "\F0C80"; }

.mdi-folder-key-outline::before {
  content: "\F10EC"; }

.mdi-folder-lock::before {
  content: "\F0250"; }

.mdi-folder-lock-open::before {
  content: "\F0251"; }

.mdi-folder-lock-open-outline::before {
  content: "\F1AA7"; }

.mdi-folder-lock-outline::before {
  content: "\F1AA8"; }

.mdi-folder-marker::before {
  content: "\F126D"; }

.mdi-folder-marker-outline::before {
  content: "\F126E"; }

.mdi-folder-minus::before {
  content: "\F1B49"; }

.mdi-folder-minus-outline::before {
  content: "\F1B4A"; }

.mdi-folder-move::before {
  content: "\F0252"; }

.mdi-folder-move-outline::before {
  content: "\F1246"; }

.mdi-folder-multiple::before {
  content: "\F0253"; }

.mdi-folder-multiple-image::before {
  content: "\F0254"; }

.mdi-folder-multiple-outline::before {
  content: "\F0255"; }

.mdi-folder-multiple-plus::before {
  content: "\F147E"; }

.mdi-folder-multiple-plus-outline::before {
  content: "\F147F"; }

.mdi-folder-music::before {
  content: "\F1359"; }

.mdi-folder-music-outline::before {
  content: "\F135A"; }

.mdi-folder-network::before {
  content: "\F0870"; }

.mdi-folder-network-outline::before {
  content: "\F0C81"; }

.mdi-folder-off::before {
  content: "\F19F8"; }

.mdi-folder-off-outline::before {
  content: "\F19F9"; }

.mdi-folder-open::before {
  content: "\F0770"; }

.mdi-folder-open-outline::before {
  content: "\F0DCF"; }

.mdi-folder-outline::before {
  content: "\F0256"; }

.mdi-folder-play::before {
  content: "\F19FA"; }

.mdi-folder-play-outline::before {
  content: "\F19FB"; }

.mdi-folder-plus::before {
  content: "\F0257"; }

.mdi-folder-plus-outline::before {
  content: "\F0B9D"; }

.mdi-folder-pound::before {
  content: "\F0D09"; }

.mdi-folder-pound-outline::before {
  content: "\F0D0A"; }

.mdi-folder-question::before {
  content: "\F19CA"; }

.mdi-folder-question-outline::before {
  content: "\F19CB"; }

.mdi-folder-refresh::before {
  content: "\F0749"; }

.mdi-folder-refresh-outline::before {
  content: "\F0542"; }

.mdi-folder-remove::before {
  content: "\F0258"; }

.mdi-folder-remove-outline::before {
  content: "\F0B9E"; }

.mdi-folder-search::before {
  content: "\F0968"; }

.mdi-folder-search-outline::before {
  content: "\F0969"; }

.mdi-folder-settings::before {
  content: "\F107D"; }

.mdi-folder-settings-outline::before {
  content: "\F107E"; }

.mdi-folder-star::before {
  content: "\F069D"; }

.mdi-folder-star-multiple::before {
  content: "\F13D3"; }

.mdi-folder-star-multiple-outline::before {
  content: "\F13D4"; }

.mdi-folder-star-outline::before {
  content: "\F0B9F"; }

.mdi-folder-swap::before {
  content: "\F0FB6"; }

.mdi-folder-swap-outline::before {
  content: "\F0FB7"; }

.mdi-folder-sync::before {
  content: "\F0D0B"; }

.mdi-folder-sync-outline::before {
  content: "\F0D0C"; }

.mdi-folder-table::before {
  content: "\F12E3"; }

.mdi-folder-table-outline::before {
  content: "\F12E4"; }

.mdi-folder-text::before {
  content: "\F0C82"; }

.mdi-folder-text-outline::before {
  content: "\F0C83"; }

.mdi-folder-upload::before {
  content: "\F0259"; }

.mdi-folder-upload-outline::before {
  content: "\F10ED"; }

.mdi-folder-wrench::before {
  content: "\F19FC"; }

.mdi-folder-wrench-outline::before {
  content: "\F19FD"; }

.mdi-folder-zip::before {
  content: "\F06EB"; }

.mdi-folder-zip-outline::before {
  content: "\F07B9"; }

.mdi-font-awesome::before {
  content: "\F003A"; }

.mdi-food::before {
  content: "\F025A"; }

.mdi-food-apple::before {
  content: "\F025B"; }

.mdi-food-apple-outline::before {
  content: "\F0C84"; }

.mdi-food-croissant::before {
  content: "\F07C8"; }

.mdi-food-drumstick::before {
  content: "\F141F"; }

.mdi-food-drumstick-off::before {
  content: "\F1468"; }

.mdi-food-drumstick-off-outline::before {
  content: "\F1469"; }

.mdi-food-drumstick-outline::before {
  content: "\F1420"; }

.mdi-food-fork-drink::before {
  content: "\F05F2"; }

.mdi-food-halal::before {
  content: "\F1572"; }

.mdi-food-hot-dog::before {
  content: "\F184B"; }

.mdi-food-kosher::before {
  content: "\F1573"; }

.mdi-food-off::before {
  content: "\F05F3"; }

.mdi-food-off-outline::before {
  content: "\F1915"; }

.mdi-food-outline::before {
  content: "\F1916"; }

.mdi-food-steak::before {
  content: "\F146A"; }

.mdi-food-steak-off::before {
  content: "\F146B"; }

.mdi-food-takeout-box::before {
  content: "\F1836"; }

.mdi-food-takeout-box-outline::before {
  content: "\F1837"; }

.mdi-food-turkey::before {
  content: "\F171C"; }

.mdi-food-variant::before {
  content: "\F025C"; }

.mdi-food-variant-off::before {
  content: "\F13E5"; }

.mdi-foot-print::before {
  content: "\F0F52"; }

.mdi-football::before {
  content: "\F025D"; }

.mdi-football-australian::before {
  content: "\F025E"; }

.mdi-football-helmet::before {
  content: "\F025F"; }

.mdi-forest::before {
  content: "\F1897"; }

.mdi-forklift::before {
  content: "\F07C9"; }

.mdi-form-dropdown::before {
  content: "\F1400"; }

.mdi-form-select::before {
  content: "\F1401"; }

.mdi-form-textarea::before {
  content: "\F1095"; }

.mdi-form-textbox::before {
  content: "\F060E"; }

.mdi-form-textbox-lock::before {
  content: "\F135D"; }

.mdi-form-textbox-password::before {
  content: "\F07F5"; }

.mdi-format-align-bottom::before {
  content: "\F0753"; }

.mdi-format-align-center::before {
  content: "\F0260"; }

.mdi-format-align-justify::before {
  content: "\F0261"; }

.mdi-format-align-left::before {
  content: "\F0262"; }

.mdi-format-align-middle::before {
  content: "\F0754"; }

.mdi-format-align-right::before {
  content: "\F0263"; }

.mdi-format-align-top::before {
  content: "\F0755"; }

.mdi-format-annotation-minus::before {
  content: "\F0ABC"; }

.mdi-format-annotation-plus::before {
  content: "\F0646"; }

.mdi-format-bold::before {
  content: "\F0264"; }

.mdi-format-clear::before {
  content: "\F0265"; }

.mdi-format-color-fill::before {
  content: "\F0266"; }

.mdi-format-color-highlight::before {
  content: "\F0E31"; }

.mdi-format-color-marker-cancel::before {
  content: "\F1313"; }

.mdi-format-color-text::before {
  content: "\F069E"; }

.mdi-format-columns::before {
  content: "\F08DF"; }

.mdi-format-float-center::before {
  content: "\F0267"; }

.mdi-format-float-left::before {
  content: "\F0268"; }

.mdi-format-float-none::before {
  content: "\F0269"; }

.mdi-format-float-right::before {
  content: "\F026A"; }

.mdi-format-font::before {
  content: "\F06D6"; }

.mdi-format-font-size-decrease::before {
  content: "\F09F3"; }

.mdi-format-font-size-increase::before {
  content: "\F09F4"; }

.mdi-format-header-1::before {
  content: "\F026B"; }

.mdi-format-header-2::before {
  content: "\F026C"; }

.mdi-format-header-3::before {
  content: "\F026D"; }

.mdi-format-header-4::before {
  content: "\F026E"; }

.mdi-format-header-5::before {
  content: "\F026F"; }

.mdi-format-header-6::before {
  content: "\F0270"; }

.mdi-format-header-decrease::before {
  content: "\F0271"; }

.mdi-format-header-equal::before {
  content: "\F0272"; }

.mdi-format-header-increase::before {
  content: "\F0273"; }

.mdi-format-header-pound::before {
  content: "\F0274"; }

.mdi-format-horizontal-align-center::before {
  content: "\F061E"; }

.mdi-format-horizontal-align-left::before {
  content: "\F061F"; }

.mdi-format-horizontal-align-right::before {
  content: "\F0620"; }

.mdi-format-indent-decrease::before {
  content: "\F0275"; }

.mdi-format-indent-increase::before {
  content: "\F0276"; }

.mdi-format-italic::before {
  content: "\F0277"; }

.mdi-format-letter-case::before {
  content: "\F0B34"; }

.mdi-format-letter-case-lower::before {
  content: "\F0B35"; }

.mdi-format-letter-case-upper::before {
  content: "\F0B36"; }

.mdi-format-letter-ends-with::before {
  content: "\F0FB8"; }

.mdi-format-letter-matches::before {
  content: "\F0FB9"; }

.mdi-format-letter-spacing::before {
  content: "\F1956"; }

.mdi-format-letter-spacing-variant::before {
  content: "\F1AFB"; }

.mdi-format-letter-starts-with::before {
  content: "\F0FBA"; }

.mdi-format-line-height::before {
  content: "\F1AFC"; }

.mdi-format-line-spacing::before {
  content: "\F0278"; }

.mdi-format-line-style::before {
  content: "\F05C8"; }

.mdi-format-line-weight::before {
  content: "\F05C9"; }

.mdi-format-list-bulleted::before {
  content: "\F0279"; }

.mdi-format-list-bulleted-square::before {
  content: "\F0DD0"; }

.mdi-format-list-bulleted-triangle::before {
  content: "\F0EB2"; }

.mdi-format-list-bulleted-type::before {
  content: "\F027A"; }

.mdi-format-list-checkbox::before {
  content: "\F096A"; }

.mdi-format-list-checks::before {
  content: "\F0756"; }

.mdi-format-list-group::before {
  content: "\F1860"; }

.mdi-format-list-group-plus::before {
  content: "\F1B56"; }

.mdi-format-list-numbered::before {
  content: "\F027B"; }

.mdi-format-list-numbered-rtl::before {
  content: "\F0D0D"; }

.mdi-format-list-text::before {
  content: "\F126F"; }

.mdi-format-overline::before {
  content: "\F0EB3"; }

.mdi-format-page-break::before {
  content: "\F06D7"; }

.mdi-format-page-split::before {
  content: "\F1917"; }

.mdi-format-paint::before {
  content: "\F027C"; }

.mdi-format-paragraph::before {
  content: "\F027D"; }

.mdi-format-paragraph-spacing::before {
  content: "\F1AFD"; }

.mdi-format-pilcrow::before {
  content: "\F06D8"; }

.mdi-format-pilcrow-arrow-left::before {
  content: "\F0286"; }

.mdi-format-pilcrow-arrow-right::before {
  content: "\F0285"; }

.mdi-format-quote-close::before {
  content: "\F027E"; }

.mdi-format-quote-close-outline::before {
  content: "\F11A8"; }

.mdi-format-quote-open::before {
  content: "\F0757"; }

.mdi-format-quote-open-outline::before {
  content: "\F11A7"; }

.mdi-format-rotate-90::before {
  content: "\F06AA"; }

.mdi-format-section::before {
  content: "\F069F"; }

.mdi-format-size::before {
  content: "\F027F"; }

.mdi-format-strikethrough::before {
  content: "\F0280"; }

.mdi-format-strikethrough-variant::before {
  content: "\F0281"; }

.mdi-format-subscript::before {
  content: "\F0282"; }

.mdi-format-superscript::before {
  content: "\F0283"; }

.mdi-format-text::before {
  content: "\F0284"; }

.mdi-format-text-rotation-angle-down::before {
  content: "\F0FBB"; }

.mdi-format-text-rotation-angle-up::before {
  content: "\F0FBC"; }

.mdi-format-text-rotation-down::before {
  content: "\F0D73"; }

.mdi-format-text-rotation-down-vertical::before {
  content: "\F0FBD"; }

.mdi-format-text-rotation-none::before {
  content: "\F0D74"; }

.mdi-format-text-rotation-up::before {
  content: "\F0FBE"; }

.mdi-format-text-rotation-vertical::before {
  content: "\F0FBF"; }

.mdi-format-text-variant::before {
  content: "\F0E32"; }

.mdi-format-text-variant-outline::before {
  content: "\F150F"; }

.mdi-format-text-wrapping-clip::before {
  content: "\F0D0E"; }

.mdi-format-text-wrapping-overflow::before {
  content: "\F0D0F"; }

.mdi-format-text-wrapping-wrap::before {
  content: "\F0D10"; }

.mdi-format-textbox::before {
  content: "\F0D11"; }

.mdi-format-title::before {
  content: "\F05F4"; }

.mdi-format-underline::before {
  content: "\F0287"; }

.mdi-format-underline-wavy::before {
  content: "\F18E9"; }

.mdi-format-vertical-align-bottom::before {
  content: "\F0621"; }

.mdi-format-vertical-align-center::before {
  content: "\F0622"; }

.mdi-format-vertical-align-top::before {
  content: "\F0623"; }

.mdi-format-wrap-inline::before {
  content: "\F0288"; }

.mdi-format-wrap-square::before {
  content: "\F0289"; }

.mdi-format-wrap-tight::before {
  content: "\F028A"; }

.mdi-format-wrap-top-bottom::before {
  content: "\F028B"; }

.mdi-forum::before {
  content: "\F028C"; }

.mdi-forum-minus::before {
  content: "\F1AA9"; }

.mdi-forum-minus-outline::before {
  content: "\F1AAA"; }

.mdi-forum-outline::before {
  content: "\F0822"; }

.mdi-forum-plus::before {
  content: "\F1AAB"; }

.mdi-forum-plus-outline::before {
  content: "\F1AAC"; }

.mdi-forum-remove::before {
  content: "\F1AAD"; }

.mdi-forum-remove-outline::before {
  content: "\F1AAE"; }

.mdi-forward::before {
  content: "\F028D"; }

.mdi-forwardburger::before {
  content: "\F0D75"; }

.mdi-fountain::before {
  content: "\F096B"; }

.mdi-fountain-pen::before {
  content: "\F0D12"; }

.mdi-fountain-pen-tip::before {
  content: "\F0D13"; }

.mdi-fraction-one-half::before {
  content: "\F1992"; }

.mdi-freebsd::before {
  content: "\F08E0"; }

.mdi-french-fries::before {
  content: "\F1957"; }

.mdi-frequently-asked-questions::before {
  content: "\F0EB4"; }

.mdi-fridge::before {
  content: "\F0290"; }

.mdi-fridge-alert::before {
  content: "\F11B1"; }

.mdi-fridge-alert-outline::before {
  content: "\F11B2"; }

.mdi-fridge-bottom::before {
  content: "\F0292"; }

.mdi-fridge-industrial::before {
  content: "\F15EE"; }

.mdi-fridge-industrial-alert::before {
  content: "\F15EF"; }

.mdi-fridge-industrial-alert-outline::before {
  content: "\F15F0"; }

.mdi-fridge-industrial-off::before {
  content: "\F15F1"; }

.mdi-fridge-industrial-off-outline::before {
  content: "\F15F2"; }

.mdi-fridge-industrial-outline::before {
  content: "\F15F3"; }

.mdi-fridge-off::before {
  content: "\F11AF"; }

.mdi-fridge-off-outline::before {
  content: "\F11B0"; }

.mdi-fridge-outline::before {
  content: "\F028F"; }

.mdi-fridge-top::before {
  content: "\F0291"; }

.mdi-fridge-variant::before {
  content: "\F15F4"; }

.mdi-fridge-variant-alert::before {
  content: "\F15F5"; }

.mdi-fridge-variant-alert-outline::before {
  content: "\F15F6"; }

.mdi-fridge-variant-off::before {
  content: "\F15F7"; }

.mdi-fridge-variant-off-outline::before {
  content: "\F15F8"; }

.mdi-fridge-variant-outline::before {
  content: "\F15F9"; }

.mdi-fruit-cherries::before {
  content: "\F1042"; }

.mdi-fruit-cherries-off::before {
  content: "\F13F8"; }

.mdi-fruit-citrus::before {
  content: "\F1043"; }

.mdi-fruit-citrus-off::before {
  content: "\F13F9"; }

.mdi-fruit-grapes::before {
  content: "\F1044"; }

.mdi-fruit-grapes-outline::before {
  content: "\F1045"; }

.mdi-fruit-pear::before {
  content: "\F1A0E"; }

.mdi-fruit-pineapple::before {
  content: "\F1046"; }

.mdi-fruit-watermelon::before {
  content: "\F1047"; }

.mdi-fuel::before {
  content: "\F07CA"; }

.mdi-fuel-cell::before {
  content: "\F18B5"; }

.mdi-fullscreen::before {
  content: "\F0293"; }

.mdi-fullscreen-exit::before {
  content: "\F0294"; }

.mdi-function::before {
  content: "\F0295"; }

.mdi-function-variant::before {
  content: "\F0871"; }

.mdi-furigana-horizontal::before {
  content: "\F1081"; }

.mdi-furigana-vertical::before {
  content: "\F1082"; }

.mdi-fuse::before {
  content: "\F0C85"; }

.mdi-fuse-alert::before {
  content: "\F142D"; }

.mdi-fuse-blade::before {
  content: "\F0C86"; }

.mdi-fuse-off::before {
  content: "\F142C"; }

.mdi-gamepad::before {
  content: "\F0296"; }

.mdi-gamepad-circle::before {
  content: "\F0E33"; }

.mdi-gamepad-circle-down::before {
  content: "\F0E34"; }

.mdi-gamepad-circle-left::before {
  content: "\F0E35"; }

.mdi-gamepad-circle-outline::before {
  content: "\F0E36"; }

.mdi-gamepad-circle-right::before {
  content: "\F0E37"; }

.mdi-gamepad-circle-up::before {
  content: "\F0E38"; }

.mdi-gamepad-down::before {
  content: "\F0E39"; }

.mdi-gamepad-left::before {
  content: "\F0E3A"; }

.mdi-gamepad-outline::before {
  content: "\F1919"; }

.mdi-gamepad-right::before {
  content: "\F0E3B"; }

.mdi-gamepad-round::before {
  content: "\F0E3C"; }

.mdi-gamepad-round-down::before {
  content: "\F0E3D"; }

.mdi-gamepad-round-left::before {
  content: "\F0E3E"; }

.mdi-gamepad-round-outline::before {
  content: "\F0E3F"; }

.mdi-gamepad-round-right::before {
  content: "\F0E40"; }

.mdi-gamepad-round-up::before {
  content: "\F0E41"; }

.mdi-gamepad-square::before {
  content: "\F0EB5"; }

.mdi-gamepad-square-outline::before {
  content: "\F0EB6"; }

.mdi-gamepad-up::before {
  content: "\F0E42"; }

.mdi-gamepad-variant::before {
  content: "\F0297"; }

.mdi-gamepad-variant-outline::before {
  content: "\F0EB7"; }

.mdi-gamma::before {
  content: "\F10EE"; }

.mdi-gantry-crane::before {
  content: "\F0DD1"; }

.mdi-garage::before {
  content: "\F06D9"; }

.mdi-garage-alert::before {
  content: "\F0872"; }

.mdi-garage-alert-variant::before {
  content: "\F12D5"; }

.mdi-garage-lock::before {
  content: "\F17FB"; }

.mdi-garage-open::before {
  content: "\F06DA"; }

.mdi-garage-open-variant::before {
  content: "\F12D4"; }

.mdi-garage-variant::before {
  content: "\F12D3"; }

.mdi-garage-variant-lock::before {
  content: "\F17FC"; }

.mdi-gas-burner::before {
  content: "\F1A1B"; }

.mdi-gas-cylinder::before {
  content: "\F0647"; }

.mdi-gas-station::before {
  content: "\F0298"; }

.mdi-gas-station-off::before {
  content: "\F1409"; }

.mdi-gas-station-off-outline::before {
  content: "\F140A"; }

.mdi-gas-station-outline::before {
  content: "\F0EB8"; }

.mdi-gate::before {
  content: "\F0299"; }

.mdi-gate-alert::before {
  content: "\F17F8"; }

.mdi-gate-and::before {
  content: "\F08E1"; }

.mdi-gate-arrow-left::before {
  content: "\F17F7"; }

.mdi-gate-arrow-right::before {
  content: "\F1169"; }

.mdi-gate-buffer::before {
  content: "\F1AFE"; }

.mdi-gate-nand::before {
  content: "\F08E2"; }

.mdi-gate-nor::before {
  content: "\F08E3"; }

.mdi-gate-not::before {
  content: "\F08E4"; }

.mdi-gate-open::before {
  content: "\F116A"; }

.mdi-gate-or::before {
  content: "\F08E5"; }

.mdi-gate-xnor::before {
  content: "\F08E6"; }

.mdi-gate-xor::before {
  content: "\F08E7"; }

.mdi-gatsby::before {
  content: "\F0E43"; }

.mdi-gauge::before {
  content: "\F029A"; }

.mdi-gauge-empty::before {
  content: "\F0873"; }

.mdi-gauge-full::before {
  content: "\F0874"; }

.mdi-gauge-low::before {
  content: "\F0875"; }

.mdi-gavel::before {
  content: "\F029B"; }

.mdi-gender-female::before {
  content: "\F029C"; }

.mdi-gender-male::before {
  content: "\F029D"; }

.mdi-gender-male-female::before {
  content: "\F029E"; }

.mdi-gender-male-female-variant::before {
  content: "\F113F"; }

.mdi-gender-non-binary::before {
  content: "\F1140"; }

.mdi-gender-transgender::before {
  content: "\F029F"; }

.mdi-gentoo::before {
  content: "\F08E8"; }

.mdi-gesture::before {
  content: "\F07CB"; }

.mdi-gesture-double-tap::before {
  content: "\F073C"; }

.mdi-gesture-pinch::before {
  content: "\F0ABD"; }

.mdi-gesture-spread::before {
  content: "\F0ABE"; }

.mdi-gesture-swipe::before {
  content: "\F0D76"; }

.mdi-gesture-swipe-down::before {
  content: "\F073D"; }

.mdi-gesture-swipe-horizontal::before {
  content: "\F0ABF"; }

.mdi-gesture-swipe-left::before {
  content: "\F073E"; }

.mdi-gesture-swipe-right::before {
  content: "\F073F"; }

.mdi-gesture-swipe-up::before {
  content: "\F0740"; }

.mdi-gesture-swipe-vertical::before {
  content: "\F0AC0"; }

.mdi-gesture-tap::before {
  content: "\F0741"; }

.mdi-gesture-tap-box::before {
  content: "\F12A9"; }

.mdi-gesture-tap-button::before {
  content: "\F12A8"; }

.mdi-gesture-tap-hold::before {
  content: "\F0D77"; }

.mdi-gesture-two-double-tap::before {
  content: "\F0742"; }

.mdi-gesture-two-tap::before {
  content: "\F0743"; }

.mdi-ghost::before {
  content: "\F02A0"; }

.mdi-ghost-off::before {
  content: "\F09F5"; }

.mdi-ghost-off-outline::before {
  content: "\F165C"; }

.mdi-ghost-outline::before {
  content: "\F165D"; }

.mdi-gift::before {
  content: "\F0E44"; }

.mdi-gift-off::before {
  content: "\F16EF"; }

.mdi-gift-off-outline::before {
  content: "\F16F0"; }

.mdi-gift-open::before {
  content: "\F16F1"; }

.mdi-gift-open-outline::before {
  content: "\F16F2"; }

.mdi-gift-outline::before {
  content: "\F02A1"; }

.mdi-git::before {
  content: "\F02A2"; }

.mdi-github::before {
  content: "\F02A4"; }

.mdi-gitlab::before {
  content: "\F0BA0"; }

.mdi-glass-cocktail::before {
  content: "\F0356"; }

.mdi-glass-cocktail-off::before {
  content: "\F15E6"; }

.mdi-glass-flute::before {
  content: "\F02A5"; }

.mdi-glass-fragile::before {
  content: "\F1873"; }

.mdi-glass-mug::before {
  content: "\F02A6"; }

.mdi-glass-mug-off::before {
  content: "\F15E7"; }

.mdi-glass-mug-variant::before {
  content: "\F1116"; }

.mdi-glass-mug-variant-off::before {
  content: "\F15E8"; }

.mdi-glass-pint-outline::before {
  content: "\F130D"; }

.mdi-glass-stange::before {
  content: "\F02A7"; }

.mdi-glass-tulip::before {
  content: "\F02A8"; }

.mdi-glass-wine::before {
  content: "\F0876"; }

.mdi-glasses::before {
  content: "\F02AA"; }

.mdi-globe-light::before {
  content: "\F066F"; }

.mdi-globe-light-outline::before {
  content: "\F12D7"; }

.mdi-globe-model::before {
  content: "\F08E9"; }

.mdi-gmail::before {
  content: "\F02AB"; }

.mdi-gnome::before {
  content: "\F02AC"; }

.mdi-go-kart::before {
  content: "\F0D79"; }

.mdi-go-kart-track::before {
  content: "\F0D7A"; }

.mdi-gog::before {
  content: "\F0BA1"; }

.mdi-gold::before {
  content: "\F124F"; }

.mdi-golf::before {
  content: "\F0823"; }

.mdi-golf-cart::before {
  content: "\F11A4"; }

.mdi-golf-tee::before {
  content: "\F1083"; }

.mdi-gondola::before {
  content: "\F0686"; }

.mdi-goodreads::before {
  content: "\F0D7B"; }

.mdi-google::before {
  content: "\F02AD"; }

.mdi-google-ads::before {
  content: "\F0C87"; }

.mdi-google-analytics::before {
  content: "\F07CC"; }

.mdi-google-assistant::before {
  content: "\F07CD"; }

.mdi-google-cardboard::before {
  content: "\F02AE"; }

.mdi-google-chrome::before {
  content: "\F02AF"; }

.mdi-google-circles::before {
  content: "\F02B0"; }

.mdi-google-circles-communities::before {
  content: "\F02B1"; }

.mdi-google-circles-extended::before {
  content: "\F02B2"; }

.mdi-google-circles-group::before {
  content: "\F02B3"; }

.mdi-google-classroom::before {
  content: "\F02C0"; }

.mdi-google-cloud::before {
  content: "\F11F6"; }

.mdi-google-downasaur::before {
  content: "\F1362"; }

.mdi-google-drive::before {
  content: "\F02B6"; }

.mdi-google-earth::before {
  content: "\F02B7"; }

.mdi-google-fit::before {
  content: "\F096C"; }

.mdi-google-glass::before {
  content: "\F02B8"; }

.mdi-google-hangouts::before {
  content: "\F02C9"; }

.mdi-google-keep::before {
  content: "\F06DC"; }

.mdi-google-lens::before {
  content: "\F09F6"; }

.mdi-google-maps::before {
  content: "\F05F5"; }

.mdi-google-my-business::before {
  content: "\F1048"; }

.mdi-google-nearby::before {
  content: "\F02B9"; }

.mdi-google-play::before {
  content: "\F02BC"; }

.mdi-google-plus::before {
  content: "\F02BD"; }

.mdi-google-podcast::before {
  content: "\F0EB9"; }

.mdi-google-spreadsheet::before {
  content: "\F09F7"; }

.mdi-google-street-view::before {
  content: "\F0C88"; }

.mdi-google-translate::before {
  content: "\F02BF"; }

.mdi-gradient-horizontal::before {
  content: "\F174A"; }

.mdi-gradient-vertical::before {
  content: "\F06A0"; }

.mdi-grain::before {
  content: "\F0D7C"; }

.mdi-graph::before {
  content: "\F1049"; }

.mdi-graph-outline::before {
  content: "\F104A"; }

.mdi-graphql::before {
  content: "\F0877"; }

.mdi-grass::before {
  content: "\F1510"; }

.mdi-grave-stone::before {
  content: "\F0BA2"; }

.mdi-grease-pencil::before {
  content: "\F0648"; }

.mdi-greater-than::before {
  content: "\F096D"; }

.mdi-greater-than-or-equal::before {
  content: "\F096E"; }

.mdi-greenhouse::before {
  content: "\F002D"; }

.mdi-grid::before {
  content: "\F02C1"; }

.mdi-grid-large::before {
  content: "\F0758"; }

.mdi-grid-off::before {
  content: "\F02C2"; }

.mdi-grill::before {
  content: "\F0E45"; }

.mdi-grill-outline::before {
  content: "\F118A"; }

.mdi-group::before {
  content: "\F02C3"; }

.mdi-guitar-acoustic::before {
  content: "\F0771"; }

.mdi-guitar-electric::before {
  content: "\F02C4"; }

.mdi-guitar-pick::before {
  content: "\F02C5"; }

.mdi-guitar-pick-outline::before {
  content: "\F02C6"; }

.mdi-guy-fawkes-mask::before {
  content: "\F0825"; }

.mdi-gymnastics::before {
  content: "\F1A41"; }

.mdi-hail::before {
  content: "\F0AC1"; }

.mdi-hair-dryer::before {
  content: "\F10EF"; }

.mdi-hair-dryer-outline::before {
  content: "\F10F0"; }

.mdi-halloween::before {
  content: "\F0BA3"; }

.mdi-hamburger::before {
  content: "\F0685"; }

.mdi-hamburger-check::before {
  content: "\F1776"; }

.mdi-hamburger-minus::before {
  content: "\F1777"; }

.mdi-hamburger-off::before {
  content: "\F1778"; }

.mdi-hamburger-plus::before {
  content: "\F1779"; }

.mdi-hamburger-remove::before {
  content: "\F177A"; }

.mdi-hammer::before {
  content: "\F08EA"; }

.mdi-hammer-screwdriver::before {
  content: "\F1322"; }

.mdi-hammer-sickle::before {
  content: "\F1887"; }

.mdi-hammer-wrench::before {
  content: "\F1323"; }

.mdi-hand-back-left::before {
  content: "\F0E46"; }

.mdi-hand-back-left-off::before {
  content: "\F1830"; }

.mdi-hand-back-left-off-outline::before {
  content: "\F1832"; }

.mdi-hand-back-left-outline::before {
  content: "\F182C"; }

.mdi-hand-back-right::before {
  content: "\F0E47"; }

.mdi-hand-back-right-off::before {
  content: "\F1831"; }

.mdi-hand-back-right-off-outline::before {
  content: "\F1833"; }

.mdi-hand-back-right-outline::before {
  content: "\F182D"; }

.mdi-hand-clap::before {
  content: "\F194B"; }

.mdi-hand-clap-off::before {
  content: "\F1A42"; }

.mdi-hand-coin::before {
  content: "\F188F"; }

.mdi-hand-coin-outline::before {
  content: "\F1890"; }

.mdi-hand-cycle::before {
  content: "\F1B9C"; }

.mdi-hand-extended::before {
  content: "\F18B6"; }

.mdi-hand-extended-outline::before {
  content: "\F18B7"; }

.mdi-hand-front-left::before {
  content: "\F182B"; }

.mdi-hand-front-left-outline::before {
  content: "\F182E"; }

.mdi-hand-front-right::before {
  content: "\F0A4F"; }

.mdi-hand-front-right-outline::before {
  content: "\F182F"; }

.mdi-hand-heart::before {
  content: "\F10F1"; }

.mdi-hand-heart-outline::before {
  content: "\F157E"; }

.mdi-hand-okay::before {
  content: "\F0A50"; }

.mdi-hand-peace::before {
  content: "\F0A51"; }

.mdi-hand-peace-variant::before {
  content: "\F0A52"; }

.mdi-hand-pointing-down::before {
  content: "\F0A53"; }

.mdi-hand-pointing-left::before {
  content: "\F0A54"; }

.mdi-hand-pointing-right::before {
  content: "\F02C7"; }

.mdi-hand-pointing-up::before {
  content: "\F0A55"; }

.mdi-hand-saw::before {
  content: "\F0E48"; }

.mdi-hand-wash::before {
  content: "\F157F"; }

.mdi-hand-wash-outline::before {
  content: "\F1580"; }

.mdi-hand-water::before {
  content: "\F139F"; }

.mdi-hand-wave::before {
  content: "\F1821"; }

.mdi-hand-wave-outline::before {
  content: "\F1822"; }

.mdi-handball::before {
  content: "\F0F53"; }

.mdi-handcuffs::before {
  content: "\F113E"; }

.mdi-hands-pray::before {
  content: "\F0579"; }

.mdi-handshake::before {
  content: "\F1218"; }

.mdi-handshake-outline::before {
  content: "\F15A1"; }

.mdi-hanger::before {
  content: "\F02C8"; }

.mdi-hard-hat::before {
  content: "\F096F"; }

.mdi-harddisk::before {
  content: "\F02CA"; }

.mdi-harddisk-plus::before {
  content: "\F104B"; }

.mdi-harddisk-remove::before {
  content: "\F104C"; }

.mdi-hat-fedora::before {
  content: "\F0BA4"; }

.mdi-hazard-lights::before {
  content: "\F0C89"; }

.mdi-hdmi-port::before {
  content: "\F1BB8"; }

.mdi-hdr::before {
  content: "\F0D7D"; }

.mdi-hdr-off::before {
  content: "\F0D7E"; }

.mdi-head::before {
  content: "\F135E"; }

.mdi-head-alert::before {
  content: "\F1338"; }

.mdi-head-alert-outline::before {
  content: "\F1339"; }

.mdi-head-check::before {
  content: "\F133A"; }

.mdi-head-check-outline::before {
  content: "\F133B"; }

.mdi-head-cog::before {
  content: "\F133C"; }

.mdi-head-cog-outline::before {
  content: "\F133D"; }

.mdi-head-dots-horizontal::before {
  content: "\F133E"; }

.mdi-head-dots-horizontal-outline::before {
  content: "\F133F"; }

.mdi-head-flash::before {
  content: "\F1340"; }

.mdi-head-flash-outline::before {
  content: "\F1341"; }

.mdi-head-heart::before {
  content: "\F1342"; }

.mdi-head-heart-outline::before {
  content: "\F1343"; }

.mdi-head-lightbulb::before {
  content: "\F1344"; }

.mdi-head-lightbulb-outline::before {
  content: "\F1345"; }

.mdi-head-minus::before {
  content: "\F1346"; }

.mdi-head-minus-outline::before {
  content: "\F1347"; }

.mdi-head-outline::before {
  content: "\F135F"; }

.mdi-head-plus::before {
  content: "\F1348"; }

.mdi-head-plus-outline::before {
  content: "\F1349"; }

.mdi-head-question::before {
  content: "\F134A"; }

.mdi-head-question-outline::before {
  content: "\F134B"; }

.mdi-head-remove::before {
  content: "\F134C"; }

.mdi-head-remove-outline::before {
  content: "\F134D"; }

.mdi-head-snowflake::before {
  content: "\F134E"; }

.mdi-head-snowflake-outline::before {
  content: "\F134F"; }

.mdi-head-sync::before {
  content: "\F1350"; }

.mdi-head-sync-outline::before {
  content: "\F1351"; }

.mdi-headphones::before {
  content: "\F02CB"; }

.mdi-headphones-bluetooth::before {
  content: "\F0970"; }

.mdi-headphones-box::before {
  content: "\F02CC"; }

.mdi-headphones-off::before {
  content: "\F07CE"; }

.mdi-headphones-settings::before {
  content: "\F02CD"; }

.mdi-headset::before {
  content: "\F02CE"; }

.mdi-headset-dock::before {
  content: "\F02CF"; }

.mdi-headset-off::before {
  content: "\F02D0"; }

.mdi-heart::before {
  content: "\F02D1"; }

.mdi-heart-box::before {
  content: "\F02D2"; }

.mdi-heart-box-outline::before {
  content: "\F02D3"; }

.mdi-heart-broken::before {
  content: "\F02D4"; }

.mdi-heart-broken-outline::before {
  content: "\F0D14"; }

.mdi-heart-circle::before {
  content: "\F0971"; }

.mdi-heart-circle-outline::before {
  content: "\F0972"; }

.mdi-heart-cog::before {
  content: "\F1663"; }

.mdi-heart-cog-outline::before {
  content: "\F1664"; }

.mdi-heart-flash::before {
  content: "\F0EF9"; }

.mdi-heart-half::before {
  content: "\F06DF"; }

.mdi-heart-half-full::before {
  content: "\F06DE"; }

.mdi-heart-half-outline::before {
  content: "\F06E0"; }

.mdi-heart-minus::before {
  content: "\F142F"; }

.mdi-heart-minus-outline::before {
  content: "\F1432"; }

.mdi-heart-multiple::before {
  content: "\F0A56"; }

.mdi-heart-multiple-outline::before {
  content: "\F0A57"; }

.mdi-heart-off::before {
  content: "\F0759"; }

.mdi-heart-off-outline::before {
  content: "\F1434"; }

.mdi-heart-outline::before {
  content: "\F02D5"; }

.mdi-heart-plus::before {
  content: "\F142E"; }

.mdi-heart-plus-outline::before {
  content: "\F1431"; }

.mdi-heart-pulse::before {
  content: "\F05F6"; }

.mdi-heart-remove::before {
  content: "\F1430"; }

.mdi-heart-remove-outline::before {
  content: "\F1433"; }

.mdi-heart-settings::before {
  content: "\F1665"; }

.mdi-heart-settings-outline::before {
  content: "\F1666"; }

.mdi-heat-pump::before {
  content: "\F1A43"; }

.mdi-heat-pump-outline::before {
  content: "\F1A44"; }

.mdi-heat-wave::before {
  content: "\F1A45"; }

.mdi-heating-coil::before {
  content: "\F1AAF"; }

.mdi-helicopter::before {
  content: "\F0AC2"; }

.mdi-help::before {
  content: "\F02D6"; }

.mdi-help-box::before {
  content: "\F078B"; }

.mdi-help-circle::before {
  content: "\F02D7"; }

.mdi-help-circle-outline::before {
  content: "\F0625"; }

.mdi-help-network::before {
  content: "\F06F5"; }

.mdi-help-network-outline::before {
  content: "\F0C8A"; }

.mdi-help-rhombus::before {
  content: "\F0BA5"; }

.mdi-help-rhombus-outline::before {
  content: "\F0BA6"; }

.mdi-hexadecimal::before {
  content: "\F12A7"; }

.mdi-hexagon::before {
  content: "\F02D8"; }

.mdi-hexagon-multiple::before {
  content: "\F06E1"; }

.mdi-hexagon-multiple-outline::before {
  content: "\F10F2"; }

.mdi-hexagon-outline::before {
  content: "\F02D9"; }

.mdi-hexagon-slice-1::before {
  content: "\F0AC3"; }

.mdi-hexagon-slice-2::before {
  content: "\F0AC4"; }

.mdi-hexagon-slice-3::before {
  content: "\F0AC5"; }

.mdi-hexagon-slice-4::before {
  content: "\F0AC6"; }

.mdi-hexagon-slice-5::before {
  content: "\F0AC7"; }

.mdi-hexagon-slice-6::before {
  content: "\F0AC8"; }

.mdi-hexagram::before {
  content: "\F0AC9"; }

.mdi-hexagram-outline::before {
  content: "\F0ACA"; }

.mdi-high-definition::before {
  content: "\F07CF"; }

.mdi-high-definition-box::before {
  content: "\F0878"; }

.mdi-highway::before {
  content: "\F05F7"; }

.mdi-hiking::before {
  content: "\F0D7F"; }

.mdi-history::before {
  content: "\F02DA"; }

.mdi-hockey-puck::before {
  content: "\F0879"; }

.mdi-hockey-sticks::before {
  content: "\F087A"; }

.mdi-hololens::before {
  content: "\F02DB"; }

.mdi-home::before {
  content: "\F02DC"; }

.mdi-home-account::before {
  content: "\F0826"; }

.mdi-home-alert::before {
  content: "\F087B"; }

.mdi-home-alert-outline::before {
  content: "\F15D0"; }

.mdi-home-analytics::before {
  content: "\F0EBA"; }

.mdi-home-assistant::before {
  content: "\F07D0"; }

.mdi-home-automation::before {
  content: "\F07D1"; }

.mdi-home-battery::before {
  content: "\F1901"; }

.mdi-home-battery-outline::before {
  content: "\F1902"; }

.mdi-home-circle::before {
  content: "\F07D2"; }

.mdi-home-circle-outline::before {
  content: "\F104D"; }

.mdi-home-city::before {
  content: "\F0D15"; }

.mdi-home-city-outline::before {
  content: "\F0D16"; }

.mdi-home-clock::before {
  content: "\F1A12"; }

.mdi-home-clock-outline::before {
  content: "\F1A13"; }

.mdi-home-edit::before {
  content: "\F1159"; }

.mdi-home-edit-outline::before {
  content: "\F115A"; }

.mdi-home-export-outline::before {
  content: "\F0F9B"; }

.mdi-home-flood::before {
  content: "\F0EFA"; }

.mdi-home-floor-0::before {
  content: "\F0DD2"; }

.mdi-home-floor-1::before {
  content: "\F0D80"; }

.mdi-home-floor-2::before {
  content: "\F0D81"; }

.mdi-home-floor-3::before {
  content: "\F0D82"; }

.mdi-home-floor-a::before {
  content: "\F0D83"; }

.mdi-home-floor-b::before {
  content: "\F0D84"; }

.mdi-home-floor-g::before {
  content: "\F0D85"; }

.mdi-home-floor-l::before {
  content: "\F0D86"; }

.mdi-home-floor-negative-1::before {
  content: "\F0DD3"; }

.mdi-home-group::before {
  content: "\F0DD4"; }

.mdi-home-group-minus::before {
  content: "\F19C1"; }

.mdi-home-group-plus::before {
  content: "\F19C0"; }

.mdi-home-group-remove::before {
  content: "\F19C2"; }

.mdi-home-heart::before {
  content: "\F0827"; }

.mdi-home-import-outline::before {
  content: "\F0F9C"; }

.mdi-home-lightbulb::before {
  content: "\F1251"; }

.mdi-home-lightbulb-outline::before {
  content: "\F1252"; }

.mdi-home-lightning-bolt::before {
  content: "\F1903"; }

.mdi-home-lightning-bolt-outline::before {
  content: "\F1904"; }

.mdi-home-lock::before {
  content: "\F08EB"; }

.mdi-home-lock-open::before {
  content: "\F08EC"; }

.mdi-home-map-marker::before {
  content: "\F05F8"; }

.mdi-home-minus::before {
  content: "\F0974"; }

.mdi-home-minus-outline::before {
  content: "\F13D5"; }

.mdi-home-modern::before {
  content: "\F02DD"; }

.mdi-home-off::before {
  content: "\F1A46"; }

.mdi-home-off-outline::before {
  content: "\F1A47"; }

.mdi-home-outline::before {
  content: "\F06A1"; }

.mdi-home-plus::before {
  content: "\F0975"; }

.mdi-home-plus-outline::before {
  content: "\F13D6"; }

.mdi-home-remove::before {
  content: "\F1247"; }

.mdi-home-remove-outline::before {
  content: "\F13D7"; }

.mdi-home-roof::before {
  content: "\F112B"; }

.mdi-home-search::before {
  content: "\F13B0"; }

.mdi-home-search-outline::before {
  content: "\F13B1"; }

.mdi-home-silo::before {
  content: "\F1BA0"; }

.mdi-home-silo-outline::before {
  content: "\F1BA1"; }

.mdi-home-switch::before {
  content: "\F1794"; }

.mdi-home-switch-outline::before {
  content: "\F1795"; }

.mdi-home-thermometer::before {
  content: "\F0F54"; }

.mdi-home-thermometer-outline::before {
  content: "\F0F55"; }

.mdi-home-variant::before {
  content: "\F02DE"; }

.mdi-home-variant-outline::before {
  content: "\F0BA7"; }

.mdi-hook::before {
  content: "\F06E2"; }

.mdi-hook-off::before {
  content: "\F06E3"; }

.mdi-hoop-house::before {
  content: "\F0E56"; }

.mdi-hops::before {
  content: "\F02DF"; }

.mdi-horizontal-rotate-clockwise::before {
  content: "\F10F3"; }

.mdi-horizontal-rotate-counterclockwise::before {
  content: "\F10F4"; }

.mdi-horse::before {
  content: "\F15BF"; }

.mdi-horse-human::before {
  content: "\F15C0"; }

.mdi-horse-variant::before {
  content: "\F15C1"; }

.mdi-horse-variant-fast::before {
  content: "\F186E"; }

.mdi-horseshoe::before {
  content: "\F0A58"; }

.mdi-hospital::before {
  content: "\F0FF6"; }

.mdi-hospital-box::before {
  content: "\F02E0"; }

.mdi-hospital-box-outline::before {
  content: "\F0FF7"; }

.mdi-hospital-building::before {
  content: "\F02E1"; }

.mdi-hospital-marker::before {
  content: "\F02E2"; }

.mdi-hot-tub::before {
  content: "\F0828"; }

.mdi-hours-24::before {
  content: "\F1478"; }

.mdi-hubspot::before {
  content: "\F0D17"; }

.mdi-hulu::before {
  content: "\F0829"; }

.mdi-human::before {
  content: "\F02E6"; }

.mdi-human-baby-changing-table::before {
  content: "\F138B"; }

.mdi-human-cane::before {
  content: "\F1581"; }

.mdi-human-capacity-decrease::before {
  content: "\F159B"; }

.mdi-human-capacity-increase::before {
  content: "\F159C"; }

.mdi-human-child::before {
  content: "\F02E7"; }

.mdi-human-dolly::before {
  content: "\F1980"; }

.mdi-human-edit::before {
  content: "\F14E8"; }

.mdi-human-female::before {
  content: "\F0649"; }

.mdi-human-female-boy::before {
  content: "\F0A59"; }

.mdi-human-female-dance::before {
  content: "\F15C9"; }

.mdi-human-female-female::before {
  content: "\F0A5A"; }

.mdi-human-female-girl::before {
  content: "\F0A5B"; }

.mdi-human-greeting::before {
  content: "\F17C4"; }

.mdi-human-greeting-proximity::before {
  content: "\F159D"; }

.mdi-human-greeting-variant::before {
  content: "\F064A"; }

.mdi-human-handsdown::before {
  content: "\F064B"; }

.mdi-human-handsup::before {
  content: "\F064C"; }

.mdi-human-male::before {
  content: "\F064D"; }

.mdi-human-male-board::before {
  content: "\F0890"; }

.mdi-human-male-board-poll::before {
  content: "\F0846"; }

.mdi-human-male-boy::before {
  content: "\F0A5C"; }

.mdi-human-male-child::before {
  content: "\F138C"; }

.mdi-human-male-female::before {
  content: "\F02E8"; }

.mdi-human-male-female-child::before {
  content: "\F1823"; }

.mdi-human-male-girl::before {
  content: "\F0A5D"; }

.mdi-human-male-height::before {
  content: "\F0EFB"; }

.mdi-human-male-height-variant::before {
  content: "\F0EFC"; }

.mdi-human-male-male::before {
  content: "\F0A5E"; }

.mdi-human-non-binary::before {
  content: "\F1848"; }

.mdi-human-pregnant::before {
  content: "\F05CF"; }

.mdi-human-queue::before {
  content: "\F1571"; }

.mdi-human-scooter::before {
  content: "\F11E9"; }

.mdi-human-walker::before {
  content: "\F1B71"; }

.mdi-human-wheelchair::before {
  content: "\F138D"; }

.mdi-human-white-cane::before {
  content: "\F1981"; }

.mdi-humble-bundle::before {
  content: "\F0744"; }

.mdi-hvac::before {
  content: "\F1352"; }

.mdi-hvac-off::before {
  content: "\F159E"; }

.mdi-hydraulic-oil-level::before {
  content: "\F1324"; }

.mdi-hydraulic-oil-temperature::before {
  content: "\F1325"; }

.mdi-hydro-power::before {
  content: "\F12E5"; }

.mdi-hydrogen-station::before {
  content: "\F1894"; }

.mdi-ice-cream::before {
  content: "\F082A"; }

.mdi-ice-cream-off::before {
  content: "\F0E52"; }

.mdi-ice-pop::before {
  content: "\F0EFD"; }

.mdi-id-card::before {
  content: "\F0FC0"; }

.mdi-identifier::before {
  content: "\F0EFE"; }

.mdi-ideogram-cjk::before {
  content: "\F1331"; }

.mdi-ideogram-cjk-variant::before {
  content: "\F1332"; }

.mdi-image::before {
  content: "\F02E9"; }

.mdi-image-album::before {
  content: "\F02EA"; }

.mdi-image-area::before {
  content: "\F02EB"; }

.mdi-image-area-close::before {
  content: "\F02EC"; }

.mdi-image-auto-adjust::before {
  content: "\F0FC1"; }

.mdi-image-broken::before {
  content: "\F02ED"; }

.mdi-image-broken-variant::before {
  content: "\F02EE"; }

.mdi-image-check::before {
  content: "\F1B25"; }

.mdi-image-check-outline::before {
  content: "\F1B26"; }

.mdi-image-edit::before {
  content: "\F11E3"; }

.mdi-image-edit-outline::before {
  content: "\F11E4"; }

.mdi-image-filter-black-white::before {
  content: "\F02F0"; }

.mdi-image-filter-center-focus::before {
  content: "\F02F1"; }

.mdi-image-filter-center-focus-strong::before {
  content: "\F0EFF"; }

.mdi-image-filter-center-focus-strong-outline::before {
  content: "\F0F00"; }

.mdi-image-filter-center-focus-weak::before {
  content: "\F02F2"; }

.mdi-image-filter-drama::before {
  content: "\F02F3"; }

.mdi-image-filter-frames::before {
  content: "\F02F4"; }

.mdi-image-filter-hdr::before {
  content: "\F02F5"; }

.mdi-image-filter-none::before {
  content: "\F02F6"; }

.mdi-image-filter-tilt-shift::before {
  content: "\F02F7"; }

.mdi-image-filter-vintage::before {
  content: "\F02F8"; }

.mdi-image-frame::before {
  content: "\F0E49"; }

.mdi-image-lock::before {
  content: "\F1AB0"; }

.mdi-image-lock-outline::before {
  content: "\F1AB1"; }

.mdi-image-marker::before {
  content: "\F177B"; }

.mdi-image-marker-outline::before {
  content: "\F177C"; }

.mdi-image-minus::before {
  content: "\F1419"; }

.mdi-image-minus-outline::before {
  content: "\F1B47"; }

.mdi-image-move::before {
  content: "\F09F8"; }

.mdi-image-multiple::before {
  content: "\F02F9"; }

.mdi-image-multiple-outline::before {
  content: "\F02EF"; }

.mdi-image-off::before {
  content: "\F082B"; }

.mdi-image-off-outline::before {
  content: "\F11D1"; }

.mdi-image-outline::before {
  content: "\F0976"; }

.mdi-image-plus::before {
  content: "\F087C"; }

.mdi-image-plus-outline::before {
  content: "\F1B46"; }

.mdi-image-refresh::before {
  content: "\F19FE"; }

.mdi-image-refresh-outline::before {
  content: "\F19FF"; }

.mdi-image-remove::before {
  content: "\F1418"; }

.mdi-image-remove-outline::before {
  content: "\F1B48"; }

.mdi-image-search::before {
  content: "\F0977"; }

.mdi-image-search-outline::before {
  content: "\F0978"; }

.mdi-image-size-select-actual::before {
  content: "\F0C8D"; }

.mdi-image-size-select-large::before {
  content: "\F0C8E"; }

.mdi-image-size-select-small::before {
  content: "\F0C8F"; }

.mdi-image-sync::before {
  content: "\F1A00"; }

.mdi-image-sync-outline::before {
  content: "\F1A01"; }

.mdi-image-text::before {
  content: "\F160D"; }

.mdi-import::before {
  content: "\F02FA"; }

.mdi-inbox::before {
  content: "\F0687"; }

.mdi-inbox-arrow-down::before {
  content: "\F02FB"; }

.mdi-inbox-arrow-down-outline::before {
  content: "\F1270"; }

.mdi-inbox-arrow-up::before {
  content: "\F03D1"; }

.mdi-inbox-arrow-up-outline::before {
  content: "\F1271"; }

.mdi-inbox-full::before {
  content: "\F1272"; }

.mdi-inbox-full-outline::before {
  content: "\F1273"; }

.mdi-inbox-multiple::before {
  content: "\F08B0"; }

.mdi-inbox-multiple-outline::before {
  content: "\F0BA8"; }

.mdi-inbox-outline::before {
  content: "\F1274"; }

.mdi-inbox-remove::before {
  content: "\F159F"; }

.mdi-inbox-remove-outline::before {
  content: "\F15A0"; }

.mdi-incognito::before {
  content: "\F05F9"; }

.mdi-incognito-circle::before {
  content: "\F1421"; }

.mdi-incognito-circle-off::before {
  content: "\F1422"; }

.mdi-incognito-off::before {
  content: "\F0075"; }

.mdi-induction::before {
  content: "\F184C"; }

.mdi-infinity::before {
  content: "\F06E4"; }

.mdi-information::before {
  content: "\F02FC"; }

.mdi-information-off::before {
  content: "\F178C"; }

.mdi-information-off-outline::before {
  content: "\F178D"; }

.mdi-information-outline::before {
  content: "\F02FD"; }

.mdi-information-variant::before {
  content: "\F064E"; }

.mdi-instagram::before {
  content: "\F02FE"; }

.mdi-instrument-triangle::before {
  content: "\F104E"; }

.mdi-integrated-circuit-chip::before {
  content: "\F1913"; }

.mdi-invert-colors::before {
  content: "\F0301"; }

.mdi-invert-colors-off::before {
  content: "\F0E4A"; }

.mdi-iobroker::before {
  content: "\F12E8"; }

.mdi-ip::before {
  content: "\F0A5F"; }

.mdi-ip-network::before {
  content: "\F0A60"; }

.mdi-ip-network-outline::before {
  content: "\F0C90"; }

.mdi-ip-outline::before {
  content: "\F1982"; }

.mdi-ipod::before {
  content: "\F0C91"; }

.mdi-iron::before {
  content: "\F1824"; }

.mdi-iron-board::before {
  content: "\F1838"; }

.mdi-iron-outline::before {
  content: "\F1825"; }

.mdi-island::before {
  content: "\F104F"; }

.mdi-iv-bag::before {
  content: "\F10B9"; }

.mdi-jabber::before {
  content: "\F0DD5"; }

.mdi-jeepney::before {
  content: "\F0302"; }

.mdi-jellyfish::before {
  content: "\F0F01"; }

.mdi-jellyfish-outline::before {
  content: "\F0F02"; }

.mdi-jira::before {
  content: "\F0303"; }

.mdi-jquery::before {
  content: "\F087D"; }

.mdi-jsfiddle::before {
  content: "\F0304"; }

.mdi-jump-rope::before {
  content: "\F12FF"; }

.mdi-kabaddi::before {
  content: "\F0D87"; }

.mdi-kangaroo::before {
  content: "\F1558"; }

.mdi-karate::before {
  content: "\F082C"; }

.mdi-kayaking::before {
  content: "\F08AF"; }

.mdi-keg::before {
  content: "\F0305"; }

.mdi-kettle::before {
  content: "\F05FA"; }

.mdi-kettle-alert::before {
  content: "\F1317"; }

.mdi-kettle-alert-outline::before {
  content: "\F1318"; }

.mdi-kettle-off::before {
  content: "\F131B"; }

.mdi-kettle-off-outline::before {
  content: "\F131C"; }

.mdi-kettle-outline::before {
  content: "\F0F56"; }

.mdi-kettle-pour-over::before {
  content: "\F173C"; }

.mdi-kettle-steam::before {
  content: "\F1319"; }

.mdi-kettle-steam-outline::before {
  content: "\F131A"; }

.mdi-kettlebell::before {
  content: "\F1300"; }

.mdi-key::before {
  content: "\F0306"; }

.mdi-key-alert::before {
  content: "\F1983"; }

.mdi-key-alert-outline::before {
  content: "\F1984"; }

.mdi-key-arrow-right::before {
  content: "\F1312"; }

.mdi-key-chain::before {
  content: "\F1574"; }

.mdi-key-chain-variant::before {
  content: "\F1575"; }

.mdi-key-change::before {
  content: "\F0307"; }

.mdi-key-link::before {
  content: "\F119F"; }

.mdi-key-minus::before {
  content: "\F0308"; }

.mdi-key-outline::before {
  content: "\F0DD6"; }

.mdi-key-plus::before {
  content: "\F0309"; }

.mdi-key-remove::before {
  content: "\F030A"; }

.mdi-key-star::before {
  content: "\F119E"; }

.mdi-key-variant::before {
  content: "\F030B"; }

.mdi-key-wireless::before {
  content: "\F0FC2"; }

.mdi-keyboard::before {
  content: "\F030C"; }

.mdi-keyboard-backspace::before {
  content: "\F030D"; }

.mdi-keyboard-caps::before {
  content: "\F030E"; }

.mdi-keyboard-close::before {
  content: "\F030F"; }

.mdi-keyboard-esc::before {
  content: "\F12B7"; }

.mdi-keyboard-f1::before {
  content: "\F12AB"; }

.mdi-keyboard-f10::before {
  content: "\F12B4"; }

.mdi-keyboard-f11::before {
  content: "\F12B5"; }

.mdi-keyboard-f12::before {
  content: "\F12B6"; }

.mdi-keyboard-f2::before {
  content: "\F12AC"; }

.mdi-keyboard-f3::before {
  content: "\F12AD"; }

.mdi-keyboard-f4::before {
  content: "\F12AE"; }

.mdi-keyboard-f5::before {
  content: "\F12AF"; }

.mdi-keyboard-f6::before {
  content: "\F12B0"; }

.mdi-keyboard-f7::before {
  content: "\F12B1"; }

.mdi-keyboard-f8::before {
  content: "\F12B2"; }

.mdi-keyboard-f9::before {
  content: "\F12B3"; }

.mdi-keyboard-off::before {
  content: "\F0310"; }

.mdi-keyboard-off-outline::before {
  content: "\F0E4B"; }

.mdi-keyboard-outline::before {
  content: "\F097B"; }

.mdi-keyboard-return::before {
  content: "\F0311"; }

.mdi-keyboard-settings::before {
  content: "\F09F9"; }

.mdi-keyboard-settings-outline::before {
  content: "\F09FA"; }

.mdi-keyboard-space::before {
  content: "\F1050"; }

.mdi-keyboard-tab::before {
  content: "\F0312"; }

.mdi-keyboard-tab-reverse::before {
  content: "\F0325"; }

.mdi-keyboard-variant::before {
  content: "\F0313"; }

.mdi-khanda::before {
  content: "\F10FD"; }

.mdi-kickstarter::before {
  content: "\F0745"; }

.mdi-kite::before {
  content: "\F1985"; }

.mdi-kite-outline::before {
  content: "\F1986"; }

.mdi-kitesurfing::before {
  content: "\F1744"; }

.mdi-klingon::before {
  content: "\F135B"; }

.mdi-knife::before {
  content: "\F09FB"; }

.mdi-knife-military::before {
  content: "\F09FC"; }

.mdi-knob::before {
  content: "\F1B96"; }

.mdi-koala::before {
  content: "\F173F"; }

.mdi-kodi::before {
  content: "\F0314"; }

.mdi-kubernetes::before {
  content: "\F10FE"; }

.mdi-label::before {
  content: "\F0315"; }

.mdi-label-multiple::before {
  content: "\F1375"; }

.mdi-label-multiple-outline::before {
  content: "\F1376"; }

.mdi-label-off::before {
  content: "\F0ACB"; }

.mdi-label-off-outline::before {
  content: "\F0ACC"; }

.mdi-label-outline::before {
  content: "\F0316"; }

.mdi-label-percent::before {
  content: "\F12EA"; }

.mdi-label-percent-outline::before {
  content: "\F12EB"; }

.mdi-label-variant::before {
  content: "\F0ACD"; }

.mdi-label-variant-outline::before {
  content: "\F0ACE"; }

.mdi-ladder::before {
  content: "\F15A2"; }

.mdi-ladybug::before {
  content: "\F082D"; }

.mdi-lambda::before {
  content: "\F0627"; }

.mdi-lamp::before {
  content: "\F06B5"; }

.mdi-lamp-outline::before {
  content: "\F17D0"; }

.mdi-lamps::before {
  content: "\F1576"; }

.mdi-lamps-outline::before {
  content: "\F17D1"; }

.mdi-lan::before {
  content: "\F0317"; }

.mdi-lan-check::before {
  content: "\F12AA"; }

.mdi-lan-connect::before {
  content: "\F0318"; }

.mdi-lan-disconnect::before {
  content: "\F0319"; }

.mdi-lan-pending::before {
  content: "\F031A"; }

.mdi-land-fields::before {
  content: "\F1AB2"; }

.mdi-land-plots::before {
  content: "\F1AB3"; }

.mdi-land-plots-circle::before {
  content: "\F1AB4"; }

.mdi-land-plots-circle-variant::before {
  content: "\F1AB5"; }

.mdi-land-rows-horizontal::before {
  content: "\F1AB6"; }

.mdi-land-rows-vertical::before {
  content: "\F1AB7"; }

.mdi-landslide::before {
  content: "\F1A48"; }

.mdi-landslide-outline::before {
  content: "\F1A49"; }

.mdi-language-c::before {
  content: "\F0671"; }

.mdi-language-cpp::before {
  content: "\F0672"; }

.mdi-language-csharp::before {
  content: "\F031B"; }

.mdi-language-css3::before {
  content: "\F031C"; }

.mdi-language-fortran::before {
  content: "\F121A"; }

.mdi-language-go::before {
  content: "\F07D3"; }

.mdi-language-haskell::before {
  content: "\F0C92"; }

.mdi-language-html5::before {
  content: "\F031D"; }

.mdi-language-java::before {
  content: "\F0B37"; }

.mdi-language-javascript::before {
  content: "\F031E"; }

.mdi-language-kotlin::before {
  content: "\F1219"; }

.mdi-language-lua::before {
  content: "\F08B1"; }

.mdi-language-markdown::before {
  content: "\F0354"; }

.mdi-language-markdown-outline::before {
  content: "\F0F5B"; }

.mdi-language-php::before {
  content: "\F031F"; }

.mdi-language-python::before {
  content: "\F0320"; }

.mdi-language-r::before {
  content: "\F07D4"; }

.mdi-language-ruby::before {
  content: "\F0D2D"; }

.mdi-language-ruby-on-rails::before {
  content: "\F0ACF"; }

.mdi-language-rust::before {
  content: "\F1617"; }

.mdi-language-swift::before {
  content: "\F06E5"; }

.mdi-language-typescript::before {
  content: "\F06E6"; }

.mdi-language-xaml::before {
  content: "\F0673"; }

.mdi-laptop::before {
  content: "\F0322"; }

.mdi-laptop-account::before {
  content: "\F1A4A"; }

.mdi-laptop-off::before {
  content: "\F06E7"; }

.mdi-laravel::before {
  content: "\F0AD0"; }

.mdi-laser-pointer::before {
  content: "\F1484"; }

.mdi-lasso::before {
  content: "\F0F03"; }

.mdi-lastpass::before {
  content: "\F0446"; }

.mdi-latitude::before {
  content: "\F0F57"; }

.mdi-launch::before {
  content: "\F0327"; }

.mdi-lava-lamp::before {
  content: "\F07D5"; }

.mdi-layers::before {
  content: "\F0328"; }

.mdi-layers-edit::before {
  content: "\F1892"; }

.mdi-layers-minus::before {
  content: "\F0E4C"; }

.mdi-layers-off::before {
  content: "\F0329"; }

.mdi-layers-off-outline::before {
  content: "\F09FD"; }

.mdi-layers-outline::before {
  content: "\F09FE"; }

.mdi-layers-plus::before {
  content: "\F0E4D"; }

.mdi-layers-remove::before {
  content: "\F0E4E"; }

.mdi-layers-search::before {
  content: "\F1206"; }

.mdi-layers-search-outline::before {
  content: "\F1207"; }

.mdi-layers-triple::before {
  content: "\F0F58"; }

.mdi-layers-triple-outline::before {
  content: "\F0F59"; }

.mdi-lead-pencil::before {
  content: "\F064F"; }

.mdi-leaf::before {
  content: "\F032A"; }

.mdi-leaf-circle::before {
  content: "\F1905"; }

.mdi-leaf-circle-outline::before {
  content: "\F1906"; }

.mdi-leaf-maple::before {
  content: "\F0C93"; }

.mdi-leaf-maple-off::before {
  content: "\F12DA"; }

.mdi-leaf-off::before {
  content: "\F12D9"; }

.mdi-leak::before {
  content: "\F0DD7"; }

.mdi-leak-off::before {
  content: "\F0DD8"; }

.mdi-lectern::before {
  content: "\F1AF0"; }

.mdi-led-off::before {
  content: "\F032B"; }

.mdi-led-on::before {
  content: "\F032C"; }

.mdi-led-outline::before {
  content: "\F032D"; }

.mdi-led-strip::before {
  content: "\F07D6"; }

.mdi-led-strip-variant::before {
  content: "\F1051"; }

.mdi-led-strip-variant-off::before {
  content: "\F1A4B"; }

.mdi-led-variant-off::before {
  content: "\F032E"; }

.mdi-led-variant-on::before {
  content: "\F032F"; }

.mdi-led-variant-outline::before {
  content: "\F0330"; }

.mdi-leek::before {
  content: "\F117D"; }

.mdi-less-than::before {
  content: "\F097C"; }

.mdi-less-than-or-equal::before {
  content: "\F097D"; }

.mdi-library::before {
  content: "\F0331"; }

.mdi-library-outline::before {
  content: "\F1A22"; }

.mdi-library-shelves::before {
  content: "\F0BA9"; }

.mdi-license::before {
  content: "\F0FC3"; }

.mdi-lifebuoy::before {
  content: "\F087E"; }

.mdi-light-flood-down::before {
  content: "\F1987"; }

.mdi-light-flood-up::before {
  content: "\F1988"; }

.mdi-light-recessed::before {
  content: "\F179B"; }

.mdi-light-switch::before {
  content: "\F097E"; }

.mdi-light-switch-off::before {
  content: "\F1A24"; }

.mdi-lightbulb::before {
  content: "\F0335"; }

.mdi-lightbulb-alert::before {
  content: "\F19E1"; }

.mdi-lightbulb-alert-outline::before {
  content: "\F19E2"; }

.mdi-lightbulb-auto::before {
  content: "\F1800"; }

.mdi-lightbulb-auto-outline::before {
  content: "\F1801"; }

.mdi-lightbulb-cfl::before {
  content: "\F1208"; }

.mdi-lightbulb-cfl-off::before {
  content: "\F1209"; }

.mdi-lightbulb-cfl-spiral::before {
  content: "\F1275"; }

.mdi-lightbulb-cfl-spiral-off::before {
  content: "\F12C3"; }

.mdi-lightbulb-fluorescent-tube::before {
  content: "\F1804"; }

.mdi-lightbulb-fluorescent-tube-outline::before {
  content: "\F1805"; }

.mdi-lightbulb-group::before {
  content: "\F1253"; }

.mdi-lightbulb-group-off::before {
  content: "\F12CD"; }

.mdi-lightbulb-group-off-outline::before {
  content: "\F12CE"; }

.mdi-lightbulb-group-outline::before {
  content: "\F1254"; }

.mdi-lightbulb-multiple::before {
  content: "\F1255"; }

.mdi-lightbulb-multiple-off::before {
  content: "\F12CF"; }

.mdi-lightbulb-multiple-off-outline::before {
  content: "\F12D0"; }

.mdi-lightbulb-multiple-outline::before {
  content: "\F1256"; }

.mdi-lightbulb-night::before {
  content: "\F1A4C"; }

.mdi-lightbulb-night-outline::before {
  content: "\F1A4D"; }

.mdi-lightbulb-off::before {
  content: "\F0E4F"; }

.mdi-lightbulb-off-outline::before {
  content: "\F0E50"; }

.mdi-lightbulb-on::before {
  content: "\F06E8"; }

.mdi-lightbulb-on-10::before {
  content: "\F1A4E"; }

.mdi-lightbulb-on-20::before {
  content: "\F1A4F"; }

.mdi-lightbulb-on-30::before {
  content: "\F1A50"; }

.mdi-lightbulb-on-40::before {
  content: "\F1A51"; }

.mdi-lightbulb-on-50::before {
  content: "\F1A52"; }

.mdi-lightbulb-on-60::before {
  content: "\F1A53"; }

.mdi-lightbulb-on-70::before {
  content: "\F1A54"; }

.mdi-lightbulb-on-80::before {
  content: "\F1A55"; }

.mdi-lightbulb-on-90::before {
  content: "\F1A56"; }

.mdi-lightbulb-on-outline::before {
  content: "\F06E9"; }

.mdi-lightbulb-outline::before {
  content: "\F0336"; }

.mdi-lightbulb-question::before {
  content: "\F19E3"; }

.mdi-lightbulb-question-outline::before {
  content: "\F19E4"; }

.mdi-lightbulb-spot::before {
  content: "\F17F4"; }

.mdi-lightbulb-spot-off::before {
  content: "\F17F5"; }

.mdi-lightbulb-variant::before {
  content: "\F1802"; }

.mdi-lightbulb-variant-outline::before {
  content: "\F1803"; }

.mdi-lighthouse::before {
  content: "\F09FF"; }

.mdi-lighthouse-on::before {
  content: "\F0A00"; }

.mdi-lightning-bolt::before {
  content: "\F140B"; }

.mdi-lightning-bolt-circle::before {
  content: "\F0820"; }

.mdi-lightning-bolt-outline::before {
  content: "\F140C"; }

.mdi-line-scan::before {
  content: "\F0624"; }

.mdi-lingerie::before {
  content: "\F1476"; }

.mdi-link::before {
  content: "\F0337"; }

.mdi-link-box::before {
  content: "\F0D1A"; }

.mdi-link-box-outline::before {
  content: "\F0D1B"; }

.mdi-link-box-variant::before {
  content: "\F0D1C"; }

.mdi-link-box-variant-outline::before {
  content: "\F0D1D"; }

.mdi-link-lock::before {
  content: "\F10BA"; }

.mdi-link-off::before {
  content: "\F0338"; }

.mdi-link-plus::before {
  content: "\F0C94"; }

.mdi-link-variant::before {
  content: "\F0339"; }

.mdi-link-variant-minus::before {
  content: "\F10FF"; }

.mdi-link-variant-off::before {
  content: "\F033A"; }

.mdi-link-variant-plus::before {
  content: "\F1100"; }

.mdi-link-variant-remove::before {
  content: "\F1101"; }

.mdi-linkedin::before {
  content: "\F033B"; }

.mdi-linux::before {
  content: "\F033D"; }

.mdi-linux-mint::before {
  content: "\F08ED"; }

.mdi-lipstick::before {
  content: "\F13B5"; }

.mdi-liquid-spot::before {
  content: "\F1826"; }

.mdi-liquor::before {
  content: "\F191E"; }

.mdi-list-box::before {
  content: "\F1B7B"; }

.mdi-list-box-outline::before {
  content: "\F1B7C"; }

.mdi-list-status::before {
  content: "\F15AB"; }

.mdi-litecoin::before {
  content: "\F0A61"; }

.mdi-loading::before {
  content: "\F0772"; }

.mdi-location-enter::before {
  content: "\F0FC4"; }

.mdi-location-exit::before {
  content: "\F0FC5"; }

.mdi-lock::before {
  content: "\F033E"; }

.mdi-lock-alert::before {
  content: "\F08EE"; }

.mdi-lock-alert-outline::before {
  content: "\F15D1"; }

.mdi-lock-check::before {
  content: "\F139A"; }

.mdi-lock-check-outline::before {
  content: "\F16A8"; }

.mdi-lock-clock::before {
  content: "\F097F"; }

.mdi-lock-minus::before {
  content: "\F16A9"; }

.mdi-lock-minus-outline::before {
  content: "\F16AA"; }

.mdi-lock-off::before {
  content: "\F1671"; }

.mdi-lock-off-outline::before {
  content: "\F1672"; }

.mdi-lock-open::before {
  content: "\F033F"; }

.mdi-lock-open-alert::before {
  content: "\F139B"; }

.mdi-lock-open-alert-outline::before {
  content: "\F15D2"; }

.mdi-lock-open-check::before {
  content: "\F139C"; }

.mdi-lock-open-check-outline::before {
  content: "\F16AB"; }

.mdi-lock-open-minus::before {
  content: "\F16AC"; }

.mdi-lock-open-minus-outline::before {
  content: "\F16AD"; }

.mdi-lock-open-outline::before {
  content: "\F0340"; }

.mdi-lock-open-plus::before {
  content: "\F16AE"; }

.mdi-lock-open-plus-outline::before {
  content: "\F16AF"; }

.mdi-lock-open-remove::before {
  content: "\F16B0"; }

.mdi-lock-open-remove-outline::before {
  content: "\F16B1"; }

.mdi-lock-open-variant::before {
  content: "\F0FC6"; }

.mdi-lock-open-variant-outline::before {
  content: "\F0FC7"; }

.mdi-lock-outline::before {
  content: "\F0341"; }

.mdi-lock-pattern::before {
  content: "\F06EA"; }

.mdi-lock-plus::before {
  content: "\F05FB"; }

.mdi-lock-plus-outline::before {
  content: "\F16B2"; }

.mdi-lock-question::before {
  content: "\F08EF"; }

.mdi-lock-remove::before {
  content: "\F16B3"; }

.mdi-lock-remove-outline::before {
  content: "\F16B4"; }

.mdi-lock-reset::before {
  content: "\F0773"; }

.mdi-lock-smart::before {
  content: "\F08B2"; }

.mdi-locker::before {
  content: "\F07D7"; }

.mdi-locker-multiple::before {
  content: "\F07D8"; }

.mdi-login::before {
  content: "\F0342"; }

.mdi-login-variant::before {
  content: "\F05FC"; }

.mdi-logout::before {
  content: "\F0343"; }

.mdi-logout-variant::before {
  content: "\F05FD"; }

.mdi-longitude::before {
  content: "\F0F5A"; }

.mdi-looks::before {
  content: "\F0344"; }

.mdi-lotion::before {
  content: "\F1582"; }

.mdi-lotion-outline::before {
  content: "\F1583"; }

.mdi-lotion-plus::before {
  content: "\F1584"; }

.mdi-lotion-plus-outline::before {
  content: "\F1585"; }

.mdi-loupe::before {
  content: "\F0345"; }

.mdi-lumx::before {
  content: "\F0346"; }

.mdi-lungs::before {
  content: "\F1084"; }

.mdi-mace::before {
  content: "\F1843"; }

.mdi-magazine-pistol::before {
  content: "\F0324"; }

.mdi-magazine-rifle::before {
  content: "\F0323"; }

.mdi-magic-staff::before {
  content: "\F1844"; }

.mdi-magnet::before {
  content: "\F0347"; }

.mdi-magnet-on::before {
  content: "\F0348"; }

.mdi-magnify::before {
  content: "\F0349"; }

.mdi-magnify-close::before {
  content: "\F0980"; }

.mdi-magnify-expand::before {
  content: "\F1874"; }

.mdi-magnify-minus::before {
  content: "\F034A"; }

.mdi-magnify-minus-cursor::before {
  content: "\F0A62"; }

.mdi-magnify-minus-outline::before {
  content: "\F06EC"; }

.mdi-magnify-plus::before {
  content: "\F034B"; }

.mdi-magnify-plus-cursor::before {
  content: "\F0A63"; }

.mdi-magnify-plus-outline::before {
  content: "\F06ED"; }

.mdi-magnify-remove-cursor::before {
  content: "\F120C"; }

.mdi-magnify-remove-outline::before {
  content: "\F120D"; }

.mdi-magnify-scan::before {
  content: "\F1276"; }

.mdi-mail::before {
  content: "\F0EBB"; }

.mdi-mailbox::before {
  content: "\F06EE"; }

.mdi-mailbox-open::before {
  content: "\F0D88"; }

.mdi-mailbox-open-outline::before {
  content: "\F0D89"; }

.mdi-mailbox-open-up::before {
  content: "\F0D8A"; }

.mdi-mailbox-open-up-outline::before {
  content: "\F0D8B"; }

.mdi-mailbox-outline::before {
  content: "\F0D8C"; }

.mdi-mailbox-up::before {
  content: "\F0D8D"; }

.mdi-mailbox-up-outline::before {
  content: "\F0D8E"; }

.mdi-manjaro::before {
  content: "\F160A"; }

.mdi-map::before {
  content: "\F034D"; }

.mdi-map-check::before {
  content: "\F0EBC"; }

.mdi-map-check-outline::before {
  content: "\F0EBD"; }

.mdi-map-clock::before {
  content: "\F0D1E"; }

.mdi-map-clock-outline::before {
  content: "\F0D1F"; }

.mdi-map-legend::before {
  content: "\F0A01"; }

.mdi-map-marker::before {
  content: "\F034E"; }

.mdi-map-marker-account::before {
  content: "\F18E3"; }

.mdi-map-marker-account-outline::before {
  content: "\F18E4"; }

.mdi-map-marker-alert::before {
  content: "\F0F05"; }

.mdi-map-marker-alert-outline::before {
  content: "\F0F06"; }

.mdi-map-marker-check::before {
  content: "\F0C95"; }

.mdi-map-marker-check-outline::before {
  content: "\F12FB"; }

.mdi-map-marker-circle::before {
  content: "\F034F"; }

.mdi-map-marker-distance::before {
  content: "\F08F0"; }

.mdi-map-marker-down::before {
  content: "\F1102"; }

.mdi-map-marker-left::before {
  content: "\F12DB"; }

.mdi-map-marker-left-outline::before {
  content: "\F12DD"; }

.mdi-map-marker-minus::before {
  content: "\F0650"; }

.mdi-map-marker-minus-outline::before {
  content: "\F12F9"; }

.mdi-map-marker-multiple::before {
  content: "\F0350"; }

.mdi-map-marker-multiple-outline::before {
  content: "\F1277"; }

.mdi-map-marker-off::before {
  content: "\F0351"; }

.mdi-map-marker-off-outline::before {
  content: "\F12FD"; }

.mdi-map-marker-outline::before {
  content: "\F07D9"; }

.mdi-map-marker-path::before {
  content: "\F0D20"; }

.mdi-map-marker-plus::before {
  content: "\F0651"; }

.mdi-map-marker-plus-outline::before {
  content: "\F12F8"; }

.mdi-map-marker-question::before {
  content: "\F0F07"; }

.mdi-map-marker-question-outline::before {
  content: "\F0F08"; }

.mdi-map-marker-radius::before {
  content: "\F0352"; }

.mdi-map-marker-radius-outline::before {
  content: "\F12FC"; }

.mdi-map-marker-remove::before {
  content: "\F0F09"; }

.mdi-map-marker-remove-outline::before {
  content: "\F12FA"; }

.mdi-map-marker-remove-variant::before {
  content: "\F0F0A"; }

.mdi-map-marker-right::before {
  content: "\F12DC"; }

.mdi-map-marker-right-outline::before {
  content: "\F12DE"; }

.mdi-map-marker-star::before {
  content: "\F1608"; }

.mdi-map-marker-star-outline::before {
  content: "\F1609"; }

.mdi-map-marker-up::before {
  content: "\F1103"; }

.mdi-map-minus::before {
  content: "\F0981"; }

.mdi-map-outline::before {
  content: "\F0982"; }

.mdi-map-plus::before {
  content: "\F0983"; }

.mdi-map-search::before {
  content: "\F0984"; }

.mdi-map-search-outline::before {
  content: "\F0985"; }

.mdi-mapbox::before {
  content: "\F0BAA"; }

.mdi-margin::before {
  content: "\F0353"; }

.mdi-marker::before {
  content: "\F0652"; }

.mdi-marker-cancel::before {
  content: "\F0DD9"; }

.mdi-marker-check::before {
  content: "\F0355"; }

.mdi-mastodon::before {
  content: "\F0AD1"; }

.mdi-material-design::before {
  content: "\F0986"; }

.mdi-material-ui::before {
  content: "\F0357"; }

.mdi-math-compass::before {
  content: "\F0358"; }

.mdi-math-cos::before {
  content: "\F0C96"; }

.mdi-math-integral::before {
  content: "\F0FC8"; }

.mdi-math-integral-box::before {
  content: "\F0FC9"; }

.mdi-math-log::before {
  content: "\F1085"; }

.mdi-math-norm::before {
  content: "\F0FCA"; }

.mdi-math-norm-box::before {
  content: "\F0FCB"; }

.mdi-math-sin::before {
  content: "\F0C97"; }

.mdi-math-tan::before {
  content: "\F0C98"; }

.mdi-matrix::before {
  content: "\F0628"; }

.mdi-medal::before {
  content: "\F0987"; }

.mdi-medal-outline::before {
  content: "\F1326"; }

.mdi-medical-bag::before {
  content: "\F06EF"; }

.mdi-medical-cotton-swab::before {
  content: "\F1AB8"; }

.mdi-medication::before {
  content: "\F1B14"; }

.mdi-medication-outline::before {
  content: "\F1B15"; }

.mdi-meditation::before {
  content: "\F117B"; }

.mdi-memory::before {
  content: "\F035B"; }

.mdi-menorah::before {
  content: "\F17D4"; }

.mdi-menorah-fire::before {
  content: "\F17D5"; }

.mdi-menu::before {
  content: "\F035C"; }

.mdi-menu-down::before {
  content: "\F035D"; }

.mdi-menu-down-outline::before {
  content: "\F06B6"; }

.mdi-menu-left::before {
  content: "\F035E"; }

.mdi-menu-left-outline::before {
  content: "\F0A02"; }

.mdi-menu-open::before {
  content: "\F0BAB"; }

.mdi-menu-right::before {
  content: "\F035F"; }

.mdi-menu-right-outline::before {
  content: "\F0A03"; }

.mdi-menu-swap::before {
  content: "\F0A64"; }

.mdi-menu-swap-outline::before {
  content: "\F0A65"; }

.mdi-menu-up::before {
  content: "\F0360"; }

.mdi-menu-up-outline::before {
  content: "\F06B7"; }

.mdi-merge::before {
  content: "\F0F5C"; }

.mdi-message::before {
  content: "\F0361"; }

.mdi-message-alert::before {
  content: "\F0362"; }

.mdi-message-alert-outline::before {
  content: "\F0A04"; }

.mdi-message-arrow-left::before {
  content: "\F12F2"; }

.mdi-message-arrow-left-outline::before {
  content: "\F12F3"; }

.mdi-message-arrow-right::before {
  content: "\F12F4"; }

.mdi-message-arrow-right-outline::before {
  content: "\F12F5"; }

.mdi-message-badge::before {
  content: "\F1941"; }

.mdi-message-badge-outline::before {
  content: "\F1942"; }

.mdi-message-bookmark::before {
  content: "\F15AC"; }

.mdi-message-bookmark-outline::before {
  content: "\F15AD"; }

.mdi-message-bulleted::before {
  content: "\F06A2"; }

.mdi-message-bulleted-off::before {
  content: "\F06A3"; }

.mdi-message-check::before {
  content: "\F1B8A"; }

.mdi-message-check-outline::before {
  content: "\F1B8B"; }

.mdi-message-cog::before {
  content: "\F06F1"; }

.mdi-message-cog-outline::before {
  content: "\F1172"; }

.mdi-message-draw::before {
  content: "\F0363"; }

.mdi-message-fast::before {
  content: "\F19CC"; }

.mdi-message-fast-outline::before {
  content: "\F19CD"; }

.mdi-message-flash::before {
  content: "\F15A9"; }

.mdi-message-flash-outline::before {
  content: "\F15AA"; }

.mdi-message-image::before {
  content: "\F0364"; }

.mdi-message-image-outline::before {
  content: "\F116C"; }

.mdi-message-lock::before {
  content: "\F0FCC"; }

.mdi-message-lock-outline::before {
  content: "\F116D"; }

.mdi-message-minus::before {
  content: "\F116E"; }

.mdi-message-minus-outline::before {
  content: "\F116F"; }

.mdi-message-off::before {
  content: "\F164D"; }

.mdi-message-off-outline::before {
  content: "\F164E"; }

.mdi-message-outline::before {
  content: "\F0365"; }

.mdi-message-plus::before {
  content: "\F0653"; }

.mdi-message-plus-outline::before {
  content: "\F10BB"; }

.mdi-message-processing::before {
  content: "\F0366"; }

.mdi-message-processing-outline::before {
  content: "\F1170"; }

.mdi-message-question::before {
  content: "\F173A"; }

.mdi-message-question-outline::before {
  content: "\F173B"; }

.mdi-message-reply::before {
  content: "\F0367"; }

.mdi-message-reply-outline::before {
  content: "\F173D"; }

.mdi-message-reply-text::before {
  content: "\F0368"; }

.mdi-message-reply-text-outline::before {
  content: "\F173E"; }

.mdi-message-settings::before {
  content: "\F06F0"; }

.mdi-message-settings-outline::before {
  content: "\F1171"; }

.mdi-message-star::before {
  content: "\F069A"; }

.mdi-message-star-outline::before {
  content: "\F1250"; }

.mdi-message-text::before {
  content: "\F0369"; }

.mdi-message-text-clock::before {
  content: "\F1173"; }

.mdi-message-text-clock-outline::before {
  content: "\F1174"; }

.mdi-message-text-fast::before {
  content: "\F19CE"; }

.mdi-message-text-fast-outline::before {
  content: "\F19CF"; }

.mdi-message-text-lock::before {
  content: "\F0FCD"; }

.mdi-message-text-lock-outline::before {
  content: "\F1175"; }

.mdi-message-text-outline::before {
  content: "\F036A"; }

.mdi-message-video::before {
  content: "\F036B"; }

.mdi-meteor::before {
  content: "\F0629"; }

.mdi-meter-electric::before {
  content: "\F1A57"; }

.mdi-meter-electric-outline::before {
  content: "\F1A58"; }

.mdi-meter-gas::before {
  content: "\F1A59"; }

.mdi-meter-gas-outline::before {
  content: "\F1A5A"; }

.mdi-metronome::before {
  content: "\F07DA"; }

.mdi-metronome-tick::before {
  content: "\F07DB"; }

.mdi-micro-sd::before {
  content: "\F07DC"; }

.mdi-microphone::before {
  content: "\F036C"; }

.mdi-microphone-message::before {
  content: "\F050A"; }

.mdi-microphone-message-off::before {
  content: "\F050B"; }

.mdi-microphone-minus::before {
  content: "\F08B3"; }

.mdi-microphone-off::before {
  content: "\F036D"; }

.mdi-microphone-outline::before {
  content: "\F036E"; }

.mdi-microphone-plus::before {
  content: "\F08B4"; }

.mdi-microphone-question::before {
  content: "\F1989"; }

.mdi-microphone-question-outline::before {
  content: "\F198A"; }

.mdi-microphone-settings::before {
  content: "\F036F"; }

.mdi-microphone-variant::before {
  content: "\F0370"; }

.mdi-microphone-variant-off::before {
  content: "\F0371"; }

.mdi-microscope::before {
  content: "\F0654"; }

.mdi-microsoft::before {
  content: "\F0372"; }

.mdi-microsoft-access::before {
  content: "\F138E"; }

.mdi-microsoft-azure::before {
  content: "\F0805"; }

.mdi-microsoft-azure-devops::before {
  content: "\F0FD5"; }

.mdi-microsoft-bing::before {
  content: "\F00A4"; }

.mdi-microsoft-dynamics-365::before {
  content: "\F0988"; }

.mdi-microsoft-edge::before {
  content: "\F01E9"; }

.mdi-microsoft-excel::before {
  content: "\F138F"; }

.mdi-microsoft-internet-explorer::before {
  content: "\F0300"; }

.mdi-microsoft-office::before {
  content: "\F03C6"; }

.mdi-microsoft-onedrive::before {
  content: "\F03CA"; }

.mdi-microsoft-onenote::before {
  content: "\F0747"; }

.mdi-microsoft-outlook::before {
  content: "\F0D22"; }

.mdi-microsoft-powerpoint::before {
  content: "\F1390"; }

.mdi-microsoft-sharepoint::before {
  content: "\F1391"; }

.mdi-microsoft-teams::before {
  content: "\F02BB"; }

.mdi-microsoft-visual-studio::before {
  content: "\F0610"; }

.mdi-microsoft-visual-studio-code::before {
  content: "\F0A1E"; }

.mdi-microsoft-windows::before {
  content: "\F05B3"; }

.mdi-microsoft-windows-classic::before {
  content: "\F0A21"; }

.mdi-microsoft-word::before {
  content: "\F1392"; }

.mdi-microsoft-xbox::before {
  content: "\F05B9"; }

.mdi-microsoft-xbox-controller::before {
  content: "\F05BA"; }

.mdi-microsoft-xbox-controller-battery-alert::before {
  content: "\F074B"; }

.mdi-microsoft-xbox-controller-battery-charging::before {
  content: "\F0A22"; }

.mdi-microsoft-xbox-controller-battery-empty::before {
  content: "\F074C"; }

.mdi-microsoft-xbox-controller-battery-full::before {
  content: "\F074D"; }

.mdi-microsoft-xbox-controller-battery-low::before {
  content: "\F074E"; }

.mdi-microsoft-xbox-controller-battery-medium::before {
  content: "\F074F"; }

.mdi-microsoft-xbox-controller-battery-unknown::before {
  content: "\F0750"; }

.mdi-microsoft-xbox-controller-menu::before {
  content: "\F0E6F"; }

.mdi-microsoft-xbox-controller-off::before {
  content: "\F05BB"; }

.mdi-microsoft-xbox-controller-view::before {
  content: "\F0E70"; }

.mdi-microwave::before {
  content: "\F0C99"; }

.mdi-microwave-off::before {
  content: "\F1423"; }

.mdi-middleware::before {
  content: "\F0F5D"; }

.mdi-middleware-outline::before {
  content: "\F0F5E"; }

.mdi-midi::before {
  content: "\F08F1"; }

.mdi-midi-port::before {
  content: "\F08F2"; }

.mdi-mine::before {
  content: "\F0DDA"; }

.mdi-minecraft::before {
  content: "\F0373"; }

.mdi-mini-sd::before {
  content: "\F0A05"; }

.mdi-minidisc::before {
  content: "\F0A06"; }

.mdi-minus::before {
  content: "\F0374"; }

.mdi-minus-box::before {
  content: "\F0375"; }

.mdi-minus-box-multiple::before {
  content: "\F1141"; }

.mdi-minus-box-multiple-outline::before {
  content: "\F1142"; }

.mdi-minus-box-outline::before {
  content: "\F06F2"; }

.mdi-minus-circle::before {
  content: "\F0376"; }

.mdi-minus-circle-multiple::before {
  content: "\F035A"; }

.mdi-minus-circle-multiple-outline::before {
  content: "\F0AD3"; }

.mdi-minus-circle-off::before {
  content: "\F1459"; }

.mdi-minus-circle-off-outline::before {
  content: "\F145A"; }

.mdi-minus-circle-outline::before {
  content: "\F0377"; }

.mdi-minus-network::before {
  content: "\F0378"; }

.mdi-minus-network-outline::before {
  content: "\F0C9A"; }

.mdi-minus-thick::before {
  content: "\F1639"; }

.mdi-mirror::before {
  content: "\F11FD"; }

.mdi-mirror-rectangle::before {
  content: "\F179F"; }

.mdi-mirror-variant::before {
  content: "\F17A0"; }

.mdi-mixed-martial-arts::before {
  content: "\F0D8F"; }

.mdi-mixed-reality::before {
  content: "\F087F"; }

.mdi-molecule::before {
  content: "\F0BAC"; }

.mdi-molecule-co::before {
  content: "\F12FE"; }

.mdi-molecule-co2::before {
  content: "\F07E4"; }

.mdi-monitor::before {
  content: "\F0379"; }

.mdi-monitor-account::before {
  content: "\F1A5B"; }

.mdi-monitor-arrow-down::before {
  content: "\F19D0"; }

.mdi-monitor-arrow-down-variant::before {
  content: "\F19D1"; }

.mdi-monitor-cellphone::before {
  content: "\F0989"; }

.mdi-monitor-cellphone-star::before {
  content: "\F098A"; }

.mdi-monitor-dashboard::before {
  content: "\F0A07"; }

.mdi-monitor-edit::before {
  content: "\F12C6"; }

.mdi-monitor-eye::before {
  content: "\F13B4"; }

.mdi-monitor-lock::before {
  content: "\F0DDB"; }

.mdi-monitor-multiple::before {
  content: "\F037A"; }

.mdi-monitor-off::before {
  content: "\F0D90"; }

.mdi-monitor-screenshot::before {
  content: "\F0E51"; }

.mdi-monitor-share::before {
  content: "\F1483"; }

.mdi-monitor-shimmer::before {
  content: "\F1104"; }

.mdi-monitor-small::before {
  content: "\F1876"; }

.mdi-monitor-speaker::before {
  content: "\F0F5F"; }

.mdi-monitor-speaker-off::before {
  content: "\F0F60"; }

.mdi-monitor-star::before {
  content: "\F0DDC"; }

.mdi-moon-first-quarter::before {
  content: "\F0F61"; }

.mdi-moon-full::before {
  content: "\F0F62"; }

.mdi-moon-last-quarter::before {
  content: "\F0F63"; }

.mdi-moon-new::before {
  content: "\F0F64"; }

.mdi-moon-waning-crescent::before {
  content: "\F0F65"; }

.mdi-moon-waning-gibbous::before {
  content: "\F0F66"; }

.mdi-moon-waxing-crescent::before {
  content: "\F0F67"; }

.mdi-moon-waxing-gibbous::before {
  content: "\F0F68"; }

.mdi-moped::before {
  content: "\F1086"; }

.mdi-moped-electric::before {
  content: "\F15B7"; }

.mdi-moped-electric-outline::before {
  content: "\F15B8"; }

.mdi-moped-outline::before {
  content: "\F15B9"; }

.mdi-more::before {
  content: "\F037B"; }

.mdi-mortar-pestle::before {
  content: "\F1748"; }

.mdi-mortar-pestle-plus::before {
  content: "\F03F1"; }

.mdi-mosque::before {
  content: "\F0D45"; }

.mdi-mosque-outline::before {
  content: "\F1827"; }

.mdi-mother-heart::before {
  content: "\F1314"; }

.mdi-mother-nurse::before {
  content: "\F0D21"; }

.mdi-motion::before {
  content: "\F15B2"; }

.mdi-motion-outline::before {
  content: "\F15B3"; }

.mdi-motion-pause::before {
  content: "\F1590"; }

.mdi-motion-pause-outline::before {
  content: "\F1592"; }

.mdi-motion-play::before {
  content: "\F158F"; }

.mdi-motion-play-outline::before {
  content: "\F1591"; }

.mdi-motion-sensor::before {
  content: "\F0D91"; }

.mdi-motion-sensor-off::before {
  content: "\F1435"; }

.mdi-motorbike::before {
  content: "\F037C"; }

.mdi-motorbike-electric::before {
  content: "\F15BA"; }

.mdi-motorbike-off::before {
  content: "\F1B16"; }

.mdi-mouse::before {
  content: "\F037D"; }

.mdi-mouse-bluetooth::before {
  content: "\F098B"; }

.mdi-mouse-move-down::before {
  content: "\F1550"; }

.mdi-mouse-move-up::before {
  content: "\F1551"; }

.mdi-mouse-move-vertical::before {
  content: "\F1552"; }

.mdi-mouse-off::before {
  content: "\F037E"; }

.mdi-mouse-variant::before {
  content: "\F037F"; }

.mdi-mouse-variant-off::before {
  content: "\F0380"; }

.mdi-move-resize::before {
  content: "\F0655"; }

.mdi-move-resize-variant::before {
  content: "\F0656"; }

.mdi-movie::before {
  content: "\F0381"; }

.mdi-movie-check::before {
  content: "\F16F3"; }

.mdi-movie-check-outline::before {
  content: "\F16F4"; }

.mdi-movie-cog::before {
  content: "\F16F5"; }

.mdi-movie-cog-outline::before {
  content: "\F16F6"; }

.mdi-movie-edit::before {
  content: "\F1122"; }

.mdi-movie-edit-outline::before {
  content: "\F1123"; }

.mdi-movie-filter::before {
  content: "\F1124"; }

.mdi-movie-filter-outline::before {
  content: "\F1125"; }

.mdi-movie-minus::before {
  content: "\F16F7"; }

.mdi-movie-minus-outline::before {
  content: "\F16F8"; }

.mdi-movie-off::before {
  content: "\F16F9"; }

.mdi-movie-off-outline::before {
  content: "\F16FA"; }

.mdi-movie-open::before {
  content: "\F0FCE"; }

.mdi-movie-open-check::before {
  content: "\F16FB"; }

.mdi-movie-open-check-outline::before {
  content: "\F16FC"; }

.mdi-movie-open-cog::before {
  content: "\F16FD"; }

.mdi-movie-open-cog-outline::before {
  content: "\F16FE"; }

.mdi-movie-open-edit::before {
  content: "\F16FF"; }

.mdi-movie-open-edit-outline::before {
  content: "\F1700"; }

.mdi-movie-open-minus::before {
  content: "\F1701"; }

.mdi-movie-open-minus-outline::before {
  content: "\F1702"; }

.mdi-movie-open-off::before {
  content: "\F1703"; }

.mdi-movie-open-off-outline::before {
  content: "\F1704"; }

.mdi-movie-open-outline::before {
  content: "\F0FCF"; }

.mdi-movie-open-play::before {
  content: "\F1705"; }

.mdi-movie-open-play-outline::before {
  content: "\F1706"; }

.mdi-movie-open-plus::before {
  content: "\F1707"; }

.mdi-movie-open-plus-outline::before {
  content: "\F1708"; }

.mdi-movie-open-remove::before {
  content: "\F1709"; }

.mdi-movie-open-remove-outline::before {
  content: "\F170A"; }

.mdi-movie-open-settings::before {
  content: "\F170B"; }

.mdi-movie-open-settings-outline::before {
  content: "\F170C"; }

.mdi-movie-open-star::before {
  content: "\F170D"; }

.mdi-movie-open-star-outline::before {
  content: "\F170E"; }

.mdi-movie-outline::before {
  content: "\F0DDD"; }

.mdi-movie-play::before {
  content: "\F170F"; }

.mdi-movie-play-outline::before {
  content: "\F1710"; }

.mdi-movie-plus::before {
  content: "\F1711"; }

.mdi-movie-plus-outline::before {
  content: "\F1712"; }

.mdi-movie-remove::before {
  content: "\F1713"; }

.mdi-movie-remove-outline::before {
  content: "\F1714"; }

.mdi-movie-roll::before {
  content: "\F07DE"; }

.mdi-movie-search::before {
  content: "\F11D2"; }

.mdi-movie-search-outline::before {
  content: "\F11D3"; }

.mdi-movie-settings::before {
  content: "\F1715"; }

.mdi-movie-settings-outline::before {
  content: "\F1716"; }

.mdi-movie-star::before {
  content: "\F1717"; }

.mdi-movie-star-outline::before {
  content: "\F1718"; }

.mdi-mower::before {
  content: "\F166F"; }

.mdi-mower-bag::before {
  content: "\F1670"; }

.mdi-mower-bag-on::before {
  content: "\F1B60"; }

.mdi-mower-on::before {
  content: "\F1B5F"; }

.mdi-muffin::before {
  content: "\F098C"; }

.mdi-multicast::before {
  content: "\F1893"; }

.mdi-multimedia::before {
  content: "\F1B97"; }

.mdi-multiplication::before {
  content: "\F0382"; }

.mdi-multiplication-box::before {
  content: "\F0383"; }

.mdi-mushroom::before {
  content: "\F07DF"; }

.mdi-mushroom-off::before {
  content: "\F13FA"; }

.mdi-mushroom-off-outline::before {
  content: "\F13FB"; }

.mdi-mushroom-outline::before {
  content: "\F07E0"; }

.mdi-music::before {
  content: "\F075A"; }

.mdi-music-accidental-double-flat::before {
  content: "\F0F69"; }

.mdi-music-accidental-double-sharp::before {
  content: "\F0F6A"; }

.mdi-music-accidental-flat::before {
  content: "\F0F6B"; }

.mdi-music-accidental-natural::before {
  content: "\F0F6C"; }

.mdi-music-accidental-sharp::before {
  content: "\F0F6D"; }

.mdi-music-box::before {
  content: "\F0384"; }

.mdi-music-box-multiple::before {
  content: "\F0333"; }

.mdi-music-box-multiple-outline::before {
  content: "\F0F04"; }

.mdi-music-box-outline::before {
  content: "\F0385"; }

.mdi-music-circle::before {
  content: "\F0386"; }

.mdi-music-circle-outline::before {
  content: "\F0AD4"; }

.mdi-music-clef-alto::before {
  content: "\F0F6E"; }

.mdi-music-clef-bass::before {
  content: "\F0F6F"; }

.mdi-music-clef-treble::before {
  content: "\F0F70"; }

.mdi-music-note::before {
  content: "\F0387"; }

.mdi-music-note-bluetooth::before {
  content: "\F05FE"; }

.mdi-music-note-bluetooth-off::before {
  content: "\F05FF"; }

.mdi-music-note-eighth::before {
  content: "\F0388"; }

.mdi-music-note-eighth-dotted::before {
  content: "\F0F71"; }

.mdi-music-note-half::before {
  content: "\F0389"; }

.mdi-music-note-half-dotted::before {
  content: "\F0F72"; }

.mdi-music-note-minus::before {
  content: "\F1B89"; }

.mdi-music-note-off::before {
  content: "\F038A"; }

.mdi-music-note-off-outline::before {
  content: "\F0F73"; }

.mdi-music-note-outline::before {
  content: "\F0F74"; }

.mdi-music-note-plus::before {
  content: "\F0DDE"; }

.mdi-music-note-quarter::before {
  content: "\F038B"; }

.mdi-music-note-quarter-dotted::before {
  content: "\F0F75"; }

.mdi-music-note-sixteenth::before {
  content: "\F038C"; }

.mdi-music-note-sixteenth-dotted::before {
  content: "\F0F76"; }

.mdi-music-note-whole::before {
  content: "\F038D"; }

.mdi-music-note-whole-dotted::before {
  content: "\F0F77"; }

.mdi-music-off::before {
  content: "\F075B"; }

.mdi-music-rest-eighth::before {
  content: "\F0F78"; }

.mdi-music-rest-half::before {
  content: "\F0F79"; }

.mdi-music-rest-quarter::before {
  content: "\F0F7A"; }

.mdi-music-rest-sixteenth::before {
  content: "\F0F7B"; }

.mdi-music-rest-whole::before {
  content: "\F0F7C"; }

.mdi-mustache::before {
  content: "\F15DE"; }

.mdi-nail::before {
  content: "\F0DDF"; }

.mdi-nas::before {
  content: "\F08F3"; }

.mdi-nativescript::before {
  content: "\F0880"; }

.mdi-nature::before {
  content: "\F038E"; }

.mdi-nature-people::before {
  content: "\F038F"; }

.mdi-navigation::before {
  content: "\F0390"; }

.mdi-navigation-outline::before {
  content: "\F1607"; }

.mdi-navigation-variant::before {
  content: "\F18F0"; }

.mdi-navigation-variant-outline::before {
  content: "\F18F1"; }

.mdi-near-me::before {
  content: "\F05CD"; }

.mdi-necklace::before {
  content: "\F0F0B"; }

.mdi-needle::before {
  content: "\F0391"; }

.mdi-needle-off::before {
  content: "\F19D2"; }

.mdi-netflix::before {
  content: "\F0746"; }

.mdi-network::before {
  content: "\F06F3"; }

.mdi-network-off::before {
  content: "\F0C9B"; }

.mdi-network-off-outline::before {
  content: "\F0C9C"; }

.mdi-network-outline::before {
  content: "\F0C9D"; }

.mdi-network-pos::before {
  content: "\F1ACB"; }

.mdi-network-strength-1::before {
  content: "\F08F4"; }

.mdi-network-strength-1-alert::before {
  content: "\F08F5"; }

.mdi-network-strength-2::before {
  content: "\F08F6"; }

.mdi-network-strength-2-alert::before {
  content: "\F08F7"; }

.mdi-network-strength-3::before {
  content: "\F08F8"; }

.mdi-network-strength-3-alert::before {
  content: "\F08F9"; }

.mdi-network-strength-4::before {
  content: "\F08FA"; }

.mdi-network-strength-4-alert::before {
  content: "\F08FB"; }

.mdi-network-strength-4-cog::before {
  content: "\F191A"; }

.mdi-network-strength-off::before {
  content: "\F08FC"; }

.mdi-network-strength-off-outline::before {
  content: "\F08FD"; }

.mdi-network-strength-outline::before {
  content: "\F08FE"; }

.mdi-new-box::before {
  content: "\F0394"; }

.mdi-newspaper::before {
  content: "\F0395"; }

.mdi-newspaper-check::before {
  content: "\F1943"; }

.mdi-newspaper-minus::before {
  content: "\F0F0C"; }

.mdi-newspaper-plus::before {
  content: "\F0F0D"; }

.mdi-newspaper-remove::before {
  content: "\F1944"; }

.mdi-newspaper-variant::before {
  content: "\F1001"; }

.mdi-newspaper-variant-multiple::before {
  content: "\F1002"; }

.mdi-newspaper-variant-multiple-outline::before {
  content: "\F1003"; }

.mdi-newspaper-variant-outline::before {
  content: "\F1004"; }

.mdi-nfc::before {
  content: "\F0396"; }

.mdi-nfc-search-variant::before {
  content: "\F0E53"; }

.mdi-nfc-tap::before {
  content: "\F0397"; }

.mdi-nfc-variant::before {
  content: "\F0398"; }

.mdi-nfc-variant-off::before {
  content: "\F0E54"; }

.mdi-ninja::before {
  content: "\F0774"; }

.mdi-nintendo-game-boy::before {
  content: "\F1393"; }

.mdi-nintendo-switch::before {
  content: "\F07E1"; }

.mdi-nintendo-wii::before {
  content: "\F05AB"; }

.mdi-nintendo-wiiu::before {
  content: "\F072D"; }

.mdi-nix::before {
  content: "\F1105"; }

.mdi-nodejs::before {
  content: "\F0399"; }

.mdi-noodles::before {
  content: "\F117E"; }

.mdi-not-equal::before {
  content: "\F098D"; }

.mdi-not-equal-variant::before {
  content: "\F098E"; }

.mdi-note::before {
  content: "\F039A"; }

.mdi-note-alert::before {
  content: "\F177D"; }

.mdi-note-alert-outline::before {
  content: "\F177E"; }

.mdi-note-check::before {
  content: "\F177F"; }

.mdi-note-check-outline::before {
  content: "\F1780"; }

.mdi-note-edit::before {
  content: "\F1781"; }

.mdi-note-edit-outline::before {
  content: "\F1782"; }

.mdi-note-minus::before {
  content: "\F164F"; }

.mdi-note-minus-outline::before {
  content: "\F1650"; }

.mdi-note-multiple::before {
  content: "\F06B8"; }

.mdi-note-multiple-outline::before {
  content: "\F06B9"; }

.mdi-note-off::before {
  content: "\F1783"; }

.mdi-note-off-outline::before {
  content: "\F1784"; }

.mdi-note-outline::before {
  content: "\F039B"; }

.mdi-note-plus::before {
  content: "\F039C"; }

.mdi-note-plus-outline::before {
  content: "\F039D"; }

.mdi-note-remove::before {
  content: "\F1651"; }

.mdi-note-remove-outline::before {
  content: "\F1652"; }

.mdi-note-search::before {
  content: "\F1653"; }

.mdi-note-search-outline::before {
  content: "\F1654"; }

.mdi-note-text::before {
  content: "\F039E"; }

.mdi-note-text-outline::before {
  content: "\F11D7"; }

.mdi-notebook::before {
  content: "\F082E"; }

.mdi-notebook-check::before {
  content: "\F14F5"; }

.mdi-notebook-check-outline::before {
  content: "\F14F6"; }

.mdi-notebook-edit::before {
  content: "\F14E7"; }

.mdi-notebook-edit-outline::before {
  content: "\F14E9"; }

.mdi-notebook-heart::before {
  content: "\F1A0B"; }

.mdi-notebook-heart-outline::before {
  content: "\F1A0C"; }

.mdi-notebook-minus::before {
  content: "\F1610"; }

.mdi-notebook-minus-outline::before {
  content: "\F1611"; }

.mdi-notebook-multiple::before {
  content: "\F0E55"; }

.mdi-notebook-outline::before {
  content: "\F0EBF"; }

.mdi-notebook-plus::before {
  content: "\F1612"; }

.mdi-notebook-plus-outline::before {
  content: "\F1613"; }

.mdi-notebook-remove::before {
  content: "\F1614"; }

.mdi-notebook-remove-outline::before {
  content: "\F1615"; }

.mdi-notification-clear-all::before {
  content: "\F039F"; }

.mdi-npm::before {
  content: "\F06F7"; }

.mdi-nuke::before {
  content: "\F06A4"; }

.mdi-null::before {
  content: "\F07E2"; }

.mdi-numeric::before {
  content: "\F03A0"; }

.mdi-numeric-0::before {
  content: "\F0B39"; }

.mdi-numeric-0-box::before {
  content: "\F03A1"; }

.mdi-numeric-0-box-multiple::before {
  content: "\F0F0E"; }

.mdi-numeric-0-box-multiple-outline::before {
  content: "\F03A2"; }

.mdi-numeric-0-box-outline::before {
  content: "\F03A3"; }

.mdi-numeric-0-circle::before {
  content: "\F0C9E"; }

.mdi-numeric-0-circle-outline::before {
  content: "\F0C9F"; }

.mdi-numeric-1::before {
  content: "\F0B3A"; }

.mdi-numeric-1-box::before {
  content: "\F03A4"; }

.mdi-numeric-1-box-multiple::before {
  content: "\F0F0F"; }

.mdi-numeric-1-box-multiple-outline::before {
  content: "\F03A5"; }

.mdi-numeric-1-box-outline::before {
  content: "\F03A6"; }

.mdi-numeric-1-circle::before {
  content: "\F0CA0"; }

.mdi-numeric-1-circle-outline::before {
  content: "\F0CA1"; }

.mdi-numeric-10::before {
  content: "\F0FE9"; }

.mdi-numeric-10-box::before {
  content: "\F0F7D"; }

.mdi-numeric-10-box-multiple::before {
  content: "\F0FEA"; }

.mdi-numeric-10-box-multiple-outline::before {
  content: "\F0FEB"; }

.mdi-numeric-10-box-outline::before {
  content: "\F0F7E"; }

.mdi-numeric-10-circle::before {
  content: "\F0FEC"; }

.mdi-numeric-10-circle-outline::before {
  content: "\F0FED"; }

.mdi-numeric-2::before {
  content: "\F0B3B"; }

.mdi-numeric-2-box::before {
  content: "\F03A7"; }

.mdi-numeric-2-box-multiple::before {
  content: "\F0F10"; }

.mdi-numeric-2-box-multiple-outline::before {
  content: "\F03A8"; }

.mdi-numeric-2-box-outline::before {
  content: "\F03A9"; }

.mdi-numeric-2-circle::before {
  content: "\F0CA2"; }

.mdi-numeric-2-circle-outline::before {
  content: "\F0CA3"; }

.mdi-numeric-3::before {
  content: "\F0B3C"; }

.mdi-numeric-3-box::before {
  content: "\F03AA"; }

.mdi-numeric-3-box-multiple::before {
  content: "\F0F11"; }

.mdi-numeric-3-box-multiple-outline::before {
  content: "\F03AB"; }

.mdi-numeric-3-box-outline::before {
  content: "\F03AC"; }

.mdi-numeric-3-circle::before {
  content: "\F0CA4"; }

.mdi-numeric-3-circle-outline::before {
  content: "\F0CA5"; }

.mdi-numeric-4::before {
  content: "\F0B3D"; }

.mdi-numeric-4-box::before {
  content: "\F03AD"; }

.mdi-numeric-4-box-multiple::before {
  content: "\F0F12"; }

.mdi-numeric-4-box-multiple-outline::before {
  content: "\F03B2"; }

.mdi-numeric-4-box-outline::before {
  content: "\F03AE"; }

.mdi-numeric-4-circle::before {
  content: "\F0CA6"; }

.mdi-numeric-4-circle-outline::before {
  content: "\F0CA7"; }

.mdi-numeric-5::before {
  content: "\F0B3E"; }

.mdi-numeric-5-box::before {
  content: "\F03B1"; }

.mdi-numeric-5-box-multiple::before {
  content: "\F0F13"; }

.mdi-numeric-5-box-multiple-outline::before {
  content: "\F03AF"; }

.mdi-numeric-5-box-outline::before {
  content: "\F03B0"; }

.mdi-numeric-5-circle::before {
  content: "\F0CA8"; }

.mdi-numeric-5-circle-outline::before {
  content: "\F0CA9"; }

.mdi-numeric-6::before {
  content: "\F0B3F"; }

.mdi-numeric-6-box::before {
  content: "\F03B3"; }

.mdi-numeric-6-box-multiple::before {
  content: "\F0F14"; }

.mdi-numeric-6-box-multiple-outline::before {
  content: "\F03B4"; }

.mdi-numeric-6-box-outline::before {
  content: "\F03B5"; }

.mdi-numeric-6-circle::before {
  content: "\F0CAA"; }

.mdi-numeric-6-circle-outline::before {
  content: "\F0CAB"; }

.mdi-numeric-7::before {
  content: "\F0B40"; }

.mdi-numeric-7-box::before {
  content: "\F03B6"; }

.mdi-numeric-7-box-multiple::before {
  content: "\F0F15"; }

.mdi-numeric-7-box-multiple-outline::before {
  content: "\F03B7"; }

.mdi-numeric-7-box-outline::before {
  content: "\F03B8"; }

.mdi-numeric-7-circle::before {
  content: "\F0CAC"; }

.mdi-numeric-7-circle-outline::before {
  content: "\F0CAD"; }

.mdi-numeric-8::before {
  content: "\F0B41"; }

.mdi-numeric-8-box::before {
  content: "\F03B9"; }

.mdi-numeric-8-box-multiple::before {
  content: "\F0F16"; }

.mdi-numeric-8-box-multiple-outline::before {
  content: "\F03BA"; }

.mdi-numeric-8-box-outline::before {
  content: "\F03BB"; }

.mdi-numeric-8-circle::before {
  content: "\F0CAE"; }

.mdi-numeric-8-circle-outline::before {
  content: "\F0CAF"; }

.mdi-numeric-9::before {
  content: "\F0B42"; }

.mdi-numeric-9-box::before {
  content: "\F03BC"; }

.mdi-numeric-9-box-multiple::before {
  content: "\F0F17"; }

.mdi-numeric-9-box-multiple-outline::before {
  content: "\F03BD"; }

.mdi-numeric-9-box-outline::before {
  content: "\F03BE"; }

.mdi-numeric-9-circle::before {
  content: "\F0CB0"; }

.mdi-numeric-9-circle-outline::before {
  content: "\F0CB1"; }

.mdi-numeric-9-plus::before {
  content: "\F0FEE"; }

.mdi-numeric-9-plus-box::before {
  content: "\F03BF"; }

.mdi-numeric-9-plus-box-multiple::before {
  content: "\F0F18"; }

.mdi-numeric-9-plus-box-multiple-outline::before {
  content: "\F03C0"; }

.mdi-numeric-9-plus-box-outline::before {
  content: "\F03C1"; }

.mdi-numeric-9-plus-circle::before {
  content: "\F0CB2"; }

.mdi-numeric-9-plus-circle-outline::before {
  content: "\F0CB3"; }

.mdi-numeric-negative-1::before {
  content: "\F1052"; }

.mdi-numeric-off::before {
  content: "\F19D3"; }

.mdi-numeric-positive-1::before {
  content: "\F15CB"; }

.mdi-nut::before {
  content: "\F06F8"; }

.mdi-nutrition::before {
  content: "\F03C2"; }

.mdi-nuxt::before {
  content: "\F1106"; }

.mdi-oar::before {
  content: "\F067C"; }

.mdi-ocarina::before {
  content: "\F0DE0"; }

.mdi-oci::before {
  content: "\F12E9"; }

.mdi-ocr::before {
  content: "\F113A"; }

.mdi-octagon::before {
  content: "\F03C3"; }

.mdi-octagon-outline::before {
  content: "\F03C4"; }

.mdi-octagram::before {
  content: "\F06F9"; }

.mdi-octagram-outline::before {
  content: "\F0775"; }

.mdi-octahedron::before {
  content: "\F1950"; }

.mdi-octahedron-off::before {
  content: "\F1951"; }

.mdi-odnoklassniki::before {
  content: "\F03C5"; }

.mdi-offer::before {
  content: "\F121B"; }

.mdi-office-building::before {
  content: "\F0991"; }

.mdi-office-building-cog::before {
  content: "\F1949"; }

.mdi-office-building-cog-outline::before {
  content: "\F194A"; }

.mdi-office-building-marker::before {
  content: "\F1520"; }

.mdi-office-building-marker-outline::before {
  content: "\F1521"; }

.mdi-office-building-minus::before {
  content: "\F1BAA"; }

.mdi-office-building-minus-outline::before {
  content: "\F1BAB"; }

.mdi-office-building-outline::before {
  content: "\F151F"; }

.mdi-office-building-plus::before {
  content: "\F1BA8"; }

.mdi-office-building-plus-outline::before {
  content: "\F1BA9"; }

.mdi-office-building-remove::before {
  content: "\F1BAC"; }

.mdi-office-building-remove-outline::before {
  content: "\F1BAD"; }

.mdi-oil::before {
  content: "\F03C7"; }

.mdi-oil-lamp::before {
  content: "\F0F19"; }

.mdi-oil-level::before {
  content: "\F1053"; }

.mdi-oil-temperature::before {
  content: "\F0FF8"; }

.mdi-om::before {
  content: "\F0973"; }

.mdi-omega::before {
  content: "\F03C9"; }

.mdi-one-up::before {
  content: "\F0BAD"; }

.mdi-onepassword::before {
  content: "\F0881"; }

.mdi-opacity::before {
  content: "\F05CC"; }

.mdi-open-in-app::before {
  content: "\F03CB"; }

.mdi-open-in-new::before {
  content: "\F03CC"; }

.mdi-open-source-initiative::before {
  content: "\F0BAE"; }

.mdi-openid::before {
  content: "\F03CD"; }

.mdi-opera::before {
  content: "\F03CE"; }

.mdi-orbit::before {
  content: "\F0018"; }

.mdi-orbit-variant::before {
  content: "\F15DB"; }

.mdi-order-alphabetical-ascending::before {
  content: "\F020D"; }

.mdi-order-alphabetical-descending::before {
  content: "\F0D07"; }

.mdi-order-bool-ascending::before {
  content: "\F02BE"; }

.mdi-order-bool-ascending-variant::before {
  content: "\F098F"; }

.mdi-order-bool-descending::before {
  content: "\F1384"; }

.mdi-order-bool-descending-variant::before {
  content: "\F0990"; }

.mdi-order-numeric-ascending::before {
  content: "\F0545"; }

.mdi-order-numeric-descending::before {
  content: "\F0546"; }

.mdi-origin::before {
  content: "\F0B43"; }

.mdi-ornament::before {
  content: "\F03CF"; }

.mdi-ornament-variant::before {
  content: "\F03D0"; }

.mdi-outdoor-lamp::before {
  content: "\F1054"; }

.mdi-overscan::before {
  content: "\F1005"; }

.mdi-owl::before {
  content: "\F03D2"; }

.mdi-pac-man::before {
  content: "\F0BAF"; }

.mdi-package::before {
  content: "\F03D3"; }

.mdi-package-check::before {
  content: "\F1B51"; }

.mdi-package-down::before {
  content: "\F03D4"; }

.mdi-package-up::before {
  content: "\F03D5"; }

.mdi-package-variant::before {
  content: "\F03D6"; }

.mdi-package-variant-closed::before {
  content: "\F03D7"; }

.mdi-package-variant-closed-check::before {
  content: "\F1B52"; }

.mdi-package-variant-closed-minus::before {
  content: "\F19D4"; }

.mdi-package-variant-closed-plus::before {
  content: "\F19D5"; }

.mdi-package-variant-closed-remove::before {
  content: "\F19D6"; }

.mdi-package-variant-minus::before {
  content: "\F19D7"; }

.mdi-package-variant-plus::before {
  content: "\F19D8"; }

.mdi-package-variant-remove::before {
  content: "\F19D9"; }

.mdi-page-first::before {
  content: "\F0600"; }

.mdi-page-last::before {
  content: "\F0601"; }

.mdi-page-layout-body::before {
  content: "\F06FA"; }

.mdi-page-layout-footer::before {
  content: "\F06FB"; }

.mdi-page-layout-header::before {
  content: "\F06FC"; }

.mdi-page-layout-header-footer::before {
  content: "\F0F7F"; }

.mdi-page-layout-sidebar-left::before {
  content: "\F06FD"; }

.mdi-page-layout-sidebar-right::before {
  content: "\F06FE"; }

.mdi-page-next::before {
  content: "\F0BB0"; }

.mdi-page-next-outline::before {
  content: "\F0BB1"; }

.mdi-page-previous::before {
  content: "\F0BB2"; }

.mdi-page-previous-outline::before {
  content: "\F0BB3"; }

.mdi-pail::before {
  content: "\F1417"; }

.mdi-pail-minus::before {
  content: "\F1437"; }

.mdi-pail-minus-outline::before {
  content: "\F143C"; }

.mdi-pail-off::before {
  content: "\F1439"; }

.mdi-pail-off-outline::before {
  content: "\F143E"; }

.mdi-pail-outline::before {
  content: "\F143A"; }

.mdi-pail-plus::before {
  content: "\F1436"; }

.mdi-pail-plus-outline::before {
  content: "\F143B"; }

.mdi-pail-remove::before {
  content: "\F1438"; }

.mdi-pail-remove-outline::before {
  content: "\F143D"; }

.mdi-palette::before {
  content: "\F03D8"; }

.mdi-palette-advanced::before {
  content: "\F03D9"; }

.mdi-palette-outline::before {
  content: "\F0E0C"; }

.mdi-palette-swatch::before {
  content: "\F08B5"; }

.mdi-palette-swatch-outline::before {
  content: "\F135C"; }

.mdi-palette-swatch-variant::before {
  content: "\F195A"; }

.mdi-palm-tree::before {
  content: "\F1055"; }

.mdi-pan::before {
  content: "\F0BB4"; }

.mdi-pan-bottom-left::before {
  content: "\F0BB5"; }

.mdi-pan-bottom-right::before {
  content: "\F0BB6"; }

.mdi-pan-down::before {
  content: "\F0BB7"; }

.mdi-pan-horizontal::before {
  content: "\F0BB8"; }

.mdi-pan-left::before {
  content: "\F0BB9"; }

.mdi-pan-right::before {
  content: "\F0BBA"; }

.mdi-pan-top-left::before {
  content: "\F0BBB"; }

.mdi-pan-top-right::before {
  content: "\F0BBC"; }

.mdi-pan-up::before {
  content: "\F0BBD"; }

.mdi-pan-vertical::before {
  content: "\F0BBE"; }

.mdi-panda::before {
  content: "\F03DA"; }

.mdi-pandora::before {
  content: "\F03DB"; }

.mdi-panorama::before {
  content: "\F03DC"; }

.mdi-panorama-fisheye::before {
  content: "\F03DD"; }

.mdi-panorama-horizontal::before {
  content: "\F1928"; }

.mdi-panorama-horizontal-outline::before {
  content: "\F03DE"; }

.mdi-panorama-outline::before {
  content: "\F198C"; }

.mdi-panorama-sphere::before {
  content: "\F198D"; }

.mdi-panorama-sphere-outline::before {
  content: "\F198E"; }

.mdi-panorama-variant::before {
  content: "\F198F"; }

.mdi-panorama-variant-outline::before {
  content: "\F1990"; }

.mdi-panorama-vertical::before {
  content: "\F1929"; }

.mdi-panorama-vertical-outline::before {
  content: "\F03DF"; }

.mdi-panorama-wide-angle::before {
  content: "\F195F"; }

.mdi-panorama-wide-angle-outline::before {
  content: "\F03E0"; }

.mdi-paper-cut-vertical::before {
  content: "\F03E1"; }

.mdi-paper-roll::before {
  content: "\F1157"; }

.mdi-paper-roll-outline::before {
  content: "\F1158"; }

.mdi-paperclip::before {
  content: "\F03E2"; }

.mdi-paperclip-check::before {
  content: "\F1AC6"; }

.mdi-paperclip-lock::before {
  content: "\F19DA"; }

.mdi-paperclip-minus::before {
  content: "\F1AC7"; }

.mdi-paperclip-off::before {
  content: "\F1AC8"; }

.mdi-paperclip-plus::before {
  content: "\F1AC9"; }

.mdi-paperclip-remove::before {
  content: "\F1ACA"; }

.mdi-parachute::before {
  content: "\F0CB4"; }

.mdi-parachute-outline::before {
  content: "\F0CB5"; }

.mdi-paragliding::before {
  content: "\F1745"; }

.mdi-parking::before {
  content: "\F03E3"; }

.mdi-party-popper::before {
  content: "\F1056"; }

.mdi-passport::before {
  content: "\F07E3"; }

.mdi-passport-biometric::before {
  content: "\F0DE1"; }

.mdi-pasta::before {
  content: "\F1160"; }

.mdi-patio-heater::before {
  content: "\F0F80"; }

.mdi-patreon::before {
  content: "\F0882"; }

.mdi-pause::before {
  content: "\F03E4"; }

.mdi-pause-box::before {
  content: "\F00BC"; }

.mdi-pause-box-outline::before {
  content: "\F1B7A"; }

.mdi-pause-circle::before {
  content: "\F03E5"; }

.mdi-pause-circle-outline::before {
  content: "\F03E6"; }

.mdi-pause-octagon::before {
  content: "\F03E7"; }

.mdi-pause-octagon-outline::before {
  content: "\F03E8"; }

.mdi-paw::before {
  content: "\F03E9"; }

.mdi-paw-off::before {
  content: "\F0657"; }

.mdi-paw-off-outline::before {
  content: "\F1676"; }

.mdi-paw-outline::before {
  content: "\F1675"; }

.mdi-peace::before {
  content: "\F0884"; }

.mdi-peanut::before {
  content: "\F0FFC"; }

.mdi-peanut-off::before {
  content: "\F0FFD"; }

.mdi-peanut-off-outline::before {
  content: "\F0FFF"; }

.mdi-peanut-outline::before {
  content: "\F0FFE"; }

.mdi-pen::before {
  content: "\F03EA"; }

.mdi-pen-lock::before {
  content: "\F0DE2"; }

.mdi-pen-minus::before {
  content: "\F0DE3"; }

.mdi-pen-off::before {
  content: "\F0DE4"; }

.mdi-pen-plus::before {
  content: "\F0DE5"; }

.mdi-pen-remove::before {
  content: "\F0DE6"; }

.mdi-pencil::before {
  content: "\F03EB"; }

.mdi-pencil-box::before {
  content: "\F03EC"; }

.mdi-pencil-box-multiple::before {
  content: "\F1144"; }

.mdi-pencil-box-multiple-outline::before {
  content: "\F1145"; }

.mdi-pencil-box-outline::before {
  content: "\F03ED"; }

.mdi-pencil-circle::before {
  content: "\F06FF"; }

.mdi-pencil-circle-outline::before {
  content: "\F0776"; }

.mdi-pencil-lock::before {
  content: "\F03EE"; }

.mdi-pencil-lock-outline::before {
  content: "\F0DE7"; }

.mdi-pencil-minus::before {
  content: "\F0DE8"; }

.mdi-pencil-minus-outline::before {
  content: "\F0DE9"; }

.mdi-pencil-off::before {
  content: "\F03EF"; }

.mdi-pencil-off-outline::before {
  content: "\F0DEA"; }

.mdi-pencil-outline::before {
  content: "\F0CB6"; }

.mdi-pencil-plus::before {
  content: "\F0DEB"; }

.mdi-pencil-plus-outline::before {
  content: "\F0DEC"; }

.mdi-pencil-remove::before {
  content: "\F0DED"; }

.mdi-pencil-remove-outline::before {
  content: "\F0DEE"; }

.mdi-pencil-ruler::before {
  content: "\F1353"; }

.mdi-penguin::before {
  content: "\F0EC0"; }

.mdi-pentagon::before {
  content: "\F0701"; }

.mdi-pentagon-outline::before {
  content: "\F0700"; }

.mdi-pentagram::before {
  content: "\F1667"; }

.mdi-percent::before {
  content: "\F03F0"; }

.mdi-percent-box::before {
  content: "\F1A02"; }

.mdi-percent-box-outline::before {
  content: "\F1A03"; }

.mdi-percent-circle::before {
  content: "\F1A04"; }

.mdi-percent-circle-outline::before {
  content: "\F1A05"; }

.mdi-percent-outline::before {
  content: "\F1278"; }

.mdi-periodic-table::before {
  content: "\F08B6"; }

.mdi-perspective-less::before {
  content: "\F0D23"; }

.mdi-perspective-more::before {
  content: "\F0D24"; }

.mdi-ph::before {
  content: "\F17C5"; }

.mdi-phone::before {
  content: "\F03F2"; }

.mdi-phone-alert::before {
  content: "\F0F1A"; }

.mdi-phone-alert-outline::before {
  content: "\F118E"; }

.mdi-phone-bluetooth::before {
  content: "\F03F3"; }

.mdi-phone-bluetooth-outline::before {
  content: "\F118F"; }

.mdi-phone-cancel::before {
  content: "\F10BC"; }

.mdi-phone-cancel-outline::before {
  content: "\F1190"; }

.mdi-phone-check::before {
  content: "\F11A9"; }

.mdi-phone-check-outline::before {
  content: "\F11AA"; }

.mdi-phone-classic::before {
  content: "\F0602"; }

.mdi-phone-classic-off::before {
  content: "\F1279"; }

.mdi-phone-clock::before {
  content: "\F19DB"; }

.mdi-phone-dial::before {
  content: "\F1559"; }

.mdi-phone-dial-outline::before {
  content: "\F155A"; }

.mdi-phone-forward::before {
  content: "\F03F4"; }

.mdi-phone-forward-outline::before {
  content: "\F1191"; }

.mdi-phone-hangup::before {
  content: "\F03F5"; }

.mdi-phone-hangup-outline::before {
  content: "\F1192"; }

.mdi-phone-in-talk::before {
  content: "\F03F6"; }

.mdi-phone-in-talk-outline::before {
  content: "\F1182"; }

.mdi-phone-incoming::before {
  content: "\F03F7"; }

.mdi-phone-incoming-outgoing::before {
  content: "\F1B3F"; }

.mdi-phone-incoming-outgoing-outline::before {
  content: "\F1B40"; }

.mdi-phone-incoming-outline::before {
  content: "\F1193"; }

.mdi-phone-lock::before {
  content: "\F03F8"; }

.mdi-phone-lock-outline::before {
  content: "\F1194"; }

.mdi-phone-log::before {
  content: "\F03F9"; }

.mdi-phone-log-outline::before {
  content: "\F1195"; }

.mdi-phone-message::before {
  content: "\F1196"; }

.mdi-phone-message-outline::before {
  content: "\F1197"; }

.mdi-phone-minus::before {
  content: "\F0658"; }

.mdi-phone-minus-outline::before {
  content: "\F1198"; }

.mdi-phone-missed::before {
  content: "\F03FA"; }

.mdi-phone-missed-outline::before {
  content: "\F11A5"; }

.mdi-phone-off::before {
  content: "\F0DEF"; }

.mdi-phone-off-outline::before {
  content: "\F11A6"; }

.mdi-phone-outgoing::before {
  content: "\F03FB"; }

.mdi-phone-outgoing-outline::before {
  content: "\F1199"; }

.mdi-phone-outline::before {
  content: "\F0DF0"; }

.mdi-phone-paused::before {
  content: "\F03FC"; }

.mdi-phone-paused-outline::before {
  content: "\F119A"; }

.mdi-phone-plus::before {
  content: "\F0659"; }

.mdi-phone-plus-outline::before {
  content: "\F119B"; }

.mdi-phone-refresh::before {
  content: "\F1993"; }

.mdi-phone-refresh-outline::before {
  content: "\F1994"; }

.mdi-phone-remove::before {
  content: "\F152F"; }

.mdi-phone-remove-outline::before {
  content: "\F1530"; }

.mdi-phone-return::before {
  content: "\F082F"; }

.mdi-phone-return-outline::before {
  content: "\F119C"; }

.mdi-phone-ring::before {
  content: "\F11AB"; }

.mdi-phone-ring-outline::before {
  content: "\F11AC"; }

.mdi-phone-rotate-landscape::before {
  content: "\F0885"; }

.mdi-phone-rotate-portrait::before {
  content: "\F0886"; }

.mdi-phone-settings::before {
  content: "\F03FD"; }

.mdi-phone-settings-outline::before {
  content: "\F119D"; }

.mdi-phone-sync::before {
  content: "\F1995"; }

.mdi-phone-sync-outline::before {
  content: "\F1996"; }

.mdi-phone-voip::before {
  content: "\F03FE"; }

.mdi-pi::before {
  content: "\F03FF"; }

.mdi-pi-box::before {
  content: "\F0400"; }

.mdi-pi-hole::before {
  content: "\F0DF1"; }

.mdi-piano::before {
  content: "\F067D"; }

.mdi-piano-off::before {
  content: "\F0698"; }

.mdi-pickaxe::before {
  content: "\F08B7"; }

.mdi-picture-in-picture-bottom-right::before {
  content: "\F0E57"; }

.mdi-picture-in-picture-bottom-right-outline::before {
  content: "\F0E58"; }

.mdi-picture-in-picture-top-right::before {
  content: "\F0E59"; }

.mdi-picture-in-picture-top-right-outline::before {
  content: "\F0E5A"; }

.mdi-pier::before {
  content: "\F0887"; }

.mdi-pier-crane::before {
  content: "\F0888"; }

.mdi-pig::before {
  content: "\F0401"; }

.mdi-pig-variant::before {
  content: "\F1006"; }

.mdi-pig-variant-outline::before {
  content: "\F1678"; }

.mdi-piggy-bank::before {
  content: "\F1007"; }

.mdi-piggy-bank-outline::before {
  content: "\F1679"; }

.mdi-pill::before {
  content: "\F0402"; }

.mdi-pill-multiple::before {
  content: "\F1B4C"; }

.mdi-pill-off::before {
  content: "\F1A5C"; }

.mdi-pillar::before {
  content: "\F0702"; }

.mdi-pin::before {
  content: "\F0403"; }

.mdi-pin-off::before {
  content: "\F0404"; }

.mdi-pin-off-outline::before {
  content: "\F0930"; }

.mdi-pin-outline::before {
  content: "\F0931"; }

.mdi-pine-tree::before {
  content: "\F0405"; }

.mdi-pine-tree-box::before {
  content: "\F0406"; }

.mdi-pine-tree-fire::before {
  content: "\F141A"; }

.mdi-pinterest::before {
  content: "\F0407"; }

.mdi-pinwheel::before {
  content: "\F0AD5"; }

.mdi-pinwheel-outline::before {
  content: "\F0AD6"; }

.mdi-pipe::before {
  content: "\F07E5"; }

.mdi-pipe-disconnected::before {
  content: "\F07E6"; }

.mdi-pipe-leak::before {
  content: "\F0889"; }

.mdi-pipe-valve::before {
  content: "\F184D"; }

.mdi-pipe-wrench::before {
  content: "\F1354"; }

.mdi-pirate::before {
  content: "\F0A08"; }

.mdi-pistol::before {
  content: "\F0703"; }

.mdi-piston::before {
  content: "\F088A"; }

.mdi-pitchfork::before {
  content: "\F1553"; }

.mdi-pizza::before {
  content: "\F0409"; }

.mdi-plane-car::before {
  content: "\F1AFF"; }

.mdi-plane-train::before {
  content: "\F1B00"; }

.mdi-play::before {
  content: "\F040A"; }

.mdi-play-box::before {
  content: "\F127A"; }

.mdi-play-box-lock::before {
  content: "\F1A16"; }

.mdi-play-box-lock-open::before {
  content: "\F1A17"; }

.mdi-play-box-lock-open-outline::before {
  content: "\F1A18"; }

.mdi-play-box-lock-outline::before {
  content: "\F1A19"; }

.mdi-play-box-multiple::before {
  content: "\F0D19"; }

.mdi-play-box-multiple-outline::before {
  content: "\F13E6"; }

.mdi-play-box-outline::before {
  content: "\F040B"; }

.mdi-play-circle::before {
  content: "\F040C"; }

.mdi-play-circle-outline::before {
  content: "\F040D"; }

.mdi-play-network::before {
  content: "\F088B"; }

.mdi-play-network-outline::before {
  content: "\F0CB7"; }

.mdi-play-outline::before {
  content: "\F0F1B"; }

.mdi-play-pause::before {
  content: "\F040E"; }

.mdi-play-protected-content::before {
  content: "\F040F"; }

.mdi-play-speed::before {
  content: "\F08FF"; }

.mdi-playlist-check::before {
  content: "\F05C7"; }

.mdi-playlist-edit::before {
  content: "\F0900"; }

.mdi-playlist-minus::before {
  content: "\F0410"; }

.mdi-playlist-music::before {
  content: "\F0CB8"; }

.mdi-playlist-music-outline::before {
  content: "\F0CB9"; }

.mdi-playlist-play::before {
  content: "\F0411"; }

.mdi-playlist-plus::before {
  content: "\F0412"; }

.mdi-playlist-remove::before {
  content: "\F0413"; }

.mdi-playlist-star::before {
  content: "\F0DF2"; }

.mdi-plex::before {
  content: "\F06BA"; }

.mdi-pliers::before {
  content: "\F19A4"; }

.mdi-plus::before {
  content: "\F0415"; }

.mdi-plus-box::before {
  content: "\F0416"; }

.mdi-plus-box-multiple::before {
  content: "\F0334"; }

.mdi-plus-box-multiple-outline::before {
  content: "\F1143"; }

.mdi-plus-box-outline::before {
  content: "\F0704"; }

.mdi-plus-circle::before {
  content: "\F0417"; }

.mdi-plus-circle-multiple::before {
  content: "\F034C"; }

.mdi-plus-circle-multiple-outline::before {
  content: "\F0418"; }

.mdi-plus-circle-outline::before {
  content: "\F0419"; }

.mdi-plus-lock::before {
  content: "\F1A5D"; }

.mdi-plus-lock-open::before {
  content: "\F1A5E"; }

.mdi-plus-minus::before {
  content: "\F0992"; }

.mdi-plus-minus-box::before {
  content: "\F0993"; }

.mdi-plus-minus-variant::before {
  content: "\F14C9"; }

.mdi-plus-network::before {
  content: "\F041A"; }

.mdi-plus-network-outline::before {
  content: "\F0CBA"; }

.mdi-plus-outline::before {
  content: "\F0705"; }

.mdi-plus-thick::before {
  content: "\F11EC"; }

.mdi-podcast::before {
  content: "\F0994"; }

.mdi-podium::before {
  content: "\F0D25"; }

.mdi-podium-bronze::before {
  content: "\F0D26"; }

.mdi-podium-gold::before {
  content: "\F0D27"; }

.mdi-podium-silver::before {
  content: "\F0D28"; }

.mdi-point-of-sale::before {
  content: "\F0D92"; }

.mdi-pokeball::before {
  content: "\F041D"; }

.mdi-pokemon-go::before {
  content: "\F0A09"; }

.mdi-poker-chip::before {
  content: "\F0830"; }

.mdi-polaroid::before {
  content: "\F041E"; }

.mdi-police-badge::before {
  content: "\F1167"; }

.mdi-police-badge-outline::before {
  content: "\F1168"; }

.mdi-police-station::before {
  content: "\F1839"; }

.mdi-poll::before {
  content: "\F041F"; }

.mdi-polo::before {
  content: "\F14C3"; }

.mdi-polymer::before {
  content: "\F0421"; }

.mdi-pool::before {
  content: "\F0606"; }

.mdi-pool-thermometer::before {
  content: "\F1A5F"; }

.mdi-popcorn::before {
  content: "\F0422"; }

.mdi-post::before {
  content: "\F1008"; }

.mdi-post-lamp::before {
  content: "\F1A60"; }

.mdi-post-outline::before {
  content: "\F1009"; }

.mdi-postage-stamp::before {
  content: "\F0CBB"; }

.mdi-pot::before {
  content: "\F02E5"; }

.mdi-pot-mix::before {
  content: "\F065B"; }

.mdi-pot-mix-outline::before {
  content: "\F0677"; }

.mdi-pot-outline::before {
  content: "\F02FF"; }

.mdi-pot-steam::before {
  content: "\F065A"; }

.mdi-pot-steam-outline::before {
  content: "\F0326"; }

.mdi-pound::before {
  content: "\F0423"; }

.mdi-pound-box::before {
  content: "\F0424"; }

.mdi-pound-box-outline::before {
  content: "\F117F"; }

.mdi-power::before {
  content: "\F0425"; }

.mdi-power-cycle::before {
  content: "\F0901"; }

.mdi-power-off::before {
  content: "\F0902"; }

.mdi-power-on::before {
  content: "\F0903"; }

.mdi-power-plug::before {
  content: "\F06A5"; }

.mdi-power-plug-off::before {
  content: "\F06A6"; }

.mdi-power-plug-off-outline::before {
  content: "\F1424"; }

.mdi-power-plug-outline::before {
  content: "\F1425"; }

.mdi-power-settings::before {
  content: "\F0426"; }

.mdi-power-sleep::before {
  content: "\F0904"; }

.mdi-power-socket::before {
  content: "\F0427"; }

.mdi-power-socket-au::before {
  content: "\F0905"; }

.mdi-power-socket-ch::before {
  content: "\F0FB3"; }

.mdi-power-socket-de::before {
  content: "\F1107"; }

.mdi-power-socket-eu::before {
  content: "\F07E7"; }

.mdi-power-socket-fr::before {
  content: "\F1108"; }

.mdi-power-socket-it::before {
  content: "\F14FF"; }

.mdi-power-socket-jp::before {
  content: "\F1109"; }

.mdi-power-socket-uk::before {
  content: "\F07E8"; }

.mdi-power-socket-us::before {
  content: "\F07E9"; }

.mdi-power-standby::before {
  content: "\F0906"; }

.mdi-powershell::before {
  content: "\F0A0A"; }

.mdi-prescription::before {
  content: "\F0706"; }

.mdi-presentation::before {
  content: "\F0428"; }

.mdi-presentation-play::before {
  content: "\F0429"; }

.mdi-pretzel::before {
  content: "\F1562"; }

.mdi-printer::before {
  content: "\F042A"; }

.mdi-printer-3d::before {
  content: "\F042B"; }

.mdi-printer-3d-nozzle::before {
  content: "\F0E5B"; }

.mdi-printer-3d-nozzle-alert::before {
  content: "\F11C0"; }

.mdi-printer-3d-nozzle-alert-outline::before {
  content: "\F11C1"; }

.mdi-printer-3d-nozzle-heat::before {
  content: "\F18B8"; }

.mdi-printer-3d-nozzle-heat-outline::before {
  content: "\F18B9"; }

.mdi-printer-3d-nozzle-off::before {
  content: "\F1B19"; }

.mdi-printer-3d-nozzle-off-outline::before {
  content: "\F1B1A"; }

.mdi-printer-3d-nozzle-outline::before {
  content: "\F0E5C"; }

.mdi-printer-3d-off::before {
  content: "\F1B0E"; }

.mdi-printer-alert::before {
  content: "\F042C"; }

.mdi-printer-check::before {
  content: "\F1146"; }

.mdi-printer-eye::before {
  content: "\F1458"; }

.mdi-printer-off::before {
  content: "\F0E5D"; }

.mdi-printer-off-outline::before {
  content: "\F1785"; }

.mdi-printer-outline::before {
  content: "\F1786"; }

.mdi-printer-pos::before {
  content: "\F1057"; }

.mdi-printer-search::before {
  content: "\F1457"; }

.mdi-printer-settings::before {
  content: "\F0707"; }

.mdi-printer-wireless::before {
  content: "\F0A0B"; }

.mdi-priority-high::before {
  content: "\F0603"; }

.mdi-priority-low::before {
  content: "\F0604"; }

.mdi-professional-hexagon::before {
  content: "\F042D"; }

.mdi-progress-alert::before {
  content: "\F0CBC"; }

.mdi-progress-check::before {
  content: "\F0995"; }

.mdi-progress-clock::before {
  content: "\F0996"; }

.mdi-progress-close::before {
  content: "\F110A"; }

.mdi-progress-download::before {
  content: "\F0997"; }

.mdi-progress-helper::before {
  content: "\F1BA2"; }

.mdi-progress-pencil::before {
  content: "\F1787"; }

.mdi-progress-question::before {
  content: "\F1522"; }

.mdi-progress-star::before {
  content: "\F1788"; }

.mdi-progress-upload::before {
  content: "\F0998"; }

.mdi-progress-wrench::before {
  content: "\F0CBD"; }

.mdi-projector::before {
  content: "\F042E"; }

.mdi-projector-off::before {
  content: "\F1A23"; }

.mdi-projector-screen::before {
  content: "\F042F"; }

.mdi-projector-screen-off::before {
  content: "\F180D"; }

.mdi-projector-screen-off-outline::before {
  content: "\F180E"; }

.mdi-projector-screen-outline::before {
  content: "\F1724"; }

.mdi-projector-screen-variant::before {
  content: "\F180F"; }

.mdi-projector-screen-variant-off::before {
  content: "\F1810"; }

.mdi-projector-screen-variant-off-outline::before {
  content: "\F1811"; }

.mdi-projector-screen-variant-outline::before {
  content: "\F1812"; }

.mdi-propane-tank::before {
  content: "\F1357"; }

.mdi-propane-tank-outline::before {
  content: "\F1358"; }

.mdi-protocol::before {
  content: "\F0FD8"; }

.mdi-publish::before {
  content: "\F06A7"; }

.mdi-publish-off::before {
  content: "\F1945"; }

.mdi-pulse::before {
  content: "\F0430"; }

.mdi-pump::before {
  content: "\F1402"; }

.mdi-pump-off::before {
  content: "\F1B22"; }

.mdi-pumpkin::before {
  content: "\F0BBF"; }

.mdi-purse::before {
  content: "\F0F1C"; }

.mdi-purse-outline::before {
  content: "\F0F1D"; }

.mdi-puzzle::before {
  content: "\F0431"; }

.mdi-puzzle-check::before {
  content: "\F1426"; }

.mdi-puzzle-check-outline::before {
  content: "\F1427"; }

.mdi-puzzle-edit::before {
  content: "\F14D3"; }

.mdi-puzzle-edit-outline::before {
  content: "\F14D9"; }

.mdi-puzzle-heart::before {
  content: "\F14D4"; }

.mdi-puzzle-heart-outline::before {
  content: "\F14DA"; }

.mdi-puzzle-minus::before {
  content: "\F14D1"; }

.mdi-puzzle-minus-outline::before {
  content: "\F14D7"; }

.mdi-puzzle-outline::before {
  content: "\F0A66"; }

.mdi-puzzle-plus::before {
  content: "\F14D0"; }

.mdi-puzzle-plus-outline::before {
  content: "\F14D6"; }

.mdi-puzzle-remove::before {
  content: "\F14D2"; }

.mdi-puzzle-remove-outline::before {
  content: "\F14D8"; }

.mdi-puzzle-star::before {
  content: "\F14D5"; }

.mdi-puzzle-star-outline::before {
  content: "\F14DB"; }

.mdi-pyramid::before {
  content: "\F1952"; }

.mdi-pyramid-off::before {
  content: "\F1953"; }

.mdi-qi::before {
  content: "\F0999"; }

.mdi-qqchat::before {
  content: "\F0605"; }

.mdi-qrcode::before {
  content: "\F0432"; }

.mdi-qrcode-edit::before {
  content: "\F08B8"; }

.mdi-qrcode-minus::before {
  content: "\F118C"; }

.mdi-qrcode-plus::before {
  content: "\F118B"; }

.mdi-qrcode-remove::before {
  content: "\F118D"; }

.mdi-qrcode-scan::before {
  content: "\F0433"; }

.mdi-quadcopter::before {
  content: "\F0434"; }

.mdi-quality-high::before {
  content: "\F0435"; }

.mdi-quality-low::before {
  content: "\F0A0C"; }

.mdi-quality-medium::before {
  content: "\F0A0D"; }

.mdi-quora::before {
  content: "\F0D29"; }

.mdi-rabbit::before {
  content: "\F0907"; }

.mdi-rabbit-variant::before {
  content: "\F1A61"; }

.mdi-rabbit-variant-outline::before {
  content: "\F1A62"; }

.mdi-racing-helmet::before {
  content: "\F0D93"; }

.mdi-racquetball::before {
  content: "\F0D94"; }

.mdi-radar::before {
  content: "\F0437"; }

.mdi-radiator::before {
  content: "\F0438"; }

.mdi-radiator-disabled::before {
  content: "\F0AD7"; }

.mdi-radiator-off::before {
  content: "\F0AD8"; }

.mdi-radio::before {
  content: "\F0439"; }

.mdi-radio-am::before {
  content: "\F0CBE"; }

.mdi-radio-fm::before {
  content: "\F0CBF"; }

.mdi-radio-handheld::before {
  content: "\F043A"; }

.mdi-radio-off::before {
  content: "\F121C"; }

.mdi-radio-tower::before {
  content: "\F043B"; }

.mdi-radioactive::before {
  content: "\F043C"; }

.mdi-radioactive-circle::before {
  content: "\F185D"; }

.mdi-radioactive-circle-outline::before {
  content: "\F185E"; }

.mdi-radioactive-off::before {
  content: "\F0EC1"; }

.mdi-radiobox-blank::before {
  content: "\F043D"; }

.mdi-radiobox-marked::before {
  content: "\F043E"; }

.mdi-radiology-box::before {
  content: "\F14C5"; }

.mdi-radiology-box-outline::before {
  content: "\F14C6"; }

.mdi-radius::before {
  content: "\F0CC0"; }

.mdi-radius-outline::before {
  content: "\F0CC1"; }

.mdi-railroad-light::before {
  content: "\F0F1E"; }

.mdi-rake::before {
  content: "\F1544"; }

.mdi-raspberry-pi::before {
  content: "\F043F"; }

.mdi-raw::before {
  content: "\F1A0F"; }

.mdi-raw-off::before {
  content: "\F1A10"; }

.mdi-ray-end::before {
  content: "\F0440"; }

.mdi-ray-end-arrow::before {
  content: "\F0441"; }

.mdi-ray-start::before {
  content: "\F0442"; }

.mdi-ray-start-arrow::before {
  content: "\F0443"; }

.mdi-ray-start-end::before {
  content: "\F0444"; }

.mdi-ray-start-vertex-end::before {
  content: "\F15D8"; }

.mdi-ray-vertex::before {
  content: "\F0445"; }

.mdi-razor-double-edge::before {
  content: "\F1997"; }

.mdi-razor-single-edge::before {
  content: "\F1998"; }

.mdi-react::before {
  content: "\F0708"; }

.mdi-read::before {
  content: "\F0447"; }

.mdi-receipt::before {
  content: "\F0824"; }

.mdi-receipt-outline::before {
  content: "\F04F7"; }

.mdi-receipt-text::before {
  content: "\F0449"; }

.mdi-receipt-text-check::before {
  content: "\F1A63"; }

.mdi-receipt-text-check-outline::before {
  content: "\F1A64"; }

.mdi-receipt-text-minus::before {
  content: "\F1A65"; }

.mdi-receipt-text-minus-outline::before {
  content: "\F1A66"; }

.mdi-receipt-text-outline::before {
  content: "\F19DC"; }

.mdi-receipt-text-plus::before {
  content: "\F1A67"; }

.mdi-receipt-text-plus-outline::before {
  content: "\F1A68"; }

.mdi-receipt-text-remove::before {
  content: "\F1A69"; }

.mdi-receipt-text-remove-outline::before {
  content: "\F1A6A"; }

.mdi-record::before {
  content: "\F044A"; }

.mdi-record-circle::before {
  content: "\F0EC2"; }

.mdi-record-circle-outline::before {
  content: "\F0EC3"; }

.mdi-record-player::before {
  content: "\F099A"; }

.mdi-record-rec::before {
  content: "\F044B"; }

.mdi-rectangle::before {
  content: "\F0E5E"; }

.mdi-rectangle-outline::before {
  content: "\F0E5F"; }

.mdi-recycle::before {
  content: "\F044C"; }

.mdi-recycle-variant::before {
  content: "\F139D"; }

.mdi-reddit::before {
  content: "\F044D"; }

.mdi-redhat::before {
  content: "\F111B"; }

.mdi-redo::before {
  content: "\F044E"; }

.mdi-redo-variant::before {
  content: "\F044F"; }

.mdi-reflect-horizontal::before {
  content: "\F0A0E"; }

.mdi-reflect-vertical::before {
  content: "\F0A0F"; }

.mdi-refresh::before {
  content: "\F0450"; }

.mdi-refresh-auto::before {
  content: "\F18F2"; }

.mdi-refresh-circle::before {
  content: "\F1377"; }

.mdi-regex::before {
  content: "\F0451"; }

.mdi-registered-trademark::before {
  content: "\F0A67"; }

.mdi-reiterate::before {
  content: "\F1588"; }

.mdi-relation-many-to-many::before {
  content: "\F1496"; }

.mdi-relation-many-to-one::before {
  content: "\F1497"; }

.mdi-relation-many-to-one-or-many::before {
  content: "\F1498"; }

.mdi-relation-many-to-only-one::before {
  content: "\F1499"; }

.mdi-relation-many-to-zero-or-many::before {
  content: "\F149A"; }

.mdi-relation-many-to-zero-or-one::before {
  content: "\F149B"; }

.mdi-relation-one-or-many-to-many::before {
  content: "\F149C"; }

.mdi-relation-one-or-many-to-one::before {
  content: "\F149D"; }

.mdi-relation-one-or-many-to-one-or-many::before {
  content: "\F149E"; }

.mdi-relation-one-or-many-to-only-one::before {
  content: "\F149F"; }

.mdi-relation-one-or-many-to-zero-or-many::before {
  content: "\F14A0"; }

.mdi-relation-one-or-many-to-zero-or-one::before {
  content: "\F14A1"; }

.mdi-relation-one-to-many::before {
  content: "\F14A2"; }

.mdi-relation-one-to-one::before {
  content: "\F14A3"; }

.mdi-relation-one-to-one-or-many::before {
  content: "\F14A4"; }

.mdi-relation-one-to-only-one::before {
  content: "\F14A5"; }

.mdi-relation-one-to-zero-or-many::before {
  content: "\F14A6"; }

.mdi-relation-one-to-zero-or-one::before {
  content: "\F14A7"; }

.mdi-relation-only-one-to-many::before {
  content: "\F14A8"; }

.mdi-relation-only-one-to-one::before {
  content: "\F14A9"; }

.mdi-relation-only-one-to-one-or-many::before {
  content: "\F14AA"; }

.mdi-relation-only-one-to-only-one::before {
  content: "\F14AB"; }

.mdi-relation-only-one-to-zero-or-many::before {
  content: "\F14AC"; }

.mdi-relation-only-one-to-zero-or-one::before {
  content: "\F14AD"; }

.mdi-relation-zero-or-many-to-many::before {
  content: "\F14AE"; }

.mdi-relation-zero-or-many-to-one::before {
  content: "\F14AF"; }

.mdi-relation-zero-or-many-to-one-or-many::before {
  content: "\F14B0"; }

.mdi-relation-zero-or-many-to-only-one::before {
  content: "\F14B1"; }

.mdi-relation-zero-or-many-to-zero-or-many::before {
  content: "\F14B2"; }

.mdi-relation-zero-or-many-to-zero-or-one::before {
  content: "\F14B3"; }

.mdi-relation-zero-or-one-to-many::before {
  content: "\F14B4"; }

.mdi-relation-zero-or-one-to-one::before {
  content: "\F14B5"; }

.mdi-relation-zero-or-one-to-one-or-many::before {
  content: "\F14B6"; }

.mdi-relation-zero-or-one-to-only-one::before {
  content: "\F14B7"; }

.mdi-relation-zero-or-one-to-zero-or-many::before {
  content: "\F14B8"; }

.mdi-relation-zero-or-one-to-zero-or-one::before {
  content: "\F14B9"; }

.mdi-relative-scale::before {
  content: "\F0452"; }

.mdi-reload::before {
  content: "\F0453"; }

.mdi-reload-alert::before {
  content: "\F110B"; }

.mdi-reminder::before {
  content: "\F088C"; }

.mdi-remote::before {
  content: "\F0454"; }

.mdi-remote-desktop::before {
  content: "\F08B9"; }

.mdi-remote-off::before {
  content: "\F0EC4"; }

.mdi-remote-tv::before {
  content: "\F0EC5"; }

.mdi-remote-tv-off::before {
  content: "\F0EC6"; }

.mdi-rename-box::before {
  content: "\F0455"; }

.mdi-reorder-horizontal::before {
  content: "\F0688"; }

.mdi-reorder-vertical::before {
  content: "\F0689"; }

.mdi-repeat::before {
  content: "\F0456"; }

.mdi-repeat-off::before {
  content: "\F0457"; }

.mdi-repeat-once::before {
  content: "\F0458"; }

.mdi-repeat-variant::before {
  content: "\F0547"; }

.mdi-replay::before {
  content: "\F0459"; }

.mdi-reply::before {
  content: "\F045A"; }

.mdi-reply-all::before {
  content: "\F045B"; }

.mdi-reply-all-outline::before {
  content: "\F0F1F"; }

.mdi-reply-circle::before {
  content: "\F11AE"; }

.mdi-reply-outline::before {
  content: "\F0F20"; }

.mdi-reproduction::before {
  content: "\F045C"; }

.mdi-resistor::before {
  content: "\F0B44"; }

.mdi-resistor-nodes::before {
  content: "\F0B45"; }

.mdi-resize::before {
  content: "\F0A68"; }

.mdi-resize-bottom-right::before {
  content: "\F045D"; }

.mdi-responsive::before {
  content: "\F045E"; }

.mdi-restart::before {
  content: "\F0709"; }

.mdi-restart-alert::before {
  content: "\F110C"; }

.mdi-restart-off::before {
  content: "\F0D95"; }

.mdi-restore::before {
  content: "\F099B"; }

.mdi-restore-alert::before {
  content: "\F110D"; }

.mdi-rewind::before {
  content: "\F045F"; }

.mdi-rewind-10::before {
  content: "\F0D2A"; }

.mdi-rewind-15::before {
  content: "\F1946"; }

.mdi-rewind-30::before {
  content: "\F0D96"; }

.mdi-rewind-45::before {
  content: "\F1B13"; }

.mdi-rewind-5::before {
  content: "\F11F9"; }

.mdi-rewind-60::before {
  content: "\F160C"; }

.mdi-rewind-outline::before {
  content: "\F070A"; }

.mdi-rhombus::before {
  content: "\F070B"; }

.mdi-rhombus-medium::before {
  content: "\F0A10"; }

.mdi-rhombus-medium-outline::before {
  content: "\F14DC"; }

.mdi-rhombus-outline::before {
  content: "\F070C"; }

.mdi-rhombus-split::before {
  content: "\F0A11"; }

.mdi-rhombus-split-outline::before {
  content: "\F14DD"; }

.mdi-ribbon::before {
  content: "\F0460"; }

.mdi-rice::before {
  content: "\F07EA"; }

.mdi-rickshaw::before {
  content: "\F15BB"; }

.mdi-rickshaw-electric::before {
  content: "\F15BC"; }

.mdi-ring::before {
  content: "\F07EB"; }

.mdi-rivet::before {
  content: "\F0E60"; }

.mdi-road::before {
  content: "\F0461"; }

.mdi-road-variant::before {
  content: "\F0462"; }

.mdi-robber::before {
  content: "\F1058"; }

.mdi-robot::before {
  content: "\F06A9"; }

.mdi-robot-angry::before {
  content: "\F169D"; }

.mdi-robot-angry-outline::before {
  content: "\F169E"; }

.mdi-robot-confused::before {
  content: "\F169F"; }

.mdi-robot-confused-outline::before {
  content: "\F16A0"; }

.mdi-robot-dead::before {
  content: "\F16A1"; }

.mdi-robot-dead-outline::before {
  content: "\F16A2"; }

.mdi-robot-excited::before {
  content: "\F16A3"; }

.mdi-robot-excited-outline::before {
  content: "\F16A4"; }

.mdi-robot-happy::before {
  content: "\F1719"; }

.mdi-robot-happy-outline::before {
  content: "\F171A"; }

.mdi-robot-industrial::before {
  content: "\F0B46"; }

.mdi-robot-industrial-outline::before {
  content: "\F1A1A"; }

.mdi-robot-love::before {
  content: "\F16A5"; }

.mdi-robot-love-outline::before {
  content: "\F16A6"; }

.mdi-robot-mower::before {
  content: "\F11F7"; }

.mdi-robot-mower-outline::before {
  content: "\F11F3"; }

.mdi-robot-off::before {
  content: "\F16A7"; }

.mdi-robot-off-outline::before {
  content: "\F167B"; }

.mdi-robot-outline::before {
  content: "\F167A"; }

.mdi-robot-vacuum::before {
  content: "\F070D"; }

.mdi-robot-vacuum-alert::before {
  content: "\F1B5D"; }

.mdi-robot-vacuum-variant::before {
  content: "\F0908"; }

.mdi-robot-vacuum-variant-alert::before {
  content: "\F1B5E"; }

.mdi-rocket::before {
  content: "\F0463"; }

.mdi-rocket-launch::before {
  content: "\F14DE"; }

.mdi-rocket-launch-outline::before {
  content: "\F14DF"; }

.mdi-rocket-outline::before {
  content: "\F13AF"; }

.mdi-rodent::before {
  content: "\F1327"; }

.mdi-roller-shade::before {
  content: "\F1A6B"; }

.mdi-roller-shade-closed::before {
  content: "\F1A6C"; }

.mdi-roller-skate::before {
  content: "\F0D2B"; }

.mdi-roller-skate-off::before {
  content: "\F0145"; }

.mdi-rollerblade::before {
  content: "\F0D2C"; }

.mdi-rollerblade-off::before {
  content: "\F002E"; }

.mdi-rollupjs::before {
  content: "\F0BC0"; }

.mdi-rolodex::before {
  content: "\F1AB9"; }

.mdi-rolodex-outline::before {
  content: "\F1ABA"; }

.mdi-roman-numeral-1::before {
  content: "\F1088"; }

.mdi-roman-numeral-10::before {
  content: "\F1091"; }

.mdi-roman-numeral-2::before {
  content: "\F1089"; }

.mdi-roman-numeral-3::before {
  content: "\F108A"; }

.mdi-roman-numeral-4::before {
  content: "\F108B"; }

.mdi-roman-numeral-5::before {
  content: "\F108C"; }

.mdi-roman-numeral-6::before {
  content: "\F108D"; }

.mdi-roman-numeral-7::before {
  content: "\F108E"; }

.mdi-roman-numeral-8::before {
  content: "\F108F"; }

.mdi-roman-numeral-9::before {
  content: "\F1090"; }

.mdi-room-service::before {
  content: "\F088D"; }

.mdi-room-service-outline::before {
  content: "\F0D97"; }

.mdi-rotate-360::before {
  content: "\F1999"; }

.mdi-rotate-3d::before {
  content: "\F0EC7"; }

.mdi-rotate-3d-variant::before {
  content: "\F0464"; }

.mdi-rotate-left::before {
  content: "\F0465"; }

.mdi-rotate-left-variant::before {
  content: "\F0466"; }

.mdi-rotate-orbit::before {
  content: "\F0D98"; }

.mdi-rotate-right::before {
  content: "\F0467"; }

.mdi-rotate-right-variant::before {
  content: "\F0468"; }

.mdi-rounded-corner::before {
  content: "\F0607"; }

.mdi-router::before {
  content: "\F11E2"; }

.mdi-router-network::before {
  content: "\F1087"; }

.mdi-router-wireless::before {
  content: "\F0469"; }

.mdi-router-wireless-off::before {
  content: "\F15A3"; }

.mdi-router-wireless-settings::before {
  content: "\F0A69"; }

.mdi-routes::before {
  content: "\F046A"; }

.mdi-routes-clock::before {
  content: "\F1059"; }

.mdi-rowing::before {
  content: "\F0608"; }

.mdi-rss::before {
  content: "\F046B"; }

.mdi-rss-box::before {
  content: "\F046C"; }

.mdi-rss-off::before {
  content: "\F0F21"; }

.mdi-rug::before {
  content: "\F1475"; }

.mdi-rugby::before {
  content: "\F0D99"; }

.mdi-ruler::before {
  content: "\F046D"; }

.mdi-ruler-square::before {
  content: "\F0CC2"; }

.mdi-ruler-square-compass::before {
  content: "\F0EBE"; }

.mdi-run::before {
  content: "\F070E"; }

.mdi-run-fast::before {
  content: "\F046E"; }

.mdi-rv-truck::before {
  content: "\F11D4"; }

.mdi-sack::before {
  content: "\F0D2E"; }

.mdi-sack-percent::before {
  content: "\F0D2F"; }

.mdi-safe::before {
  content: "\F0A6A"; }

.mdi-safe-square::before {
  content: "\F127C"; }

.mdi-safe-square-outline::before {
  content: "\F127D"; }

.mdi-safety-goggles::before {
  content: "\F0D30"; }

.mdi-sail-boat::before {
  content: "\F0EC8"; }

.mdi-sail-boat-sink::before {
  content: "\F1AEF"; }

.mdi-sale::before {
  content: "\F046F"; }

.mdi-sale-outline::before {
  content: "\F1A06"; }

.mdi-salesforce::before {
  content: "\F088E"; }

.mdi-sass::before {
  content: "\F07EC"; }

.mdi-satellite::before {
  content: "\F0470"; }

.mdi-satellite-uplink::before {
  content: "\F0909"; }

.mdi-satellite-variant::before {
  content: "\F0471"; }

.mdi-sausage::before {
  content: "\F08BA"; }

.mdi-sausage-off::before {
  content: "\F1789"; }

.mdi-saw-blade::before {
  content: "\F0E61"; }

.mdi-sawtooth-wave::before {
  content: "\F147A"; }

.mdi-saxophone::before {
  content: "\F0609"; }

.mdi-scale::before {
  content: "\F0472"; }

.mdi-scale-balance::before {
  content: "\F05D1"; }

.mdi-scale-bathroom::before {
  content: "\F0473"; }

.mdi-scale-off::before {
  content: "\F105A"; }

.mdi-scale-unbalanced::before {
  content: "\F19B8"; }

.mdi-scan-helper::before {
  content: "\F13D8"; }

.mdi-scanner::before {
  content: "\F06AB"; }

.mdi-scanner-off::before {
  content: "\F090A"; }

.mdi-scatter-plot::before {
  content: "\F0EC9"; }

.mdi-scatter-plot-outline::before {
  content: "\F0ECA"; }

.mdi-scent::before {
  content: "\F1958"; }

.mdi-scent-off::before {
  content: "\F1959"; }

.mdi-school::before {
  content: "\F0474"; }

.mdi-school-outline::before {
  content: "\F1180"; }

.mdi-scissors-cutting::before {
  content: "\F0A6B"; }

.mdi-scooter::before {
  content: "\F15BD"; }

.mdi-scooter-electric::before {
  content: "\F15BE"; }

.mdi-scoreboard::before {
  content: "\F127E"; }

.mdi-scoreboard-outline::before {
  content: "\F127F"; }

.mdi-screen-rotation::before {
  content: "\F0475"; }

.mdi-screen-rotation-lock::before {
  content: "\F0478"; }

.mdi-screw-flat-top::before {
  content: "\F0DF3"; }

.mdi-screw-lag::before {
  content: "\F0DF4"; }

.mdi-screw-machine-flat-top::before {
  content: "\F0DF5"; }

.mdi-screw-machine-round-top::before {
  content: "\F0DF6"; }

.mdi-screw-round-top::before {
  content: "\F0DF7"; }

.mdi-screwdriver::before {
  content: "\F0476"; }

.mdi-script::before {
  content: "\F0BC1"; }

.mdi-script-outline::before {
  content: "\F0477"; }

.mdi-script-text::before {
  content: "\F0BC2"; }

.mdi-script-text-key::before {
  content: "\F1725"; }

.mdi-script-text-key-outline::before {
  content: "\F1726"; }

.mdi-script-text-outline::before {
  content: "\F0BC3"; }

.mdi-script-text-play::before {
  content: "\F1727"; }

.mdi-script-text-play-outline::before {
  content: "\F1728"; }

.mdi-sd::before {
  content: "\F0479"; }

.mdi-seal::before {
  content: "\F047A"; }

.mdi-seal-variant::before {
  content: "\F0FD9"; }

.mdi-search-web::before {
  content: "\F070F"; }

.mdi-seat::before {
  content: "\F0CC3"; }

.mdi-seat-flat::before {
  content: "\F047B"; }

.mdi-seat-flat-angled::before {
  content: "\F047C"; }

.mdi-seat-individual-suite::before {
  content: "\F047D"; }

.mdi-seat-legroom-extra::before {
  content: "\F047E"; }

.mdi-seat-legroom-normal::before {
  content: "\F047F"; }

.mdi-seat-legroom-reduced::before {
  content: "\F0480"; }

.mdi-seat-outline::before {
  content: "\F0CC4"; }

.mdi-seat-passenger::before {
  content: "\F1249"; }

.mdi-seat-recline-extra::before {
  content: "\F0481"; }

.mdi-seat-recline-normal::before {
  content: "\F0482"; }

.mdi-seatbelt::before {
  content: "\F0CC5"; }

.mdi-security::before {
  content: "\F0483"; }

.mdi-security-network::before {
  content: "\F0484"; }

.mdi-seed::before {
  content: "\F0E62"; }

.mdi-seed-off::before {
  content: "\F13FD"; }

.mdi-seed-off-outline::before {
  content: "\F13FE"; }

.mdi-seed-outline::before {
  content: "\F0E63"; }

.mdi-seed-plus::before {
  content: "\F1A6D"; }

.mdi-seed-plus-outline::before {
  content: "\F1A6E"; }

.mdi-seesaw::before {
  content: "\F15A4"; }

.mdi-segment::before {
  content: "\F0ECB"; }

.mdi-select::before {
  content: "\F0485"; }

.mdi-select-all::before {
  content: "\F0486"; }

.mdi-select-arrow-down::before {
  content: "\F1B59"; }

.mdi-select-arrow-up::before {
  content: "\F1B58"; }

.mdi-select-color::before {
  content: "\F0D31"; }

.mdi-select-compare::before {
  content: "\F0AD9"; }

.mdi-select-drag::before {
  content: "\F0A6C"; }

.mdi-select-group::before {
  content: "\F0F82"; }

.mdi-select-inverse::before {
  content: "\F0487"; }

.mdi-select-marker::before {
  content: "\F1280"; }

.mdi-select-multiple::before {
  content: "\F1281"; }

.mdi-select-multiple-marker::before {
  content: "\F1282"; }

.mdi-select-off::before {
  content: "\F0488"; }

.mdi-select-place::before {
  content: "\F0FDA"; }

.mdi-select-remove::before {
  content: "\F17C1"; }

.mdi-select-search::before {
  content: "\F1204"; }

.mdi-selection::before {
  content: "\F0489"; }

.mdi-selection-drag::before {
  content: "\F0A6D"; }

.mdi-selection-ellipse::before {
  content: "\F0D32"; }

.mdi-selection-ellipse-arrow-inside::before {
  content: "\F0F22"; }

.mdi-selection-ellipse-remove::before {
  content: "\F17C2"; }

.mdi-selection-marker::before {
  content: "\F1283"; }

.mdi-selection-multiple::before {
  content: "\F1285"; }

.mdi-selection-multiple-marker::before {
  content: "\F1284"; }

.mdi-selection-off::before {
  content: "\F0777"; }

.mdi-selection-remove::before {
  content: "\F17C3"; }

.mdi-selection-search::before {
  content: "\F1205"; }

.mdi-semantic-web::before {
  content: "\F1316"; }

.mdi-send::before {
  content: "\F048A"; }

.mdi-send-check::before {
  content: "\F1161"; }

.mdi-send-check-outline::before {
  content: "\F1162"; }

.mdi-send-circle::before {
  content: "\F0DF8"; }

.mdi-send-circle-outline::before {
  content: "\F0DF9"; }

.mdi-send-clock::before {
  content: "\F1163"; }

.mdi-send-clock-outline::before {
  content: "\F1164"; }

.mdi-send-lock::before {
  content: "\F07ED"; }

.mdi-send-lock-outline::before {
  content: "\F1166"; }

.mdi-send-outline::before {
  content: "\F1165"; }

.mdi-serial-port::before {
  content: "\F065C"; }

.mdi-server::before {
  content: "\F048B"; }

.mdi-server-minus::before {
  content: "\F048C"; }

.mdi-server-network::before {
  content: "\F048D"; }

.mdi-server-network-off::before {
  content: "\F048E"; }

.mdi-server-off::before {
  content: "\F048F"; }

.mdi-server-plus::before {
  content: "\F0490"; }

.mdi-server-remove::before {
  content: "\F0491"; }

.mdi-server-security::before {
  content: "\F0492"; }

.mdi-set-all::before {
  content: "\F0778"; }

.mdi-set-center::before {
  content: "\F0779"; }

.mdi-set-center-right::before {
  content: "\F077A"; }

.mdi-set-left::before {
  content: "\F077B"; }

.mdi-set-left-center::before {
  content: "\F077C"; }

.mdi-set-left-right::before {
  content: "\F077D"; }

.mdi-set-merge::before {
  content: "\F14E0"; }

.mdi-set-none::before {
  content: "\F077E"; }

.mdi-set-right::before {
  content: "\F077F"; }

.mdi-set-split::before {
  content: "\F14E1"; }

.mdi-set-square::before {
  content: "\F145D"; }

.mdi-set-top-box::before {
  content: "\F099F"; }

.mdi-settings-helper::before {
  content: "\F0A6E"; }

.mdi-shaker::before {
  content: "\F110E"; }

.mdi-shaker-outline::before {
  content: "\F110F"; }

.mdi-shape::before {
  content: "\F0831"; }

.mdi-shape-circle-plus::before {
  content: "\F065D"; }

.mdi-shape-outline::before {
  content: "\F0832"; }

.mdi-shape-oval-plus::before {
  content: "\F11FA"; }

.mdi-shape-plus::before {
  content: "\F0495"; }

.mdi-shape-polygon-plus::before {
  content: "\F065E"; }

.mdi-shape-rectangle-plus::before {
  content: "\F065F"; }

.mdi-shape-square-plus::before {
  content: "\F0660"; }

.mdi-shape-square-rounded-plus::before {
  content: "\F14FA"; }

.mdi-share::before {
  content: "\F0496"; }

.mdi-share-all::before {
  content: "\F11F4"; }

.mdi-share-all-outline::before {
  content: "\F11F5"; }

.mdi-share-circle::before {
  content: "\F11AD"; }

.mdi-share-off::before {
  content: "\F0F23"; }

.mdi-share-off-outline::before {
  content: "\F0F24"; }

.mdi-share-outline::before {
  content: "\F0932"; }

.mdi-share-variant::before {
  content: "\F0497"; }

.mdi-share-variant-outline::before {
  content: "\F1514"; }

.mdi-shark::before {
  content: "\F18BA"; }

.mdi-shark-fin::before {
  content: "\F1673"; }

.mdi-shark-fin-outline::before {
  content: "\F1674"; }

.mdi-shark-off::before {
  content: "\F18BB"; }

.mdi-sheep::before {
  content: "\F0CC6"; }

.mdi-shield::before {
  content: "\F0498"; }

.mdi-shield-account::before {
  content: "\F088F"; }

.mdi-shield-account-outline::before {
  content: "\F0A12"; }

.mdi-shield-account-variant::before {
  content: "\F15A7"; }

.mdi-shield-account-variant-outline::before {
  content: "\F15A8"; }

.mdi-shield-airplane::before {
  content: "\F06BB"; }

.mdi-shield-airplane-outline::before {
  content: "\F0CC7"; }

.mdi-shield-alert::before {
  content: "\F0ECC"; }

.mdi-shield-alert-outline::before {
  content: "\F0ECD"; }

.mdi-shield-bug::before {
  content: "\F13DA"; }

.mdi-shield-bug-outline::before {
  content: "\F13DB"; }

.mdi-shield-car::before {
  content: "\F0F83"; }

.mdi-shield-check::before {
  content: "\F0565"; }

.mdi-shield-check-outline::before {
  content: "\F0CC8"; }

.mdi-shield-cross::before {
  content: "\F0CC9"; }

.mdi-shield-cross-outline::before {
  content: "\F0CCA"; }

.mdi-shield-crown::before {
  content: "\F18BC"; }

.mdi-shield-crown-outline::before {
  content: "\F18BD"; }

.mdi-shield-edit::before {
  content: "\F11A0"; }

.mdi-shield-edit-outline::before {
  content: "\F11A1"; }

.mdi-shield-half::before {
  content: "\F1360"; }

.mdi-shield-half-full::before {
  content: "\F0780"; }

.mdi-shield-home::before {
  content: "\F068A"; }

.mdi-shield-home-outline::before {
  content: "\F0CCB"; }

.mdi-shield-key::before {
  content: "\F0BC4"; }

.mdi-shield-key-outline::before {
  content: "\F0BC5"; }

.mdi-shield-link-variant::before {
  content: "\F0D33"; }

.mdi-shield-link-variant-outline::before {
  content: "\F0D34"; }

.mdi-shield-lock::before {
  content: "\F099D"; }

.mdi-shield-lock-open::before {
  content: "\F199A"; }

.mdi-shield-lock-open-outline::before {
  content: "\F199B"; }

.mdi-shield-lock-outline::before {
  content: "\F0CCC"; }

.mdi-shield-moon::before {
  content: "\F1828"; }

.mdi-shield-moon-outline::before {
  content: "\F1829"; }

.mdi-shield-off::before {
  content: "\F099E"; }

.mdi-shield-off-outline::before {
  content: "\F099C"; }

.mdi-shield-outline::before {
  content: "\F0499"; }

.mdi-shield-plus::before {
  content: "\F0ADA"; }

.mdi-shield-plus-outline::before {
  content: "\F0ADB"; }

.mdi-shield-refresh::before {
  content: "\F00AA"; }

.mdi-shield-refresh-outline::before {
  content: "\F01E0"; }

.mdi-shield-remove::before {
  content: "\F0ADC"; }

.mdi-shield-remove-outline::before {
  content: "\F0ADD"; }

.mdi-shield-search::before {
  content: "\F0D9A"; }

.mdi-shield-star::before {
  content: "\F113B"; }

.mdi-shield-star-outline::before {
  content: "\F113C"; }

.mdi-shield-sun::before {
  content: "\F105D"; }

.mdi-shield-sun-outline::before {
  content: "\F105E"; }

.mdi-shield-sword::before {
  content: "\F18BE"; }

.mdi-shield-sword-outline::before {
  content: "\F18BF"; }

.mdi-shield-sync::before {
  content: "\F11A2"; }

.mdi-shield-sync-outline::before {
  content: "\F11A3"; }

.mdi-shimmer::before {
  content: "\F1545"; }

.mdi-ship-wheel::before {
  content: "\F0833"; }

.mdi-shipping-pallet::before {
  content: "\F184E"; }

.mdi-shoe-ballet::before {
  content: "\F15CA"; }

.mdi-shoe-cleat::before {
  content: "\F15C7"; }

.mdi-shoe-formal::before {
  content: "\F0B47"; }

.mdi-shoe-heel::before {
  content: "\F0B48"; }

.mdi-shoe-print::before {
  content: "\F0DFA"; }

.mdi-shoe-sneaker::before {
  content: "\F15C8"; }

.mdi-shopping::before {
  content: "\F049A"; }

.mdi-shopping-music::before {
  content: "\F049B"; }

.mdi-shopping-outline::before {
  content: "\F11D5"; }

.mdi-shopping-search::before {
  content: "\F0F84"; }

.mdi-shopping-search-outline::before {
  content: "\F1A6F"; }

.mdi-shore::before {
  content: "\F14F9"; }

.mdi-shovel::before {
  content: "\F0710"; }

.mdi-shovel-off::before {
  content: "\F0711"; }

.mdi-shower::before {
  content: "\F09A0"; }

.mdi-shower-head::before {
  content: "\F09A1"; }

.mdi-shredder::before {
  content: "\F049C"; }

.mdi-shuffle::before {
  content: "\F049D"; }

.mdi-shuffle-disabled::before {
  content: "\F049E"; }

.mdi-shuffle-variant::before {
  content: "\F049F"; }

.mdi-shuriken::before {
  content: "\F137F"; }

.mdi-sickle::before {
  content: "\F18C0"; }

.mdi-sigma::before {
  content: "\F04A0"; }

.mdi-sigma-lower::before {
  content: "\F062B"; }

.mdi-sign-caution::before {
  content: "\F04A1"; }

.mdi-sign-direction::before {
  content: "\F0781"; }

.mdi-sign-direction-minus::before {
  content: "\F1000"; }

.mdi-sign-direction-plus::before {
  content: "\F0FDC"; }

.mdi-sign-direction-remove::before {
  content: "\F0FDD"; }

.mdi-sign-language::before {
  content: "\F1B4D"; }

.mdi-sign-language-outline::before {
  content: "\F1B4E"; }

.mdi-sign-pole::before {
  content: "\F14F8"; }

.mdi-sign-real-estate::before {
  content: "\F1118"; }

.mdi-sign-text::before {
  content: "\F0782"; }

.mdi-sign-yield::before {
  content: "\F1BAF"; }

.mdi-signal::before {
  content: "\F04A2"; }

.mdi-signal-2g::before {
  content: "\F0712"; }

.mdi-signal-3g::before {
  content: "\F0713"; }

.mdi-signal-4g::before {
  content: "\F0714"; }

.mdi-signal-5g::before {
  content: "\F0A6F"; }

.mdi-signal-cellular-1::before {
  content: "\F08BC"; }

.mdi-signal-cellular-2::before {
  content: "\F08BD"; }

.mdi-signal-cellular-3::before {
  content: "\F08BE"; }

.mdi-signal-cellular-outline::before {
  content: "\F08BF"; }

.mdi-signal-distance-variant::before {
  content: "\F0E64"; }

.mdi-signal-hspa::before {
  content: "\F0715"; }

.mdi-signal-hspa-plus::before {
  content: "\F0716"; }

.mdi-signal-off::before {
  content: "\F0783"; }

.mdi-signal-variant::before {
  content: "\F060A"; }

.mdi-signature::before {
  content: "\F0DFB"; }

.mdi-signature-freehand::before {
  content: "\F0DFC"; }

.mdi-signature-image::before {
  content: "\F0DFD"; }

.mdi-signature-text::before {
  content: "\F0DFE"; }

.mdi-silo::before {
  content: "\F1B9F"; }

.mdi-silo-outline::before {
  content: "\F0B49"; }

.mdi-silverware::before {
  content: "\F04A3"; }

.mdi-silverware-clean::before {
  content: "\F0FDE"; }

.mdi-silverware-fork::before {
  content: "\F04A4"; }

.mdi-silverware-fork-knife::before {
  content: "\F0A70"; }

.mdi-silverware-spoon::before {
  content: "\F04A5"; }

.mdi-silverware-variant::before {
  content: "\F04A6"; }

.mdi-sim::before {
  content: "\F04A7"; }

.mdi-sim-alert::before {
  content: "\F04A8"; }

.mdi-sim-alert-outline::before {
  content: "\F15D3"; }

.mdi-sim-off::before {
  content: "\F04A9"; }

.mdi-sim-off-outline::before {
  content: "\F15D4"; }

.mdi-sim-outline::before {
  content: "\F15D5"; }

.mdi-simple-icons::before {
  content: "\F131D"; }

.mdi-sina-weibo::before {
  content: "\F0ADF"; }

.mdi-sine-wave::before {
  content: "\F095B"; }

.mdi-sitemap::before {
  content: "\F04AA"; }

.mdi-sitemap-outline::before {
  content: "\F199C"; }

.mdi-size-l::before {
  content: "\F13A6"; }

.mdi-size-m::before {
  content: "\F13A5"; }

.mdi-size-s::before {
  content: "\F13A4"; }

.mdi-size-xl::before {
  content: "\F13A7"; }

.mdi-size-xs::before {
  content: "\F13A3"; }

.mdi-size-xxl::before {
  content: "\F13A8"; }

.mdi-size-xxs::before {
  content: "\F13A2"; }

.mdi-size-xxxl::before {
  content: "\F13A9"; }

.mdi-skate::before {
  content: "\F0D35"; }

.mdi-skate-off::before {
  content: "\F0699"; }

.mdi-skateboard::before {
  content: "\F14C2"; }

.mdi-skateboarding::before {
  content: "\F0501"; }

.mdi-skew-less::before {
  content: "\F0D36"; }

.mdi-skew-more::before {
  content: "\F0D37"; }

.mdi-ski::before {
  content: "\F1304"; }

.mdi-ski-cross-country::before {
  content: "\F1305"; }

.mdi-ski-water::before {
  content: "\F1306"; }

.mdi-skip-backward::before {
  content: "\F04AB"; }

.mdi-skip-backward-outline::before {
  content: "\F0F25"; }

.mdi-skip-forward::before {
  content: "\F04AC"; }

.mdi-skip-forward-outline::before {
  content: "\F0F26"; }

.mdi-skip-next::before {
  content: "\F04AD"; }

.mdi-skip-next-circle::before {
  content: "\F0661"; }

.mdi-skip-next-circle-outline::before {
  content: "\F0662"; }

.mdi-skip-next-outline::before {
  content: "\F0F27"; }

.mdi-skip-previous::before {
  content: "\F04AE"; }

.mdi-skip-previous-circle::before {
  content: "\F0663"; }

.mdi-skip-previous-circle-outline::before {
  content: "\F0664"; }

.mdi-skip-previous-outline::before {
  content: "\F0F28"; }

.mdi-skull::before {
  content: "\F068C"; }

.mdi-skull-crossbones::before {
  content: "\F0BC6"; }

.mdi-skull-crossbones-outline::before {
  content: "\F0BC7"; }

.mdi-skull-outline::before {
  content: "\F0BC8"; }

.mdi-skull-scan::before {
  content: "\F14C7"; }

.mdi-skull-scan-outline::before {
  content: "\F14C8"; }

.mdi-skype::before {
  content: "\F04AF"; }

.mdi-skype-business::before {
  content: "\F04B0"; }

.mdi-slack::before {
  content: "\F04B1"; }

.mdi-slash-forward::before {
  content: "\F0FDF"; }

.mdi-slash-forward-box::before {
  content: "\F0FE0"; }

.mdi-sledding::before {
  content: "\F041B"; }

.mdi-sleep::before {
  content: "\F04B2"; }

.mdi-sleep-off::before {
  content: "\F04B3"; }

.mdi-slide::before {
  content: "\F15A5"; }

.mdi-slope-downhill::before {
  content: "\F0DFF"; }

.mdi-slope-uphill::before {
  content: "\F0E00"; }

.mdi-slot-machine::before {
  content: "\F1114"; }

.mdi-slot-machine-outline::before {
  content: "\F1115"; }

.mdi-smart-card::before {
  content: "\F10BD"; }

.mdi-smart-card-off::before {
  content: "\F18F7"; }

.mdi-smart-card-off-outline::before {
  content: "\F18F8"; }

.mdi-smart-card-outline::before {
  content: "\F10BE"; }

.mdi-smart-card-reader::before {
  content: "\F10BF"; }

.mdi-smart-card-reader-outline::before {
  content: "\F10C0"; }

.mdi-smog::before {
  content: "\F0A71"; }

.mdi-smoke::before {
  content: "\F1799"; }

.mdi-smoke-detector::before {
  content: "\F0392"; }

.mdi-smoke-detector-alert::before {
  content: "\F192E"; }

.mdi-smoke-detector-alert-outline::before {
  content: "\F192F"; }

.mdi-smoke-detector-off::before {
  content: "\F1809"; }

.mdi-smoke-detector-off-outline::before {
  content: "\F180A"; }

.mdi-smoke-detector-outline::before {
  content: "\F1808"; }

.mdi-smoke-detector-variant::before {
  content: "\F180B"; }

.mdi-smoke-detector-variant-alert::before {
  content: "\F1930"; }

.mdi-smoke-detector-variant-off::before {
  content: "\F180C"; }

.mdi-smoking::before {
  content: "\F04B4"; }

.mdi-smoking-off::before {
  content: "\F04B5"; }

.mdi-smoking-pipe::before {
  content: "\F140D"; }

.mdi-smoking-pipe-off::before {
  content: "\F1428"; }

.mdi-snail::before {
  content: "\F1677"; }

.mdi-snake::before {
  content: "\F150E"; }

.mdi-snapchat::before {
  content: "\F04B6"; }

.mdi-snowboard::before {
  content: "\F1307"; }

.mdi-snowflake::before {
  content: "\F0717"; }

.mdi-snowflake-alert::before {
  content: "\F0F29"; }

.mdi-snowflake-check::before {
  content: "\F1A70"; }

.mdi-snowflake-melt::before {
  content: "\F12CB"; }

.mdi-snowflake-off::before {
  content: "\F14E3"; }

.mdi-snowflake-thermometer::before {
  content: "\F1A71"; }

.mdi-snowflake-variant::before {
  content: "\F0F2A"; }

.mdi-snowman::before {
  content: "\F04B7"; }

.mdi-snowmobile::before {
  content: "\F06DD"; }

.mdi-snowshoeing::before {
  content: "\F1A72"; }

.mdi-soccer::before {
  content: "\F04B8"; }

.mdi-soccer-field::before {
  content: "\F0834"; }

.mdi-social-distance-2-meters::before {
  content: "\F1579"; }

.mdi-social-distance-6-feet::before {
  content: "\F157A"; }

.mdi-sofa::before {
  content: "\F04B9"; }

.mdi-sofa-outline::before {
  content: "\F156D"; }

.mdi-sofa-single::before {
  content: "\F156E"; }

.mdi-sofa-single-outline::before {
  content: "\F156F"; }

.mdi-solar-panel::before {
  content: "\F0D9B"; }

.mdi-solar-panel-large::before {
  content: "\F0D9C"; }

.mdi-solar-power::before {
  content: "\F0A72"; }

.mdi-solar-power-variant::before {
  content: "\F1A73"; }

.mdi-solar-power-variant-outline::before {
  content: "\F1A74"; }

.mdi-soldering-iron::before {
  content: "\F1092"; }

.mdi-solid::before {
  content: "\F068D"; }

.mdi-sony-playstation::before {
  content: "\F0414"; }

.mdi-sort::before {
  content: "\F04BA"; }

.mdi-sort-alphabetical-ascending::before {
  content: "\F05BD"; }

.mdi-sort-alphabetical-ascending-variant::before {
  content: "\F1148"; }

.mdi-sort-alphabetical-descending::before {
  content: "\F05BF"; }

.mdi-sort-alphabetical-descending-variant::before {
  content: "\F1149"; }

.mdi-sort-alphabetical-variant::before {
  content: "\F04BB"; }

.mdi-sort-ascending::before {
  content: "\F04BC"; }

.mdi-sort-bool-ascending::before {
  content: "\F1385"; }

.mdi-sort-bool-ascending-variant::before {
  content: "\F1386"; }

.mdi-sort-bool-descending::before {
  content: "\F1387"; }

.mdi-sort-bool-descending-variant::before {
  content: "\F1388"; }

.mdi-sort-calendar-ascending::before {
  content: "\F1547"; }

.mdi-sort-calendar-descending::before {
  content: "\F1548"; }

.mdi-sort-clock-ascending::before {
  content: "\F1549"; }

.mdi-sort-clock-ascending-outline::before {
  content: "\F154A"; }

.mdi-sort-clock-descending::before {
  content: "\F154B"; }

.mdi-sort-clock-descending-outline::before {
  content: "\F154C"; }

.mdi-sort-descending::before {
  content: "\F04BD"; }

.mdi-sort-numeric-ascending::before {
  content: "\F1389"; }

.mdi-sort-numeric-ascending-variant::before {
  content: "\F090D"; }

.mdi-sort-numeric-descending::before {
  content: "\F138A"; }

.mdi-sort-numeric-descending-variant::before {
  content: "\F0AD2"; }

.mdi-sort-numeric-variant::before {
  content: "\F04BE"; }

.mdi-sort-reverse-variant::before {
  content: "\F033C"; }

.mdi-sort-variant::before {
  content: "\F04BF"; }

.mdi-sort-variant-lock::before {
  content: "\F0CCD"; }

.mdi-sort-variant-lock-open::before {
  content: "\F0CCE"; }

.mdi-sort-variant-off::before {
  content: "\F1ABB"; }

.mdi-sort-variant-remove::before {
  content: "\F1147"; }

.mdi-soundbar::before {
  content: "\F17DB"; }

.mdi-soundcloud::before {
  content: "\F04C0"; }

.mdi-source-branch::before {
  content: "\F062C"; }

.mdi-source-branch-check::before {
  content: "\F14CF"; }

.mdi-source-branch-minus::before {
  content: "\F14CB"; }

.mdi-source-branch-plus::before {
  content: "\F14CA"; }

.mdi-source-branch-refresh::before {
  content: "\F14CD"; }

.mdi-source-branch-remove::before {
  content: "\F14CC"; }

.mdi-source-branch-sync::before {
  content: "\F14CE"; }

.mdi-source-commit::before {
  content: "\F0718"; }

.mdi-source-commit-end::before {
  content: "\F0719"; }

.mdi-source-commit-end-local::before {
  content: "\F071A"; }

.mdi-source-commit-local::before {
  content: "\F071B"; }

.mdi-source-commit-next-local::before {
  content: "\F071C"; }

.mdi-source-commit-start::before {
  content: "\F071D"; }

.mdi-source-commit-start-next-local::before {
  content: "\F071E"; }

.mdi-source-fork::before {
  content: "\F04C1"; }

.mdi-source-merge::before {
  content: "\F062D"; }

.mdi-source-pull::before {
  content: "\F04C2"; }

.mdi-source-repository::before {
  content: "\F0CCF"; }

.mdi-source-repository-multiple::before {
  content: "\F0CD0"; }

.mdi-soy-sauce::before {
  content: "\F07EE"; }

.mdi-soy-sauce-off::before {
  content: "\F13FC"; }

.mdi-spa::before {
  content: "\F0CD1"; }

.mdi-spa-outline::before {
  content: "\F0CD2"; }

.mdi-space-invaders::before {
  content: "\F0BC9"; }

.mdi-space-station::before {
  content: "\F1383"; }

.mdi-spade::before {
  content: "\F0E65"; }

.mdi-speaker::before {
  content: "\F04C3"; }

.mdi-speaker-bluetooth::before {
  content: "\F09A2"; }

.mdi-speaker-message::before {
  content: "\F1B11"; }

.mdi-speaker-multiple::before {
  content: "\F0D38"; }

.mdi-speaker-off::before {
  content: "\F04C4"; }

.mdi-speaker-pause::before {
  content: "\F1B73"; }

.mdi-speaker-play::before {
  content: "\F1B72"; }

.mdi-speaker-stop::before {
  content: "\F1B74"; }

.mdi-speaker-wireless::before {
  content: "\F071F"; }

.mdi-spear::before {
  content: "\F1845"; }

.mdi-speedometer::before {
  content: "\F04C5"; }

.mdi-speedometer-medium::before {
  content: "\F0F85"; }

.mdi-speedometer-slow::before {
  content: "\F0F86"; }

.mdi-spellcheck::before {
  content: "\F04C6"; }

.mdi-sphere::before {
  content: "\F1954"; }

.mdi-sphere-off::before {
  content: "\F1955"; }

.mdi-spider::before {
  content: "\F11EA"; }

.mdi-spider-thread::before {
  content: "\F11EB"; }

.mdi-spider-web::before {
  content: "\F0BCA"; }

.mdi-spirit-level::before {
  content: "\F14F1"; }

.mdi-spoon-sugar::before {
  content: "\F1429"; }

.mdi-spotify::before {
  content: "\F04C7"; }

.mdi-spotlight::before {
  content: "\F04C8"; }

.mdi-spotlight-beam::before {
  content: "\F04C9"; }

.mdi-spray::before {
  content: "\F0665"; }

.mdi-spray-bottle::before {
  content: "\F0AE0"; }

.mdi-sprinkler::before {
  content: "\F105F"; }

.mdi-sprinkler-fire::before {
  content: "\F199D"; }

.mdi-sprinkler-variant::before {
  content: "\F1060"; }

.mdi-sprout::before {
  content: "\F0E66"; }

.mdi-sprout-outline::before {
  content: "\F0E67"; }

.mdi-square::before {
  content: "\F0764"; }

.mdi-square-circle::before {
  content: "\F1500"; }

.mdi-square-edit-outline::before {
  content: "\F090C"; }

.mdi-square-medium::before {
  content: "\F0A13"; }

.mdi-square-medium-outline::before {
  content: "\F0A14"; }

.mdi-square-off::before {
  content: "\F12EE"; }

.mdi-square-off-outline::before {
  content: "\F12EF"; }

.mdi-square-opacity::before {
  content: "\F1854"; }

.mdi-square-outline::before {
  content: "\F0763"; }

.mdi-square-root::before {
  content: "\F0784"; }

.mdi-square-root-box::before {
  content: "\F09A3"; }

.mdi-square-rounded::before {
  content: "\F14FB"; }

.mdi-square-rounded-badge::before {
  content: "\F1A07"; }

.mdi-square-rounded-badge-outline::before {
  content: "\F1A08"; }

.mdi-square-rounded-outline::before {
  content: "\F14FC"; }

.mdi-square-small::before {
  content: "\F0A15"; }

.mdi-square-wave::before {
  content: "\F147B"; }

.mdi-squeegee::before {
  content: "\F0AE1"; }

.mdi-ssh::before {
  content: "\F08C0"; }

.mdi-stack-exchange::before {
  content: "\F060B"; }

.mdi-stack-overflow::before {
  content: "\F04CC"; }

.mdi-stackpath::before {
  content: "\F0359"; }

.mdi-stadium::before {
  content: "\F0FF9"; }

.mdi-stadium-outline::before {
  content: "\F1B03"; }

.mdi-stadium-variant::before {
  content: "\F0720"; }

.mdi-stairs::before {
  content: "\F04CD"; }

.mdi-stairs-box::before {
  content: "\F139E"; }

.mdi-stairs-down::before {
  content: "\F12BE"; }

.mdi-stairs-up::before {
  content: "\F12BD"; }

.mdi-stamper::before {
  content: "\F0D39"; }

.mdi-standard-definition::before {
  content: "\F07EF"; }

.mdi-star::before {
  content: "\F04CE"; }

.mdi-star-box::before {
  content: "\F0A73"; }

.mdi-star-box-multiple::before {
  content: "\F1286"; }

.mdi-star-box-multiple-outline::before {
  content: "\F1287"; }

.mdi-star-box-outline::before {
  content: "\F0A74"; }

.mdi-star-check::before {
  content: "\F1566"; }

.mdi-star-check-outline::before {
  content: "\F156A"; }

.mdi-star-circle::before {
  content: "\F04CF"; }

.mdi-star-circle-outline::before {
  content: "\F09A4"; }

.mdi-star-cog::before {
  content: "\F1668"; }

.mdi-star-cog-outline::before {
  content: "\F1669"; }

.mdi-star-crescent::before {
  content: "\F0979"; }

.mdi-star-david::before {
  content: "\F097A"; }

.mdi-star-face::before {
  content: "\F09A5"; }

.mdi-star-four-points::before {
  content: "\F0AE2"; }

.mdi-star-four-points-outline::before {
  content: "\F0AE3"; }

.mdi-star-half::before {
  content: "\F0246"; }

.mdi-star-half-full::before {
  content: "\F04D0"; }

.mdi-star-minus::before {
  content: "\F1564"; }

.mdi-star-minus-outline::before {
  content: "\F1568"; }

.mdi-star-off::before {
  content: "\F04D1"; }

.mdi-star-off-outline::before {
  content: "\F155B"; }

.mdi-star-outline::before {
  content: "\F04D2"; }

.mdi-star-plus::before {
  content: "\F1563"; }

.mdi-star-plus-outline::before {
  content: "\F1567"; }

.mdi-star-remove::before {
  content: "\F1565"; }

.mdi-star-remove-outline::before {
  content: "\F1569"; }

.mdi-star-settings::before {
  content: "\F166A"; }

.mdi-star-settings-outline::before {
  content: "\F166B"; }

.mdi-star-shooting::before {
  content: "\F1741"; }

.mdi-star-shooting-outline::before {
  content: "\F1742"; }

.mdi-star-three-points::before {
  content: "\F0AE4"; }

.mdi-star-three-points-outline::before {
  content: "\F0AE5"; }

.mdi-state-machine::before {
  content: "\F11EF"; }

.mdi-steam::before {
  content: "\F04D3"; }

.mdi-steering::before {
  content: "\F04D4"; }

.mdi-steering-off::before {
  content: "\F090E"; }

.mdi-step-backward::before {
  content: "\F04D5"; }

.mdi-step-backward-2::before {
  content: "\F04D6"; }

.mdi-step-forward::before {
  content: "\F04D7"; }

.mdi-step-forward-2::before {
  content: "\F04D8"; }

.mdi-stethoscope::before {
  content: "\F04D9"; }

.mdi-sticker::before {
  content: "\F1364"; }

.mdi-sticker-alert::before {
  content: "\F1365"; }

.mdi-sticker-alert-outline::before {
  content: "\F1366"; }

.mdi-sticker-check::before {
  content: "\F1367"; }

.mdi-sticker-check-outline::before {
  content: "\F1368"; }

.mdi-sticker-circle-outline::before {
  content: "\F05D0"; }

.mdi-sticker-emoji::before {
  content: "\F0785"; }

.mdi-sticker-minus::before {
  content: "\F1369"; }

.mdi-sticker-minus-outline::before {
  content: "\F136A"; }

.mdi-sticker-outline::before {
  content: "\F136B"; }

.mdi-sticker-plus::before {
  content: "\F136C"; }

.mdi-sticker-plus-outline::before {
  content: "\F136D"; }

.mdi-sticker-remove::before {
  content: "\F136E"; }

.mdi-sticker-remove-outline::before {
  content: "\F136F"; }

.mdi-sticker-text::before {
  content: "\F178E"; }

.mdi-sticker-text-outline::before {
  content: "\F178F"; }

.mdi-stocking::before {
  content: "\F04DA"; }

.mdi-stomach::before {
  content: "\F1093"; }

.mdi-stool::before {
  content: "\F195D"; }

.mdi-stool-outline::before {
  content: "\F195E"; }

.mdi-stop::before {
  content: "\F04DB"; }

.mdi-stop-circle::before {
  content: "\F0666"; }

.mdi-stop-circle-outline::before {
  content: "\F0667"; }

.mdi-storage-tank::before {
  content: "\F1A75"; }

.mdi-storage-tank-outline::before {
  content: "\F1A76"; }

.mdi-store::before {
  content: "\F04DC"; }

.mdi-store-24-hour::before {
  content: "\F04DD"; }

.mdi-store-alert::before {
  content: "\F18C1"; }

.mdi-store-alert-outline::before {
  content: "\F18C2"; }

.mdi-store-check::before {
  content: "\F18C3"; }

.mdi-store-check-outline::before {
  content: "\F18C4"; }

.mdi-store-clock::before {
  content: "\F18C5"; }

.mdi-store-clock-outline::before {
  content: "\F18C6"; }

.mdi-store-cog::before {
  content: "\F18C7"; }

.mdi-store-cog-outline::before {
  content: "\F18C8"; }

.mdi-store-edit::before {
  content: "\F18C9"; }

.mdi-store-edit-outline::before {
  content: "\F18CA"; }

.mdi-store-marker::before {
  content: "\F18CB"; }

.mdi-store-marker-outline::before {
  content: "\F18CC"; }

.mdi-store-minus::before {
  content: "\F165E"; }

.mdi-store-minus-outline::before {
  content: "\F18CD"; }

.mdi-store-off::before {
  content: "\F18CE"; }

.mdi-store-off-outline::before {
  content: "\F18CF"; }

.mdi-store-outline::before {
  content: "\F1361"; }

.mdi-store-plus::before {
  content: "\F165F"; }

.mdi-store-plus-outline::before {
  content: "\F18D0"; }

.mdi-store-remove::before {
  content: "\F1660"; }

.mdi-store-remove-outline::before {
  content: "\F18D1"; }

.mdi-store-search::before {
  content: "\F18D2"; }

.mdi-store-search-outline::before {
  content: "\F18D3"; }

.mdi-store-settings::before {
  content: "\F18D4"; }

.mdi-store-settings-outline::before {
  content: "\F18D5"; }

.mdi-storefront::before {
  content: "\F07C7"; }

.mdi-storefront-check::before {
  content: "\F1B7D"; }

.mdi-storefront-check-outline::before {
  content: "\F1B7E"; }

.mdi-storefront-edit::before {
  content: "\F1B7F"; }

.mdi-storefront-edit-outline::before {
  content: "\F1B80"; }

.mdi-storefront-minus::before {
  content: "\F1B83"; }

.mdi-storefront-minus-outline::before {
  content: "\F1B84"; }

.mdi-storefront-outline::before {
  content: "\F10C1"; }

.mdi-storefront-plus::before {
  content: "\F1B81"; }

.mdi-storefront-plus-outline::before {
  content: "\F1B82"; }

.mdi-storefront-remove::before {
  content: "\F1B85"; }

.mdi-storefront-remove-outline::before {
  content: "\F1B86"; }

.mdi-stove::before {
  content: "\F04DE"; }

.mdi-strategy::before {
  content: "\F11D6"; }

.mdi-stretch-to-page::before {
  content: "\F0F2B"; }

.mdi-stretch-to-page-outline::before {
  content: "\F0F2C"; }

.mdi-string-lights::before {
  content: "\F12BA"; }

.mdi-string-lights-off::before {
  content: "\F12BB"; }

.mdi-subdirectory-arrow-left::before {
  content: "\F060C"; }

.mdi-subdirectory-arrow-right::before {
  content: "\F060D"; }

.mdi-submarine::before {
  content: "\F156C"; }

.mdi-subtitles::before {
  content: "\F0A16"; }

.mdi-subtitles-outline::before {
  content: "\F0A17"; }

.mdi-subway::before {
  content: "\F06AC"; }

.mdi-subway-alert-variant::before {
  content: "\F0D9D"; }

.mdi-subway-variant::before {
  content: "\F04DF"; }

.mdi-summit::before {
  content: "\F0786"; }

.mdi-sun-angle::before {
  content: "\F1B27"; }

.mdi-sun-angle-outline::before {
  content: "\F1B28"; }

.mdi-sun-clock::before {
  content: "\F1A77"; }

.mdi-sun-clock-outline::before {
  content: "\F1A78"; }

.mdi-sun-compass::before {
  content: "\F19A5"; }

.mdi-sun-snowflake::before {
  content: "\F1796"; }

.mdi-sun-snowflake-variant::before {
  content: "\F1A79"; }

.mdi-sun-thermometer::before {
  content: "\F18D6"; }

.mdi-sun-thermometer-outline::before {
  content: "\F18D7"; }

.mdi-sun-wireless::before {
  content: "\F17FE"; }

.mdi-sun-wireless-outline::before {
  content: "\F17FF"; }

.mdi-sunglasses::before {
  content: "\F04E0"; }

.mdi-surfing::before {
  content: "\F1746"; }

.mdi-surround-sound::before {
  content: "\F05C5"; }

.mdi-surround-sound-2-0::before {
  content: "\F07F0"; }

.mdi-surround-sound-2-1::before {
  content: "\F1729"; }

.mdi-surround-sound-3-1::before {
  content: "\F07F1"; }

.mdi-surround-sound-5-1::before {
  content: "\F07F2"; }

.mdi-surround-sound-5-1-2::before {
  content: "\F172A"; }

.mdi-surround-sound-7-1::before {
  content: "\F07F3"; }

.mdi-svg::before {
  content: "\F0721"; }

.mdi-swap-horizontal::before {
  content: "\F04E1"; }

.mdi-swap-horizontal-bold::before {
  content: "\F0BCD"; }

.mdi-swap-horizontal-circle::before {
  content: "\F0FE1"; }

.mdi-swap-horizontal-circle-outline::before {
  content: "\F0FE2"; }

.mdi-swap-horizontal-variant::before {
  content: "\F08C1"; }

.mdi-swap-vertical::before {
  content: "\F04E2"; }

.mdi-swap-vertical-bold::before {
  content: "\F0BCE"; }

.mdi-swap-vertical-circle::before {
  content: "\F0FE3"; }

.mdi-swap-vertical-circle-outline::before {
  content: "\F0FE4"; }

.mdi-swap-vertical-variant::before {
  content: "\F08C2"; }

.mdi-swim::before {
  content: "\F04E3"; }

.mdi-switch::before {
  content: "\F04E4"; }

.mdi-sword::before {
  content: "\F04E5"; }

.mdi-sword-cross::before {
  content: "\F0787"; }

.mdi-syllabary-hangul::before {
  content: "\F1333"; }

.mdi-syllabary-hiragana::before {
  content: "\F1334"; }

.mdi-syllabary-katakana::before {
  content: "\F1335"; }

.mdi-syllabary-katakana-halfwidth::before {
  content: "\F1336"; }

.mdi-symbol::before {
  content: "\F1501"; }

.mdi-symfony::before {
  content: "\F0AE6"; }

.mdi-synagogue::before {
  content: "\F1B04"; }

.mdi-synagogue-outline::before {
  content: "\F1B05"; }

.mdi-sync::before {
  content: "\F04E6"; }

.mdi-sync-alert::before {
  content: "\F04E7"; }

.mdi-sync-circle::before {
  content: "\F1378"; }

.mdi-sync-off::before {
  content: "\F04E8"; }

.mdi-tab::before {
  content: "\F04E9"; }

.mdi-tab-minus::before {
  content: "\F0B4B"; }

.mdi-tab-plus::before {
  content: "\F075C"; }

.mdi-tab-remove::before {
  content: "\F0B4C"; }

.mdi-tab-search::before {
  content: "\F199E"; }

.mdi-tab-unselected::before {
  content: "\F04EA"; }

.mdi-table::before {
  content: "\F04EB"; }

.mdi-table-account::before {
  content: "\F13B9"; }

.mdi-table-alert::before {
  content: "\F13BA"; }

.mdi-table-arrow-down::before {
  content: "\F13BB"; }

.mdi-table-arrow-left::before {
  content: "\F13BC"; }

.mdi-table-arrow-right::before {
  content: "\F13BD"; }

.mdi-table-arrow-up::before {
  content: "\F13BE"; }

.mdi-table-border::before {
  content: "\F0A18"; }

.mdi-table-cancel::before {
  content: "\F13BF"; }

.mdi-table-chair::before {
  content: "\F1061"; }

.mdi-table-check::before {
  content: "\F13C0"; }

.mdi-table-clock::before {
  content: "\F13C1"; }

.mdi-table-cog::before {
  content: "\F13C2"; }

.mdi-table-column::before {
  content: "\F0835"; }

.mdi-table-column-plus-after::before {
  content: "\F04EC"; }

.mdi-table-column-plus-before::before {
  content: "\F04ED"; }

.mdi-table-column-remove::before {
  content: "\F04EE"; }

.mdi-table-column-width::before {
  content: "\F04EF"; }

.mdi-table-edit::before {
  content: "\F04F0"; }

.mdi-table-eye::before {
  content: "\F1094"; }

.mdi-table-eye-off::before {
  content: "\F13C3"; }

.mdi-table-filter::before {
  content: "\F1B8C"; }

.mdi-table-furniture::before {
  content: "\F05BC"; }

.mdi-table-headers-eye::before {
  content: "\F121D"; }

.mdi-table-headers-eye-off::before {
  content: "\F121E"; }

.mdi-table-heart::before {
  content: "\F13C4"; }

.mdi-table-key::before {
  content: "\F13C5"; }

.mdi-table-large::before {
  content: "\F04F1"; }

.mdi-table-large-plus::before {
  content: "\F0F87"; }

.mdi-table-large-remove::before {
  content: "\F0F88"; }

.mdi-table-lock::before {
  content: "\F13C6"; }

.mdi-table-merge-cells::before {
  content: "\F09A6"; }

.mdi-table-minus::before {
  content: "\F13C7"; }

.mdi-table-multiple::before {
  content: "\F13C8"; }

.mdi-table-network::before {
  content: "\F13C9"; }

.mdi-table-of-contents::before {
  content: "\F0836"; }

.mdi-table-off::before {
  content: "\F13CA"; }

.mdi-table-picnic::before {
  content: "\F1743"; }

.mdi-table-pivot::before {
  content: "\F183C"; }

.mdi-table-plus::before {
  content: "\F0A75"; }

.mdi-table-question::before {
  content: "\F1B21"; }

.mdi-table-refresh::before {
  content: "\F13A0"; }

.mdi-table-remove::before {
  content: "\F0A76"; }

.mdi-table-row::before {
  content: "\F0837"; }

.mdi-table-row-height::before {
  content: "\F04F2"; }

.mdi-table-row-plus-after::before {
  content: "\F04F3"; }

.mdi-table-row-plus-before::before {
  content: "\F04F4"; }

.mdi-table-row-remove::before {
  content: "\F04F5"; }

.mdi-table-search::before {
  content: "\F090F"; }

.mdi-table-settings::before {
  content: "\F0838"; }

.mdi-table-split-cell::before {
  content: "\F142A"; }

.mdi-table-star::before {
  content: "\F13CB"; }

.mdi-table-sync::before {
  content: "\F13A1"; }

.mdi-table-tennis::before {
  content: "\F0E68"; }

.mdi-tablet::before {
  content: "\F04F6"; }

.mdi-tablet-cellphone::before {
  content: "\F09A7"; }

.mdi-tablet-dashboard::before {
  content: "\F0ECE"; }

.mdi-taco::before {
  content: "\F0762"; }

.mdi-tag::before {
  content: "\F04F9"; }

.mdi-tag-arrow-down::before {
  content: "\F172B"; }

.mdi-tag-arrow-down-outline::before {
  content: "\F172C"; }

.mdi-tag-arrow-left::before {
  content: "\F172D"; }

.mdi-tag-arrow-left-outline::before {
  content: "\F172E"; }

.mdi-tag-arrow-right::before {
  content: "\F172F"; }

.mdi-tag-arrow-right-outline::before {
  content: "\F1730"; }

.mdi-tag-arrow-up::before {
  content: "\F1731"; }

.mdi-tag-arrow-up-outline::before {
  content: "\F1732"; }

.mdi-tag-check::before {
  content: "\F1A7A"; }

.mdi-tag-check-outline::before {
  content: "\F1A7B"; }

.mdi-tag-faces::before {
  content: "\F04FA"; }

.mdi-tag-heart::before {
  content: "\F068B"; }

.mdi-tag-heart-outline::before {
  content: "\F0BCF"; }

.mdi-tag-minus::before {
  content: "\F0910"; }

.mdi-tag-minus-outline::before {
  content: "\F121F"; }

.mdi-tag-multiple::before {
  content: "\F04FB"; }

.mdi-tag-multiple-outline::before {
  content: "\F12F7"; }

.mdi-tag-off::before {
  content: "\F1220"; }

.mdi-tag-off-outline::before {
  content: "\F1221"; }

.mdi-tag-outline::before {
  content: "\F04FC"; }

.mdi-tag-plus::before {
  content: "\F0722"; }

.mdi-tag-plus-outline::before {
  content: "\F1222"; }

.mdi-tag-remove::before {
  content: "\F0723"; }

.mdi-tag-remove-outline::before {
  content: "\F1223"; }

.mdi-tag-search::before {
  content: "\F1907"; }

.mdi-tag-search-outline::before {
  content: "\F1908"; }

.mdi-tag-text::before {
  content: "\F1224"; }

.mdi-tag-text-outline::before {
  content: "\F04FD"; }

.mdi-tailwind::before {
  content: "\F13FF"; }

.mdi-tally-mark-1::before {
  content: "\F1ABC"; }

.mdi-tally-mark-2::before {
  content: "\F1ABD"; }

.mdi-tally-mark-3::before {
  content: "\F1ABE"; }

.mdi-tally-mark-4::before {
  content: "\F1ABF"; }

.mdi-tally-mark-5::before {
  content: "\F1AC0"; }

.mdi-tangram::before {
  content: "\F04F8"; }

.mdi-tank::before {
  content: "\F0D3A"; }

.mdi-tanker-truck::before {
  content: "\F0FE5"; }

.mdi-tape-drive::before {
  content: "\F16DF"; }

.mdi-tape-measure::before {
  content: "\F0B4D"; }

.mdi-target::before {
  content: "\F04FE"; }

.mdi-target-account::before {
  content: "\F0BD0"; }

.mdi-target-variant::before {
  content: "\F0A77"; }

.mdi-taxi::before {
  content: "\F04FF"; }

.mdi-tea::before {
  content: "\F0D9E"; }

.mdi-tea-outline::before {
  content: "\F0D9F"; }

.mdi-teamviewer::before {
  content: "\F0500"; }

.mdi-teddy-bear::before {
  content: "\F18FB"; }

.mdi-telescope::before {
  content: "\F0B4E"; }

.mdi-television::before {
  content: "\F0502"; }

.mdi-television-ambient-light::before {
  content: "\F1356"; }

.mdi-television-box::before {
  content: "\F0839"; }

.mdi-television-classic::before {
  content: "\F07F4"; }

.mdi-television-classic-off::before {
  content: "\F083A"; }

.mdi-television-guide::before {
  content: "\F0503"; }

.mdi-television-off::before {
  content: "\F083B"; }

.mdi-television-pause::before {
  content: "\F0F89"; }

.mdi-television-play::before {
  content: "\F0ECF"; }

.mdi-television-shimmer::before {
  content: "\F1110"; }

.mdi-television-speaker::before {
  content: "\F1B1B"; }

.mdi-television-speaker-off::before {
  content: "\F1B1C"; }

.mdi-television-stop::before {
  content: "\F0F8A"; }

.mdi-temperature-celsius::before {
  content: "\F0504"; }

.mdi-temperature-fahrenheit::before {
  content: "\F0505"; }

.mdi-temperature-kelvin::before {
  content: "\F0506"; }

.mdi-temple-buddhist::before {
  content: "\F1B06"; }

.mdi-temple-buddhist-outline::before {
  content: "\F1B07"; }

.mdi-temple-hindu::before {
  content: "\F1B08"; }

.mdi-temple-hindu-outline::before {
  content: "\F1B09"; }

.mdi-tennis::before {
  content: "\F0DA0"; }

.mdi-tennis-ball::before {
  content: "\F0507"; }

.mdi-tent::before {
  content: "\F0508"; }

.mdi-terraform::before {
  content: "\F1062"; }

.mdi-terrain::before {
  content: "\F0509"; }

.mdi-test-tube::before {
  content: "\F0668"; }

.mdi-test-tube-empty::before {
  content: "\F0911"; }

.mdi-test-tube-off::before {
  content: "\F0912"; }

.mdi-text::before {
  content: "\F09A8"; }

.mdi-text-account::before {
  content: "\F1570"; }

.mdi-text-box::before {
  content: "\F021A"; }

.mdi-text-box-check::before {
  content: "\F0EA6"; }

.mdi-text-box-check-outline::before {
  content: "\F0EA7"; }

.mdi-text-box-edit::before {
  content: "\F1A7C"; }

.mdi-text-box-edit-outline::before {
  content: "\F1A7D"; }

.mdi-text-box-minus::before {
  content: "\F0EA8"; }

.mdi-text-box-minus-outline::before {
  content: "\F0EA9"; }

.mdi-text-box-multiple::before {
  content: "\F0AB7"; }

.mdi-text-box-multiple-outline::before {
  content: "\F0AB8"; }

.mdi-text-box-outline::before {
  content: "\F09ED"; }

.mdi-text-box-plus::before {
  content: "\F0EAA"; }

.mdi-text-box-plus-outline::before {
  content: "\F0EAB"; }

.mdi-text-box-remove::before {
  content: "\F0EAC"; }

.mdi-text-box-remove-outline::before {
  content: "\F0EAD"; }

.mdi-text-box-search::before {
  content: "\F0EAE"; }

.mdi-text-box-search-outline::before {
  content: "\F0EAF"; }

.mdi-text-long::before {
  content: "\F09AA"; }

.mdi-text-recognition::before {
  content: "\F113D"; }

.mdi-text-search::before {
  content: "\F13B8"; }

.mdi-text-search-variant::before {
  content: "\F1A7E"; }

.mdi-text-shadow::before {
  content: "\F0669"; }

.mdi-text-short::before {
  content: "\F09A9"; }

.mdi-texture::before {
  content: "\F050C"; }

.mdi-texture-box::before {
  content: "\F0FE6"; }

.mdi-theater::before {
  content: "\F050D"; }

.mdi-theme-light-dark::before {
  content: "\F050E"; }

.mdi-thermometer::before {
  content: "\F050F"; }

.mdi-thermometer-alert::before {
  content: "\F0E01"; }

.mdi-thermometer-auto::before {
  content: "\F1B0F"; }

.mdi-thermometer-bluetooth::before {
  content: "\F1895"; }

.mdi-thermometer-check::before {
  content: "\F1A7F"; }

.mdi-thermometer-chevron-down::before {
  content: "\F0E02"; }

.mdi-thermometer-chevron-up::before {
  content: "\F0E03"; }

.mdi-thermometer-high::before {
  content: "\F10C2"; }

.mdi-thermometer-lines::before {
  content: "\F0510"; }

.mdi-thermometer-low::before {
  content: "\F10C3"; }

.mdi-thermometer-minus::before {
  content: "\F0E04"; }

.mdi-thermometer-off::before {
  content: "\F1531"; }

.mdi-thermometer-plus::before {
  content: "\F0E05"; }

.mdi-thermometer-probe::before {
  content: "\F1B2B"; }

.mdi-thermometer-probe-off::before {
  content: "\F1B2C"; }

.mdi-thermometer-water::before {
  content: "\F1A80"; }

.mdi-thermostat::before {
  content: "\F0393"; }

.mdi-thermostat-auto::before {
  content: "\F1B17"; }

.mdi-thermostat-box::before {
  content: "\F0891"; }

.mdi-thermostat-box-auto::before {
  content: "\F1B18"; }

.mdi-thought-bubble::before {
  content: "\F07F6"; }

.mdi-thought-bubble-outline::before {
  content: "\F07F7"; }

.mdi-thumb-down::before {
  content: "\F0511"; }

.mdi-thumb-down-outline::before {
  content: "\F0512"; }

.mdi-thumb-up::before {
  content: "\F0513"; }

.mdi-thumb-up-outline::before {
  content: "\F0514"; }

.mdi-thumbs-up-down::before {
  content: "\F0515"; }

.mdi-thumbs-up-down-outline::before {
  content: "\F1914"; }

.mdi-ticket::before {
  content: "\F0516"; }

.mdi-ticket-account::before {
  content: "\F0517"; }

.mdi-ticket-confirmation::before {
  content: "\F0518"; }

.mdi-ticket-confirmation-outline::before {
  content: "\F13AA"; }

.mdi-ticket-outline::before {
  content: "\F0913"; }

.mdi-ticket-percent::before {
  content: "\F0724"; }

.mdi-ticket-percent-outline::before {
  content: "\F142B"; }

.mdi-tie::before {
  content: "\F0519"; }

.mdi-tilde::before {
  content: "\F0725"; }

.mdi-tilde-off::before {
  content: "\F18F3"; }

.mdi-timelapse::before {
  content: "\F051A"; }

.mdi-timeline::before {
  content: "\F0BD1"; }

.mdi-timeline-alert::before {
  content: "\F0F95"; }

.mdi-timeline-alert-outline::before {
  content: "\F0F98"; }

.mdi-timeline-check::before {
  content: "\F1532"; }

.mdi-timeline-check-outline::before {
  content: "\F1533"; }

.mdi-timeline-clock::before {
  content: "\F11FB"; }

.mdi-timeline-clock-outline::before {
  content: "\F11FC"; }

.mdi-timeline-minus::before {
  content: "\F1534"; }

.mdi-timeline-minus-outline::before {
  content: "\F1535"; }

.mdi-timeline-outline::before {
  content: "\F0BD2"; }

.mdi-timeline-plus::before {
  content: "\F0F96"; }

.mdi-timeline-plus-outline::before {
  content: "\F0F97"; }

.mdi-timeline-question::before {
  content: "\F0F99"; }

.mdi-timeline-question-outline::before {
  content: "\F0F9A"; }

.mdi-timeline-remove::before {
  content: "\F1536"; }

.mdi-timeline-remove-outline::before {
  content: "\F1537"; }

.mdi-timeline-text::before {
  content: "\F0BD3"; }

.mdi-timeline-text-outline::before {
  content: "\F0BD4"; }

.mdi-timer::before {
  content: "\F13AB"; }

.mdi-timer-10::before {
  content: "\F051C"; }

.mdi-timer-3::before {
  content: "\F051D"; }

.mdi-timer-alert::before {
  content: "\F1ACC"; }

.mdi-timer-alert-outline::before {
  content: "\F1ACD"; }

.mdi-timer-cancel::before {
  content: "\F1ACE"; }

.mdi-timer-cancel-outline::before {
  content: "\F1ACF"; }

.mdi-timer-check::before {
  content: "\F1AD0"; }

.mdi-timer-check-outline::before {
  content: "\F1AD1"; }

.mdi-timer-cog::before {
  content: "\F1925"; }

.mdi-timer-cog-outline::before {
  content: "\F1926"; }

.mdi-timer-edit::before {
  content: "\F1AD2"; }

.mdi-timer-edit-outline::before {
  content: "\F1AD3"; }

.mdi-timer-lock::before {
  content: "\F1AD4"; }

.mdi-timer-lock-open::before {
  content: "\F1AD5"; }

.mdi-timer-lock-open-outline::before {
  content: "\F1AD6"; }

.mdi-timer-lock-outline::before {
  content: "\F1AD7"; }

.mdi-timer-marker::before {
  content: "\F1AD8"; }

.mdi-timer-marker-outline::before {
  content: "\F1AD9"; }

.mdi-timer-minus::before {
  content: "\F1ADA"; }

.mdi-timer-minus-outline::before {
  content: "\F1ADB"; }

.mdi-timer-music::before {
  content: "\F1ADC"; }

.mdi-timer-music-outline::before {
  content: "\F1ADD"; }

.mdi-timer-off::before {
  content: "\F13AC"; }

.mdi-timer-off-outline::before {
  content: "\F051E"; }

.mdi-timer-outline::before {
  content: "\F051B"; }

.mdi-timer-pause::before {
  content: "\F1ADE"; }

.mdi-timer-pause-outline::before {
  content: "\F1ADF"; }

.mdi-timer-play::before {
  content: "\F1AE0"; }

.mdi-timer-play-outline::before {
  content: "\F1AE1"; }

.mdi-timer-plus::before {
  content: "\F1AE2"; }

.mdi-timer-plus-outline::before {
  content: "\F1AE3"; }

.mdi-timer-refresh::before {
  content: "\F1AE4"; }

.mdi-timer-refresh-outline::before {
  content: "\F1AE5"; }

.mdi-timer-remove::before {
  content: "\F1AE6"; }

.mdi-timer-remove-outline::before {
  content: "\F1AE7"; }

.mdi-timer-sand::before {
  content: "\F051F"; }

.mdi-timer-sand-complete::before {
  content: "\F199F"; }

.mdi-timer-sand-empty::before {
  content: "\F06AD"; }

.mdi-timer-sand-full::before {
  content: "\F078C"; }

.mdi-timer-sand-paused::before {
  content: "\F19A0"; }

.mdi-timer-settings::before {
  content: "\F1923"; }

.mdi-timer-settings-outline::before {
  content: "\F1924"; }

.mdi-timer-star::before {
  content: "\F1AE8"; }

.mdi-timer-star-outline::before {
  content: "\F1AE9"; }

.mdi-timer-stop::before {
  content: "\F1AEA"; }

.mdi-timer-stop-outline::before {
  content: "\F1AEB"; }

.mdi-timer-sync::before {
  content: "\F1AEC"; }

.mdi-timer-sync-outline::before {
  content: "\F1AED"; }

.mdi-timetable::before {
  content: "\F0520"; }

.mdi-tire::before {
  content: "\F1896"; }

.mdi-toaster::before {
  content: "\F1063"; }

.mdi-toaster-off::before {
  content: "\F11B7"; }

.mdi-toaster-oven::before {
  content: "\F0CD3"; }

.mdi-toggle-switch::before {
  content: "\F0521"; }

.mdi-toggle-switch-off::before {
  content: "\F0522"; }

.mdi-toggle-switch-off-outline::before {
  content: "\F0A19"; }

.mdi-toggle-switch-outline::before {
  content: "\F0A1A"; }

.mdi-toggle-switch-variant::before {
  content: "\F1A25"; }

.mdi-toggle-switch-variant-off::before {
  content: "\F1A26"; }

.mdi-toilet::before {
  content: "\F09AB"; }

.mdi-toolbox::before {
  content: "\F09AC"; }

.mdi-toolbox-outline::before {
  content: "\F09AD"; }

.mdi-tools::before {
  content: "\F1064"; }

.mdi-tooltip::before {
  content: "\F0523"; }

.mdi-tooltip-account::before {
  content: "\F000C"; }

.mdi-tooltip-cellphone::before {
  content: "\F183B"; }

.mdi-tooltip-check::before {
  content: "\F155C"; }

.mdi-tooltip-check-outline::before {
  content: "\F155D"; }

.mdi-tooltip-edit::before {
  content: "\F0524"; }

.mdi-tooltip-edit-outline::before {
  content: "\F12C5"; }

.mdi-tooltip-image::before {
  content: "\F0525"; }

.mdi-tooltip-image-outline::before {
  content: "\F0BD5"; }

.mdi-tooltip-minus::before {
  content: "\F155E"; }

.mdi-tooltip-minus-outline::before {
  content: "\F155F"; }

.mdi-tooltip-outline::before {
  content: "\F0526"; }

.mdi-tooltip-plus::before {
  content: "\F0BD6"; }

.mdi-tooltip-plus-outline::before {
  content: "\F0527"; }

.mdi-tooltip-remove::before {
  content: "\F1560"; }

.mdi-tooltip-remove-outline::before {
  content: "\F1561"; }

.mdi-tooltip-text::before {
  content: "\F0528"; }

.mdi-tooltip-text-outline::before {
  content: "\F0BD7"; }

.mdi-tooth::before {
  content: "\F08C3"; }

.mdi-tooth-outline::before {
  content: "\F0529"; }

.mdi-toothbrush::before {
  content: "\F1129"; }

.mdi-toothbrush-electric::before {
  content: "\F112C"; }

.mdi-toothbrush-paste::before {
  content: "\F112A"; }

.mdi-torch::before {
  content: "\F1606"; }

.mdi-tortoise::before {
  content: "\F0D3B"; }

.mdi-toslink::before {
  content: "\F12B8"; }

.mdi-tournament::before {
  content: "\F09AE"; }

.mdi-tow-truck::before {
  content: "\F083C"; }

.mdi-tower-beach::before {
  content: "\F0681"; }

.mdi-tower-fire::before {
  content: "\F0682"; }

.mdi-town-hall::before {
  content: "\F1875"; }

.mdi-toy-brick::before {
  content: "\F1288"; }

.mdi-toy-brick-marker::before {
  content: "\F1289"; }

.mdi-toy-brick-marker-outline::before {
  content: "\F128A"; }

.mdi-toy-brick-minus::before {
  content: "\F128B"; }

.mdi-toy-brick-minus-outline::before {
  content: "\F128C"; }

.mdi-toy-brick-outline::before {
  content: "\F128D"; }

.mdi-toy-brick-plus::before {
  content: "\F128E"; }

.mdi-toy-brick-plus-outline::before {
  content: "\F128F"; }

.mdi-toy-brick-remove::before {
  content: "\F1290"; }

.mdi-toy-brick-remove-outline::before {
  content: "\F1291"; }

.mdi-toy-brick-search::before {
  content: "\F1292"; }

.mdi-toy-brick-search-outline::before {
  content: "\F1293"; }

.mdi-track-light::before {
  content: "\F0914"; }

.mdi-track-light-off::before {
  content: "\F1B01"; }

.mdi-trackpad::before {
  content: "\F07F8"; }

.mdi-trackpad-lock::before {
  content: "\F0933"; }

.mdi-tractor::before {
  content: "\F0892"; }

.mdi-tractor-variant::before {
  content: "\F14C4"; }

.mdi-trademark::before {
  content: "\F0A78"; }

.mdi-traffic-cone::before {
  content: "\F137C"; }

.mdi-traffic-light::before {
  content: "\F052B"; }

.mdi-traffic-light-outline::before {
  content: "\F182A"; }

.mdi-train::before {
  content: "\F052C"; }

.mdi-train-car::before {
  content: "\F0BD8"; }

.mdi-train-car-autorack::before {
  content: "\F1B2D"; }

.mdi-train-car-box::before {
  content: "\F1B2E"; }

.mdi-train-car-box-full::before {
  content: "\F1B2F"; }

.mdi-train-car-box-open::before {
  content: "\F1B30"; }

.mdi-train-car-caboose::before {
  content: "\F1B31"; }

.mdi-train-car-centerbeam::before {
  content: "\F1B32"; }

.mdi-train-car-centerbeam-full::before {
  content: "\F1B33"; }

.mdi-train-car-container::before {
  content: "\F1B34"; }

.mdi-train-car-flatbed::before {
  content: "\F1B35"; }

.mdi-train-car-flatbed-car::before {
  content: "\F1B36"; }

.mdi-train-car-flatbed-tank::before {
  content: "\F1B37"; }

.mdi-train-car-gondola::before {
  content: "\F1B38"; }

.mdi-train-car-gondola-full::before {
  content: "\F1B39"; }

.mdi-train-car-hopper::before {
  content: "\F1B3A"; }

.mdi-train-car-hopper-covered::before {
  content: "\F1B3B"; }

.mdi-train-car-hopper-full::before {
  content: "\F1B3C"; }

.mdi-train-car-intermodal::before {
  content: "\F1B3D"; }

.mdi-train-car-passenger::before {
  content: "\F1733"; }

.mdi-train-car-passenger-door::before {
  content: "\F1734"; }

.mdi-train-car-passenger-door-open::before {
  content: "\F1735"; }

.mdi-train-car-passenger-variant::before {
  content: "\F1736"; }

.mdi-train-car-tank::before {
  content: "\F1B3E"; }

.mdi-train-variant::before {
  content: "\F08C4"; }

.mdi-tram::before {
  content: "\F052D"; }

.mdi-tram-side::before {
  content: "\F0FE7"; }

.mdi-transcribe::before {
  content: "\F052E"; }

.mdi-transcribe-close::before {
  content: "\F052F"; }

.mdi-transfer::before {
  content: "\F1065"; }

.mdi-transfer-down::before {
  content: "\F0DA1"; }

.mdi-transfer-left::before {
  content: "\F0DA2"; }

.mdi-transfer-right::before {
  content: "\F0530"; }

.mdi-transfer-up::before {
  content: "\F0DA3"; }

.mdi-transit-connection::before {
  content: "\F0D3C"; }

.mdi-transit-connection-horizontal::before {
  content: "\F1546"; }

.mdi-transit-connection-variant::before {
  content: "\F0D3D"; }

.mdi-transit-detour::before {
  content: "\F0F8B"; }

.mdi-transit-skip::before {
  content: "\F1515"; }

.mdi-transit-transfer::before {
  content: "\F06AE"; }

.mdi-transition::before {
  content: "\F0915"; }

.mdi-transition-masked::before {
  content: "\F0916"; }

.mdi-translate::before {
  content: "\F05CA"; }

.mdi-translate-off::before {
  content: "\F0E06"; }

.mdi-translate-variant::before {
  content: "\F1B99"; }

.mdi-transmission-tower::before {
  content: "\F0D3E"; }

.mdi-transmission-tower-export::before {
  content: "\F192C"; }

.mdi-transmission-tower-import::before {
  content: "\F192D"; }

.mdi-transmission-tower-off::before {
  content: "\F19DD"; }

.mdi-trash-can::before {
  content: "\F0A79"; }

.mdi-trash-can-outline::before {
  content: "\F0A7A"; }

.mdi-tray::before {
  content: "\F1294"; }

.mdi-tray-alert::before {
  content: "\F1295"; }

.mdi-tray-arrow-down::before {
  content: "\F0120"; }

.mdi-tray-arrow-up::before {
  content: "\F011D"; }

.mdi-tray-full::before {
  content: "\F1296"; }

.mdi-tray-minus::before {
  content: "\F1297"; }

.mdi-tray-plus::before {
  content: "\F1298"; }

.mdi-tray-remove::before {
  content: "\F1299"; }

.mdi-treasure-chest::before {
  content: "\F0726"; }

.mdi-tree::before {
  content: "\F0531"; }

.mdi-tree-outline::before {
  content: "\F0E69"; }

.mdi-trello::before {
  content: "\F0532"; }

.mdi-trending-down::before {
  content: "\F0533"; }

.mdi-trending-neutral::before {
  content: "\F0534"; }

.mdi-trending-up::before {
  content: "\F0535"; }

.mdi-triangle::before {
  content: "\F0536"; }

.mdi-triangle-outline::before {
  content: "\F0537"; }

.mdi-triangle-small-down::before {
  content: "\F1A09"; }

.mdi-triangle-small-up::before {
  content: "\F1A0A"; }

.mdi-triangle-wave::before {
  content: "\F147C"; }

.mdi-triforce::before {
  content: "\F0BD9"; }

.mdi-trophy::before {
  content: "\F0538"; }

.mdi-trophy-award::before {
  content: "\F0539"; }

.mdi-trophy-broken::before {
  content: "\F0DA4"; }

.mdi-trophy-outline::before {
  content: "\F053A"; }

.mdi-trophy-variant::before {
  content: "\F053B"; }

.mdi-trophy-variant-outline::before {
  content: "\F053C"; }

.mdi-truck::before {
  content: "\F053D"; }

.mdi-truck-alert::before {
  content: "\F19DE"; }

.mdi-truck-alert-outline::before {
  content: "\F19DF"; }

.mdi-truck-cargo-container::before {
  content: "\F18D8"; }

.mdi-truck-check::before {
  content: "\F0CD4"; }

.mdi-truck-check-outline::before {
  content: "\F129A"; }

.mdi-truck-delivery::before {
  content: "\F053E"; }

.mdi-truck-delivery-outline::before {
  content: "\F129B"; }

.mdi-truck-fast::before {
  content: "\F0788"; }

.mdi-truck-fast-outline::before {
  content: "\F129C"; }

.mdi-truck-flatbed::before {
  content: "\F1891"; }

.mdi-truck-minus::before {
  content: "\F19AE"; }

.mdi-truck-minus-outline::before {
  content: "\F19BD"; }

.mdi-truck-outline::before {
  content: "\F129D"; }

.mdi-truck-plus::before {
  content: "\F19AD"; }

.mdi-truck-plus-outline::before {
  content: "\F19BC"; }

.mdi-truck-remove::before {
  content: "\F19AF"; }

.mdi-truck-remove-outline::before {
  content: "\F19BE"; }

.mdi-truck-snowflake::before {
  content: "\F19A6"; }

.mdi-truck-trailer::before {
  content: "\F0727"; }

.mdi-trumpet::before {
  content: "\F1096"; }

.mdi-tshirt-crew::before {
  content: "\F0A7B"; }

.mdi-tshirt-crew-outline::before {
  content: "\F053F"; }

.mdi-tshirt-v::before {
  content: "\F0A7C"; }

.mdi-tshirt-v-outline::before {
  content: "\F0540"; }

.mdi-tsunami::before {
  content: "\F1A81"; }

.mdi-tumble-dryer::before {
  content: "\F0917"; }

.mdi-tumble-dryer-alert::before {
  content: "\F11BA"; }

.mdi-tumble-dryer-off::before {
  content: "\F11BB"; }

.mdi-tune::before {
  content: "\F062E"; }

.mdi-tune-variant::before {
  content: "\F1542"; }

.mdi-tune-vertical::before {
  content: "\F066A"; }

.mdi-tune-vertical-variant::before {
  content: "\F1543"; }

.mdi-tunnel::before {
  content: "\F183D"; }

.mdi-tunnel-outline::before {
  content: "\F183E"; }

.mdi-turbine::before {
  content: "\F1A82"; }

.mdi-turkey::before {
  content: "\F171B"; }

.mdi-turnstile::before {
  content: "\F0CD5"; }

.mdi-turnstile-outline::before {
  content: "\F0CD6"; }

.mdi-turtle::before {
  content: "\F0CD7"; }

.mdi-twitch::before {
  content: "\F0543"; }

.mdi-twitter::before {
  content: "\F0544"; }

.mdi-two-factor-authentication::before {
  content: "\F09AF"; }

.mdi-typewriter::before {
  content: "\F0F2D"; }

.mdi-ubisoft::before {
  content: "\F0BDA"; }

.mdi-ubuntu::before {
  content: "\F0548"; }

.mdi-ufo::before {
  content: "\F10C4"; }

.mdi-ufo-outline::before {
  content: "\F10C5"; }

.mdi-ultra-high-definition::before {
  content: "\F07F9"; }

.mdi-umbraco::before {
  content: "\F0549"; }

.mdi-umbrella::before {
  content: "\F054A"; }

.mdi-umbrella-beach::before {
  content: "\F188A"; }

.mdi-umbrella-beach-outline::before {
  content: "\F188B"; }

.mdi-umbrella-closed::before {
  content: "\F09B0"; }

.mdi-umbrella-closed-outline::before {
  content: "\F13E2"; }

.mdi-umbrella-closed-variant::before {
  content: "\F13E1"; }

.mdi-umbrella-outline::before {
  content: "\F054B"; }

.mdi-undo::before {
  content: "\F054C"; }

.mdi-undo-variant::before {
  content: "\F054D"; }

.mdi-unfold-less-horizontal::before {
  content: "\F054E"; }

.mdi-unfold-less-vertical::before {
  content: "\F0760"; }

.mdi-unfold-more-horizontal::before {
  content: "\F054F"; }

.mdi-unfold-more-vertical::before {
  content: "\F0761"; }

.mdi-ungroup::before {
  content: "\F0550"; }

.mdi-unicode::before {
  content: "\F0ED0"; }

.mdi-unicorn::before {
  content: "\F15C2"; }

.mdi-unicorn-variant::before {
  content: "\F15C3"; }

.mdi-unicycle::before {
  content: "\F15E5"; }

.mdi-unity::before {
  content: "\F06AF"; }

.mdi-unreal::before {
  content: "\F09B1"; }

.mdi-update::before {
  content: "\F06B0"; }

.mdi-upload::before {
  content: "\F0552"; }

.mdi-upload-lock::before {
  content: "\F1373"; }

.mdi-upload-lock-outline::before {
  content: "\F1374"; }

.mdi-upload-multiple::before {
  content: "\F083D"; }

.mdi-upload-network::before {
  content: "\F06F6"; }

.mdi-upload-network-outline::before {
  content: "\F0CD8"; }

.mdi-upload-off::before {
  content: "\F10C6"; }

.mdi-upload-off-outline::before {
  content: "\F10C7"; }

.mdi-upload-outline::before {
  content: "\F0E07"; }

.mdi-usb::before {
  content: "\F0553"; }

.mdi-usb-flash-drive::before {
  content: "\F129E"; }

.mdi-usb-flash-drive-outline::before {
  content: "\F129F"; }

.mdi-usb-port::before {
  content: "\F11F0"; }

.mdi-vacuum::before {
  content: "\F19A1"; }

.mdi-vacuum-outline::before {
  content: "\F19A2"; }

.mdi-valve::before {
  content: "\F1066"; }

.mdi-valve-closed::before {
  content: "\F1067"; }

.mdi-valve-open::before {
  content: "\F1068"; }

.mdi-van-passenger::before {
  content: "\F07FA"; }

.mdi-van-utility::before {
  content: "\F07FB"; }

.mdi-vanish::before {
  content: "\F07FC"; }

.mdi-vanish-quarter::before {
  content: "\F1554"; }

.mdi-vanity-light::before {
  content: "\F11E1"; }

.mdi-variable::before {
  content: "\F0AE7"; }

.mdi-variable-box::before {
  content: "\F1111"; }

.mdi-vector-arrange-above::before {
  content: "\F0554"; }

.mdi-vector-arrange-below::before {
  content: "\F0555"; }

.mdi-vector-bezier::before {
  content: "\F0AE8"; }

.mdi-vector-circle::before {
  content: "\F0556"; }

.mdi-vector-circle-variant::before {
  content: "\F0557"; }

.mdi-vector-combine::before {
  content: "\F0558"; }

.mdi-vector-curve::before {
  content: "\F0559"; }

.mdi-vector-difference::before {
  content: "\F055A"; }

.mdi-vector-difference-ab::before {
  content: "\F055B"; }

.mdi-vector-difference-ba::before {
  content: "\F055C"; }

.mdi-vector-ellipse::before {
  content: "\F0893"; }

.mdi-vector-intersection::before {
  content: "\F055D"; }

.mdi-vector-line::before {
  content: "\F055E"; }

.mdi-vector-link::before {
  content: "\F0FE8"; }

.mdi-vector-point::before {
  content: "\F01C4"; }

.mdi-vector-point-edit::before {
  content: "\F09E8"; }

.mdi-vector-point-minus::before {
  content: "\F1B78"; }

.mdi-vector-point-plus::before {
  content: "\F1B79"; }

.mdi-vector-point-select::before {
  content: "\F055F"; }

.mdi-vector-polygon::before {
  content: "\F0560"; }

.mdi-vector-polygon-variant::before {
  content: "\F1856"; }

.mdi-vector-polyline::before {
  content: "\F0561"; }

.mdi-vector-polyline-edit::before {
  content: "\F1225"; }

.mdi-vector-polyline-minus::before {
  content: "\F1226"; }

.mdi-vector-polyline-plus::before {
  content: "\F1227"; }

.mdi-vector-polyline-remove::before {
  content: "\F1228"; }

.mdi-vector-radius::before {
  content: "\F074A"; }

.mdi-vector-rectangle::before {
  content: "\F05C6"; }

.mdi-vector-selection::before {
  content: "\F0562"; }

.mdi-vector-square::before {
  content: "\F0001"; }

.mdi-vector-square-close::before {
  content: "\F1857"; }

.mdi-vector-square-edit::before {
  content: "\F18D9"; }

.mdi-vector-square-minus::before {
  content: "\F18DA"; }

.mdi-vector-square-open::before {
  content: "\F1858"; }

.mdi-vector-square-plus::before {
  content: "\F18DB"; }

.mdi-vector-square-remove::before {
  content: "\F18DC"; }

.mdi-vector-triangle::before {
  content: "\F0563"; }

.mdi-vector-union::before {
  content: "\F0564"; }

.mdi-vhs::before {
  content: "\F0A1B"; }

.mdi-vibrate::before {
  content: "\F0566"; }

.mdi-vibrate-off::before {
  content: "\F0CD9"; }

.mdi-video::before {
  content: "\F0567"; }

.mdi-video-2d::before {
  content: "\F1A1C"; }

.mdi-video-3d::before {
  content: "\F07FD"; }

.mdi-video-3d-off::before {
  content: "\F13D9"; }

.mdi-video-3d-variant::before {
  content: "\F0ED1"; }

.mdi-video-4k-box::before {
  content: "\F083E"; }

.mdi-video-account::before {
  content: "\F0919"; }

.mdi-video-box::before {
  content: "\F00FD"; }

.mdi-video-box-off::before {
  content: "\F00FE"; }

.mdi-video-check::before {
  content: "\F1069"; }

.mdi-video-check-outline::before {
  content: "\F106A"; }

.mdi-video-high-definition::before {
  content: "\F152E"; }

.mdi-video-image::before {
  content: "\F091A"; }

.mdi-video-input-antenna::before {
  content: "\F083F"; }

.mdi-video-input-component::before {
  content: "\F0840"; }

.mdi-video-input-hdmi::before {
  content: "\F0841"; }

.mdi-video-input-scart::before {
  content: "\F0F8C"; }

.mdi-video-input-svideo::before {
  content: "\F0842"; }

.mdi-video-marker::before {
  content: "\F19A9"; }

.mdi-video-marker-outline::before {
  content: "\F19AA"; }

.mdi-video-minus::before {
  content: "\F09B2"; }

.mdi-video-minus-outline::before {
  content: "\F02BA"; }

.mdi-video-off::before {
  content: "\F0568"; }

.mdi-video-off-outline::before {
  content: "\F0BDB"; }

.mdi-video-outline::before {
  content: "\F0BDC"; }

.mdi-video-plus::before {
  content: "\F09B3"; }

.mdi-video-plus-outline::before {
  content: "\F01D3"; }

.mdi-video-stabilization::before {
  content: "\F091B"; }

.mdi-video-switch::before {
  content: "\F0569"; }

.mdi-video-switch-outline::before {
  content: "\F0790"; }

.mdi-video-vintage::before {
  content: "\F0A1C"; }

.mdi-video-wireless::before {
  content: "\F0ED2"; }

.mdi-video-wireless-outline::before {
  content: "\F0ED3"; }

.mdi-view-agenda::before {
  content: "\F056A"; }

.mdi-view-agenda-outline::before {
  content: "\F11D8"; }

.mdi-view-array::before {
  content: "\F056B"; }

.mdi-view-array-outline::before {
  content: "\F1485"; }

.mdi-view-carousel::before {
  content: "\F056C"; }

.mdi-view-carousel-outline::before {
  content: "\F1486"; }

.mdi-view-column::before {
  content: "\F056D"; }

.mdi-view-column-outline::before {
  content: "\F1487"; }

.mdi-view-comfy::before {
  content: "\F0E6A"; }

.mdi-view-comfy-outline::before {
  content: "\F1488"; }

.mdi-view-compact::before {
  content: "\F0E6B"; }

.mdi-view-compact-outline::before {
  content: "\F0E6C"; }

.mdi-view-dashboard::before {
  content: "\F056E"; }

.mdi-view-dashboard-edit::before {
  content: "\F1947"; }

.mdi-view-dashboard-edit-outline::before {
  content: "\F1948"; }

.mdi-view-dashboard-outline::before {
  content: "\F0A1D"; }

.mdi-view-dashboard-variant::before {
  content: "\F0843"; }

.mdi-view-dashboard-variant-outline::before {
  content: "\F1489"; }

.mdi-view-day::before {
  content: "\F056F"; }

.mdi-view-day-outline::before {
  content: "\F148A"; }

.mdi-view-gallery::before {
  content: "\F1888"; }

.mdi-view-gallery-outline::before {
  content: "\F1889"; }

.mdi-view-grid::before {
  content: "\F0570"; }

.mdi-view-grid-outline::before {
  content: "\F11D9"; }

.mdi-view-grid-plus::before {
  content: "\F0F8D"; }

.mdi-view-grid-plus-outline::before {
  content: "\F11DA"; }

.mdi-view-headline::before {
  content: "\F0571"; }

.mdi-view-list::before {
  content: "\F0572"; }

.mdi-view-list-outline::before {
  content: "\F148B"; }

.mdi-view-module::before {
  content: "\F0573"; }

.mdi-view-module-outline::before {
  content: "\F148C"; }

.mdi-view-parallel::before {
  content: "\F0728"; }

.mdi-view-parallel-outline::before {
  content: "\F148D"; }

.mdi-view-quilt::before {
  content: "\F0574"; }

.mdi-view-quilt-outline::before {
  content: "\F148E"; }

.mdi-view-sequential::before {
  content: "\F0729"; }

.mdi-view-sequential-outline::before {
  content: "\F148F"; }

.mdi-view-split-horizontal::before {
  content: "\F0BCB"; }

.mdi-view-split-vertical::before {
  content: "\F0BCC"; }

.mdi-view-stream::before {
  content: "\F0575"; }

.mdi-view-stream-outline::before {
  content: "\F1490"; }

.mdi-view-week::before {
  content: "\F0576"; }

.mdi-view-week-outline::before {
  content: "\F1491"; }

.mdi-vimeo::before {
  content: "\F0577"; }

.mdi-violin::before {
  content: "\F060F"; }

.mdi-virtual-reality::before {
  content: "\F0894"; }

.mdi-virus::before {
  content: "\F13B6"; }

.mdi-virus-off::before {
  content: "\F18E1"; }

.mdi-virus-off-outline::before {
  content: "\F18E2"; }

.mdi-virus-outline::before {
  content: "\F13B7"; }

.mdi-vlc::before {
  content: "\F057C"; }

.mdi-voicemail::before {
  content: "\F057D"; }

.mdi-volcano::before {
  content: "\F1A83"; }

.mdi-volcano-outline::before {
  content: "\F1A84"; }

.mdi-volleyball::before {
  content: "\F09B4"; }

.mdi-volume-equal::before {
  content: "\F1B10"; }

.mdi-volume-high::before {
  content: "\F057E"; }

.mdi-volume-low::before {
  content: "\F057F"; }

.mdi-volume-medium::before {
  content: "\F0580"; }

.mdi-volume-minus::before {
  content: "\F075E"; }

.mdi-volume-mute::before {
  content: "\F075F"; }

.mdi-volume-off::before {
  content: "\F0581"; }

.mdi-volume-plus::before {
  content: "\F075D"; }

.mdi-volume-source::before {
  content: "\F1120"; }

.mdi-volume-variant-off::before {
  content: "\F0E08"; }

.mdi-volume-vibrate::before {
  content: "\F1121"; }

.mdi-vote::before {
  content: "\F0A1F"; }

.mdi-vote-outline::before {
  content: "\F0A20"; }

.mdi-vpn::before {
  content: "\F0582"; }

.mdi-vuejs::before {
  content: "\F0844"; }

.mdi-vuetify::before {
  content: "\F0E6D"; }

.mdi-walk::before {
  content: "\F0583"; }

.mdi-wall::before {
  content: "\F07FE"; }

.mdi-wall-fire::before {
  content: "\F1A11"; }

.mdi-wall-sconce::before {
  content: "\F091C"; }

.mdi-wall-sconce-flat::before {
  content: "\F091D"; }

.mdi-wall-sconce-flat-outline::before {
  content: "\F17C9"; }

.mdi-wall-sconce-flat-variant::before {
  content: "\F041C"; }

.mdi-wall-sconce-flat-variant-outline::before {
  content: "\F17CA"; }

.mdi-wall-sconce-outline::before {
  content: "\F17CB"; }

.mdi-wall-sconce-round::before {
  content: "\F0748"; }

.mdi-wall-sconce-round-outline::before {
  content: "\F17CC"; }

.mdi-wall-sconce-round-variant::before {
  content: "\F091E"; }

.mdi-wall-sconce-round-variant-outline::before {
  content: "\F17CD"; }

.mdi-wallet::before {
  content: "\F0584"; }

.mdi-wallet-giftcard::before {
  content: "\F0585"; }

.mdi-wallet-membership::before {
  content: "\F0586"; }

.mdi-wallet-outline::before {
  content: "\F0BDD"; }

.mdi-wallet-plus::before {
  content: "\F0F8E"; }

.mdi-wallet-plus-outline::before {
  content: "\F0F8F"; }

.mdi-wallet-travel::before {
  content: "\F0587"; }

.mdi-wallpaper::before {
  content: "\F0E09"; }

.mdi-wan::before {
  content: "\F0588"; }

.mdi-wardrobe::before {
  content: "\F0F90"; }

.mdi-wardrobe-outline::before {
  content: "\F0F91"; }

.mdi-warehouse::before {
  content: "\F0F81"; }

.mdi-washing-machine::before {
  content: "\F072A"; }

.mdi-washing-machine-alert::before {
  content: "\F11BC"; }

.mdi-washing-machine-off::before {
  content: "\F11BD"; }

.mdi-watch::before {
  content: "\F0589"; }

.mdi-watch-export::before {
  content: "\F058A"; }

.mdi-watch-export-variant::before {
  content: "\F0895"; }

.mdi-watch-import::before {
  content: "\F058B"; }

.mdi-watch-import-variant::before {
  content: "\F0896"; }

.mdi-watch-variant::before {
  content: "\F0897"; }

.mdi-watch-vibrate::before {
  content: "\F06B1"; }

.mdi-watch-vibrate-off::before {
  content: "\F0CDA"; }

.mdi-water::before {
  content: "\F058C"; }

.mdi-water-alert::before {
  content: "\F1502"; }

.mdi-water-alert-outline::before {
  content: "\F1503"; }

.mdi-water-boiler::before {
  content: "\F0F92"; }

.mdi-water-boiler-alert::before {
  content: "\F11B3"; }

.mdi-water-boiler-auto::before {
  content: "\F1B98"; }

.mdi-water-boiler-off::before {
  content: "\F11B4"; }

.mdi-water-check::before {
  content: "\F1504"; }

.mdi-water-check-outline::before {
  content: "\F1505"; }

.mdi-water-circle::before {
  content: "\F1806"; }

.mdi-water-minus::before {
  content: "\F1506"; }

.mdi-water-minus-outline::before {
  content: "\F1507"; }

.mdi-water-off::before {
  content: "\F058D"; }

.mdi-water-off-outline::before {
  content: "\F1508"; }

.mdi-water-opacity::before {
  content: "\F1855"; }

.mdi-water-outline::before {
  content: "\F0E0A"; }

.mdi-water-percent::before {
  content: "\F058E"; }

.mdi-water-percent-alert::before {
  content: "\F1509"; }

.mdi-water-plus::before {
  content: "\F150A"; }

.mdi-water-plus-outline::before {
  content: "\F150B"; }

.mdi-water-polo::before {
  content: "\F12A0"; }

.mdi-water-pump::before {
  content: "\F058F"; }

.mdi-water-pump-off::before {
  content: "\F0F93"; }

.mdi-water-remove::before {
  content: "\F150C"; }

.mdi-water-remove-outline::before {
  content: "\F150D"; }

.mdi-water-sync::before {
  content: "\F17C6"; }

.mdi-water-thermometer::before {
  content: "\F1A85"; }

.mdi-water-thermometer-outline::before {
  content: "\F1A86"; }

.mdi-water-well::before {
  content: "\F106B"; }

.mdi-water-well-outline::before {
  content: "\F106C"; }

.mdi-waterfall::before {
  content: "\F1849"; }

.mdi-watering-can::before {
  content: "\F1481"; }

.mdi-watering-can-outline::before {
  content: "\F1482"; }

.mdi-watermark::before {
  content: "\F0612"; }

.mdi-wave::before {
  content: "\F0F2E"; }

.mdi-waveform::before {
  content: "\F147D"; }

.mdi-waves::before {
  content: "\F078D"; }

.mdi-waves-arrow-left::before {
  content: "\F1859"; }

.mdi-waves-arrow-right::before {
  content: "\F185A"; }

.mdi-waves-arrow-up::before {
  content: "\F185B"; }

.mdi-waze::before {
  content: "\F0BDE"; }

.mdi-weather-cloudy::before {
  content: "\F0590"; }

.mdi-weather-cloudy-alert::before {
  content: "\F0F2F"; }

.mdi-weather-cloudy-arrow-right::before {
  content: "\F0E6E"; }

.mdi-weather-cloudy-clock::before {
  content: "\F18F6"; }

.mdi-weather-dust::before {
  content: "\F1B5A"; }

.mdi-weather-fog::before {
  content: "\F0591"; }

.mdi-weather-hail::before {
  content: "\F0592"; }

.mdi-weather-hazy::before {
  content: "\F0F30"; }

.mdi-weather-hurricane::before {
  content: "\F0898"; }

.mdi-weather-lightning::before {
  content: "\F0593"; }

.mdi-weather-lightning-rainy::before {
  content: "\F067E"; }

.mdi-weather-night::before {
  content: "\F0594"; }

.mdi-weather-night-partly-cloudy::before {
  content: "\F0F31"; }

.mdi-weather-partly-cloudy::before {
  content: "\F0595"; }

.mdi-weather-partly-lightning::before {
  content: "\F0F32"; }

.mdi-weather-partly-rainy::before {
  content: "\F0F33"; }

.mdi-weather-partly-snowy::before {
  content: "\F0F34"; }

.mdi-weather-partly-snowy-rainy::before {
  content: "\F0F35"; }

.mdi-weather-pouring::before {
  content: "\F0596"; }

.mdi-weather-rainy::before {
  content: "\F0597"; }

.mdi-weather-snowy::before {
  content: "\F0598"; }

.mdi-weather-snowy-heavy::before {
  content: "\F0F36"; }

.mdi-weather-snowy-rainy::before {
  content: "\F067F"; }

.mdi-weather-sunny::before {
  content: "\F0599"; }

.mdi-weather-sunny-alert::before {
  content: "\F0F37"; }

.mdi-weather-sunny-off::before {
  content: "\F14E4"; }

.mdi-weather-sunset::before {
  content: "\F059A"; }

.mdi-weather-sunset-down::before {
  content: "\F059B"; }

.mdi-weather-sunset-up::before {
  content: "\F059C"; }

.mdi-weather-tornado::before {
  content: "\F0F38"; }

.mdi-weather-windy::before {
  content: "\F059D"; }

.mdi-weather-windy-variant::before {
  content: "\F059E"; }

.mdi-web::before {
  content: "\F059F"; }

.mdi-web-box::before {
  content: "\F0F94"; }

.mdi-web-cancel::before {
  content: "\F1790"; }

.mdi-web-check::before {
  content: "\F0789"; }

.mdi-web-clock::before {
  content: "\F124A"; }

.mdi-web-minus::before {
  content: "\F10A0"; }

.mdi-web-off::before {
  content: "\F0A8E"; }

.mdi-web-plus::before {
  content: "\F0033"; }

.mdi-web-refresh::before {
  content: "\F1791"; }

.mdi-web-remove::before {
  content: "\F0551"; }

.mdi-web-sync::before {
  content: "\F1792"; }

.mdi-webcam::before {
  content: "\F05A0"; }

.mdi-webcam-off::before {
  content: "\F1737"; }

.mdi-webhook::before {
  content: "\F062F"; }

.mdi-webpack::before {
  content: "\F072B"; }

.mdi-webrtc::before {
  content: "\F1248"; }

.mdi-wechat::before {
  content: "\F0611"; }

.mdi-weight::before {
  content: "\F05A1"; }

.mdi-weight-gram::before {
  content: "\F0D3F"; }

.mdi-weight-kilogram::before {
  content: "\F05A2"; }

.mdi-weight-lifter::before {
  content: "\F115D"; }

.mdi-weight-pound::before {
  content: "\F09B5"; }

.mdi-whatsapp::before {
  content: "\F05A3"; }

.mdi-wheel-barrow::before {
  content: "\F14F2"; }

.mdi-wheelchair::before {
  content: "\F1A87"; }

.mdi-wheelchair-accessibility::before {
  content: "\F05A4"; }

.mdi-whistle::before {
  content: "\F09B6"; }

.mdi-whistle-outline::before {
  content: "\F12BC"; }

.mdi-white-balance-auto::before {
  content: "\F05A5"; }

.mdi-white-balance-incandescent::before {
  content: "\F05A6"; }

.mdi-white-balance-iridescent::before {
  content: "\F05A7"; }

.mdi-white-balance-sunny::before {
  content: "\F05A8"; }

.mdi-widgets::before {
  content: "\F072C"; }

.mdi-widgets-outline::before {
  content: "\F1355"; }

.mdi-wifi::before {
  content: "\F05A9"; }

.mdi-wifi-alert::before {
  content: "\F16B5"; }

.mdi-wifi-arrow-down::before {
  content: "\F16B6"; }

.mdi-wifi-arrow-left::before {
  content: "\F16B7"; }

.mdi-wifi-arrow-left-right::before {
  content: "\F16B8"; }

.mdi-wifi-arrow-right::before {
  content: "\F16B9"; }

.mdi-wifi-arrow-up::before {
  content: "\F16BA"; }

.mdi-wifi-arrow-up-down::before {
  content: "\F16BB"; }

.mdi-wifi-cancel::before {
  content: "\F16BC"; }

.mdi-wifi-check::before {
  content: "\F16BD"; }

.mdi-wifi-cog::before {
  content: "\F16BE"; }

.mdi-wifi-lock::before {
  content: "\F16BF"; }

.mdi-wifi-lock-open::before {
  content: "\F16C0"; }

.mdi-wifi-marker::before {
  content: "\F16C1"; }

.mdi-wifi-minus::before {
  content: "\F16C2"; }

.mdi-wifi-off::before {
  content: "\F05AA"; }

.mdi-wifi-plus::before {
  content: "\F16C3"; }

.mdi-wifi-refresh::before {
  content: "\F16C4"; }

.mdi-wifi-remove::before {
  content: "\F16C5"; }

.mdi-wifi-settings::before {
  content: "\F16C6"; }

.mdi-wifi-star::before {
  content: "\F0E0B"; }

.mdi-wifi-strength-1::before {
  content: "\F091F"; }

.mdi-wifi-strength-1-alert::before {
  content: "\F0920"; }

.mdi-wifi-strength-1-lock::before {
  content: "\F0921"; }

.mdi-wifi-strength-1-lock-open::before {
  content: "\F16CB"; }

.mdi-wifi-strength-2::before {
  content: "\F0922"; }

.mdi-wifi-strength-2-alert::before {
  content: "\F0923"; }

.mdi-wifi-strength-2-lock::before {
  content: "\F0924"; }

.mdi-wifi-strength-2-lock-open::before {
  content: "\F16CC"; }

.mdi-wifi-strength-3::before {
  content: "\F0925"; }

.mdi-wifi-strength-3-alert::before {
  content: "\F0926"; }

.mdi-wifi-strength-3-lock::before {
  content: "\F0927"; }

.mdi-wifi-strength-3-lock-open::before {
  content: "\F16CD"; }

.mdi-wifi-strength-4::before {
  content: "\F0928"; }

.mdi-wifi-strength-4-alert::before {
  content: "\F0929"; }

.mdi-wifi-strength-4-lock::before {
  content: "\F092A"; }

.mdi-wifi-strength-4-lock-open::before {
  content: "\F16CE"; }

.mdi-wifi-strength-alert-outline::before {
  content: "\F092B"; }

.mdi-wifi-strength-lock-open-outline::before {
  content: "\F16CF"; }

.mdi-wifi-strength-lock-outline::before {
  content: "\F092C"; }

.mdi-wifi-strength-off::before {
  content: "\F092D"; }

.mdi-wifi-strength-off-outline::before {
  content: "\F092E"; }

.mdi-wifi-strength-outline::before {
  content: "\F092F"; }

.mdi-wifi-sync::before {
  content: "\F16C7"; }

.mdi-wikipedia::before {
  content: "\F05AC"; }

.mdi-wind-power::before {
  content: "\F1A88"; }

.mdi-wind-power-outline::before {
  content: "\F1A89"; }

.mdi-wind-turbine::before {
  content: "\F0DA5"; }

.mdi-wind-turbine-alert::before {
  content: "\F19AB"; }

.mdi-wind-turbine-check::before {
  content: "\F19AC"; }

.mdi-window-close::before {
  content: "\F05AD"; }

.mdi-window-closed::before {
  content: "\F05AE"; }

.mdi-window-closed-variant::before {
  content: "\F11DB"; }

.mdi-window-maximize::before {
  content: "\F05AF"; }

.mdi-window-minimize::before {
  content: "\F05B0"; }

.mdi-window-open::before {
  content: "\F05B1"; }

.mdi-window-open-variant::before {
  content: "\F11DC"; }

.mdi-window-restore::before {
  content: "\F05B2"; }

.mdi-window-shutter::before {
  content: "\F111C"; }

.mdi-window-shutter-alert::before {
  content: "\F111D"; }

.mdi-window-shutter-auto::before {
  content: "\F1BA3"; }

.mdi-window-shutter-cog::before {
  content: "\F1A8A"; }

.mdi-window-shutter-open::before {
  content: "\F111E"; }

.mdi-window-shutter-settings::before {
  content: "\F1A8B"; }

.mdi-windsock::before {
  content: "\F15FA"; }

.mdi-wiper::before {
  content: "\F0AE9"; }

.mdi-wiper-wash::before {
  content: "\F0DA6"; }

.mdi-wiper-wash-alert::before {
  content: "\F18DF"; }

.mdi-wizard-hat::before {
  content: "\F1477"; }

.mdi-wordpress::before {
  content: "\F05B4"; }

.mdi-wrap::before {
  content: "\F05B6"; }

.mdi-wrap-disabled::before {
  content: "\F0BDF"; }

.mdi-wrench::before {
  content: "\F05B7"; }

.mdi-wrench-check::before {
  content: "\F1B8F"; }

.mdi-wrench-check-outline::before {
  content: "\F1B90"; }

.mdi-wrench-clock::before {
  content: "\F19A3"; }

.mdi-wrench-clock-outline::before {
  content: "\F1B93"; }

.mdi-wrench-cog::before {
  content: "\F1B91"; }

.mdi-wrench-cog-outline::before {
  content: "\F1B92"; }

.mdi-wrench-outline::before {
  content: "\F0BE0"; }

.mdi-xamarin::before {
  content: "\F0845"; }

.mdi-xml::before {
  content: "\F05C0"; }

.mdi-xmpp::before {
  content: "\F07FF"; }

.mdi-yahoo::before {
  content: "\F0B4F"; }

.mdi-yeast::before {
  content: "\F05C1"; }

.mdi-yin-yang::before {
  content: "\F0680"; }

.mdi-yoga::before {
  content: "\F117C"; }

.mdi-youtube::before {
  content: "\F05C3"; }

.mdi-youtube-gaming::before {
  content: "\F0848"; }

.mdi-youtube-studio::before {
  content: "\F0847"; }

.mdi-youtube-subscription::before {
  content: "\F0D40"; }

.mdi-youtube-tv::before {
  content: "\F0448"; }

.mdi-yurt::before {
  content: "\F1516"; }

.mdi-z-wave::before {
  content: "\F0AEA"; }

.mdi-zend::before {
  content: "\F0AEB"; }

.mdi-zigbee::before {
  content: "\F0D41"; }

.mdi-zip-box::before {
  content: "\F05C4"; }

.mdi-zip-box-outline::before {
  content: "\F0FFA"; }

.mdi-zip-disk::before {
  content: "\F0A23"; }

.mdi-zodiac-aquarius::before {
  content: "\F0A7D"; }

.mdi-zodiac-aries::before {
  content: "\F0A7E"; }

.mdi-zodiac-cancer::before {
  content: "\F0A7F"; }

.mdi-zodiac-capricorn::before {
  content: "\F0A80"; }

.mdi-zodiac-gemini::before {
  content: "\F0A81"; }

.mdi-zodiac-leo::before {
  content: "\F0A82"; }

.mdi-zodiac-libra::before {
  content: "\F0A83"; }

.mdi-zodiac-pisces::before {
  content: "\F0A84"; }

.mdi-zodiac-sagittarius::before {
  content: "\F0A85"; }

.mdi-zodiac-scorpio::before {
  content: "\F0A86"; }

.mdi-zodiac-taurus::before {
  content: "\F0A87"; }

.mdi-zodiac-virgo::before {
  content: "\F0A88"; }

.mdi-blank::before {
  content: "\F68C";
  visibility: hidden; }

.mdi-18px.mdi-set, .mdi-18px.mdi:before {
  font-size: 18px; }

.mdi-24px.mdi-set, .mdi-24px.mdi:before {
  font-size: 24px; }

.mdi-36px.mdi-set, .mdi-36px.mdi:before {
  font-size: 36px; }

.mdi-48px.mdi-set, .mdi-48px.mdi:before {
  font-size: 48px; }

.mdi-dark:before {
  color: rgba(0, 0, 0, 0.54); }

.mdi-dark.mdi-inactive:before {
  color: rgba(0, 0, 0, 0.26); }

.mdi-light:before {
  color: white; }

.mdi-light.mdi-inactive:before {
  color: rgba(255, 255, 255, 0.3); }

.mdi-rotate-45 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(45deg);
            transform: scaleX(-1) rotate(45deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: scaleY(-1) rotate(45deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */ }
  .mdi-rotate-45:before {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }

.mdi-rotate-90 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(90deg);
            transform: scaleX(-1) rotate(90deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(90deg);
            -ms-transform: rotate(90deg);
            transform: scaleY(-1) rotate(90deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */ }
  .mdi-rotate-90:before {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg); }

.mdi-rotate-135 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(135deg);
            transform: scaleX(-1) rotate(135deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(135deg);
            -ms-transform: rotate(135deg);
            transform: scaleY(-1) rotate(135deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */ }
  .mdi-rotate-135:before {
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg); }

.mdi-rotate-180 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(180deg);
            transform: scaleX(-1) rotate(180deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(180deg);
            -ms-transform: rotate(180deg);
            transform: scaleY(-1) rotate(180deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */ }
  .mdi-rotate-180:before {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg); }

.mdi-rotate-225 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(225deg);
            transform: scaleX(-1) rotate(225deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(225deg);
            -ms-transform: rotate(225deg);
            transform: scaleY(-1) rotate(225deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */ }
  .mdi-rotate-225:before {
    -webkit-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    transform: rotate(225deg); }

.mdi-rotate-270 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(270deg);
            transform: scaleX(-1) rotate(270deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(270deg);
            -ms-transform: rotate(270deg);
            transform: scaleY(-1) rotate(270deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */ }
  .mdi-rotate-270:before {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg); }

.mdi-rotate-315 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(315deg);
            transform: scaleX(-1) rotate(315deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(315deg);
            -ms-transform: rotate(315deg);
            transform: scaleY(-1) rotate(315deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */ }
  .mdi-rotate-315:before {
    -webkit-transform: rotate(315deg);
    -ms-transform: rotate(315deg);
    transform: rotate(315deg); }

.mdi-flip-h:before {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH"; }

.mdi-flip-v:before {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: FlipV;
  -ms-filter: "FlipV"; }

.mdi-spin:before {
  -webkit-animation: mdi-spin 2s infinite linear;
  animation: mdi-spin 2s infinite linear; }

@-webkit-keyframes mdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes mdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@font-face {
  font-family: 'Roboto';
  src: url("fonts/Roboto-Thin.eot");
  src: url("fonts/Roboto-Thin.eot?#iefix") format("embedded-opentype"), url("fonts/Roboto-Thin.woff2") format("woff2"), url("fonts/Roboto-Thin.woff") format("woff"), url("fonts/Roboto-Thin.ttf") format("truetype"), url("fonts/Roboto-Thin.svg#Roboto") format("svg");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: "Roboto-Thin";
  src: url("fonts/Roboto-Thin.eot");
  src: url("fonts/Roboto-Thin.eot?#iefix") format("embedded-opentype"), url("fonts/Roboto-Thin.woff2") format("woff2"), url("fonts/Roboto-Thin.woff") format("woff"), url("fonts/Roboto-Thin.ttf") format("truetype"), url("fonts/Roboto-Thin.svg#Roboto") format("svg"); }

@font-face {
  font-family: 'Roboto';
  src: url("fonts/Roboto-ThinItalic.eot");
  src: url("fonts/Roboto-ThinItalic.eot?#iefix") format("embedded-opentype"), url("fonts/Roboto-ThinItalic.woff2") format("woff2"), url("fonts/Roboto-ThinItalic.woff") format("woff"), url("fonts/Roboto-ThinItalic.ttf") format("truetype"), url("fonts/Roboto-ThinItalic.svg#Roboto") format("svg");
  font-weight: 100;
  font-style: italic; }

@font-face {
  font-family: "Roboto-ThinItalic";
  src: url("fonts/Roboto-ThinItalic.eot");
  src: url("fonts/Roboto-ThinItalic.eot?#iefix") format("embedded-opentype"), url("fonts/Roboto-ThinItalic.woff2") format("woff2"), url("fonts/Roboto-ThinItalic.woff") format("woff"), url("fonts/Roboto-ThinItalic.ttf") format("truetype"), url("fonts/Roboto-ThinItalic.svg#Roboto") format("svg"); }

@font-face {
  font-family: 'Roboto';
  src: url("fonts/Roboto-Light.eot");
  src: url("fonts/Roboto-Light.eot?#iefix") format("embedded-opentype"), url("fonts/Roboto-Light.woff2") format("woff2"), url("fonts/Roboto-Light.woff") format("woff"), url("fonts/Roboto-Light.ttf") format("truetype"), url("fonts/Roboto-Light.svg#Roboto") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Roboto-Light";
  src: url("fonts/Roboto-Light.eot");
  src: url("fonts/Roboto-Light.eot?#iefix") format("embedded-opentype"), url("fonts/Roboto-Light.woff2") format("woff2"), url("fonts/Roboto-Light.woff") format("woff"), url("fonts/Roboto-Light.ttf") format("truetype"), url("fonts/Roboto-Light.svg#Roboto") format("svg"); }

@font-face {
  font-family: 'Roboto';
  src: url("fonts/Roboto-LightItalic.eot");
  src: url("fonts/Roboto-LightItalic.eot?#iefix") format("embedded-opentype"), url("fonts/Roboto-LightItalic.woff2") format("woff2"), url("fonts/Roboto-LightItalic.woff") format("woff"), url("fonts/Roboto-LightItalic.ttf") format("truetype"), url("fonts/Roboto-LightItalic.svg#Roboto") format("svg");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: "Roboto-LightItalic";
  src: url("fonts/Roboto-LightItalic.eot");
  src: url("fonts/Roboto-LightItalic.eot?#iefix") format("embedded-opentype"), url("fonts/Roboto-LightItalic.woff2") format("woff2"), url("fonts/Roboto-LightItalic.woff") format("woff"), url("fonts/Roboto-LightItalic.ttf") format("truetype"), url("fonts/Roboto-LightItalic.svg#Roboto") format("svg"); }

@font-face {
  font-family: 'Roboto';
  src: url("fonts/Roboto-Regular.eot");
  src: url("fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("fonts/Roboto-Regular.woff2") format("woff2"), url("fonts/Roboto-Regular.woff") format("woff"), url("fonts/Roboto-Regular.ttf") format("truetype"), url("fonts/Roboto-Regular.svg#Roboto") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Roboto-Regular";
  src: url("fonts/Roboto-Regular.eot");
  src: url("fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("fonts/Roboto-Regular.woff2") format("woff2"), url("fonts/Roboto-Regular.woff") format("woff"), url("fonts/Roboto-Regular.ttf") format("truetype"), url("fonts/Roboto-Regular.svg#Roboto") format("svg"); }

@font-face {
  font-family: 'Roboto';
  src: url("fonts/Roboto-RegularItalic.eot");
  src: url("fonts/Roboto-RegularItalic.eot?#iefix") format("embedded-opentype"), url("fonts/Roboto-RegularItalic.woff2") format("woff2"), url("fonts/Roboto-RegularItalic.woff") format("woff"), url("fonts/Roboto-RegularItalic.ttf") format("truetype"), url("fonts/Roboto-RegularItalic.svg#Roboto") format("svg");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: "Roboto-RegularItalic";
  src: url("fonts/Roboto-RegularItalic.eot");
  src: url("fonts/Roboto-RegularItalic.eot?#iefix") format("embedded-opentype"), url("fonts/Roboto-RegularItalic.woff2") format("woff2"), url("fonts/Roboto-RegularItalic.woff") format("woff"), url("fonts/Roboto-RegularItalic.ttf") format("truetype"), url("fonts/Roboto-RegularItalic.svg#Roboto") format("svg"); }

@font-face {
  font-family: 'Roboto';
  src: url("fonts/Roboto-Medium.eot");
  src: url("fonts/Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("fonts/Roboto-Medium.woff2") format("woff2"), url("fonts/Roboto-Medium.woff") format("woff"), url("fonts/Roboto-Medium.ttf") format("truetype"), url("fonts/Roboto-Medium.svg#Roboto") format("svg");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "Roboto-Medium";
  src: url("fonts/Roboto-Medium.eot");
  src: url("fonts/Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("fonts/Roboto-Medium.woff2") format("woff2"), url("fonts/Roboto-Medium.woff") format("woff"), url("fonts/Roboto-Medium.ttf") format("truetype"), url("fonts/Roboto-Medium.svg#Roboto") format("svg"); }

@font-face {
  font-family: 'Roboto';
  src: url("fonts/Roboto-MediumItalic.eot");
  src: url("fonts/Roboto-MediumItalic.eot?#iefix") format("embedded-opentype"), url("fonts/Roboto-MediumItalic.woff2") format("woff2"), url("fonts/Roboto-MediumItalic.woff") format("woff"), url("fonts/Roboto-MediumItalic.ttf") format("truetype"), url("fonts/Roboto-MediumItalic.svg#Roboto") format("svg");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: "Roboto-MediumItalic";
  src: url("fonts/Roboto-MediumItalic.eot");
  src: url("fonts/Roboto-MediumItalic.eot?#iefix") format("embedded-opentype"), url("fonts/Roboto-MediumItalic.woff2") format("woff2"), url("fonts/Roboto-MediumItalic.woff") format("woff"), url("fonts/Roboto-MediumItalic.ttf") format("truetype"), url("fonts/Roboto-MediumItalic.svg#Roboto") format("svg"); }

@font-face {
  font-family: 'Roboto';
  src: url("fonts/Roboto-Bold.eot");
  src: url("fonts/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("fonts/Roboto-Bold.woff2") format("woff2"), url("fonts/Roboto-Bold.woff") format("woff"), url("fonts/Roboto-Bold.ttf") format("truetype"), url("fonts/Roboto-Bold.svg#Roboto") format("svg");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "Roboto-Bold";
  src: url("fonts/Roboto-Bold.eot");
  src: url("fonts/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("fonts/Roboto-Bold.woff2") format("woff2"), url("fonts/Roboto-Bold.woff") format("woff"), url("fonts/Roboto-Bold.ttf") format("truetype"), url("fonts/Roboto-Bold.svg#Roboto") format("svg"); }

@font-face {
  font-family: 'Roboto';
  src: url("fonts/Roboto-BoldItalic.eot");
  src: url("fonts/Roboto-BoldItalic.eot?#iefix") format("embedded-opentype"), url("fonts/Roboto-BoldItalic.woff2") format("woff2"), url("fonts/Roboto-BoldItalic.woff") format("woff"), url("fonts/Roboto-BoldItalic.ttf") format("truetype"), url("fonts/Roboto-BoldItalic.svg#Roboto") format("svg");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: "Roboto-BoldItalic";
  src: url("fonts/Roboto-BoldItalic.eot");
  src: url("fonts/Roboto-BoldItalic.eot?#iefix") format("embedded-opentype"), url("fonts/Roboto-BoldItalic.woff2") format("woff2"), url("fonts/Roboto-BoldItalic.woff") format("woff"), url("fonts/Roboto-BoldItalic.ttf") format("truetype"), url("fonts/Roboto-BoldItalic.svg#Roboto") format("svg"); }

@font-face {
  font-family: 'Roboto';
  src: url("fonts/Roboto-Black.eot");
  src: url("fonts/Roboto-Black.eot?#iefix") format("embedded-opentype"), url("fonts/Roboto-Black.woff2") format("woff2"), url("fonts/Roboto-Black.woff") format("woff"), url("fonts/Roboto-Black.ttf") format("truetype"), url("fonts/Roboto-Black.svg#Roboto") format("svg");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: "Roboto-Black";
  src: url("fonts/Roboto-Black.eot");
  src: url("fonts/Roboto-Black.eot?#iefix") format("embedded-opentype"), url("fonts/Roboto-Black.woff2") format("woff2"), url("fonts/Roboto-Black.woff") format("woff"), url("fonts/Roboto-Black.ttf") format("truetype"), url("fonts/Roboto-Black.svg#Roboto") format("svg"); }

@font-face {
  font-family: 'Roboto';
  src: url("fonts/Roboto-BlackItalic.eot");
  src: url("fonts/Roboto-BlackItalic.eot?#iefix") format("embedded-opentype"), url("fonts/Roboto-BlackItalic.woff2") format("woff2"), url("fonts/Roboto-BlackItalic.woff") format("woff"), url("fonts/Roboto-BlackItalic.ttf") format("truetype"), url("fonts/Roboto-BlackItalic.svg#Roboto") format("svg");
  font-weight: 900;
  font-style: italic; }

@font-face {
  font-family: "Roboto-BlackItalic";
  src: url("fonts/Roboto-BlackItalic.eot");
  src: url("fonts/Roboto-BlackItalic.eot?#iefix") format("embedded-opentype"), url("fonts/Roboto-BlackItalic.woff2") format("woff2"), url("fonts/Roboto-BlackItalic.woff") format("woff"), url("fonts/Roboto-BlackItalic.ttf") format("truetype"), url("fonts/Roboto-BlackItalic.svg#Roboto") format("svg"); }

@font-face {
  font-family: 'BebasNeue';
  src: url("fonts/BebasNeue.eot");
  src: url("fonts/BebasNeue.eot?#iefix") format("embedded-opentype"), url("fonts/BebasNeue.woff2") format("woff2"), url("fonts/BebasNeue.woff") format("woff"), url("fonts/BebasNeue.ttf") format("truetype"), url("fonts/BebasNeue.svg#BebasNeue") format("svg");
  font-weight: normal;
  font-style: normal; }

body {
  font-family: Roboto, sans-serif;
  font-weight: 300;
  font-size: 16px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column; }
  body.prevent-scrolling {
    overflow: hidden; }

h1 {
  font-family: inherit;
  font-size: 36px;
  font-weight: 300;
  line-height: 42px;
  padding-top: 8px;
  padding-bottom: 6px;
  margin: 0;
  overflow-wrap: anywhere; }

.sub-headline {
  color: #aaa;
  font-size: 22px;
  padding-top: 6px;
  padding-bottom: 24px;
  margin: 0;
  overflow-wrap: anywhere; }

h2 {
  font-family: inherit;
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
  margin-top: 24px;
  margin-bottom: 0;
  margin-left: 0;
  margin-bottom: 0;
  overflow-wrap: anywhere; }

h3 {
  font-family: inherit;
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  padding-top: 4px;
  margin: 0;
  margin-top: 20px;
  overflow-wrap: anywhere; }

h4 {
  font-family: inherit;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  margin-top: 8px;
  margin-bottom: 2px;
  overflow-wrap: anywhere; }

p, ul, div.code {
  font-family: inherit;
  font-size: 18px;
  font-weight: 300;
  line-height: 30px; }

ul {
  padding-left: 16px;
  margin-top: 8px;
  margin-bottom: 8px; }

p, iframe, div.code {
  margin-bottom: 16px; }

iframe {
  margin-top: 8px; }

mark {
  background-color: rgb(255,238,88); }

div.code {
  font-family: monospace; }

div.code, pre.highlight {
  overflow: auto; }

kbd {
  display: inline-block;
  padding: 3px 8px;
  margin: 0px 3px;
  font-size: 15px;
  line-height: 14px;
  color: #444d56;
  vertical-align: middle;
  background-color: #fafbfc;
  border: solid 1px #c6cbd1;
  border-bottom-color: #959da5;
  border-radius: 3px;
  box-shadow: inset 0 -1px 0 #959da5; }

*, *::before, *::after {
  box-sizing: border-box; }

a:not([role=button]), span.javascript-link {
  color: rgb(33,150,243);
  text-decoration: none;
  font-weight: normal;
  cursor: pointer; }

.container {
  margin: 0 auto;
  flex: 1;
  width: 100%; }

video::cue {
  opacity: 0.8; }

@media (min-width: 1200px) {
  .container {
    max-width: 1170px; } }

header {
  display: block;
  background-color: rgb(33,150,243);
  z-index: 1000; }
  header .container {
    padding-top: 0;
    padding-bottom: 0; }
    header .container > div {
      display: flex;
      align-items: center;
      height: 72px; }
      header .container > div .logotype {
        background-image: url("media/calcapp.svg"), none;
        background-repeat: no-repeat;
        width: 130px;
        height: 34px;
        margin: 0 auto; }
      header .container > div .links {
        display: none;
        padding-left: 37px; }
        header .container > div .links.last {
          padding-left: 0; }
        header .container > div .links a {
          font-size: 19px;
          color: white;
          height: 72px;
          line-height: 72px;
          min-width: unset;
          padding: 0 18px; }
          header .container > div .links a:hover {
            border-bottom: none;
            background-color: rgba(255, 255, 255, 0.2); }
          header .container > div .links a:active {
            background-color: rgba(255, 255, 255, 0.7); }
          header .container > div .links a.active {
            background-color: #55aef6; }
          header .container > div .links a > span:first-child {
            position: relative;
            top: 2px; }
          header .container > div .links a .mdl-ripple {
            background: white; }
      header .container > div .space-before-sign-in-link {
        flex: 1; }
      header .container > div .menu-button-for-phones {
        position: fixed;
        left: 14px;
        top: 20px;
        color: white; }

#toast {
  z-index: 3000; }

body:not(.no-fixed-header) header {
  position: fixed;
  width: 100%; }

body:not(.no-fixed-header) > .container {
  padding-top: 72px; }

div.main-container div.content {
  padding: 16px; }

.google-search {
  margin-top: 8px; }
  .google-search .gsc-control-cse {
    font: unset;
    padding: 0;
    background-color: transparent;
    border: none; }
  .google-search .gsc-input-box {
    border-bottom: 1px solid rgb(33,150,243);
    box-shadow: none;
    background-color: transparent;
    border-left: none;
    border-top: none;
    border-right: none; }
    .google-search .gsc-input-box td.gsib_a {
      padding-left: 4px; }
    .google-search .gsc-input-box input {
      font: unset;
      font-size: 16px;
      font-weight: normal;
      border-color: transparent;
      background-color: transparent; }

@media (max-width: 319px) {
  header .logotype {
    display: none; } }

@media (min-width: 768px) {
  header .menu-button-for-phones {
    display: none; }
  header .container {
    padding-top: 0;
    padding-bottom: 0; }
    header .container > div {
      justify-content: space-between; }
      header .container > div .links {
        display: flex;
        margin-left: 37px; }
      header .container > div .logotype {
        margin: 0;
        margin-left: 14px; } }

div.navigation {
  position: fixed;
  left: 0;
  bottom: 0;
  top: 72px;
  width: 305px;
  overflow-y: auto;
  border-right: 1px #eee solid;
  border-left: 1px #eee solid;
  background: linear-gradient(250deg, #efefff, white);
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 24px;
  padding-top: 8px;
  transform: translateX(-110%);
  transition: transform 200ms ease-in-out;
  z-index: 1000; }
  div.navigation.open {
    transform: translateX(0); }
  div.navigation h1 {
    font-family: BebasNeue;
    color: #333;
    padding-top: 20px;
    padding-bottom: 20px; }
    div.navigation h1.current {
      color: rgb(194,24,91); }
      div.navigation h1.current.has-menu {
        padding-bottom: 8px; }
    div.navigation h1 a {
      color: inherit; }
  div.navigation > ul {
    padding-left: 0; }
    div.navigation > ul > li > ul {
      padding-left: 0; }
      div.navigation > ul > li > ul ul.top-level {
        padding-left: 18px; }
        div.navigation > ul > li > ul ul.top-level > li > ul {
          padding-left: 18px; }
          div.navigation > ul > li > ul ul.top-level > li > ul:not(.top-level) {
            font-size: 16px;
            line-height: 24px; }
            div.navigation > ul > li > ul ul.top-level > li > ul:not(.top-level) > li {
              margin-bottom: 8px; }
              div.navigation > ul > li > ul ul.top-level > li > ul:not(.top-level) > li:first-of-type {
                margin-top: -8px; }
    div.navigation > ul li span.symbol {
      display: inline-block;
      width: 28px;
      text-align: center;
      font-family: monospace; }
    div.navigation > ul > li {
      font-weight: bold;
      line-height: 36px; }
      div.navigation > ul > li:first-of-type {
        padding-top: 8px; }
      div.navigation > ul > li:not(:first-of-type) {
        padding-top: 24px; }
    div.navigation > ul a {
      color: #333; }
      div.navigation > ul a:hover {
        color: rgb(33,150,243); }
  div.navigation ul {
    list-style-type: none;
    margin-top: 16px; }
    div.navigation ul li {
      line-height: 26px;
      margin-bottom: 14px; }
      div.navigation ul li.active {
        font-weight: bold;
        color: rgb(194,24,91); }
      div.navigation ul li .property-category {
        padding-left: 18px;
        margin-top: 20px;
        font-style: italic; }
      div.navigation ul li.services i.mdi {
        color: #aaa; }
  div.navigation ul.top-level + ul:not(.top-level) {
    margin-top: 36px; }

@media (min-width: 768px) {
  div.navigation {
    display: none;
    transform: unset;
    left: auto; }
    div.navigation h1 {
      display: none; } }

.content-page img, .content-page video, .content-page figure {
  width: 100%;
  margin-bottom: 25px;
  margin-top: 10px; }

.content-page img:not(.no-shadow),
.content-page video:not(.no-shadow),
.content-page figure:not(.no-shadow):not(.simple) {
  box-shadow: 3px 6px 8px 0px #ccc; }

.content-page figure {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 30px; }
  .content-page figure:not(.simple) {
    padding: 16px;
    background-color: #f9f9f9; }
  .content-page figure.simple {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .content-page figure pre {
    margin: 0; }

.content-page h2::before {
  display: block;
  content: " ";
  margin-top: -80px;
  height: 80px;
  visibility: hidden; }

.content-page .horizontal-images {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }

.content-page blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  font-style: normal;
  margin: 1.5em 10px;
  padding-top: 0.7em;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 15px;
  quotes: "“" "”" "‘" "’"; }
  .content-page blockquote::before {
    content: open-quote;
    color: #ccc;
    font-size: 5em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
    left: -0.35em;
    top: 0.1em; }
  .content-page blockquote::after {
    content: "";
    margin-left: 0; }

.content-page blockquote blockquote {
  border-left: 6px solid rgb(33,150,243);
  margin: 0.5em 0; }
  .content-page blockquote blockquote.plain {
    border-color: #ccc; }
  .content-page blockquote blockquote::before {
    content: ""; }

.content-page .data-table {
  width: 100%;
  margin-bottom: 8px;
  table-layout: fixed; }
  .content-page .data-table caption {
    display: none; }
  .content-page .data-table th {
    text-align: left;
    padding-right: 20px; }
    .content-page .data-table th i.mdi {
      font-size: 20px; }
  .content-page .data-table td {
    vertical-align: middle;
    padding-right: 16px; }
  .content-page .data-table td:last-of-type {
    padding-right: 0; }
  .content-page .data-table th, .content-page .data-table td {
    padding-top: 4px;
    padding-bottom: 4px; }
  .content-page .data-table tr.indent td:first-of-type {
    padding-left: 1.5em; }

.content-page .section-break {
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  margin-bottom: 21px;
  font-family: Roboto, sans-serif;
  font-size: 66px;
  color: #aaa; }
  .content-page .section-break::after {
    content: "︙"; }

.content-page .formula-holder {
  display: flex;
  flex-direction: row;
  font-size: 18px;
  line-height: 28px;
  padding: 12px 16px;
  margin-bottom: 12px;
  border-radius: 6px;
  background-color: #565664;
  color: white; }
  .content-page .formula-holder a {
    color: #90caf9; }
  .content-page .formula-holder span {
    overflow-x: auto; }
  .content-page .formula-holder .buttons {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    font-size: 24px; }
    .content-page .formula-holder .buttons button {
      cursor: pointer;
      background-color: transparent;
      border: none;
      color: inherit;
      font-size: inherit;
      width: 36px;
      height: 36px; }
      .content-page .formula-holder .buttons button.decimal-separator-toggle {
        padding-right: 46px; }
        .content-page .formula-holder .buttons button.decimal-separator-toggle img {
          width: 34px;
          margin: 0;
          box-shadow: none; }
  .content-page .formula-holder:first-of-type {
    margin-top: 4px; }
  .content-page .formula-holder + .formula-holder {
    margin-top: -24px; }
    .content-page .formula-holder + .formula-holder .buttons .decimal-separator-toggle {
      visibility: hidden; }

.content-page a.unary-operator, .content-page a.binary-operator {
  font-family: monospace; }

.content-page span.formula {
  font-family: monospace;
  overflow-wrap: anywhere;
  margin: auto 0; }
  .content-page span.formula.decimalpoint.flex {
    display: flex; }
  .content-page span.formula.decimalcomma {
    display: none; }

.content-page div.details {
  padding-top: 8px; }

.content-page div.prototype {
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  padding-bottom: 4px; }
  .content-page div.prototype:has(.toggle-details) {
    margin-bottom: 12px; }
  .content-page div.prototype div.formula-holder {
    z-index: 2;
    margin-bottom: 18px; }
    .content-page div.prototype div.formula-holder span.formula {
      overflow-wrap: normal; }
      .content-page div.prototype div.formula-holder span.formula span {
        display: inline-block;
        overflow: visible; }
        .content-page div.prototype div.formula-holder span.formula span.name {
          vertical-align: top; }
  .content-page div.prototype .toggle-details {
    border-radius: 6px;
    background-color: #565664;
    color: #fff;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .content-page div.prototype .toggle-details button {
      border: none;
      font-size: 18px;
      background-color: inherit;
      cursor: pointer;
      z-index: 2; }
      .content-page div.prototype .toggle-details button .text {
        color: #90caf9;
        margin-right: 4px; }
      .content-page div.prototype .toggle-details button i {
        display: inline-block;
        color: white;
        transform: rotate(0deg);
        transition-property: transform;
        transition-duration: 400ms;
        transition-delay: 150ms;
        transition-timing-function: ease-in-out; }
      .content-page div.prototype .toggle-details button.active i {
        transform: rotate(180deg); }

.content-page div.parameters {
  background-color: #efeff2;
  margin-top: -34px;
  margin-bottom: -4px; }
  .content-page div.parameters .inner {
    overflow: hidden;
    padding: 16px;
    padding-bottom: 0; }
    .content-page div.parameters .inner.closed {
      display: none; }
    .content-page div.parameters .inner h1 {
      font-size: 18px;
      font-style: italic;
      margin-bottom: -20px; }
    .content-page div.parameters .inner p {
      transition-property: border;
      transition-duration: 0.4s;
      transition-timing-function: ease-in; }
      .content-page div.parameters .inner p.highlighted {
        border-left: 5px solid rgb(194,24,91);
        padding-left: 12px; }
  .content-page div.parameters h2 {
    display: flex;
    font-size: 16px;
    font-weight: normal;
    padding-bottom: 0;
    margin-top: 16px;
    overflow-wrap: normal; }
    .content-page div.parameters h2.name {
      font-weight: 700; }
  .content-page div.parameters div.type {
    font-family: monospace;
    display: inline-block;
    line-height: 1.6;
    margin-bottom: 4px; }
    .content-page div.parameters div.type span.postscript {
      font-family: Roboto, sans-serif;
      font-weight: normal;
      font-style: italic;
      color: #888; }
  .content-page div.parameters p {
    font-size: 16px;
    line-height: 1.5; }
  .content-page div.parameters li {
    font-size: 16px; }

.content-page div.examples div.example {
  margin-top: 8px;
  margin-bottom: 24px;
  font-size: 18px; }
  .content-page div.examples div.example .formula-holder {
    margin-bottom: 8px; }

.content-page .note {
  margin-top: 16px;
  margin-bottom: 8px;
  font-style: italic;
  color: #888;
  font-size: 14px; }
  .content-page .note a {
    color: #888; }

.content-page table.section-items {
  border-collapse: collapse;
  width: 100%;
  line-height: 1.6; }
  .content-page table.section-items tr:nth-child(even) {
    background-color: #efeff2; }
  .content-page table.section-items td {
    padding-top: 10px;
    padding-bottom: 10px; }
  .content-page table.section-items td.name {
    padding-left: 8px;
    padding-right: 24px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px; }
    .content-page table.section-items td.name a {
      white-space: nowrap; }
      .content-page table.section-items td.name a .symbol {
        display: inline-block;
        width: 44px;
        text-align: center;
        font-family: monospace; }
  .content-page table.section-items td.description {
    padding-right: 8px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; }

.content-page .further-reading {
  float: right;
  padding: 20px;
  border-radius: 16px;
  background-color: #2196f32e;
  display: flex;
  flex-direction: column;
  max-width: 43%;
  margin-left: 16px; }
  .content-page .further-reading a {
    color: #000000f0; }
  .content-page .further-reading::before {
    content: "Further reading";
    color: #0000006e;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 2px;
    margin-bottom: 8px; }

.content-page .colors {
  display: flex;
  flex-wrap: wrap; }

.content-page .colors > div {
  width: 150px;
  padding: 8px;
  margin: 4px; }

@media (max-width: 767px) {
  .data-table td {
    overflow-wrap: anywhere; } }

@media (min-width: 768px) {
  .content-page blockquote::before {
    left: -60px;
    top: 12px; }
  .content-page .horizontal-images a:not(:last-of-type) {
    margin-right: 25px; }
  .content-page img {
    width: 75%; }
  .content-page .small-image {
    width: 250px; }
  .content-page .small-horizontal-image {
    width: 390px; }
  .content-page .image-320px {
    width: 320px; }
  .content-page .image-220px {
    width: 220px; }
  .content-page .medium-image {
    width: 60%; }
  .content-page .large-image {
    width: 100%; }
  .content-page .data-table {
    width: auto;
    line-height: 1.5; }
    .content-page .data-table td {
      padding-right: 48px; }
    .content-page .data-table td:last-of-type {
      padding-right: 0; }
  .content-page table.category-items td {
    padding-top: 16px;
    padding-bottom: 16px; }
  .content-page table.category-items td.name {
    padding-left: 16px; }
  .content-page table.category-items td.description {
    padding-right: 16px; } }

table.reference {
  font-size: 16px;
  line-height: 1.6; }
  table.reference td {
    padding: 4px;
    vertical-align: top; }
  table.reference td.meta {
    text-align: right; }
    table.reference td.meta .parameter-name {
      font-weight: bold; }
    table.reference td.meta .parameter-type {
      color: #888;
      white-space: nowrap; }
    table.reference td.meta .parameter-optional {
      font-style: italic; }
  table.reference ul {
    padding-left: 0;
    margin: 0; }
    table.reference ul li {
      list-style-type: none;
      font-size: 16px;
      line-height: 1.6; }

pre.highlight {
  padding: 16px;
  border-radius: 8px;
  line-height: 1.6;
  background-color: #eee;
  overflow-x: auto;
  /* Comment */
  /* Error */
  /* Keyword */
  /* Comment.Multiline */
  /* Comment.Preproc */
  /* Comment.Single */
  /* Comment.Special */
  /* Generic.Deleted */
  /* Generic.Emph */
  /* Generic.Error */
  /* Generic.Heading */
  /* Generic.Inserted */
  /* Generic.Output */
  /* Generic.Prompt */
  /* Generic.Strong */
  /* Generic.Subheading */
  /* Generic.Traceback */
  /* Keyword.Constant */
  /* Keyword.Declaration */
  /* Keyword.Namespace */
  /* Keyword.Pseudo */
  /* Keyword.Reserved */
  /* Keyword.Type */
  /* Literal.Number */
  /* Literal.String */
  /* Name.Attribute */
  /* Name.Tag */
  /* Operator.Word */
  /* Text.Whitespace */
  /* Literal.Number.Float */
  /* Literal.Number.Hex */
  /* Literal.Number.Integer */
  /* Literal.Number.Oct */
  /* Literal.String.Backtick */
  /* Literal.String.Char */
  /* Literal.String.Doc */
  /* Literal.String.Double */
  /* Literal.String.Escape */
  /* Literal.String.Heredoc */
  /* Literal.String.Interpol */
  /* Literal.String.Other */
  /* Literal.String.Regex */
  /* Literal.String.Single */
  /* Literal.String.Symbol */
  /* Literal.Number.Integer.Long */ }
  pre.highlight .hll {
    background-color: #ffffcc; }
  pre.highlight .c {
    color: #008800;
    font-style: italic; }
  pre.highlight .err {
    color: #a61717;
    background-color: #e3d2d2; }
  pre.highlight .k {
    color: #000080;
    font-weight: bold; }
  pre.highlight .cm {
    color: #008800;
    font-style: italic; }
  pre.highlight .cp {
    color: #008080; }
  pre.highlight .c1 {
    color: #008800;
    font-style: italic; }
  pre.highlight .cs {
    color: #008800;
    font-weight: bold; }
  pre.highlight .gd {
    color: #000000;
    background-color: #ffdddd; }
  pre.highlight .ge {
    font-style: italic; }
  pre.highlight .gr {
    color: #673ab7; }
  pre.highlight .gh {
    color: #999999; }
  pre.highlight .gi {
    color: #000000;
    background-color: #ddffdd; }
  pre.highlight .go {
    color: #888888; }
  pre.highlight .gp {
    color: #555555; }
  pre.highlight .gs {
    font-weight: bold; }
  pre.highlight .gu {
    color: #aaaaaa; }
  pre.highlight .gt {
    color: #aa0000; }
  pre.highlight .kc {
    color: #000080;
    font-weight: bold; }
  pre.highlight .kd {
    color: #000080;
    font-weight: bold; }
  pre.highlight .kn {
    color: #000080;
    font-weight: bold; }
  pre.highlight .kp {
    color: #000080;
    font-weight: bold; }
  pre.highlight .kr {
    color: #000080;
    font-weight: bold; }
  pre.highlight .kt {
    color: #000080;
    font-weight: bold; }
  pre.highlight .m {
    color: rgb(33,150,243); }
  pre.highlight .s {
    color: rgb(33,150,243); }
  pre.highlight .na {
    color: rgb(194,24,91); }
  pre.highlight .nt {
    color: #000080;
    font-weight: bold; }
  pre.highlight .ow {
    font-weight: bold; }
  pre.highlight .w {
    color: #bbbbbb; }
  pre.highlight .mf {
    color: rgb(33,150,243); }
  pre.highlight .mh {
    color: rgb(33,150,243); }
  pre.highlight .mi {
    color: rgb(33,150,243); }
  pre.highlight .mo {
    color: rgb(33,150,243); }
  pre.highlight .sb {
    color: rgb(33,150,243); }
  pre.highlight .sc {
    color: #800080; }
  pre.highlight .sd {
    color: rgb(33,150,243); }
  pre.highlight .s2 {
    color: rgb(33,150,243); }
  pre.highlight .se {
    color: rgb(33,150,243); }
  pre.highlight .sh {
    color: rgb(33,150,243); }
  pre.highlight .si {
    color: rgb(33,150,243); }
  pre.highlight .sx {
    color: rgb(33,150,243); }
  pre.highlight .sr {
    color: rgb(33,150,243); }
  pre.highlight .s1 {
    color: rgb(33,150,243); }
  pre.highlight .ss {
    color: rgb(33,150,243); }
  pre.highlight .il {
    color: rgb(33,150,243); }

footer {
  background-color: black;
  color: white;
  width: 100%;
  position: relative;
  padding: 16px;
  z-index: 2000; }
  footer a {
    color: #bbb !important; }
    footer a:hover {
      text-decoration: underline !important; }
  footer .container.main {
    display: flex;
    flex-direction: column; }
    footer .container.main h3 {
      text-transform: uppercase;
      font-size: 15px;
      font-weight: 600;
      letter-spacing: 3px;
      padding-top: 12px; }
    footer .container.main p {
      font-size: 15px;
      line-height: 1.7; }
    footer .container.main ul {
      padding-left: 0;
      margin: 0; }
      footer .container.main ul li {
        font-size: 15px;
        list-style-type: none;
        margin-bottom: 6px;
        line-height: 1.5; }
  footer .container.secondary .social-links {
    display: flex;
    flex-wrap: wrap;
    line-height: 38px;
    justify-content: center;
    margin-top: 36px; }
    footer .container.secondary .social-links a {
      color: white;
      font-size: 40px;
      margin-right: 20px; }
  footer .copyright {
    font-size: 15px;
    text-align: center; }

@media (min-width: 768px) {
  footer .container.main {
    flex-direction: row;
    padding: 0; }
    footer .container.main > div {
      flex: 1; }
      footer .container.main > div + div {
        padding-left: 20px; }
  footer .container.secondary .social-links a {
    font-size: 50px;
    margin-right: 36px; }
  footer .container.secondary .copyright {
    position: absolute;
    right: 32px;
    bottom: 20px; } }

.landing-page {
  align-items: center; }
  .landing-page header {
    align-self: normal; }
    .landing-page header .container > div .menu-button-for-phones {
      position: absolute; }
  .landing-page blockquote {
    line-height: 1.5; }

@media (min-width: 768px) {
  .landing-page .mdl-button {
    font-size: 18px;
    line-height: 40px;
    height: 40px; }
  .landing-page header {
    padding: 30px;
    padding-bottom: 0; } }

.landing-page .top {
  background-color: rgb(33,150,243);
  color: white;
  align-self: normal;
  padding-top: 24px;
  padding-bottom: 24px; }
  .landing-page .top .container {
    display: flex;
    flex-direction: column;
    padding: 0; }
  .landing-page .top figure {
    order: 2; }
    .landing-page .top figure.embedded-application {
      margin: 16px; }
      .landing-page .top figure.embedded-application iframe {
        border: none;
        height: 606px;
        width: 100%;
        box-shadow: #90caf9 0 0 20px;
        border-radius: 16px; }
    .landing-page .top figure.standalone-application {
      position: relative;
      width: calc(100% - 16px);
      margin: 8px; }
      .landing-page .top figure.standalone-application::before {
        content: "";
        position: absolute;
        background-color: rgb(194,24,91);
        top: 2.9%;
        left: 7.3%;
        width: 86%;
        height: 94.1%; }
      .landing-page .top figure.standalone-application img.phone {
        position: relative;
        pointer-events: none;
        top: 0;
        left: 4px;
        width: calc(100% - 8px);
        height: auto; }
      .landing-page .top figure.standalone-application iframe {
        position: absolute;
        border: none;
        top: 6.3%;
        left: 7.3%;
        width: 86%;
        height: 90.7%;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 0; }
  .landing-page .top h1 {
    font-size: 40px;
    line-height: 1.25;
    letter-spacing: 0.2px;
    margin-top: 0;
    padding-top: 0; }
  .landing-page .top .content {
    margin: 16px;
    order: 1; }
    .landing-page .top .content p {
      font-size: 22px;
      line-height: 1.5; }
  .landing-page .top .introductory-buttons {
    margin-top: 32px;
    margin-bottom: 32px; }
    .landing-page .top .introductory-buttons > div .mdl-button {
      width: 100%;
      margin-bottom: 16px;
      font-size: 17px; }
    .landing-page .top .introductory-buttons > div:last-of-type .mdl-button {
      margin-bottom: 0; }
    .landing-page .top .introductory-buttons .fine-print {
      position: relative;
      top: -4px;
      text-align: center;
      margin-top: 0;
      margin-bottom: 20px; }

@media (min-width: 768px) {
  .landing-page .top {
    padding-top: 0;
    padding-bottom: 0; }
    .landing-page .top .container {
      flex-direction: row;
      max-width: 800px;
      margin-top: 50px; }
    .landing-page .top figure {
      order: 1; }
      .landing-page .top figure.embedded-application {
        margin: 0;
        margin-left: 24px;
        margin-right: 42px; }
        .landing-page .top figure.embedded-application iframe {
          width: 300px; }
      .landing-page .top figure.standalone-application {
        height: 620px;
        margin-bottom: 0;
        width: auto;
        margin: 1em 50px; }
        .landing-page .top figure.standalone-application::before {
          top: 18px;
          left: 22px;
          width: 254px;
          height: 545px; }
        .landing-page .top figure.standalone-application img.phone {
          height: 580px;
          width: 290px; }
        .landing-page .top figure.standalone-application iframe {
          top: 37px;
          left: 22px;
          width: 254px;
          height: 526px; }
    .landing-page .top .content {
      order: 2; }
    .landing-page .top::after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-right: 100vw solid white;
      border-top: 140px solid rgb(33,150,243); }
    .landing-page .top h1 {
      font-size: 50px;
      margin-bottom: 24px; }
    .landing-page .top .introductory-buttons {
      display: flex;
      margin-top: 36px;
      margin-bottom: 0; }
      .landing-page .top .introductory-buttons > div {
        display: inline-block !important;
        padding-right: 10px; }
        .landing-page .top .introductory-buttons > div .mdl-button {
          margin-bottom: 0; }
        .landing-page .top .introductory-buttons > div:last-of-type {
          padding-right: 0; }
      .landing-page .top .introductory-buttons .fine-print {
        margin-bottom: 0;
        top: 0;
        font-size: 16px; }
    .landing-page .top .content {
      font-size: 24px;
      line-height: 34px;
      width: 410px;
      margin: 0;
      margin-top: 90px; } }

@media (min-width: 1200px) {
  .landing-page .top figure.embedded-application iframe {
    margin-left: 0;
    width: 360px; } }

.landing-page .small-features {
  margin-top: 40px; }
  .landing-page .small-features.extra-margin-top {
    margin-top: 120px; }
  .landing-page .small-features .container {
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .landing-page .small-features .container .small-feature {
      padding: 20px;
      max-width: 370px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 16px; }
      .landing-page .small-features .container .small-feature .icon {
        font-size: 54px;
        color: #666; }
      .landing-page .small-features .container .small-feature h1 {
        font-size: 20px;
        margin-top: 16px;
        margin-bottom: 0;
        font-weight: 400;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: rgb(33,150,243); }
      .landing-page .small-features .container .small-feature p {
        text-align: center;
        line-height: 1.7;
        font-size: 18px;
        font-weight: 350; }

@media (min-width: 768px) {
  .landing-page .small-features {
    flex-direction: row; }
    .landing-page .small-features .container {
      flex-direction: row;
      width: 768px; }
      .landing-page .small-features .container .small-feature {
        margin-bottom: 0; } }

@media (min-width: 1200px) {
  .landing-page .small-features .container {
    width: 1200px; }
    .landing-page .small-features .container .small-feature {
      padding: 40px; }
      .landing-page .small-features .container .small-feature h1 {
        font-size: 24px;
        letter-spacing: 4px; }
      .landing-page .small-features .container .small-feature p {
        font-size: 22px; } }

.landing-page .large-feature .container {
  display: flex;
  flex-direction: column;
  padding: 16px; }
  .landing-page .large-feature .container .content {
    flex: 1; }
  .landing-page .large-feature .container figure {
    flex: 1; }

.landing-page .large-feature.opaque-background {
  background-color: #f4f7fb; }

.landing-page .large-feature.horizontal {
  text-align: center; }

.landing-page .large-feature.vertical {
  text-align: center; }
  .landing-page .large-feature.vertical .container {
    flex-direction: column; }

.landing-page .large-feature h1 {
  font-size: 22px;
  margin-top: 6px;
  margin-bottom: 10px;
  font-weight: 400;
  letter-spacing: 4px;
  text-transform: uppercase;
  color: rgb(33,150,243); }

.landing-page .large-feature p {
  line-height: 1.7;
  font-size: 18px;
  font-weight: 350; }

.landing-page .large-feature .visible-small-device-portrait-orientation {
  display: none; }

@media (min-width: 768px) {
  .landing-page .large-feature .container {
    flex-direction: row;
    width: 768px;
    padding: 8px; }
  .landing-page .large-feature .content, .landing-page .large-feature figure {
    margin: 8px; }
  .landing-page .large-feature.horizontal {
    text-align: left; }
  .landing-page .large-feature h1 {
    font-size: 26px; }
  .landing-page .large-feature p {
    font-size: 22px; } }

@media (min-width: 1200px) {
  .landing-page .large-feature .container {
    width: 800px; } }

@media screen and (max-device-width: 768px) and (orientation: portrait) {
  .landing-page .large-feature .visible-small-device-portrait-orientation {
    display: block;
    padding: 10px;
    padding-top: 20px; } }

.landing-page .large-feature.powerful .content {
  flex: auto; }

.landing-page .large-feature.powerful figure {
  margin: 0; }

.landing-page .large-feature.powerful iframe {
  flex: auto;
  height: 430px;
  width: 100%;
  border: none; }

.landing-page .large-feature.powerful.bmi iframe {
  height: 477px; }

.landing-page .large-feature.reports {
  width: 100%;
  position: relative; }
  .landing-page .large-feature.reports figure {
    margin: 40px 0; }
    .landing-page .large-feature.reports figure img {
      width: 100%;
      height: auto; }

.landing-page .large-feature.app {
  margin-bottom: 50px; }
  .landing-page .large-feature.app .container {
    flex-direction: column; }
  .landing-page .large-feature.app .content {
    margin-bottom: 24px; }
  .landing-page .large-feature.app img {
    filter: drop-shadow(0 0 30px #333);
    width: 100%;
    height: auto; }

.landing-page .large-feature.iframe figure {
  max-width: 340px;
  margin: 0; }

.landing-page .large-feature.iframe .demonstration {
  font-size: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  margin-bottom: 8px; }
  .landing-page .large-feature.iframe .demonstration .space {
    flex: 1; }
  .landing-page .large-feature.iframe .demonstration .update-action {
    width: 100%; }
  .landing-page .large-feature.iframe .demonstration button {
    display: none;
    font-size: 24px;
    position: relative;
    top: -1px;
    width: 40px;
    height: 40px; }

.landing-page .large-feature.creator {
  margin-bottom: 50px; }
  .landing-page .large-feature.creator figure {
    position: relative; }
    .landing-page .large-feature.creator figure img {
      width: 100%;
      height: auto; }
    .landing-page .large-feature.creator figure video {
      position: absolute;
      top: 18.4%;
      left: 12.3%;
      width: 76.1%;
      height: auto; }

@media (min-width: 768px) {
  .landing-page .large-feature.powerful {
    margin-top: 80px;
    margin-bottom: 80px; }
    .landing-page .large-feature.powerful .content {
      padding-right: 20px; }
    .landing-page .large-feature.powerful iframe {
      width: 336px;
      position: relative;
      left: -10px;
      transform: scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg);
      box-shadow: 1px 1px 5px 0 rgba(26, 26, 67, 0.05), 39px 62.5px 86px -25px rgba(50, 50, 93, 0.5), 23.4px 37.5px 39px -37.5px rgba(0, 0, 0, 0.6); }
  .landing-page .large-feature.reports {
    margin-top: 80px;
    margin-bottom: 80px; }
    .landing-page .large-feature.reports .content {
      width: 730px; }
    .landing-page .large-feature.reports .container {
      margin-top: 50px;
      margin-bottom: 80px; }
    .landing-page .large-feature.reports figure {
      margin: 16px 40px; }
  .landing-page .large-feature.app {
    margin-top: 100px;
    margin-bottom: 100px; }
    .landing-page .large-feature.app .container {
      flex-direction: row-reverse; }
    .landing-page .large-feature.app .content {
      margin-top: 100px;
      margin-bottom: 0; }
    .landing-page .large-feature.app img {
      width: 350px; }
  .landing-page .large-feature.iframe {
    margin-top: 80px;
    margin-bottom: 80px; }
    .landing-page .large-feature.iframe figure {
      max-width: 340px;
      margin: 8px; }
    .landing-page .large-feature.iframe .update-action {
      width: auto; }
    .landing-page .large-feature.iframe button {
      margin-right: 16px; }
  .landing-page .large-feature.creator {
    margin-bottom: 80px; } }

@media (min-width: 1200px) {
  .landing-page .large-feature.powerful .content {
    padding-right: 0; }
  .landing-page .large-feature.powerful iframe {
    left: 80px; }
  .landing-page .large-feature.app img {
    position: relative;
    right: 50px; }
  .landing-page .large-feature.creator {
    max-width: 80%; } }

.landing-page .testimonial {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px; }
  .landing-page .testimonial.larger-margins {
    margin-top: 100px;
    margin-bottom: 100px; }
  .landing-page .testimonial blockquote {
    font-size: 20px;
    line-height: 1.7;
    font-style: normal;
    margin: 1.5em 10px;
    margin-top: 60px;
    quotes: "“" "”" "‘" "’"; }
    .landing-page .testimonial blockquote::before {
      content: open-quote;
      color: #ccc;
      font-size: 5em;
      line-height: 0.1em;
      margin-right: 0.25em;
      vertical-align: -0.4em;
      left: -0.15em;
      top: 0.1em; }
    .landing-page .testimonial blockquote::after {
      content: close-quote;
      color: #ccc;
      font-size: 5em;
      line-height: 0.1em;
      margin-left: 0.25em;
      vertical-align: -0.4em;
      right: -0.15em;
      bottom: -0.5em;
      position: absolute; }
  .landing-page .testimonial cite {
    line-height: 1.7;
    font-weight: 400;
    font-style: normal; }
    .landing-page .testimonial cite .name {
      font-size: 24px;
      color: rgb(33,150,243); }
    .landing-page .testimonial cite .title {
      color: #666; }

@media (min-width: 768px) {
  .landing-page .testimonial {
    max-width: 700px; }
    .landing-page .testimonial blockquote {
      font-size: 23px;
      padding-top: 0.7em;
      padding-left: 25px;
      padding-right: 25px;
      padding-bottom: 15px;
      margin-top: 1.5em; }
      .landing-page .testimonial blockquote::before {
        left: -0.35em;
        top: 0.4em; }
      .landing-page .testimonial blockquote::after {
        right: -0.35em;
        bottom: -0.2em; } }

.landing-page .message {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  margin: 0px 20px; }
  .landing-page .message .container {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-style: normal;
    line-height: 1.5;
    margin-bottom: 25px;
    margin-top: 25px;
    padding: 8px; }
    .landing-page .message .container .icon {
      font-size: 72px;
      color: #888;
      margin-right: 18px; }

@media (min-width: 768px) {
  .landing-page .message {
    margin-top: 40px;
    margin-bottom: 60px; }
    .landing-page .message .container {
      max-width: 700px;
      text-align: left; } }

.landing-page .bottom {
  background-color: rgb(33,150,243);
  color: white;
  text-align: center;
  align-self: normal; }
  .landing-page .bottom .last-container {
    margin-top: 10px;
    padding-top: 20px;
    padding-left: 16px;
    padding-right: 16px; }
  .landing-page .bottom h1 {
    margin-bottom: 8px; }
  .landing-page .bottom .buttons {
    margin-top: 32px;
    margin-bottom: 32px; }
    .landing-page .bottom .buttons > div .mdl-button {
      width: 100%;
      margin-bottom: 16px; }
    .landing-page .bottom .buttons > div:last-of-type .mdl-button {
      margin-bottom: 0; }

@media (max-width: 767px) {
  .landing-page .bottom button {
    width: 100% !important;
    margin-left: 0 !important; } }

@media (min-width: 768px) {
  .landing-page .bottom .last-container {
    margin: 0 auto;
    margin-bottom: 40px;
    max-width: 580px; }
  .landing-page .bottom .container {
    margin-top: 50px;
    padding-bottom: 20px; }
  .landing-page .bottom .buttons {
    display: flex;
    margin-top: 36px;
    margin-bottom: 0;
    justify-content: center; }
    .landing-page .bottom .buttons > div {
      display: inline-block !important;
      padding-right: 10px; }
      .landing-page .bottom .buttons > div .mdl-button {
        margin-bottom: 0; }
      .landing-page .bottom .buttons > div:last-of-type {
        padding-right: 0; } }

@media (min-width: 768px) {
  .landing-page .bottom::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-right: 100vw solid rgb(33,150,243);
    border-top: 100px solid white; } }

.health-care .large-feature.powerful .content {
  flex: auto; }

.health-care .large-feature.powerful figure {
  margin: 0; }

.health-care .large-feature.powerful iframe {
  flex: auto;
  height: 430px;
  width: 100%;
  border: none; }

.health-care .large-feature.reports {
  background-color: #f4f7fb;
  width: 100%;
  position: relative; }
  .health-care .large-feature.reports figure {
    margin: 40px 0; }
    .health-care .large-feature.reports figure img {
      width: 100%;
      height: auto; }

.health-care .large-feature.app {
  margin-bottom: 50px; }
  .health-care .large-feature.app .container {
    flex-direction: column; }
  .health-care .large-feature.app .content {
    margin-bottom: 24px; }
  .health-care .large-feature.app img {
    filter: drop-shadow(0 0 30px #333);
    width: 100%;
    height: auto; }

.health-care .large-feature.creator {
  margin-bottom: 50px; }
  .health-care .large-feature.creator figure {
    position: relative; }
    .health-care .large-feature.creator figure img {
      width: 100%;
      height: auto; }
    .health-care .large-feature.creator figure video {
      position: absolute;
      top: 18.4%;
      left: 12.3%;
      width: 76.1%;
      height: auto; }

@media (min-width: 768px) {
  .health-care .large-feature.powerful {
    margin-top: 80px; }
    .health-care .large-feature.powerful .content {
      padding-right: 20px; }
    .health-care .large-feature.powerful iframe {
      width: 336px;
      position: relative;
      left: -10px;
      transform: scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg);
      box-shadow: 1px 1px 5px 0 rgba(26, 26, 67, 0.05), 39px 62.5px 86px -25px rgba(50, 50, 93, 0.5), 23.4px 37.5px 39px -37.5px rgba(0, 0, 0, 0.6); }
  .health-care .large-feature.reports {
    margin-top: 80px;
    margin-bottom: 20px; }
    .health-care .large-feature.reports .content {
      width: 730px; }
    .health-care .large-feature.reports .container {
      margin-top: 50px;
      margin-bottom: 80px; }
    .health-care .large-feature.reports figure {
      margin: 16px 40px; }
  .health-care .large-feature.app {
    margin-top: 50px; }
    .health-care .large-feature.app .container {
      flex-direction: row-reverse; }
    .health-care .large-feature.app .content {
      margin-top: 100px;
      margin-bottom: 0; }
    .health-care .large-feature.app img {
      width: 350px; }
  .health-care .large-feature.creator {
    margin-bottom: 80px; } }

@media (min-width: 1200px) {
  .health-care .large-feature.powerful .content {
    padding-right: 0; }
  .health-care .large-feature.powerful iframe {
    left: 80px; }
  .health-care .large-feature.app img {
    position: relative;
    right: 50px; }
  .health-care .large-feature.creator {
    max-width: 80%; } }

@media (min-width: 768px) {
  .business-apps .large-feature.reports {
    margin-bottom: 0; } }

.learn-page div.main-container div.content {
  padding-top: 16px; }

@media (min-width: 768px) {
  .learn-page div.navigation {
    display: block; }
  .learn-page div.main-container div.content {
    padding-left: 337px;
    padding-right: 30px; } }

@media (min-width: 1000px) {
  .learn-page div.main-container div.content {
    padding-left: 380px;
    padding-right: 80px; } }

.blog-index-page .excerpt {
  font-size: 18px;
  margin-bottom: 16px; }

.blog-index-page .container > .mdl-grid {
  margin-top: 40px; }

.blog-index-page .pagination {
  text-align: right; }

.blog-index-page .sidebar h1 {
  padding-top: 4px; }

.blog-post-page h1 {
  line-height: 1.3; }

.blog-post-page pre {
  white-space: pre-line; }

.blog-post-page .excerpt {
  margin-bottom: 24px;
  margin-top: 25px;
  font-size: 22px;
  line-height: 1.4;
  font-style: italic;
  color: #666;
  border-left: 5px solid rgb(194,24,91);
  padding-left: 25px; }

.blog-post-page .container > .mdl-grid {
  margin-top: 20px; }

.blog-post-page hr {
  margin-top: 22px;
  margin-bottom: 60px;
  overflow: visible;
  text-align: center;
  border: none; }
  .blog-post-page hr:before {
    content: "...";
    display: inline-block;
    font-weight: 400;
    font-style: italic;
    font-size: 30px;
    letter-spacing: .6em;
    color: rgba(0, 0, 0, 0.68); }

.blog-page div.navigation {
  display: block; }

.blog-page div.main-container div.content {
  padding-top: 30px; }

.blog-page ul.post-list {
  list-style-type: none;
  padding-left: 0; }
  .blog-page ul.post-list h2 {
    margin-bottom: 5px; }
    .blog-page ul.post-list h2 a {
      text-decoration: none;
      font-weight: 300;
      font-size: 34px;
      line-height: 1.3; }
  .blog-page ul.post-list .date-container {
    margin-bottom: 10px; }
  .blog-page ul.post-list li {
    margin-bottom: 40px; }

.blog-page article li {
  margin-bottom: 12px; }

.blog-page .date-container time {
  color: #888; }

.blog-page .mdl-button a {
  color: black; }

.blog-page .post-navigation {
  padding-bottom: 50px;
  display: flex;
  margin-top: 32px; }
  .blog-page .post-navigation a:nth-child(1) {
    flex: 1; }
  .blog-page .post-navigation a:nth-child(2) {
    flex: 1;
    text-align: right; }

.blog-page .sidebar h1 {
  font-size: 28px; }

.blog-page .sidebar h2 {
  font-size: 22px;
  font-weight: 300;
  margin-top: 40px; }

.blog-page .sidebar ul {
  list-style-type: none;
  padding-left: 0;
  margin-top: 0; }

@media (min-width: 768px) {
  .blog-page {
    /*
    .post-list, article {
      padding-right: 50px;
    }
*/ }
    .blog-page div.main-container div.content {
      padding-left: 337px;
      padding-right: 30px; }
    .blog-page .sidebar {
      padding: 16px; }
  .blog-post-page .excerpt {
    padding-left: 50px; } }

@media (min-width: 1000px) {
  .blog-page div.main-container div.content {
    padding-left: 370px;
    padding-right: 80px; } }

.pricing-page > .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px; }

.pricing-page .hide {
  display: none !important; }

.pricing-page .information {
  text-align: center;
  max-width: 600px;
  font-size: 24px;
  line-height: 34px;
  margin-top: 60px;
  margin-bottom: 30px;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  padding: 20px 0; }

.pricing-page > .container > h1 {
  text-align: center;
  padding-bottom: 12px; }

.pricing-page .boxes {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 40px; }
  .pricing-page .boxes.plan-category {
    max-width: 788px; }

.pricing-page .box {
  display: flex;
  flex-direction: column;
  align-items: normal;
  position: relative;
  flex: 1;
  margin-top: 20px;
  height: auto;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  text-transform: none;
  text-align: left;
  overflow: hidden;
  background-color: rgb(250,250,250);
  transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out; }
  .pricing-page .box.has-footer {
    padding-bottom: 72px; }
  .pricing-page .box .icon {
    font-size: 50px;
    text-align: center;
    margin-top: 46px; }
  .pricing-page .box h1 {
    font-size: 28px;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 16px;
    padding-bottom: 10px; }
  .pricing-page .box ul {
    list-style: none; }
    .pricing-page .box ul li {
      padding-left: 0;
      margin-bottom: 12px; }
    .pricing-page .box ul li:last-of-type {
      margin-bottom: 0; }
    .pricing-page .box ul li.separator {
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      margin-top: 16px; }
  .pricing-page .box > div, .pricing-page .box ul {
    font-size: 18px;
    font-weight: 300;
    line-height: 28px;
    padding-left: 15px;
    padding-right: 15px; }
  .pricing-page .box .billed-annually {
    text-align: center;
    font-style: italic; }
  .pricing-page .box .footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 52px;
    padding: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    display: flex; }
    .pricing-page .box .footer > div {
      padding-left: 15px;
      padding-top: 12px;
      padding-bottom: 12px;
      line-height: 2;
      font-size: 14px;
      font-weight: 300; }

.pricing-page .plan-category .box {
  text-align: center; }

.pricing-page .plan-category.selected {
  background-color: rgb(197,17,98);
  color: white; }
  .pricing-page .plan-category.selected .footer {
    transform: translateY(0); }

.pricing-page .plan-category .footer {
  transform: translateY(52px);
  justify-content: center; }

.pricing-page .plans-container {
  width: 100%; }
  .pricing-page .plans-container .dynamic-controls-container {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 10px; }
    .pricing-page .plans-container .dynamic-controls-container > div {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      flex-direction: column; }
    .pricing-page .plans-container .dynamic-controls-container .pay-annually-container {
      margin-top: 10px;
      margin-bottom: 18px; }
    .pricing-page .plans-container .dynamic-controls-container.private {
      margin-top: 16px;
      margin-bottom: 16px;
      border-top: 1px solid lightgray;
      border-bottom: 1px solid lightgray;
      padding: 12px 20px; }
    .pricing-page .plans-container .dynamic-controls-container .mdl-switch__label {
      position: static;
      margin-left: 24px;
      font-size: 18px; }
  .pricing-page .plans-container .user-count-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; }
    .pricing-page .plans-container .user-count-container div.main {
      display: flex; }
    .pricing-page .plans-container .user-count-container .caption {
      display: flex;
      flex-direction: column;
      font-size: 20px;
      line-height: 28px; }
    .pricing-page .plans-container .user-count-container input {
      border: none;
      font-size: 36px;
      width: 70px;
      text-align: right;
      background-color: transparent;
      margin-left: 16px;
      margin-right: 4px;
      -moz-appearance: textfield; }
      .pricing-page .plans-container .user-count-container input::-webkit-outer-spin-button, .pricing-page .plans-container .user-count-container input::-webkit-inner-spin-button {
        -webkit-appearance: none; }
    .pricing-page .plans-container .user-count-container calcapp-stepper {
      padding-top: 4px;
      padding-bottom: 4px; }
      .pricing-page .plans-container .user-count-container calcapp-stepper button {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        height: 24px;
        min-width: 36px;
        padding: 0; }
        .pricing-page .plans-container .user-count-container calcapp-stepper button i.mdi {
          line-height: 10px; }
  .pricing-page .plans-container div.many-users {
    margin-top: 16px;
    line-height: 22px;
    text-align: center; }

.pricing-page .plans-container {
  position: relative; }

.pricing-page .plans {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .pricing-page .plans > h1 {
    text-align: center; }
  .pricing-page .plans .boxes .box.best-value {
    background-color: #263238;
    color: white; }
    .pricing-page .plans .boxes .box.best-value .header {
      width: 100%;
      height: 46px;
      text-align: center;
      font-size: 14px;
      color: lightgray;
      padding-top: 14px;
      letter-spacing: 5px;
      text-transform: uppercase; }
    .pricing-page .plans .boxes .box.best-value li.separator {
      border-top: 1px solid rgba(255, 255, 255, 0.2); }
  .pricing-page .plans .footer {
    justify-content: center;
    border-top: none; }
  .pricing-page .plans .price {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 12px;
    padding-left: 15px;
    padding-right: 15px; }
    .pricing-page .plans .price .amount {
      font-size: 48px;
      line-height: 1;
      margin: 0 auto;
      font-weight: 400;
      position: relative; }
      .pricing-page .plans .price .amount.smaller-size {
        font-size: 33px;
        top: -2px; }
    .pricing-page .plans .price .qualifier {
      margin: 0 auto;
      margin-top: 4px; }
  .pricing-page .plans .enterprise-contact-us {
    display: flex;
    flex-direction: column;
    margin-top: 14px;
    margin-bottom: 12px;
    padding-left: 15px;
    padding-right: 15px; }
    .pricing-page .plans .enterprise-contact-us .label {
      font-size: 38px;
      line-height: 1;
      margin: 0 auto;
      font-weight: 400; }
    .pricing-page .plans .enterprise-contact-us .email {
      margin: 0 auto;
      margin-top: 8px; }
      .pricing-page .plans .enterprise-contact-us .email a {
        color: inherit;
        font-weight: 300; }
  .pricing-page .plans select {
    display: block;
    font-size: 18px;
    font-weight: 300;
    font-family: inherit;
    color: #444;
    line-height: 1.3;
    padding: .6em 1.6em .5em .8em;
    box-sizing: border-box;
    margin: 0;
    border: none;
    appearance: none;
    background-color: #fff;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23444%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat, repeat;
    background-position: right .5em top 50%, 0 0;
    background-size: .65em auto, 100%; }
    .pricing-page .plans select::-ms-expand {
      display: none; }
    .pricing-page .plans select:hover {
      border-color: #888; }
    .pricing-page .plans select:focus {
      outline: none; }

.pricing-page.fully-loaded .plan-category .footer {
  transition: transform 0.3s ease-in-out; }

.pricing-page.fully-loaded .box ul li {
  transition: height 0.15s ease-in; }

.pricing-page .questions > .container {
  display: flex;
  flex-direction: column; }
  .pricing-page .questions > .container h1 {
    font-size: 30px;
    font-weight: 400;
    line-height: 140%;
    padding-top: 8px; }
  .pricing-page .questions > .container .column {
    flex: 1; }

@media (min-width: 768px) {
  .pricing-page > .container .boxes {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0; }
    .pricing-page > .container .boxes .box {
      margin: 20px; }
      .pricing-page > .container .boxes .box.starter ul {
        padding-right: 25px; }
        .pricing-page > .container .boxes .box.starter ul li.separator {
          width: calc(100% + 10px); }
      .pricing-page > .container .boxes .box.business ul {
        padding-right: 20px; }
        .pricing-page > .container .boxes .box.business ul li.separator {
          width: calc(100% + 5px); }
  .pricing-page > .container li.additional-users {
    padding-right: 10px; }
  .pricing-page > .container .dynamic-controls-container {
    width: 350px; }
    .pricing-page > .container .dynamic-controls-container > div {
      flex-direction: row; }
    .pricing-page > .container .dynamic-controls-container.private {
      width: 700px; }
      .pricing-page > .container .dynamic-controls-container.private .currency-container {
        top: -1px; }
    .pricing-page > .container .dynamic-controls-container .user-count-container {
      order: 1; }
    .pricing-page > .container .dynamic-controls-container .pay-annually-container {
      order: 2;
      margin: 0; }
    .pricing-page > .container .dynamic-controls-container .currency-container {
      order: 3;
      position: relative;
      top: -2px; }
  .pricing-page > .container .plans .box {
    min-width: 290px;
    max-width: 290px; }
    .pricing-page > .container .plans .box:not(.best-value) {
      margin-top: 60px; }
    .pricing-page > .container .plans .box li {
      display: flex;
      min-height: 29px; }
      .pricing-page > .container .plans .box li .spacer {
        flex-grow: 1; }
      .pricing-page > .container .plans .box li a {
        color: #bbb;
        font-size: 26px; }
    .pricing-page > .container .plans .box li.additional-users {
      display: block; }
    .pricing-page > .container .plans .box li.separator {
      min-height: 0; }
  .pricing-page .questions > .container {
    flex-direction: row; }
    .pricing-page .questions > .container .column:nth-child(1) {
      margin-right: 40px; }
    .pricing-page .questions > .container .column:nth-child(2) {
      margin-left: 40px; } }

@media (min-width: 1024px) and (max-width: 1199px) {
  .pricing-page > .container {
    max-width: 1024px; } }

.container.legal {
  padding: 24px;
  padding-top: 90px !important;
  max-width: 768px; }

.about-page .container {
  width: 100%; }

.about-page .inner-container {
  max-width: 800px;
  padding: 16px; }

.unsubscribe-page > .container {
  width: 650px; }

.unsubscribe-page .mdl-textfield {
  width: 100%; }

.unsubscribe-page .fine-print {
  font-size: 12px;
  line-height: 18px; }

.unsubscribe-page .select-container {
  margin-bottom: 20px; }
  .unsubscribe-page .select-container select {
    background-color: white;
    height: 30px; }

.unsubscribe-page .textarea-container {
  margin-top: 10px; }
